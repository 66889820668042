import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { YouTube } from "@mui/icons-material";

import { Translate } from "react-auto-translate";

import isEmail from "validator/lib/isEmail";

import { postHubspotSubscribe } from "../../../api/database";

import { ButtonContainedGreen, LogoItem } from "../../common";

import { XIcon, LinkedInIcon, EmailIcon } from "../../../utils/icons/";

import { constants, pagesWebsiteLeft } from "../../../constants";

import { AICPASOCLogo, ISO27001Logo } from "../../../utils/images/logos";

import { spacing } from "../../../styles";

import "./footer.css";

function Footer() {
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const handleClickSubmitSubscribe = async (event) => {
    event.preventDefault();

    setMessageError("");
    setMessageSuccess("");
    setIsErrorEmail(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError("Please enter a valid email.");
    } else {
      const requestBody = {
        email,
      };

      try {
        const hubspotSubscribe = await postHubspotSubscribe(requestBody);

        if (hubspotSubscribe && hubspotSubscribe.error) {
          setMessageError(constants.ERROR_DEFAULT);
        } else {
          setMessageSuccess(
            "Your subscription has been successfully processed.",
          );
        }
      } catch (error) {
        setMessageError(
          "There was an issue processing your request. Please try again later.",
        );
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        marginTop="160px"
        padding="0 30px"
      >
        <Box
          borderRadius="10px"
          maxWidth={{
            xs: "100%",
            md: spacing.MAX_WIDTH_WEBSITE,
          }}
          width="100%"
          sx={{
            boxShadow: "1px 1px 20px rgba(0, 255, 130, 0.35)",
          }}
        >
          <Box className="footer-box-main" padding="20px">
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              justifyContent="space-between"
            >
              <Box>
                <Box maxWidth="450px" width="100%">
                  <Box>
                    <Typography variant="h3" component="h3">
                      <Translate>Contact Us</Translate>
                    </Typography>
                  </Box>
                  <Box marginTop="10px">
                    <Typography variant="body-gray" component="p">
                      <Translate>
                        Reach out to our team for expert assistance and to find
                        out more about Dune Security.
                      </Translate>
                    </Typography>
                  </Box>
                  <div className="mt-1.5">
                    <Link to="/company/contact-us/">
                      <ButtonContainedGreen title="Get in touch">
                        Get in touch
                      </ButtonContainedGreen>
                    </Link>
                  </div>
                </Box>
                <form
                  onSubmit={handleClickSubmitSubscribe}
                  className="flex flex-wrap justify-start items-center mt-2.5"
                >
                  <input
                    type="email"
                    className={`bg-transparent w-full md:w-2/3 mr-2 text-h4.5 border-b ${
                      isErrorEmail ? "border-red-500" : "border-white"
                    } border-opacity-35 hover:border-opacity-50 text-gray focus:outline-none focus:border-opacity-50 mb-1 md:mb-0`}
                    placeholder="Sign up for our newsletter"
                    aria-label="Sign up for our newsletter"
                    name="email"
                  />
                  <ButtonContainedGreen title="Sign up">
                    Sign Up
                  </ButtonContainedGreen>
                </form>

                {messageError && (
                  <Box marginTop="10px">
                    <Typography variant="body2" color="error">
                      <Translate>{messageError}</Translate>
                    </Typography>
                  </Box>
                )}
                {messageSuccess && (
                  <Box marginTop="10px">
                    <Typography variant="body2" color="success">
                      {messageSuccess}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                marginTop={{
                  xs: "60px",
                  md: 0,
                }}
              >
                <Stack
                  direction={{
                    xs: "column",
                    md: "row",
                  }}
                >
                  <Box
                    marginLeft={{
                      xs: 0,
                      md: "20px",
                    }}
                  >
                    <Box>
                      <LogoItem height="40px" />
                    </Box>
                    <Box marginTop="10px">
                      <Typography variant="body-gray" component="p">
                        <Translate>User Adaptive Risk Management</Translate>
                      </Typography>
                    </Box>
                    <Box marginTop="15px">
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          className="footer-icon-button"
                          title="Go to Dune Security's LinkedIn page."
                          aria-label="Go to Dune Security's LinkedIn page."
                          href="https://www.linkedin.com/company/dune-security/"
                          target="_blank"
                        >
                          <LinkedInIcon role="button" />
                        </IconButton>
                        <IconButton
                          className="footer-icon-button"
                          title="Go to Dune Security's YouTube page."
                          aria-label="Go to Dune Security's YouTube page."
                          href="https://www.youtube.com/@DuneSecurity"
                          target="_blank"
                        >
                          <YouTube role="button" />
                        </IconButton>
                        <IconButton
                          className="footer-icon-button"
                          title="Go to Dune Security's X page."
                          aria-label="Go to Dune Security's X page."
                          href="https://x.com/DuneSecurity"
                          target="_blank"
                        >
                          <XIcon role="button" />
                        </IconButton>
                        <IconButton
                          component="a"
                          href="mailto:marketing@dune.security"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="footer-icon-button"
                          title="Go to Dune Security's email."
                          aria-label="Go to Dune Security's email."
                        >
                          <EmailIcon role="button" />
                        </IconButton>
                      </Stack>
                    </Box>
                    <div
                      role="heading"
                      aria-level="6"
                      className="text-white mt-0.5 text-h6"
                    >
                      <Translate>New York, NY</Translate>
                    </div>
                  </Box>
                  <Box
                    marginTop={{
                      xs: "20px",
                      md: 0,
                    }}
                  >
                    <Stack
                      direction={{
                        xs: "column",
                        md: "row",
                      }}
                    >
                      {pagesWebsiteLeft.map((page, pageIndex) => (
                        <Box
                          key={`footer-page-${pageIndex}`}
                          marginLeft={{
                            xs: 0,
                            md: "50px",
                          }}
                          marginTop={{
                            xs: pageIndex > 0 ? "40px" : 0,
                            md: 0,
                          }}
                        >
                          <Stack direction="column">
                            <NavLink
                              variant="text"
                              className="footer-button-main-page"
                              to={page.path}
                              title={page.tooltip}
                              key={`footer-page-${pageIndex}`}
                            >
                              <Translate>{page.name}</Translate>
                            </NavLink>
                            {page.subpages &&
                              page.subpages.map((subpage, subpageIndex) => (
                                <NavLink
                                  variant="text"
                                  className="footer-button-subpage"
                                  to={subpage.path}
                                  title={subpage.tooltip}
                                  key={`footer-page-${pageIndex}-subpage-${subpageIndex}`}
                                >
                                  <Translate>{subpage.name}</Translate>
                                </NavLink>
                              ))}
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
                <div className="mt-0.625 md:mt-1.5 md:flex justify-between items-end md:mx-1.25">
                  <div className="flex items-center space-x-0.25">
                    <Typography variant="detailed" component="p">
                      <Translate>
                        Copyright © {new Date().getFullYear()} Dune Security
                      </Translate>
                    </Typography>
                    <Typography variant="detailed" component="p">
                      <Link
                        className="dune-link copyright-link"
                        title="Go to the Privacy Policy page."
                        aria-label="Go to the Privacy Policy page."
                        to="/privacy-policy/"
                      >
                        <Translate>Privacy Policy</Translate>
                      </Link>{" "}
                      |{" "}
                      <Link
                        className="dune-link copyright-link"
                        title="Go to the Fulfillment Policy page."
                        aria-label="Go to the Fulfillment Policy page."
                        to="/fulfillment-policy/"
                      >
                        <Translate>Fulfillment Policy</Translate>
                      </Link>
                    </Typography>
                  </div>
                  <div className="flex my-1 md:my-0">
                    <div className="w-[78px] h-[78px]">
                      <ISO27001Logo />
                    </div>
                    <div className="w-[78px] h-[78px] ml-0.625">
                      <AICPASOCLogo />
                    </div>
                  </div>
                </div>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
