export const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "50%",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "25%",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "25%",
  },
];
