import React from "react";

function InProgressIcon() {
  return (
    <svg
      role="presentation"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_10603_16737"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="14"
      >
        <path
          d="M7.9987 1.3335C4.31203 1.3335 1.33203 4.3135 1.33203 8.00016C1.33203 11.6868 4.31203 14.6668 7.9987 14.6668C11.6854 14.6668 14.6654 11.6868 14.6654 8.00016C14.6654 4.3135 11.6854 1.3335 7.9987 1.3335ZM7.9987 13.3335C5.05203 13.3335 2.66536 10.9468 2.66536 8.00016C2.66536 5.0535 5.05203 2.66683 7.9987 2.66683C10.9454 2.66683 13.332 5.0535 13.332 8.00016C13.332 10.9468 10.9454 13.3335 7.9987 13.3335Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10603_16737)">
        <rect
          width="6.66667"
          height="13.3333"
          transform="matrix(-1 0 0 1 8 1.3335)"
          fill="#FFEA00"
        />
      </g>
      <mask
        id="mask1_10603_16737"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="14"
      >
        <path
          d="M8.0013 1.3335C11.688 1.3335 14.668 4.3135 14.668 8.00016C14.668 11.6868 11.688 14.6668 8.0013 14.6668C4.31464 14.6668 1.33463 11.6868 1.33463 8.00016C1.33463 4.3135 4.31464 1.3335 8.0013 1.3335ZM8.0013 13.3335C10.948 13.3335 13.3346 10.9468 13.3346 8.00016C13.3346 5.0535 10.948 2.66683 8.0013 2.66683C5.05464 2.66683 2.66797 5.0535 2.66797 8.00016C2.66797 10.9468 5.05464 13.3335 8.0013 13.3335Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_10603_16737)">
        <rect
          x="8"
          y="1.3335"
          width="6.66667"
          height="13.3333"
          fill="#232221"
        />
      </g>
    </svg>
  );
}

export default InProgressIcon;
