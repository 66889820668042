import React from "react";

const Avatar = ({
  size = "24px",
  padding = "p-[5px]",
  backgroundColor = "#4f4e4a",
  borderColor = "#0c0c0b",
  children,
}) => {
  return (
    <div
      className={`description flex justify-center items-center rounded-full w-[${size}] h-[${size}] ${padding} text-white text-[0.625rem]`}
      style={{ backgroundColor, border: `1px solid ${borderColor}` }}
    >
      {children}
    </div>
  );
};

export default Avatar;
