import React from "react";

import {
  ButtonOutlinedGray,
  DotLabelGreen,
  DotLabelOrange,
  DotLabelYellow,
  SortIcon,
} from "../..";

import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

import { truncate } from "../../../utils/helper-functions/truncate";
import { EyeIcon } from "../../../utils";

const ConfigureTestingTable = ({
  filteredData,
  sortData,
  toggleSwitch,
  toggleAssetViewer,
}) => {
  return (
    <div className="mt-1">
      <table className="max-w-full table-fixed">
        <thead>
          <tr className="border-b border-gray border-opacity-35">
            <th className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left">
              <div className="flex items-center space-x-0.313">
                <span>View</span>
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("title")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Name</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("is_active")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Status</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("impersonation")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Sender</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("fidelity")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Difficulty</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("motivational_factor")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Motive</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("method")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Method</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("vector")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Vector</span>
                <SortIcon />
              </div>
            </th>
            <th
              className="w-32 truncate text-gray uppercase tracking-custon-1.08 text-h6 text-left cursor-pointer"
              onClick={() => sortData("geography")}
            >
              <div className="flex items-center space-x-0.313">
                <span>Geography</span>
                <SortIcon />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((card, index) => (
            <tr
              key={index}
              className="h-4 border-b border-gray border-opacity-35"
            >
              <td className="w-32 overflow-hidden truncate">
                <div className="ml-0.25">
                  <ButtonOutlinedGray
                    className="ml-1"
                    padding="p-[5px]"
                    title="View this asset."
                    onClick={() =>
                      toggleAssetViewer(
                        card.content,
                        card.title,
                        card.file_name,
                      )
                    }
                  >
                    <div
                      tabIndex="0"
                      role="button"
                      className="w-[16px] h-[16px]"
                    >
                      <EyeIcon />
                    </div>
                  </ButtonOutlinedGray>
                </div>
              </td>
              <td className="w-32 overflow-hidden truncate">
                {capitalizeFirstWords(truncate(card.title || "N/A", 20))}
              </td>
              <td className="w-32 overflow-hidden truncate cursor-pointer">
                <div className="flex items-center">
                  <div className="w-3 text-h6">
                    {card.is_active ? "Active" : "Inactive"}
                  </div>
                  <div
                    onClick={() => toggleSwitch(index)}
                    className={`w-2.25 h-1.125 flex items-center rounded-full p-0.25 cursor-pointer transition-colors duration-300 ml-0.25 ${
                      card.is_active ? "bg-green" : "bg-gray"
                    }`}
                  >
                    <div
                      className={`bg-white w-0.75 h-0.75 rounded-full shadow-md transform transition-transform duration-300 ${
                        card.is_active ? "translate-x-1" : "-translate-x-0"
                      }`}
                    ></div>
                  </div>
                </div>
              </td>
              <td className="w-32 overflow-hidden truncate">
                {capitalizeFirstWords(
                  truncate(card.impersonation || "Unknown", 10),
                )}
              </td>
              <td className="w-32 overflow-hidden truncate">
                {card.fidelity === "easy" ? (
                  <DotLabelGreen label={capitalizeFirstWords(card.fidelity)} />
                ) : card.fidelity === "medium" ? (
                  <DotLabelYellow label={capitalizeFirstWords(card.fidelity)} />
                ) : (
                  <DotLabelOrange label={capitalizeFirstWords(card.fidelity)} />
                )}
              </td>
              <td className="w-32 overflow-hidden truncate">
                {capitalizeFirstWords(card.motivational_factor)}
              </td>
              <td className="w-32 overflow-hidden truncate">
                {truncate(capitalizeFirstWords(card.method), 15)}
              </td>
              <td className="w-32 overflow-hidden truncate">
                {capitalizeFirstWords(card.vector)}
              </td>
              <td className="w-32 overflow-hidden truncate">
                {card.geography === "international"
                  ? "🌎 International"
                  : card.geography === "united states"
                    ? "🇺🇸 United States"
                    : "🗽 New York"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ConfigureTestingTable;
