import React from "react";

import { OnboardingDashboard } from "../../../utils";

const OnboardingTemplate = ({ title, mainContent }) => {
  return (
    <div className="flex h-full min-h-[765px]">
      <div
        className="pt-2 pl-4 max-w-[572px] w-full"
        style={{
          background:
            "radial-gradient(114.47% 141.42% at 100% 100%, #006031 0%, #006031 18.55%, #0C0C0B 100%)",
        }}
      >
        <div className="pr-2">
          <h2 className="h2">{title}</h2>
        </div>
        <div className="mt-4">
          <OnboardingDashboard />
        </div>
      </div>
      <div className="w-full pt-2 pb-2 pl-4 pr-2">{mainContent}</div>
    </div>
  );
};

export default OnboardingTemplate;
