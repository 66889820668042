import { getResponseGet } from "../utils";

const getPostUserGuardRails = async () => {
  try {
    const response = await getResponseGet("/post-user-guardrails/");
    return response.data;
  } catch (error) {
    return {
      error: {
        message: error.message || "An error occurred while fetching data",
        details: error,
      },
    };
  }
};
export default getPostUserGuardRails;
