// Capitalize the first letter of each word in a string and leave the rest as is, with an exception of the word "bec" which should be capitalized as "BEC".
export const capitalizeFirstWords = (str) => {
  return str.replace(/\b\w+/g, (word) => {
    return word === "bec"
      ? "BEC"
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

// Capitalize the first letter of each word in a string and lowercase the rest, also checks if the word contains (bec) in parentheses and replaces it with (BEC).
export const capitalizeOnlyFirstWords = (str) => {
  return str
    .split(" ") // Split the string by spaces into an array of words.
    .map((word) => {
      // Capitalize the first letter of the word and lowercase the rest.
      const capitalizedWord =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

      // Check if the word contains "(bec)" and replace it with "(BEC)".
      return capitalizedWord.replace(/\(bec\)/gi, "(BEC)");
    })
    .join(" "); // Join the array back into a string.
};
