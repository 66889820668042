import React from "react";

import DotLabel from "../dot-label";

import { CircleFilledYellowIcon } from "../../../../utils/icons";

const DotLabelYellow = ({ label = "" }) => {
  return <DotLabel icon={<CircleFilledYellowIcon />} label={label} />;
};

export default DotLabelYellow;
