import React from "react";

import { SectionHeader } from "../../common";

import {
  CrowdstrikeBrandMarkIcon,
  MicrosoftEntraIDBrandMarkIcon,
  MicrosoftPurviewBrandMarkIcon,
} from "../../../../../../utils";

const HeaderUserActivity = () => {
  return (
    <SectionHeader
      title="User Activity"
      secondColumn={
        <div className="flex items-center gap-0.5">
          <div className="w-[24px]">
            <MicrosoftEntraIDBrandMarkIcon />
          </div>
          <div className="w-[24px]">
            <CrowdstrikeBrandMarkIcon />
          </div>
          <div className="w-[24px]">
            <MicrosoftPurviewBrandMarkIcon />
          </div>
        </div>
      }
    />
  );
};

export default HeaderUserActivity;
