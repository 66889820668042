import React, { useEffect, useState } from "react";
import { Modal } from "../../../../../..";
import { getDisplayAsset } from "../../../../../../../../api";
import { capitalizeFirstWords } from "../../../../../../../../utils/helper-functions/capitalize";

function AssetViewer({ isOpen, onCloseModal, title, fileName }) {
  const [contentHTML, setContentHTML] = useState("");

  const handleCloseModal = () => {
    setContentHTML("");
    onCloseModal();
  };

  useEffect(() => {
    const fetchAsset = async () => {
      const asset = await getDisplayAsset(fileName);

      if (asset && asset.content) {
        setContentHTML(asset.content);
      }
    };

    if (fileName) {
      fetchAsset();
    }
  }, [fileName]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={capitalizeFirstWords(title)}
    >
      <iframe
        className="bg-white border-none rounded-b-small"
        title={title}
        srcDoc={contentHTML}
        height="500px"
        width="100%"
      ></iframe>
    </Modal>
  );
}

export default AssetViewer;
