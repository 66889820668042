import { getResponsePost } from "../utils";

export const postRequestNewCustomAsset = async (requestBody) => {
  let postRequestNewCustomAssetResponse = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost(
      "/upload-assets/",
      requestBody,
      "multipart/form-data",
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.result) {
      postRequestNewCustomAssetResponse.result = data.result;
    }
  } catch (error) {
    postRequestNewCustomAssetResponse.error = error;
  }

  return postRequestNewCustomAssetResponse;
};

export default postRequestNewCustomAsset;
