import React from "react";

import { Divider } from "../../../../../components";

const CustomTwoColumn = ({ firstColumn, secondColumn }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between max-w-[830px] my-2">
        <div className="max-w-[312px] w-full">{firstColumn}</div>
        <div className="mt-2 md:mt-0 md:ml-2 md:max-w-[456px] w-full">
          {secondColumn}
        </div>
      </div>
      <Divider />
    </>
  );
};

export default CustomTwoColumn;
