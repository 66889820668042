import React from "react";

import Button from "../button";

const ButtonOutlinedGreen = ({
  children,
  padding,
  title,
  isSmall,
  isEndButton,
  isRounded,
  type,
  disabled,
  onClick,
}) => {
  return (
    <Button
      padding={padding}
      title={title}
      isSmall={isSmall}
      isEndButton={isEndButton}
      isRounded={isRounded}
      type={type}
      disabled={disabled}
      onClick={onClick}
      borderColor="green"
      textColor="green"
    >
      {children}
    </Button>
  );
};

export default ButtonOutlinedGreen;
