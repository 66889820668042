export const USER_ACTIVITY_HEATMAP = [
  {
    UserEmail: "kristinagrimes@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "kristinagrimes@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "kristinagrimes@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "kristinagrimes@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "lensexton@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "lensexton@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "lensexton@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "lensexton@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "lensexton@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "asiacladd@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "enochbediako@synovus.com",
    Latitude: 33.7485,
    Longitude: -84.3871,
  },
  {
    UserEmail: "wywantalias@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "anisahardin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "migdaliavelez-mieles@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "migdaliavelez-mieles@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "migdaliavelez-mieles@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "migdaliavelez-mieles@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "josephpellerinjr@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "arlindaechols@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "aidanajarian@synovus.com",
    Latitude: 33.7468,
    Longitude: -84.3137,
  },
  {
    UserEmail: "alfredhandy@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "paulbush@synovus.com",
    Latitude: 40.4058,
    Longitude: -111.841,
  },
  {
    UserEmail: "sabrinafitzpatrick@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "sabrinafitzpatrick@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "sabrinafitzpatrick@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "sabrinafitzpatrick@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "josephcomerford@synovus.com",
    Latitude: 30.5012,
    Longitude: -87.8795,
  },
  {
    UserEmail: "clintoncarter@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amandadeboer@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amandadeboer@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amandadeboer@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amandadeboer@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "jillsierra@synovus.com",
    Latitude: 32.3012,
    Longitude: -90.1834,
  },
  {
    UserEmail: "susanstuckey@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "gradysaunders@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "antoniogamble@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "antoniogamble@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 33.7574,
    Longitude: -84.4236,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "scottvarela@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "aliciadouglas@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "aliciadouglas@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "aliciadouglas@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "aliciadouglas@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "milisameece@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "milisameece@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "milisameece@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "milisameece@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "gildacoker@synovus.com",
    Latitude: 33.4166,
    Longitude: -86.7795,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "markpurcell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "markpurcell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "markpurcell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "markpurcell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "amandatodd@synovus.com",
    Latitude: 28.5274,
    Longitude: -81.6012,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "garymunn@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "roberthardell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "roberthardell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "roberthardell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "roberthardell@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "archiethompson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "amandadeboer@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "albertfils@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "albertfils@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "albertfils@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "albertfils@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "bradyjohnson@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "christopherwhitlock@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "sarithasiddamshetty@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "asiacladd@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "zenoviatoledo@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "zenoviatoledo@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "zenoviatoledo@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "zenoviatoledo@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allenprewitt@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allenprewitt@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allenprewitt@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allenprewitt@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amycowart@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amycowart@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amycowart@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "amycowart@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "claireaustin@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "lmassey@synovus.com",
    Latitude: 29.9734,
    Longitude: -90.0885,
  },
  {
    UserEmail: "lmassey@synovus.com",
    Latitude: 29.9734,
    Longitude: -90.0885,
  },
  {
    UserEmail: "lmassey@synovus.com",
    Latitude: 29.9734,
    Longitude: -90.0885,
  },
  {
    UserEmail: "lmassey@synovus.com",
    Latitude: 29.9734,
    Longitude: -90.0885,
  },
  {
    UserEmail: "jillianmcnamara@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "jillianmcnamara@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "jillianmcnamara@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "jillianmcnamara@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "meredithlockerman@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "meredithlockerman@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "meredithlockerman@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "meredithlockerman@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "heatherhile@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allisonsullivan@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allisonsullivan@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allisonsullivan@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "allisonsullivan@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "gregorywilliams@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "gregorywilliams@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "gregorywilliams@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "gregorywilliams@synovus.com",
    Latitude: 32.4783,
    Longitude: -84.8985,
  },
  {
    UserEmail: "mandynolen@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "mandynolen@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "mandynolen@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "mandynolen@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "alijahreeves@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "alijahreeves@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "alijahreeves@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
  {
    UserEmail: "alijahreeves@synovus.com",
    Latitude: 36.6676,
    Longitude: -78.3875,
  },
];
