import { getResponsePatch } from "../utils";

export const patchChangeAssetStatus = async (requestBody) => {
  let changeAssetStatus = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePatch(
      "/change-asset-status",
      requestBody,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    changeAssetStatus.result = response;
  } catch (error) {
    changeAssetStatus.error = error;
  }
};

export default patchChangeAssetStatus;
