import { getResponsePost } from "../utils";

export const postTrainingDashboardData = async (requestBody = {}) => {
  let responseResult = {
    result: {},
    error: {},
  };

  try {
    // Send the request to the specified URL with the provided body
    const response = await getResponsePost("/training-dashboard", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    // Store the result if the request was successful
    responseResult.result = response.data;
  } catch (error) {
    // Store the error in case of failure
    responseResult.error = error;
  }

  return responseResult;
};

export default postTrainingDashboardData;
