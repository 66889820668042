import React from "react";

import Badge from "../badge";

const BadgeGreen = ({ text, icon }) => {
  return (
    <Badge
      background="bg-green"
      textColor="text-black"
      text={text}
      icon={icon}
    />
  );
};

export default BadgeGreen;
