import React from "react";

import Badge from "../badge";

const BadgeGray = ({ text, icon }) => {
  return (
    <Badge
      background="bg-gray"
      textColor="text-black"
      text={text}
      icon={icon}
    />
  );
};

export default BadgeGray;
