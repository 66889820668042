import React from "react";

const HRISIcon = () => {
  return (
    <svg
      role="presentation"
      width="31"
      height="21"
      viewBox="0 0 31 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.12021"
        y="1.02204"
        width="28.7067"
        height="18.7067"
        rx="2.85337"
        stroke="white"
        strokeWidth="0.815249"
      />
      <path
        d="M5.77469 13.8754H4.72085V6.99674H5.77469V9.87085H9.26193V6.99674H10.3158V13.8754H9.26193V10.7906H5.77469V13.8754ZM12.8664 13.8754H11.8126V6.99674H14.3801C15.9608 6.99674 16.9093 7.77275 16.9093 9.14274C16.9093 10.1295 16.2866 10.8864 15.2423 11.1355L17.1105 13.8754H15.8459L14.131 11.2887H12.8664V13.8754ZM12.8664 7.87813V10.4073H14.313C15.2902 10.4073 15.8459 9.97623 15.8459 9.14274C15.8459 8.31883 15.2902 7.87813 14.313 7.87813H12.8664ZM19.1816 13.8754H18.1277V6.99674H19.1816V13.8754ZM25.7022 11.8636C25.7022 13.0611 24.7441 13.9712 23.0772 13.9712C21.4198 13.9712 20.4138 13.0611 20.3276 11.6911H21.4006C21.4485 12.5629 22.0042 13.1282 23.058 13.1282C23.9298 13.1282 24.5813 12.7162 24.5813 12.0073C24.5813 11.4324 24.2076 11.145 23.4125 10.9726L22.4161 10.781C21.4485 10.5894 20.5671 10.1008 20.5671 8.93197C20.5671 7.77275 21.5826 6.90094 23.0101 6.90094C24.4376 6.90094 25.5201 7.77275 25.6064 9.14274H24.5334C24.4759 8.31883 23.8819 7.74401 23.0197 7.74401C22.1287 7.74401 21.6401 8.27093 21.6401 8.86491C21.6401 9.51637 22.2053 9.7463 22.8472 9.88043L23.8627 10.0816C25.0411 10.3211 25.7022 10.8385 25.7022 11.8636Z"
        fill="white"
      />
    </svg>
  );
};

export default HRISIcon;
