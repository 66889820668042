import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import InProgressIcon from "./utils/in-progress-icon/in-progress-icon";
import QuizDialog from "./utils/quiz-dialog/quiz-dialog";
import TrainingVideo from "./utils/training-video/training-video";
import { formatDateFive } from "../../../utils/helper-functions/formatDate";

import { getTrainings, postTrainingCompleted, getUser } from "../../../api";

import {
  ButtonContainedGreen,
  Divider,
  TitleWithBadge,
} from "../../../components";

import {
  CircleOutlinedIcon,
  GraduationCapFilledIcon,
  CheckmarkWithCircleIcon,
} from "../../../utils/icons";

import "./trainings-video-player.css";

function TrainingsVideoPlayer({ isInitialLoading, email, setNewTitle }) {
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [modules, setModules] = useState([]);
  const [category, setCategory] = useState(null);

  const [isClickedTakeQuiz, setIsClickedTakeQuiz] = useState(false);
  const [isComplianceModulesExisting, setIsComplianceModulesExisting] =
    useState(false);
  const [isCompletedModulesExisting, setIsCompletedModulesExisting] =
    useState(false);
  const [isQuizLocked, setIsQuizLocked] = useState(true);

  const [openDialogQuiz, setOpenDialogQuiz] = useState(false);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [errorMsg, setErrorMsg] = useState(
    "Complete the video to get your certificate.",
  );

  const navigate = useNavigate();

  const complianceFrameworks = [
    { name: "GDPR" },
    { name: "GLBA" },
    { name: "HIPAA" },
    { name: "ISO 27001" },
    { name: "NIST" },
    { name: "SOC 2 Type 2" },
  ];

  const { pathname, state } = useLocation();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUser();

      setUserFirstName(userInfo.result.firstName);
      setUserLastName(userInfo.result.lastName);
    };

    fetchUserInfo();
  }, []);

  const handleClickModule = (module) => {
    setSelectedVideo(module);
    setIsClickedTakeQuiz(false);
    setIsQuizLocked(module.status !== "in progress");
  };

  const handleClickTakeQuiz = () => {
    if (!isClickedTakeQuiz) {
      setIsClickedTakeQuiz(true);
    }
    if (!isQuizLocked) {
      setOpenDialogQuiz(true);
    }
  };

  const handleDownloadCertificate = () => {
    const allCompleted = modules.every(
      (module) => module.status === "completed",
    );

    if (allCompleted) {
      navigate("/certificate/", {
        state: {
          firstName: userFirstName,
          lastName: userLastName,
          trainingName: selectedVideo.category,
          date: formatDateFive(selectedVideo.startDate),
        },
      });
    } else {
      setErrorMsg(
        "Not all trainings in this compliance framework are completed. Cannot download certificate.",
      );
      console.log(
        "Not all trainings in this compliance framework are completed. Cannot download certificate.",
      );
    }
  };

  const handleCloseDialogQuiz = async () => {
    setOpenDialogQuiz(false);
    await loadTrainings();
  };

  const handleEndedVideo = async () => {
    if (selectedVideo.status !== "completed") {
      if (
        complianceFrameworks.some(
          (complianceFramework) =>
            complianceFramework.name === selectedVideo.category,
        )
      ) {
        const requestBody = {
          training_id: selectedVideo.id,
        };

        const videoTrainingCompletion =
          await postTrainingCompleted(requestBody);

        if (Object.keys(videoTrainingCompletion.error).length > 0) {
          console.error(videoTrainingCompletion.error.message);
        } else {
          const updatedSelectedVideo = { ...selectedVideo };
          updatedSelectedVideo.status = "completed";
          setSelectedVideo(updatedSelectedVideo);

          const updatedModules = [...modules];
          const selectedVideoIndex = updatedModules.findIndex(
            (module) => module.id === selectedVideo.id,
          );

          if (selectedVideoIndex !== -1) {
            updatedModules[selectedVideoIndex].status = "completed";
          }

          setModules(updatedModules);
        }
      } else {
        setOpenDialogQuiz(true);
        setIsQuizLocked(false);
      }
    }
  };

  const loadTrainings = useCallback(
    async (isFirstTime = false) => {
      const trainings = await getTrainings();
      console.log("trainings", trainings);
      if (!trainings.error) {
        const { learningModules, complianceModules, completedModules } =
          trainings.result;
        if (pathname.includes("compliance")) {
          if (!complianceModules?.length) {
            setIsComplianceModulesExisting(true);
          } else {
            setModules(complianceModules[0].videos);
            if (isFirstTime) {
              setSelectedVideo(complianceModules[0].videos[0]);
              setCategory(`${complianceModules[0].name} compliance`);
              setIsQuizLocked(
                complianceModules[0].videos[0].status !== "in progress",
              );
            }
          }
        } else if (pathname.includes("completed")) {
          if (!completedModules?.length) {
            setIsCompletedModulesExisting(true);
          } else {
            setModules(completedModules);
            if (isFirstTime) {
              setSelectedVideo(completedModules[0]);
              setCategory("Completed modules");
              setIsQuizLocked(completedModules[0].status !== "in progress");
            }
          }
        } else {
          setModules(learningModules);
          if (isFirstTime) {
            setSelectedVideo(learningModules[0]);
            setCategory("Learning modules");
            setIsQuizLocked(learningModules[0].status !== "in progress");
          }
        }
      }
    },
    [pathname],
  );

  useEffect(() => {
    if (!isInitialLoading && email) {
      if (state) {
        setSelectedVideo(state.selectedVideo);
        setModules(state.modules);
        setCategory(state.category);
        setIsQuizLocked(state.selectedVideo.status !== "in progress");
        if (email === "duncan.idaho@dune.demo") {
          // Demo account
          setIsQuizLocked(false);
        } else {
          setIsQuizLocked(state.selectedVideo.status !== "in progress");
        }
      } else {
        if (email === "duncan.idaho@dune.demo") {
          const modules = [
            {
              id: "0",
              status: "Not started",
              title: "Domain Spoofing",
              fileName: "domain-spoofing.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "1",
              status: "Not started",
              title: "Malicious Attachments",
              fileName: "malicious-attachments.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "2",
              status: "Not started",
              title: "Business Email Compromise",
              fileName: "business-email-compromise.mp4",
              startDate: "2024-06-18T00:00:00",
            },
          ];

          setSelectedVideo(modules[0]);
          setModules(modules);
          setCategory("Learning modules");
          setIsQuizLocked(false);
        } else {
          loadTrainings(true);
        }
      }
    }
  }, [isInitialLoading, email, loadTrainings, state]);

  useEffect(() => {
    if (selectedVideo.title) {
      setNewTitle(
        <TitleWithBadge
          title={selectedVideo.title}
          badgeText="Training"
          badgeIcon={<GraduationCapFilledIcon />}
        />,
      );
    }
  }, [selectedVideo, setNewTitle]);

  if (isComplianceModulesExisting || isCompletedModulesExisting) {
    return <Navigate to="/error/" />;
  }

  return (
    <>
      <div className="mt-0.625">
        <div className="flex flex-col">
          {selectedVideo && (
            <div>
              <div className="flex justify-center">
                <div className="w-[800px] h-[450px]">
                  <TrainingVideo
                    selectedVideo={selectedVideo}
                    pathname={pathname}
                    onEndedVideo={handleEndedVideo}
                  />
                </div>
              </div>
              <div className="max-w-[800px] mx-auto flex flex-col justify-end mt-0.625 md:flex-row">
                <div>
                  {!complianceFrameworks.some(
                    (framework) => framework.name === selectedVideo.category,
                  ) &&
                    selectedVideo.status !== "completed" && (
                      <div className="flex items-center">
                        {isQuizLocked && isClickedTakeQuiz && (
                          <p className="description text-green mr-0.625">
                            Complete the video to take the quiz.
                          </p>
                        )}
                        <div className="flex justify-start md:justify-end">
                          <ButtonContainedGreen onClick={handleClickTakeQuiz}>
                            Take quiz
                          </ButtonContainedGreen>
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {!complianceFrameworks.some(
                    (framework) => framework.name === selectedVideo.category,
                  ) &&
                    selectedVideo.status === "completed" && (
                      <div className="flex items-center">
                        {isQuizLocked && isClickedTakeQuiz && (
                          <p className="description text-green mr-0.625">
                            {errorMsg}
                          </p>
                        )}
                        <div className="flex justify-start md:justify-end">
                          <ButtonContainedGreen
                            onClick={handleDownloadCertificate}
                          >
                            Get your Certificate
                          </ButtonContainedGreen>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <div className="mt-0.625">
                <Divider />
              </div>
            </div>
          )}
        </div>
        <div className="mt-0.625">
          {modules && modules.length > 0 && category && (
            <div>
              <div>
                <h4 className="h4">{category}</h4>
                <p className="description mt-0.25">1 of {modules.length}</p>
              </div>
              <div className="mt-0.625">
                {modules.map((module, index) => (
                  <div
                    className="flex items-center cursor-pointer"
                    key={`modules-list-item-${index}`}
                    onClick={() => handleClickModule(module)}
                  >
                    {module.status === "in progress" ? (
                      <div className="w-[24px] h-[24px]">
                        <InProgressIcon />
                      </div>
                    ) : module.status === "completed" ? (
                      <div className="w-[24px] h-[24px]">
                        <CheckmarkWithCircleIcon />
                      </div>
                    ) : (
                      <div className="w-[24px] h-[24px]">
                        <CircleOutlinedIcon />
                      </div>
                    )}
                    <div className="w-full ml-0.625">
                      <p className="description">{module.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <QuizDialog
        openDialog={openDialogQuiz}
        selectedVideo={selectedVideo}
        email={email}
        onCloseDialogQuiz={handleCloseDialogQuiz}
      />
    </>
  );
}

export default TrainingsVideoPlayer;
