import { getResponseGet } from "../utils";

const getMSSPOrganizations = async () => {
  let msspOrganizationsData = {
    result: {
      msspOrganizations: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/mssp_company_list/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (
      data &&
      data.mssp_company_names &&
      Array.isArray(data.mssp_company_names)
    ) {
      const msspOrganizations = data.mssp_company_names.map((company) => {
        return {
          id: company.company_id,
          name: company.company_name,
          email: company.subordinate_email,
        };
      });

      msspOrganizations.sort((a, b) => a.name.localeCompare(b.name));

      msspOrganizationsData.result.msspOrganizations = msspOrganizations;
    }
  } catch (error) {
    msspOrganizationsData.error = error;
  }

  return msspOrganizationsData;
};

export default getMSSPOrganizations;
