import { capitalizeOnlyFirstWords } from "../../utils/helper-functions/capitalize";
import { getResponseGet } from "../utils";

const formatAssetName = (assetName) => {
  // Remove the file extension ".html".
  const nameWithoutExtension = assetName.replace(".html", "");

  // Split the string by dashes, capitalize each word, and join them with spaces.
  const formattedAssetName = nameWithoutExtension
    .split("-") // Split by dashes.
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word.
    .join(" "); // Join with spaces.

  return formattedAssetName;
};

const getTestAssetsByDate = async (selectedDate) => {
  let testAssetsByDateResponse = {
    result: {
      testAssets: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/get-upcoming-assets/?from=${selectedDate}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (
      data.results &&
      Array.isArray(data.results) &&
      data.results.length > 0
    ) {
      for (let i = 0; i < data.results.length; i++) {
        const asset = data.results[i];

        let recipients = [];

        if (
          asset.users &&
          Array.isArray(asset.users) &&
          asset.users.length > 0
        ) {
          for (let j = 0; j < asset.users.length; j++) {
            const recipient = asset.users[j];

            recipients.push({
              firstName: recipient.first_name || "",
              lastName: recipient.last_name || "",
              initials: `${
                recipient.first_name &&
                recipient.first_name.charAt(0).toUpperCase()
              }${
                recipient.last_name &&
                recipient.last_name.charAt(0).toUpperCase()
              }`,
              email: recipient.email,
              riskScore: recipient.latest_risk_score
                ? +recipient.latest_risk_score.toFixed()
                : 0,
            });
          }

          const sortedRecipients = recipients.sort((a, b) => {
            const firstNameA = a.firstName ? a.firstName.toLowerCase() : "";
            const firstNameB = b.firstName ? b.firstName.toLowerCase() : "";

            if (firstNameA < firstNameB) {
              return -1;
            }

            if (firstNameA > firstNameB) {
              return 1;
            }

            return 0; // If they are equal or both missing.
          });

          testAssetsByDateResponse.result.testAssets.push({
            assetName: asset.asset_name
              ? formatAssetName(asset.asset_name)
              : "",
            assetFileName: asset.asset_name || "",
            sender: asset.sender
              ? asset.sender === "company-name"
                ? "Company"
                : capitalizeOnlyFirstWords(asset.sender)
              : "",
            recipients: sortedRecipients,
            difficulty: asset.difficulty
              ? capitalizeOnlyFirstWords(asset.difficulty)
              : "",
            motive: asset.motivational_factor
              ? capitalizeOnlyFirstWords(asset.motivational_factor)
              : "",
            method: asset.method ? capitalizeOnlyFirstWords(asset.method) : "",
          });
        }
      }
    }
  } catch (error) {
    testAssetsByDateResponse.error = error;
  }

  return testAssetsByDateResponse;
};

export default getTestAssetsByDate;
