import { getResponsePost } from "../utils";

export const postAccessKey = async () => {
    let accessKeyData = {
        result: {
            accessKey: ""
        },
        error: {}
    };

    let requestBody = {};

    try {
        const response = await getResponsePost("/access-key/", requestBody);

        if (response.status !== 201) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        accessKeyData.result.accessKey = response.data.access_key;
    } catch (error) {
        accessKeyData.error = error;
    }

    return accessKeyData;
};

export default postAccessKey;