import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Fade,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { postCampaignLaunch } from "../../../constants/apis";

import "./campaigns.css";

function Campaigns({
  firstName,
  lastName,
  organization,
  isBRACompleted,
  startTimestamp,
}) {
  // Timer
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  // Launch
  const [hasRequestedLaunch, setHasRequestedLaunch] = useState(false);

  const handleClickRequestLaunch = async () => {
    await postCampaignLaunch();

    setHasRequestedLaunch(true);
  };

  useEffect(() => {
    const getTimerData = () => {
      const now = new Date();
      const nowInUTC = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
      );

      const endOfBRA = new Date(startTimestamp);
      endOfBRA.setDate(endOfBRA.getDate() + 30);

      const timeDifference = endOfBRA.getTime() - nowInUTC.getTime();

      const cd = 24 * 60 * 60 * 1000;
      const ch = 60 * 60 * 1000;
      let updatedDays = Math.floor(timeDifference / cd);
      let updatedHours = Math.floor((timeDifference - updatedDays * cd) / ch);
      let updatedMinutes = Math.round(
        (timeDifference - updatedDays * cd - updatedHours * ch) / 60000,
      );

      if (updatedMinutes === 60) {
        updatedHours++;
        updatedMinutes = 0;
      }

      if (updatedHours === 24) {
        updatedDays++;
        updatedHours = 0;
      }

      return {
        updatedDays,
        updatedHours,
        updatedMinutes,
      };
    };

    let interval = null;

    if (startTimestamp) {
      const { updatedDays, updatedHours, updatedMinutes } = getTimerData();

      setDays(updatedDays);
      setHours(updatedHours);
      setMinutes(updatedMinutes);

      interval = setInterval(() => {
        if (hours === 0 && minutes === 0) {
          setDays(days - 1);
          setHours(23);
          setMinutes(59);
        } else if (minutes === 0) {
          setHours(hours - 1);
          setMinutes(59);
        } else {
          setMinutes(minutes - 1);
        }
      }, 60000);
    }

    return () => {
      if (startTimestamp) {
        clearInterval(interval);
      }
    };
  }, [days, hours, minutes, startTimestamp]);

  const details = {
    "Time frame": "30 Days",
    Frequency: "4 simulations per week",
  };

  return (
    <>
      {hasRequestedLaunch && (
        <Fade
          in={hasRequestedLaunch}
          {...(hasRequestedLaunch ? { timeout: 2000 } : {})}
        >
          <Box marginTop="80px">
            <Typography
              role="heading"
              aria-level="2"
              component="h2"
              className="dune-text-header-dashboard"
            >
              Risk assessment will launch within 24 hours
            </Typography>
            <Typography component="p" marginTop="20px">
              Once it's launched, we'll begin the countdown.
            </Typography>
          </Box>
        </Fade>
      )}
      {isBRACompleted && (
        <>
          <Typography
            role="heading"
            aria-level="2"
            component="h2"
            className="dune-text-header-dashboard"
            marginTop="80px"
          >
            Post-baseline risk assessment
          </Typography>
          <Typography component="p" marginTop="20px">
            Here is an email template you can send to your team as an intro to
            Dune Security.
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="40px"
          >
            Subject: Welcome to Enhanced Security Awareness Training with Dune
            Security
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="40px"
          >
            Dear [Name],
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="20px"
          >
            As you may already know, {organization} is now partnering with Dune
            Security for a more efficient approach to cybersecurity training.
            This means more targeted learning, only training you on areas that
            need improvement and then letting you get back to work.
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="20px"
          >
            Expect to see regular, automated security tests, like simulated
            phishing emails. If you're caught by one, immediate feedback will
            help you learn and prepare better for next time. Your engagement
            will be reflected in a personal risk score, based on your
            cybersecurity behavior and information access.
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="20px"
          >
            Dune Security's platform zeroes in on your unique areas for
            improvement. Instead of hours of generic training, you'll receive
            quick customized modules focused precisely on the skills you need to
            enhance. This means more relevant, and less time-costly, learning
            for you, and better security for all of us at {organization}.
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="20px"
          >
            For any questions during this transition, feel free to reach out!
          </Typography>
          <Typography
            component="p"
            className="campaigns-text-email"
            marginTop="20px"
          >
            Best,
            <br />
            {firstName} {lastName}
          </Typography>
        </>
      )}
      {
        // If the baseline risk assessment has not started.
        (!startTimestamp || days < 0) && (
          <Grid container>
            <Grid item xs={12} marginTop="80px">
              <Typography
                role="heading"
                aria-level="2"
                component="h2"
                className="dune-text-header-dashboard"
              >
                Launch baseline risk assessment
              </Typography>
              <Typography component="p" marginTop="20px">
                Kickstart protection by identifying high-risk users and their
                vulnerabilities.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} marginTop="80px">
              {Object.keys(details).map((key, index) => (
                <Box
                  className="campaigns-box-text-field"
                  key={`details-${index}`}
                >
                  <Typography component="p">{key}</Typography>
                  <TextField
                    variant="outlined"
                    className="dune-text-field-outlined-gray campaigns-text-field"
                    placeholder="Name"
                    name="company-name"
                    value={details[key]}
                    type="text"
                    required
                    fullWidth
                    disabled
                  />
                </Box>
              ))}
              <Button
                aria-label="Request launch"
                variant="outlined"
                className="dune-button-outlined-white campaigns-button-launch"
                fullWidth
                onClick={handleClickRequestLaunch}
              >
                Request launch
              </Button>
            </Grid>
            <Grid item xs={12} marginTop="20px">
              <Typography component="p" className="campaigns-text-warning">
                You won't be able to add more users until the baseline risk
                assessment is complete.
              </Typography>
            </Grid>
          </Grid>
        )
      }
      {!hasRequestedLaunch &&
        !isBRACompleted &&
        !(!startTimestamp || days < 0) && (
          <Box marginTop="80px">
            <Typography
              role="heading"
              aria-level="2"
              component="h2"
              className="dune-text-header-dashboard"
            >
              Risk insights available in:
            </Typography>
            <Stack direction="row" alignItems="flex-end">
              <Typography component="p" marginTop="32px" marginRight="52px">
                <Typography
                  component="span"
                  className="campaigns-text-timer-number"
                >
                  {days}
                </Typography>{" "}
                Days
              </Typography>
              <Typography component="p" marginTop="32px" marginRight="52px">
                <Typography
                  component="span"
                  className="campaigns-text-timer-number"
                >
                  {hours}
                </Typography>{" "}
                Hours
              </Typography>
              <Typography component="p" marginTop="32px">
                <Typography
                  component="span"
                  className="campaigns-text-timer-number"
                >
                  {minutes}
                </Typography>{" "}
                Minutes
              </Typography>
            </Stack>
          </Box>
        )}
    </>
  );
}

export default Campaigns;
