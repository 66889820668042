import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const formatAreasToImproveUser = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  let formattedAreasToImproveUser = areasToImprove
    .sort((a, b) => b.count - a.count) // Sort by value (descending).
    .slice(0, 6) // Limit to top 6.
    .map(({ point_of_failure, count }) => ({
      name: capitalizeFirstWords(point_of_failure),
      count, // Map to the desired format.
    }));

  // Calculate the percentages of the top 6 areas to improve and add value for the green circle in the radar chart.
  const totalCount = formattedAreasToImproveUser.reduce(
    (acc, obj) => acc + obj.count,
    0,
  );
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < formattedAreasToImproveUser.length; i++) {
    formattedAreasToImproveUser[i].percentage =
      formattedAreasToImproveUser[i].count / totalCount;
    formattedAreasToImproveUser[i].letter = alphabet[i % alphabet.length];
  }

  return formattedAreasToImproveUser;
};

export default formatAreasToImproveUser;
