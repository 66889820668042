import React from "react";

import { Divider, FileUpload } from "../../../../../components";

const CustomUploadForm = ({ selectedFile, setSelectedFile }) => {
  return (
    <div>
      <div className="flex justify-between max-w-[832px] py-2">
        <div className="max-w-[308px] w-full">
          <p className="body">JSON Key File</p>
        </div>
        <div className="w-full ml-1">
          <FileUpload
            selectedFiles={selectedFile}
            setSelectedFiles={setSelectedFile}
            fileType="JSON"
            accept={{ "application/json": [".json"] }}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default CustomUploadForm;
