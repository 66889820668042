import React, { useEffect, useState } from "react";

import { StepButton } from "./utils";

import { ButtonOutlinedGreen, OnboardingTemplate } from "../../../components";

const Welcome = () => {
  const [selectedStepNumber, setSelectedStepNumber] = useState(1); // Default step is 1.

  const steps = [
    {
      description: "Set up SSO and SCIM Provisioning",
      path: "/onboarding/setup-sso-and-scim/",
    },
    {
      description: "DMI Setup",
    },
    {
      description: "Setup Dune Watchtower",
    },
    {
      description: "Start Initial Testing",
    },
  ];

  useEffect(() => {
    setSelectedStepNumber(1); // To update
  }, []);

  return (
    <OnboardingTemplate
      title="Welcome To Dune Security!"
      mainContent={
        <div>
          <div>
            <h3 className="h4">Let's get you set up!</h3>
          </div>
          <div className="flex flex-col w-full">
            {steps.map((step, index) => (
              <div className="mt-2" key={`welcome-step-${index}`}>
                <StepButton
                  stepNumber={index + 1}
                  description={step.description}
                  path={step.path}
                  selectedStepNumber={selectedStepNumber}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end mt-2 p-0.625">
            <ButtonOutlinedGreen isSmall>Get started</ButtonOutlinedGreen>
          </div>
        </div>
      }
    />
  );
};

export default Welcome;
