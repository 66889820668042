import React, { useEffect, useState } from "react";

import { GreenMinusIcon, RedPlusIcon } from "./utils";

import { CardWithLoadingState } from "../../../../../common";

import { colors } from "../../../../../../styles";

function CardScoreChange({ scoreChange, selectedDays }) {
  const [animatedScoreChange, setAnimatedScoreChange] = useState(0);

  useEffect(() => {
    if (scoreChange) {
      let start = 0;
      const duration = 1000;
      const increment = Math.abs(scoreChange) / (duration / 50);

      const interval = setInterval(() => {
        start += increment;

        if (start >= Math.abs(scoreChange)) {
          setAnimatedScoreChange(scoreChange);
          clearInterval(interval);
        } else {
          setAnimatedScoreChange(start);
        }
      }, 50);

      return () => clearInterval(interval);
    } else if (!scoreChange && animatedScoreChange !== 0) {
      setAnimatedScoreChange(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreChange]);

  return (
    <CardWithLoadingState
      loadingVariable={scoreChange}
      borderColor={
        !scoreChange
          ? "rgba(194, 191, 184, 0.35)"
          : scoreChange > 0
            ? colors.redFade
            : colors.greenFade
      }
      minHeight="144px"
    >
      <div>
        <h3 className="text-center card-header">Risk Change</h3>
      </div>
      <div className="mt-1">
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="max-w-[24px]">
              {!scoreChange ? (
                <></>
              ) : scoreChange > 0 ? (
                <RedPlusIcon />
              ) : (
                <GreenMinusIcon />
              )}
            </div>
          </div>
          <div className="flex ml-0.5">
            <p className="text-white text-[4.625rem] text-center font-medium leading-[50px]">
              {Math.round(Math.abs(animatedScoreChange))}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-0.5">
        <p className="text-center description">
          {selectedDays === "All Time"
            ? selectedDays
            : `Past ${selectedDays} Days`}
        </p>
      </div>
    </CardWithLoadingState>
  );
}

export default CardScoreChange;
