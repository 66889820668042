export const cisos = [
  {
    name: "Harshal Mehta",
    role: "CISO",
    companyName: "CWT",
    headshotFileName: "harshal.jpeg",
  },
  {
    name: "Jeannine Gaudreau",
    role: "Technical CISO",
    companyName: "HealthEdge",
    headshotFileName: "jeannine.jpeg",
  },
  {
    name: "Konrad Fellmann",
    role: "CISO",
    companyName: "Cubic Corporation",
    headshotFileName: "konrad.jpeg",
  },
  {
    name: "Bradley Schaufenbuel",
    role: "VP, CISO",
    companyName: "Paychex",
    headshotFileName: "bradley.png",
  },
  {
    name: "Benjamin Corll",
    role: "CISO Americas",
    companyName: "Zscaler",
    headshotFileName: "benjamin.jpeg",
  },
  {
    name: "LeRoy Foster",
    role: "CISO",
    companyName: "University of Chicago",
    headshotFileName: "leroy.jpeg",
  },
  {
    name: "Diego Souza",
    role: "EVP, CISO",
    companyName: "Prog Holdings",
    headshotFileName: "diego.jpeg",
  },
  {
    name: "Jim Motes",
    role: "CISO",
    companyName: "Ryan",
    headshotFileName: "jim.jpeg",
  },
  {
    name: "Alicia Lynch",
    role: "CISO",
    companyName: "TD SYNNEX",
    headshotFileName: "alicia.jpeg",
  },
  {
    name: "Dr. Tyrone Grandison",
    role: "CTO, Apps, Infra & Security",
    companyName: "Microsoft",
    headshotFileName: "tyrone.jpeg",
  },
  {
    name: "Murtaza Nisar",
    role: "VP, CISO",
    companyName: "Clario",
    headshotFileName: "murtaza.jpeg",
  },
  {
    name: "Marcos Marrero",
    role: "CTO",
    companyName: "H.I.G. Capital",
    headshotFileName: "marcos.jpeg",
  },
  {
    name: "Thanh Thai",
    role: "CISO",
    companyName: "Constellis",
    headshotFileName: "thanh.jpeg",
  },
  {
    name: "Steve Safranek",
    role: "CISO",
    companyName: "West Chester University of Pennsylvania",
    headshotFileName: "steve.jpeg",
  },
];
