import React from "react";

import DotLabel from "../dot-label";

import { CircleFilledRedIcon } from "../../../../utils/icons";

const DotLabelRed = ({ label = "" }) => {
  return <DotLabel icon={<CircleFilledRedIcon />} label={label} />;
};

export default DotLabelRed;
