import React from "react";

const QuizDialogIllustration = () => {
  return (
    <svg
      role="presentation"
      width="100%"
      height="100%"
      viewBox="0 0 282 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.8742 136.931L14.3738 95.0303L1 102.758L86.8742 152.387L172.748 102.758L159.375 95.0303L86.8742 136.931Z"
        fill="#085016"
      />
      <path
        d="M86.8742 37.6733L1 87.3024L86.8742 136.932L172.748 87.3024L86.8742 37.6733Z"
        fill="url(#paint0_linear_10651_19587)"
      />
      <path
        d="M123.306 74.4834C114.893 86.7117 100.77 94.7465 84.7508 94.7465C68.7317 94.7465 54.5972 86.7117 46.196 74.4834C41.1553 78.8019 38.2148 84.0069 38.2148 89.6096C38.2148 104.531 59.0476 116.623 84.7508 116.623C110.454 116.623 131.287 104.531 131.287 89.6096C131.287 84.0069 128.346 78.8019 123.306 74.4834Z"
        fill="#011906"
      />
      <path
        d="M172.748 87.3022L86.8742 136.931L1 87.3022"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.8728 82.8249C87.3118 82.8249 87.6676 82.4688 87.6676 82.0294C87.6676 81.5901 87.3118 81.2339 86.8728 81.2339C86.4339 81.2339 86.0781 81.5901 86.0781 82.0294C86.0781 82.4688 86.4339 82.8249 86.8728 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 88.0984C96.4406 88.0984 96.7964 87.7422 96.7964 87.3028C96.7964 86.8635 96.4406 86.5073 96.0017 86.5073C95.5628 86.5073 95.207 86.8635 95.207 87.3028C95.207 87.7422 95.5628 88.0984 96.0017 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 93.3825C87.3118 93.3825 87.6676 93.0264 87.6676 92.587C87.6676 92.1477 87.3118 91.7915 86.8728 91.7915C86.4339 91.7915 86.0781 92.1477 86.0781 92.587C86.0781 93.0264 86.4339 93.3825 86.8728 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M105.127 93.3825C105.566 93.3825 105.921 93.0264 105.921 92.587C105.921 92.1477 105.566 91.7915 105.127 91.7915C104.688 91.7915 104.332 92.1477 104.332 92.587C104.332 93.0264 104.688 93.3825 105.127 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 98.656C96.4406 98.656 96.7964 98.2998 96.7964 97.8604C96.7964 97.4211 96.4406 97.0649 96.0017 97.0649C95.5628 97.0649 95.207 97.4211 95.207 97.8604C95.207 98.2998 95.5628 98.656 96.0017 98.656Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 103.929C87.3118 103.929 87.6676 103.573 87.6676 103.133C87.6676 102.694 87.3118 102.338 86.8728 102.338C86.4339 102.338 86.0781 102.694 86.0781 103.133C86.0781 103.573 86.4339 103.929 86.8728 103.929Z"
        fill="#15C237"
      />
      <path
        d="M114.267 98.656C114.706 98.656 115.062 98.2998 115.062 97.8604C115.062 97.4211 114.706 97.0649 114.267 97.0649C113.828 97.0649 113.473 97.4211 113.473 97.8604C113.473 98.2998 113.828 98.656 114.267 98.656Z"
        fill="#15C237"
      />
      <path
        d="M105.127 103.929C105.566 103.929 105.921 103.573 105.921 103.133C105.921 102.694 105.566 102.338 105.127 102.338C104.688 102.338 104.332 102.694 104.332 103.133C104.332 103.573 104.688 103.929 105.127 103.929Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 109.202C96.4406 109.202 96.7964 108.846 96.7964 108.407C96.7964 107.967 96.4406 107.611 96.0017 107.611C95.5628 107.611 95.207 107.967 95.207 108.407C95.207 108.846 95.5628 109.202 96.0017 109.202Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 114.487C87.3118 114.487 87.6676 114.13 87.6676 113.691C87.6676 113.252 87.3118 112.896 86.8728 112.896C86.4339 112.896 86.0781 113.252 86.0781 113.691C86.0781 114.13 86.4339 114.487 86.8728 114.487Z"
        fill="#15C237"
      />
      <path
        d="M114.267 109.202C114.706 109.202 115.062 108.846 115.062 108.407C115.062 107.967 114.706 107.611 114.267 107.611C113.828 107.611 113.473 107.967 113.473 108.407C113.473 108.846 113.828 109.202 114.267 109.202Z"
        fill="#15C237"
      />
      <path
        d="M105.127 114.487C105.566 114.487 105.921 114.13 105.921 113.691C105.921 113.252 105.566 112.896 105.127 112.896C104.688 112.896 104.332 113.252 104.332 113.691C104.332 114.13 104.688 114.487 105.127 114.487Z"
        fill="#15C237"
      />
      <path
        d="M123.396 103.929C123.835 103.929 124.191 103.573 124.191 103.133C124.191 102.694 123.835 102.338 123.396 102.338C122.957 102.338 122.602 102.694 122.602 103.133C122.602 103.573 122.957 103.929 123.396 103.929Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 119.76C96.4406 119.76 96.7964 119.404 96.7964 118.964C96.7964 118.525 96.4406 118.169 96.0017 118.169C95.5628 118.169 95.207 118.525 95.207 118.964C95.207 119.404 95.5628 119.76 96.0017 119.76Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 125.033C87.3118 125.033 87.6676 124.677 87.6676 124.237C87.6676 123.798 87.3118 123.442 86.8728 123.442C86.4339 123.442 86.0781 123.798 86.0781 124.237C86.0781 124.677 86.4339 125.033 86.8728 125.033Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 88.0984C78.1828 88.0984 78.5386 87.7422 78.5386 87.3028C78.5386 86.8635 78.1828 86.5073 77.7439 86.5073C77.305 86.5073 76.9492 86.8635 76.9492 87.3028C76.9492 87.7422 77.305 88.0984 77.7439 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M105.127 72.2673C105.566 72.2673 105.921 71.9111 105.921 71.4718C105.921 71.0324 105.566 70.6763 105.127 70.6763C104.688 70.6763 104.332 71.0324 104.332 71.4718C104.332 71.9111 104.688 72.2673 105.127 72.2673Z"
        fill="#15C237"
      />
      <path
        d="M114.267 77.552C114.706 77.552 115.062 77.1958 115.062 76.7564C115.062 76.3171 114.706 75.9609 114.267 75.9609C113.828 75.9609 113.473 76.3171 113.473 76.7564C113.473 77.1958 113.828 77.552 114.267 77.552Z"
        fill="#15C237"
      />
      <path
        d="M105.127 82.8249C105.566 82.8249 105.921 82.4688 105.921 82.0294C105.921 81.5901 105.566 81.2339 105.127 81.2339C104.688 81.2339 104.332 81.5901 104.332 82.0294C104.332 82.4688 104.688 82.8249 105.127 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M123.396 82.8249C123.835 82.8249 124.191 82.4688 124.191 82.0294C124.191 81.5901 123.835 81.2339 123.396 81.2339C122.957 81.2339 122.602 81.5901 122.602 82.0294C122.602 82.4688 122.957 82.8249 123.396 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M114.267 88.0984C114.706 88.0984 115.062 87.7422 115.062 87.3028C115.062 86.8635 114.706 86.5073 114.267 86.5073C113.828 86.5073 113.473 86.8635 113.473 87.3028C113.473 87.7422 113.828 88.0984 114.267 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M132.521 88.0984C132.96 88.0984 133.316 87.7422 133.316 87.3028C133.316 86.8635 132.96 86.5073 132.521 86.5073C132.082 86.5073 131.727 86.8635 131.727 87.3028C131.727 87.7422 132.082 88.0984 132.521 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M123.396 93.3825C123.835 93.3825 124.191 93.0264 124.191 92.587C124.191 92.1477 123.835 91.7915 123.396 91.7915C122.957 91.7915 122.602 92.1477 122.602 92.587C122.602 93.0264 122.957 93.3825 123.396 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M132.521 98.656C132.96 98.656 133.316 98.2998 133.316 97.8604C133.316 97.4211 132.96 97.0649 132.521 97.0649C132.082 97.0649 131.727 97.4211 131.727 97.8604C131.727 98.2998 132.082 98.656 132.521 98.656Z"
        fill="#15C237"
      />
      <path
        d="M141.65 93.3825C142.089 93.3825 142.445 93.0264 142.445 92.587C142.445 92.1477 142.089 91.7915 141.65 91.7915C141.211 91.7915 140.855 92.1477 140.855 92.587C140.855 93.0264 141.211 93.3825 141.65 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 77.552C96.4406 77.552 96.7964 77.1958 96.7964 76.7564C96.7964 76.3171 96.4406 75.9609 96.0017 75.9609C95.5628 75.9609 95.207 76.3171 95.207 76.7564C95.207 77.1958 95.5628 77.552 96.0017 77.552Z"
        fill="#15C237"
      />
      <path
        d="M123.396 72.2673C123.835 72.2673 124.191 71.9111 124.191 71.4718C124.191 71.0324 123.835 70.6763 123.396 70.6763C122.957 70.6763 122.602 71.0324 122.602 71.4718C122.602 71.9111 122.957 72.2673 123.396 72.2673Z"
        fill="#15C237"
      />
      <path
        d="M132.521 77.552C132.96 77.552 133.316 77.1958 133.316 76.7564C133.316 76.3171 132.96 75.9609 132.521 75.9609C132.082 75.9609 131.727 76.3171 131.727 76.7564C131.727 77.1958 132.082 77.552 132.521 77.552Z"
        fill="#15C237"
      />
      <path
        d="M141.65 82.8249C142.089 82.8249 142.445 82.4688 142.445 82.0294C142.445 81.5901 142.089 81.2339 141.65 81.2339C141.211 81.2339 140.855 81.5901 140.855 82.0294C140.855 82.4688 141.211 82.8249 141.65 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M150.779 88.0984C151.218 88.0984 151.574 87.7422 151.574 87.3028C151.574 86.8635 151.218 86.5073 150.779 86.5073C150.34 86.5073 149.984 86.8635 149.984 87.3028C149.984 87.7422 150.34 88.0984 150.779 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M114.267 66.9944C114.706 66.9944 115.062 66.6382 115.062 66.1989C115.062 65.7595 114.706 65.4033 114.267 65.4033C113.828 65.4033 113.473 65.7595 113.473 66.1989C113.473 66.6382 113.828 66.9944 114.267 66.9944Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 93.3825C69.0578 93.3825 69.4137 93.0264 69.4137 92.587C69.4137 92.1477 69.0578 91.7915 68.6189 91.7915C68.18 91.7915 67.8242 92.1477 67.8242 92.587C67.8242 93.0264 68.18 93.3825 68.6189 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 98.656C78.1828 98.656 78.5386 98.2998 78.5386 97.8604C78.5386 97.4211 78.1828 97.0649 77.7439 97.0649C77.305 97.0649 76.9492 97.4211 76.9492 97.8604C76.9492 98.2998 77.305 98.656 77.7439 98.656Z"
        fill="#15C237"
      />
      <path
        d="M59.49 98.656C59.9289 98.656 60.2847 98.2998 60.2847 97.8604C60.2847 97.4211 59.9289 97.0649 59.49 97.0649C59.0511 97.0649 58.6953 97.4211 58.6953 97.8604C58.6953 98.2998 59.0511 98.656 59.49 98.656Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 103.929C69.0578 103.929 69.4137 103.573 69.4137 103.133C69.4137 102.694 69.0578 102.338 68.6189 102.338C68.18 102.338 67.8242 102.694 67.8242 103.133C67.8242 103.573 68.18 103.929 68.6189 103.929Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 109.202C78.1828 109.202 78.5386 108.846 78.5386 108.407C78.5386 107.967 78.1828 107.611 77.7439 107.611C77.305 107.611 76.9492 107.967 76.9492 108.407C76.9492 108.846 77.305 109.202 77.7439 109.202Z"
        fill="#15C237"
      />
      <path
        d="M59.49 109.202C59.9289 109.202 60.2847 108.846 60.2847 108.407C60.2847 107.967 59.9289 107.611 59.49 107.611C59.0511 107.611 58.6953 107.967 58.6953 108.407C58.6953 108.846 59.0511 109.202 59.49 109.202Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 114.487C69.0578 114.487 69.4137 114.13 69.4137 113.691C69.4137 113.252 69.0578 112.896 68.6189 112.896C68.18 112.896 67.8242 113.252 67.8242 113.691C67.8242 114.13 68.18 114.487 68.6189 114.487Z"
        fill="#15C237"
      />
      <path
        d="M50.3611 103.929C50.8 103.929 51.1558 103.573 51.1558 103.133C51.1558 102.694 50.8 102.338 50.3611 102.338C49.9222 102.338 49.5664 102.694 49.5664 103.133C49.5664 103.573 49.9222 103.929 50.3611 103.929Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 119.76C78.1828 119.76 78.5386 119.404 78.5386 118.964C78.5386 118.525 78.1828 118.169 77.7439 118.169C77.305 118.169 76.9492 118.525 76.9492 118.964C76.9492 119.404 77.305 119.76 77.7439 119.76Z"
        fill="#15C237"
      />
      <path
        d="M59.49 88.0984C59.9289 88.0984 60.2847 87.7422 60.2847 87.3028C60.2847 86.8635 59.9289 86.5073 59.49 86.5073C59.0511 86.5073 58.6953 86.8635 58.6953 87.3028C58.6953 87.7422 59.0511 88.0984 59.49 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M50.3611 93.3825C50.8 93.3825 51.1558 93.0264 51.1558 92.587C51.1558 92.1477 50.8 91.7915 50.3611 91.7915C49.9222 91.7915 49.5664 92.1477 49.5664 92.587C49.5664 93.0264 49.9222 93.3825 50.3611 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 77.552C78.1828 77.552 78.5386 77.1958 78.5386 76.7564C78.5386 76.3171 78.1828 75.9609 77.7439 75.9609C77.305 75.9609 76.9492 76.3171 76.9492 76.7564C76.9492 77.1958 77.305 77.552 77.7439 77.552Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 82.8249C69.0578 82.8249 69.4137 82.4688 69.4137 82.0294C69.4137 81.5901 69.0578 81.2339 68.6189 81.2339C68.18 81.2339 67.8242 81.5901 67.8242 82.0294C67.8242 82.4688 68.18 82.8249 68.6189 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 72.2673C87.3118 72.2673 87.6676 71.9111 87.6676 71.4718C87.6676 71.0324 87.3118 70.6763 86.8728 70.6763C86.4339 70.6763 86.0781 71.0324 86.0781 71.4718C86.0781 71.9111 86.4339 72.2673 86.8728 72.2673Z"
        fill="#15C237"
      />
      <path
        d="M41.2205 98.656C41.6594 98.656 42.0152 98.2998 42.0152 97.8604C42.0152 97.4211 41.6594 97.0649 41.2205 97.0649C40.7816 97.0649 40.4258 97.4211 40.4258 97.8604C40.4258 98.2998 40.7816 98.656 41.2205 98.656Z"
        fill="#15C237"
      />
      <path
        d="M59.49 66.9944C59.9289 66.9944 60.2847 66.6382 60.2847 66.1989C60.2847 65.7595 59.9289 65.4033 59.49 65.4033C59.0511 65.4033 58.6953 65.7595 58.6953 66.1989C58.6953 66.6382 59.0511 66.9944 59.49 66.9944Z"
        fill="#15C237"
      />
      <path
        d="M50.3611 72.2673C50.8 72.2673 51.1558 71.9111 51.1558 71.4718C51.1558 71.0324 50.8 70.6763 50.3611 70.6763C49.9222 70.6763 49.5664 71.0324 49.5664 71.4718C49.5664 71.9111 49.9222 72.2673 50.3611 72.2673Z"
        fill="#15C237"
      />
      <path
        d="M41.2205 77.552C41.6594 77.552 42.0152 77.1958 42.0152 76.7564C42.0152 76.3171 41.6594 75.9609 41.2205 75.9609C40.7816 75.9609 40.4258 76.3171 40.4258 76.7564C40.4258 77.1958 40.7816 77.552 41.2205 77.552Z"
        fill="#15C237"
      />
      <path
        d="M32.0955 82.8249C32.5344 82.8249 32.8902 82.4688 32.8902 82.0294C32.8902 81.5901 32.5344 81.2339 32.0955 81.2339C31.6566 81.2339 31.3008 81.5901 31.3008 82.0294C31.3008 82.4688 31.6566 82.8249 32.0955 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M59.49 77.552C59.9289 77.552 60.2847 77.1958 60.2847 76.7564C60.2847 76.3171 59.9289 75.9609 59.49 75.9609C59.0511 75.9609 58.6953 76.3171 58.6953 76.7564C58.6953 77.1958 59.0511 77.552 59.49 77.552Z"
        fill="#15C237"
      />
      <path
        d="M50.3611 82.8249C50.8 82.8249 51.1558 82.4688 51.1558 82.0294C51.1558 81.5901 50.8 81.2339 50.3611 81.2339C49.9222 81.2339 49.5664 81.5901 49.5664 82.0294C49.5664 82.4688 49.9222 82.8249 50.3611 82.8249Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 72.2673C69.0578 72.2673 69.4137 71.9111 69.4137 71.4718C69.4137 71.0324 69.0578 70.6763 68.6189 70.6763C68.18 70.6763 67.8242 71.0324 67.8242 71.4718C67.8242 71.9111 68.18 72.2673 68.6189 72.2673Z"
        fill="#15C237"
      />
      <path
        d="M41.2205 88.0984C41.6594 88.0984 42.0152 87.7422 42.0152 87.3028C42.0152 86.8635 41.6594 86.5073 41.2205 86.5073C40.7816 86.5073 40.4258 86.8635 40.4258 87.3028C40.4258 87.7422 40.7816 88.0984 41.2205 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M32.0955 93.3825C32.5344 93.3825 32.8902 93.0264 32.8902 92.587C32.8902 92.1477 32.5344 91.7915 32.0955 91.7915C31.6566 91.7915 31.3008 92.1477 31.3008 92.587C31.3008 93.0264 31.6566 93.3825 32.0955 93.3825Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 66.9944C96.4406 66.9944 96.7964 66.6382 96.7964 66.1989C96.7964 65.7595 96.4406 65.4033 96.0017 65.4033C95.5628 65.4033 95.207 65.7595 95.207 66.1989C95.207 66.6382 95.5628 66.9944 96.0017 66.9944Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 56.448C78.1828 56.448 78.5386 56.0918 78.5386 55.6525C78.5386 55.2131 78.1828 54.8569 77.7439 54.8569C77.305 54.8569 76.9492 55.2131 76.9492 55.6525C76.9492 56.0918 77.305 56.448 77.7439 56.448Z"
        fill="#15C237"
      />
      <path
        d="M68.6189 61.7209C69.0578 61.7209 69.4137 61.3647 69.4137 60.9254C69.4137 60.486 69.0578 60.1299 68.6189 60.1299C68.18 60.1299 67.8242 60.486 67.8242 60.9254C67.8242 61.3647 68.18 61.7209 68.6189 61.7209Z"
        fill="#15C237"
      />
      <path
        d="M77.7439 66.9944C78.1828 66.9944 78.5386 66.6382 78.5386 66.1989C78.5386 65.7595 78.1828 65.4033 77.7439 65.4033C77.305 65.4033 76.9492 65.7595 76.9492 66.1989C76.9492 66.6382 77.305 66.9944 77.7439 66.9944Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 61.7209C87.3118 61.7209 87.6676 61.3647 87.6676 60.9254C87.6676 60.486 87.3118 60.1299 86.8728 60.1299C86.4339 60.1299 86.0781 60.486 86.0781 60.9254C86.0781 61.3647 86.4339 61.7209 86.8728 61.7209Z"
        fill="#15C237"
      />
      <path
        d="M105.127 61.7209C105.566 61.7209 105.921 61.3647 105.921 60.9254C105.921 60.486 105.566 60.1299 105.127 60.1299C104.688 60.1299 104.332 60.486 104.332 60.9254C104.332 61.3647 104.688 61.7209 105.127 61.7209Z"
        fill="#15C237"
      />
      <path
        d="M86.8728 51.1633C87.3118 51.1633 87.6676 50.8071 87.6676 50.3678C87.6676 49.9284 87.3118 49.5723 86.8728 49.5723C86.4339 49.5723 86.0781 49.9284 86.0781 50.3678C86.0781 50.8071 86.4339 51.1633 86.8728 51.1633Z"
        fill="#15C237"
      />
      <path
        d="M96.0017 56.448C96.4406 56.448 96.7964 56.0918 96.7964 55.6525C96.7964 55.2131 96.4406 54.8569 96.0017 54.8569C95.5628 54.8569 95.207 55.2131 95.207 55.6525C95.207 56.0918 95.5628 56.448 96.0017 56.448Z"
        fill="#15C237"
      />
      <path
        d="M22.9666 88.0984C23.4055 88.0984 23.7613 87.7422 23.7613 87.3028C23.7613 86.8635 23.4055 86.5073 22.9666 86.5073C22.5277 86.5073 22.1719 86.8635 22.1719 87.3028C22.1719 87.7422 22.5277 88.0984 22.9666 88.0984Z"
        fill="#15C237"
      />
      <path
        d="M84.75 94.7466C110.533 94.7466 131.434 73.9515 131.434 48.2996C131.434 22.6476 110.533 1.85254 84.75 1.85254C58.9674 1.85254 38.0664 22.6476 38.0664 48.2996C38.0664 73.9515 58.9674 94.7466 84.75 94.7466Z"
        fill="#15C237"
      />
      <path
        d="M77.9726 93.5873L81.3899 92.5758L84.1032 89.3824L80.6973 87.1549L75.1798 85.2457L74.5894 84.2683L67.4257 79.0974L62.1238 76.4154L58.4114 76.8586L55.5277 73.9834L53.5069 69.7558L53.1776 65.5509L51.6563 65.1986L49.2041 64.6758L48.1937 60.0391L51.1681 57.1297L56.7765 63.1302L57.4918 60.9255L58.4568 57.7093L64.5534 51.0383L69.3897 50.8224L66.6196 46.7653L71.9328 46.197L71.9782 44.0719C71.9782 44.0719 68.788 36.003 67.9706 35.6734C67.1532 35.3438 66.2449 35.2188 66.2449 35.2188L61.8968 41.833L59.7964 34.2528L60.6593 32.73L67.4597 30.2525L65.9271 26.9682L63.1796 27.525L60.5912 25.5021L58.7633 19.0016C58.7633 19.0016 59.0131 14.0353 59.4899 14.5467C59.9667 15.0581 57.5031 14.0239 57.5031 14.0239L49.8398 21.32L52.417 22.9224L51.0546 26.8659L47.7396 34.9006L43.9703 44.1514L43.7773 52.868L47.1038 64.2099L53.0528 71.5287L59.0926 80.6544L58.7179 83.9047L63.5997 87.3254L68.9697 91.9849L74.1353 93.5532H77.984L77.9726 93.5873Z"
        fill="#00FF82"
      />
      <path
        d="M64.6907 24.2405L67.5062 20.7175L66.0871 17.7627L63.93 18.8878L62.3633 23.4677L64.6907 24.2405Z"
        fill="#00FF82"
      />
      <path
        d="M67.6172 23.32L67.9805 21.729L70.3192 22.5245L69.3542 23.2746L67.6172 23.32Z"
        fill="#00FF82"
      />
      <path
        d="M72.8645 22.6949L70.7188 21.8311L71.82 20.3765L74.0679 19.5241L73.5343 18.5809L74.5334 17.3194L79.313 16.5693L80.3575 17.7626L74.2382 21.2061L72.8645 22.6949Z"
        fill="#00FF82"
      />
      <path
        d="M76.3699 22.9109C76.1655 22.7859 76.1883 21.7403 76.1883 21.7403L76.5175 20.547L81.8307 18.0355L84.7257 17.5127L87.1893 20.0129L87.212 25.0815L87.0077 29.866L81.5469 34.5482L79.1287 39.6622L77.3122 39.1054L76.6764 31.3434L77.562 30.1046L77.4484 23.7973L76.3812 22.9222L76.3699 22.9109Z"
        fill="#00FF82"
      />
      <path
        d="M97.9525 40.6967L96.1133 38.7647L96.2268 35.6963L97.5551 36.1054L98.259 37.8442L100.836 40.4353V41.765L98.3158 43.0264L97.9525 40.6967Z"
        fill="#00FF82"
      />
      <path
        d="M97.1704 41.3216L96.1145 39.5146L95.4561 39.8101L95.1836 42.1853L95.9556 42.4013L97.1704 41.3216Z"
        fill="#00FF82"
      />
      <path
        d="M89.9734 36.7073L88.6905 37.3664L87.067 36.4118L86.9648 35.0367L88.1569 34.9799L89.5193 34.0366L90.6886 35.0708L89.9734 36.7073Z"
        fill="#00FF82"
      />
      <path
        d="M128.846 63.5506L124.725 65.6758L121.466 58.8116L116.868 59.3003L116.573 55.6522L115.006 52.1292L113.78 51.8564L109.58 59.323L101.099 63.2665L99.8842 65.4144L96.0469 71.8127L95.3203 80.0861L98.0337 81.8362L100.668 82.4158L105.39 80.5861L109.364 81.3475L109.557 84.0068L107.082 89.0982C117.186 83.6091 125.02 74.5174 128.846 63.5506Z"
        fill="#00FF82"
      />
      <path
        d="M129.822 46.3219L131.423 47.9471C131.264 27.7181 118.117 10.569 99.8614 4.35254L99.2029 6.31862L101.008 8.77337L98.6239 9.77343L99.8728 11.535L103.131 13.5692L103.506 15.3648L106.673 17.1036L104.493 18.1037C104.493 18.1037 102.302 16.8195 102.041 16.5581C101.78 16.2967 101.053 14.8989 101.053 14.8989L100.225 14.933L100.849 17.2059L103.812 19.0583L104.97 18.5583L105.969 18.8537L106.333 21.3085L105.186 21.8426L106.253 22.7404L106.685 25.1156L105.629 25.1838C105.629 25.1838 103.812 24.5815 103.914 24.3769C104.017 24.1723 105.197 23.2632 105.197 23.2632L104.641 22.3768L103.12 22.2517L101.428 22.6495L99.6684 22.229L98.6466 24.5928L99.5322 28.7977L98.9191 32.048L99.9182 34.0368L101.36 34.3437L101.587 33.6731H102.121L103.96 35.8097L104.789 34.7869L102.802 30.332L102.552 27.0931L103.074 26.8658L105.22 30.5933L105.901 30.5479L106.798 29.5705L107.161 29.9683L106.775 30.9229L106.162 31.4684L106.775 32.8776L106.378 33.3322L107.025 35.3551L106.571 36.4802L103.619 37.378L102.609 35.9802L102.28 36.4461L102.529 38.8667L101.258 42.0602L98.6694 44.7536L100.52 45.8673L101.371 48.4698L98.4309 49.379L97.8633 50.595C97.8633 50.595 98.4877 54.6521 98.6353 55.0044C98.7829 55.3681 100.225 55.7431 100.225 55.7431C100.225 55.7431 103.313 54.618 103.131 54.3226C102.949 54.0271 103.029 51.7996 103.029 51.7996C103.029 51.7996 104.255 48.9357 104.403 48.7312C104.55 48.538 106.015 46.9583 106.015 46.9583H107.195L111.214 50.3222L111.703 50.1631L111.6 48.3107L108.683 46.7424L107.956 45.89L108.07 44.8331L111.566 46.8901L113.882 49.9699L114.995 50.1745L115.086 49.1062L114.552 47.8675L113.916 47.2993L115.37 46.5037L115.949 47.3675L116.051 48.2994L117.039 48.9699L119.809 46.7765L120.626 50.7654L119.888 53.5839L121.795 54.0612L125.678 62.4823L127.075 61.6982L129.254 54.8453L128.982 50.3677L127.415 51.4928L125.69 48.2766L129.152 48.0834L129.924 46.3219H129.822Z"
        fill="#00FF82"
      />
      <path
        d="M108.785 27.082L134.341 4.53467"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1721 40.5943L28.293 36.4121"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.4144 83.0068L60.1484 96.1557"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.2003 92.8486H55.1406V101.918H64.2003V92.8486Z"
        fill="white"
      />
      <path
        d="M60.4894 42.5034H51.4297V51.5723H60.4894V42.5034Z"
        fill="white"
      />
      <path d="M69.548 33.4346H60.4883V42.5035H69.548V33.4346Z" fill="white" />
      <path d="M138.868 0H129.809V9.06891H138.868V0Z" fill="white" />
      <path
        d="M32.8214 31.8774H23.7617V40.9464H32.8214V31.8774Z"
        fill="white"
      />
      <path
        d="M112.872 63.6304H103.812V72.6993H112.872V63.6304Z"
        fill="white"
      />
      <path
        d="M195.124 199.494L122.613 157.593L109.25 165.321L195.124 214.95L280.998 165.321L267.625 157.593L195.124 199.494Z"
        fill="#085016"
      />
      <path
        d="M228.695 180.208L241.876 187.822"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.124 100.236L109.25 149.865L195.124 199.494L280.998 149.865L195.124 100.236Z"
        fill="url(#paint1_linear_10651_19587)"
      />
      <path
        d="M195.125 199.494L280.999 149.865"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.25 149.865L195.124 199.494"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.123 145.387C195.562 145.387 195.918 145.031 195.918 144.591C195.918 144.152 195.562 143.796 195.123 143.796C194.684 143.796 194.328 144.152 194.328 144.591C194.328 145.031 194.684 145.387 195.123 145.387Z"
        fill="#15C237"
      />
      <path
        d="M204.252 150.66C204.691 150.66 205.046 150.304 205.046 149.865C205.046 149.426 204.691 149.069 204.252 149.069C203.813 149.069 203.457 149.426 203.457 149.865C203.457 150.304 203.813 150.66 204.252 150.66Z"
        fill="#15C237"
      />
      <path
        d="M195.123 155.933C195.562 155.933 195.918 155.577 195.918 155.138C195.918 154.698 195.562 154.342 195.123 154.342C194.684 154.342 194.328 154.698 194.328 155.138C194.328 155.577 194.684 155.933 195.123 155.933Z"
        fill="#15C237"
      />
      <path
        d="M213.377 155.933C213.816 155.933 214.171 155.577 214.171 155.138C214.171 154.698 213.816 154.342 213.377 154.342C212.938 154.342 212.582 154.698 212.582 155.138C212.582 155.577 212.938 155.933 213.377 155.933Z"
        fill="#15C237"
      />
      <path
        d="M204.252 161.218C204.691 161.218 205.046 160.862 205.046 160.422C205.046 159.983 204.691 159.627 204.252 159.627C203.813 159.627 203.457 159.983 203.457 160.422C203.457 160.862 203.813 161.218 204.252 161.218Z"
        fill="#15C237"
      />
      <path
        d="M195.123 166.491C195.562 166.491 195.918 166.135 195.918 165.695C195.918 165.256 195.562 164.9 195.123 164.9C194.684 164.9 194.328 165.256 194.328 165.695C194.328 166.135 194.684 166.491 195.123 166.491Z"
        fill="#15C237"
      />
      <path
        d="M222.506 161.218C222.945 161.218 223.3 160.862 223.3 160.422C223.3 159.983 222.945 159.627 222.506 159.627C222.067 159.627 221.711 159.983 221.711 160.422C221.711 160.862 222.067 161.218 222.506 161.218Z"
        fill="#15C237"
      />
      <path
        d="M213.377 166.491C213.816 166.491 214.171 166.135 214.171 165.695C214.171 165.256 213.816 164.9 213.377 164.9C212.938 164.9 212.582 165.256 212.582 165.695C212.582 166.135 212.938 166.491 213.377 166.491Z"
        fill="#15C237"
      />
      <path
        d="M204.252 171.764C204.691 171.764 205.046 171.408 205.046 170.969C205.046 170.53 204.691 170.173 204.252 170.173C203.813 170.173 203.457 170.53 203.457 170.969C203.457 171.408 203.813 171.764 204.252 171.764Z"
        fill="#15C237"
      />
      <path
        d="M195.123 177.037C195.562 177.037 195.918 176.681 195.918 176.242C195.918 175.802 195.562 175.446 195.123 175.446C194.684 175.446 194.328 175.802 194.328 176.242C194.328 176.681 194.684 177.037 195.123 177.037Z"
        fill="#15C237"
      />
      <path
        d="M222.506 171.764C222.945 171.764 223.3 171.408 223.3 170.969C223.3 170.53 222.945 170.173 222.506 170.173C222.067 170.173 221.711 170.53 221.711 170.969C221.711 171.408 222.067 171.764 222.506 171.764Z"
        fill="#15C237"
      />
      <path
        d="M213.377 177.037C213.816 177.037 214.171 176.681 214.171 176.242C214.171 175.802 213.816 175.446 213.377 175.446C212.938 175.446 212.582 175.802 212.582 176.242C212.582 176.681 212.938 177.037 213.377 177.037Z"
        fill="#15C237"
      />
      <path
        d="M231.635 166.491C232.073 166.491 232.429 166.135 232.429 165.695C232.429 165.256 232.073 164.9 231.635 164.9C231.196 164.9 230.84 165.256 230.84 165.695C230.84 166.135 231.196 166.491 231.635 166.491Z"
        fill="#15C237"
      />
      <path
        d="M204.252 182.322C204.691 182.322 205.046 181.966 205.046 181.526C205.046 181.087 204.691 180.731 204.252 180.731C203.813 180.731 203.457 181.087 203.457 181.526C203.457 181.966 203.813 182.322 204.252 182.322Z"
        fill="#15C237"
      />
      <path
        d="M195.123 187.595C195.562 187.595 195.918 187.239 195.918 186.799C195.918 186.36 195.562 186.004 195.123 186.004C194.684 186.004 194.328 186.36 194.328 186.799C194.328 187.239 194.684 187.595 195.123 187.595Z"
        fill="#15C237"
      />
      <path
        d="M185.994 150.66C186.433 150.66 186.789 150.304 186.789 149.865C186.789 149.426 186.433 149.069 185.994 149.069C185.555 149.069 185.199 149.426 185.199 149.865C185.199 150.304 185.555 150.66 185.994 150.66Z"
        fill="#15C237"
      />
      <path
        d="M213.377 134.829C213.816 134.829 214.171 134.473 214.171 134.034C214.171 133.594 213.816 133.238 213.377 133.238C212.938 133.238 212.582 133.594 212.582 134.034C212.582 134.473 212.938 134.829 213.377 134.829Z"
        fill="#15C237"
      />
      <path
        d="M222.506 140.114C222.945 140.114 223.3 139.758 223.3 139.318C223.3 138.879 222.945 138.523 222.506 138.523C222.067 138.523 221.711 138.879 221.711 139.318C221.711 139.758 222.067 140.114 222.506 140.114Z"
        fill="#15C237"
      />
      <path
        d="M213.377 145.387C213.816 145.387 214.171 145.031 214.171 144.591C214.171 144.152 213.816 143.796 213.377 143.796C212.938 143.796 212.582 144.152 212.582 144.591C212.582 145.031 212.938 145.387 213.377 145.387Z"
        fill="#15C237"
      />
      <path
        d="M231.635 145.387C232.073 145.387 232.429 145.031 232.429 144.591C232.429 144.152 232.073 143.796 231.635 143.796C231.196 143.796 230.84 144.152 230.84 144.591C230.84 145.031 231.196 145.387 231.635 145.387Z"
        fill="#15C237"
      />
      <path
        d="M222.506 150.66C222.945 150.66 223.3 150.304 223.3 149.865C223.3 149.426 222.945 149.069 222.506 149.069C222.067 149.069 221.711 149.426 221.711 149.865C221.711 150.304 222.067 150.66 222.506 150.66Z"
        fill="#15C237"
      />
      <path
        d="M240.763 150.66C241.202 150.66 241.558 150.304 241.558 149.865C241.558 149.426 241.202 149.069 240.763 149.069C240.325 149.069 239.969 149.426 239.969 149.865C239.969 150.304 240.325 150.66 240.763 150.66Z"
        fill="#15C237"
      />
      <path
        d="M231.635 155.933C232.073 155.933 232.429 155.577 232.429 155.138C232.429 154.698 232.073 154.342 231.635 154.342C231.196 154.342 230.84 154.698 230.84 155.138C230.84 155.577 231.196 155.933 231.635 155.933Z"
        fill="#15C237"
      />
      <path
        d="M240.763 161.218C241.202 161.218 241.558 160.862 241.558 160.422C241.558 159.983 241.202 159.627 240.763 159.627C240.325 159.627 239.969 159.983 239.969 160.422C239.969 160.862 240.325 161.218 240.763 161.218Z"
        fill="#15C237"
      />
      <path
        d="M249.9 155.933C250.339 155.933 250.695 155.577 250.695 155.138C250.695 154.698 250.339 154.342 249.9 154.342C249.461 154.342 249.105 154.698 249.105 155.138C249.105 155.577 249.461 155.933 249.9 155.933Z"
        fill="#15C237"
      />
      <path
        d="M204.252 140.114C204.691 140.114 205.046 139.758 205.046 139.318C205.046 138.879 204.691 138.523 204.252 138.523C203.813 138.523 203.457 138.879 203.457 139.318C203.457 139.758 203.813 140.114 204.252 140.114Z"
        fill="#15C237"
      />
      <path
        d="M231.635 134.829C232.073 134.829 232.429 134.473 232.429 134.034C232.429 133.594 232.073 133.238 231.635 133.238C231.196 133.238 230.84 133.594 230.84 134.034C230.84 134.473 231.196 134.829 231.635 134.829Z"
        fill="#15C237"
      />
      <path
        d="M240.763 140.114C241.202 140.114 241.558 139.758 241.558 139.318C241.558 138.879 241.202 138.523 240.763 138.523C240.325 138.523 239.969 138.879 239.969 139.318C239.969 139.758 240.325 140.114 240.763 140.114Z"
        fill="#15C237"
      />
      <path
        d="M249.9 145.387C250.339 145.387 250.695 145.031 250.695 144.591C250.695 144.152 250.339 143.796 249.9 143.796C249.461 143.796 249.105 144.152 249.105 144.591C249.105 145.031 249.461 145.387 249.9 145.387Z"
        fill="#15C237"
      />
      <path
        d="M259.029 150.66C259.468 150.66 259.824 150.304 259.824 149.865C259.824 149.426 259.468 149.069 259.029 149.069C258.59 149.069 258.234 149.426 258.234 149.865C258.234 150.304 258.59 150.66 259.029 150.66Z"
        fill="#15C237"
      />
      <path
        d="M222.506 129.556C222.945 129.556 223.3 129.2 223.3 128.761C223.3 128.322 222.945 127.965 222.506 127.965C222.067 127.965 221.711 128.322 221.711 128.761C221.711 129.2 222.067 129.556 222.506 129.556Z"
        fill="#15C237"
      />
      <path
        d="M176.857 155.933C177.296 155.933 177.652 155.577 177.652 155.138C177.652 154.698 177.296 154.342 176.857 154.342C176.418 154.342 176.062 154.698 176.062 155.138C176.062 155.577 176.418 155.933 176.857 155.933Z"
        fill="#15C237"
      />
      <path
        d="M185.994 161.218C186.433 161.218 186.789 160.862 186.789 160.422C186.789 159.983 186.433 159.627 185.994 159.627C185.555 159.627 185.199 159.983 185.199 160.422C185.199 160.862 185.555 161.218 185.994 161.218Z"
        fill="#15C237"
      />
      <path
        d="M167.728 161.218C168.167 161.218 168.523 160.862 168.523 160.422C168.523 159.983 168.167 159.627 167.728 159.627C167.289 159.627 166.934 159.983 166.934 160.422C166.934 160.862 167.289 161.218 167.728 161.218Z"
        fill="#15C237"
      />
      <path
        d="M176.857 166.491C177.296 166.491 177.652 166.135 177.652 165.695C177.652 165.256 177.296 164.9 176.857 164.9C176.418 164.9 176.062 165.256 176.062 165.695C176.062 166.135 176.418 166.491 176.857 166.491Z"
        fill="#15C237"
      />
      <path
        d="M185.994 171.764C186.433 171.764 186.789 171.408 186.789 170.969C186.789 170.53 186.433 170.173 185.994 170.173C185.555 170.173 185.199 170.53 185.199 170.969C185.199 171.408 185.555 171.764 185.994 171.764Z"
        fill="#15C237"
      />
      <path
        d="M167.728 171.764C168.167 171.764 168.523 171.408 168.523 170.969C168.523 170.53 168.167 170.173 167.728 170.173C167.289 170.173 166.934 170.53 166.934 170.969C166.934 171.408 167.289 171.764 167.728 171.764Z"
        fill="#15C237"
      />
      <path
        d="M176.857 177.037C177.296 177.037 177.652 176.681 177.652 176.242C177.652 175.802 177.296 175.446 176.857 175.446C176.418 175.446 176.062 175.802 176.062 176.242C176.062 176.681 176.418 177.037 176.857 177.037Z"
        fill="#15C237"
      />
      <path
        d="M158.599 166.491C159.038 166.491 159.394 166.135 159.394 165.695C159.394 165.256 159.038 164.9 158.599 164.9C158.16 164.9 157.805 165.256 157.805 165.695C157.805 166.135 158.16 166.491 158.599 166.491Z"
        fill="#15C237"
      />
      <path
        d="M185.994 182.322C186.433 182.322 186.789 181.966 186.789 181.526C186.789 181.087 186.433 180.731 185.994 180.731C185.555 180.731 185.199 181.087 185.199 181.526C185.199 181.966 185.555 182.322 185.994 182.322Z"
        fill="#15C237"
      />
      <path
        d="M167.728 150.66C168.167 150.66 168.523 150.304 168.523 149.865C168.523 149.426 168.167 149.069 167.728 149.069C167.289 149.069 166.934 149.426 166.934 149.865C166.934 150.304 167.289 150.66 167.728 150.66Z"
        fill="#15C237"
      />
      <path
        d="M158.599 155.933C159.038 155.933 159.394 155.577 159.394 155.138C159.394 154.698 159.038 154.342 158.599 154.342C158.16 154.342 157.805 154.698 157.805 155.138C157.805 155.577 158.16 155.933 158.599 155.933Z"
        fill="#15C237"
      />
      <path
        d="M185.994 140.114C186.433 140.114 186.789 139.758 186.789 139.318C186.789 138.879 186.433 138.523 185.994 138.523C185.555 138.523 185.199 138.879 185.199 139.318C185.199 139.758 185.555 140.114 185.994 140.114Z"
        fill="#15C237"
      />
      <path
        d="M176.857 145.387C177.296 145.387 177.652 145.031 177.652 144.591C177.652 144.152 177.296 143.796 176.857 143.796C176.418 143.796 176.062 144.152 176.062 144.591C176.062 145.031 176.418 145.387 176.857 145.387Z"
        fill="#15C237"
      />
      <path
        d="M195.123 134.829C195.562 134.829 195.918 134.473 195.918 134.034C195.918 133.594 195.562 133.238 195.123 133.238C194.684 133.238 194.328 133.594 194.328 134.034C194.328 134.473 194.684 134.829 195.123 134.829Z"
        fill="#15C237"
      />
      <path
        d="M149.471 161.218C149.909 161.218 150.265 160.862 150.265 160.422C150.265 159.983 149.909 159.627 149.471 159.627C149.032 159.627 148.676 159.983 148.676 160.422C148.676 160.862 149.032 161.218 149.471 161.218Z"
        fill="#15C237"
      />
      <path
        d="M167.728 129.556C168.167 129.556 168.523 129.2 168.523 128.761C168.523 128.322 168.167 127.965 167.728 127.965C167.289 127.965 166.934 128.322 166.934 128.761C166.934 129.2 167.289 129.556 167.728 129.556Z"
        fill="#15C237"
      />
      <path
        d="M158.599 134.829C159.038 134.829 159.394 134.473 159.394 134.034C159.394 133.594 159.038 133.238 158.599 133.238C158.16 133.238 157.805 133.594 157.805 134.034C157.805 134.473 158.16 134.829 158.599 134.829Z"
        fill="#15C237"
      />
      <path
        d="M149.471 140.114C149.909 140.114 150.265 139.758 150.265 139.318C150.265 138.879 149.909 138.523 149.471 138.523C149.032 138.523 148.676 138.879 148.676 139.318C148.676 139.758 149.032 140.114 149.471 140.114Z"
        fill="#15C237"
      />
      <path
        d="M140.345 145.387C140.784 145.387 141.14 145.031 141.14 144.591C141.14 144.152 140.784 143.796 140.345 143.796C139.907 143.796 139.551 144.152 139.551 144.591C139.551 145.031 139.907 145.387 140.345 145.387Z"
        fill="#15C237"
      />
      <path
        d="M167.728 140.114C168.167 140.114 168.523 139.758 168.523 139.318C168.523 138.879 168.167 138.523 167.728 138.523C167.289 138.523 166.934 138.879 166.934 139.318C166.934 139.758 167.289 140.114 167.728 140.114Z"
        fill="#15C237"
      />
      <path
        d="M158.599 145.387C159.038 145.387 159.394 145.031 159.394 144.591C159.394 144.152 159.038 143.796 158.599 143.796C158.16 143.796 157.805 144.152 157.805 144.591C157.805 145.031 158.16 145.387 158.599 145.387Z"
        fill="#15C237"
      />
      <path
        d="M176.857 134.829C177.296 134.829 177.652 134.473 177.652 134.034C177.652 133.594 177.296 133.238 176.857 133.238C176.418 133.238 176.062 133.594 176.062 134.034C176.062 134.473 176.418 134.829 176.857 134.829Z"
        fill="#15C237"
      />
      <path
        d="M149.471 150.66C149.909 150.66 150.265 150.304 150.265 149.865C150.265 149.426 149.909 149.069 149.471 149.069C149.032 149.069 148.676 149.426 148.676 149.865C148.676 150.304 149.032 150.66 149.471 150.66Z"
        fill="#15C237"
      />
      <path
        d="M140.345 155.933C140.784 155.933 141.14 155.577 141.14 155.138C141.14 154.698 140.784 154.342 140.345 154.342C139.907 154.342 139.551 154.698 139.551 155.138C139.551 155.577 139.907 155.933 140.345 155.933Z"
        fill="#15C237"
      />
      <path
        d="M204.252 129.556C204.691 129.556 205.046 129.2 205.046 128.761C205.046 128.322 204.691 127.965 204.252 127.965C203.813 127.965 203.457 128.322 203.457 128.761C203.457 129.2 203.813 129.556 204.252 129.556Z"
        fill="#15C237"
      />
      <path
        d="M185.994 118.998C186.433 118.998 186.789 118.642 186.789 118.203C186.789 117.763 186.433 117.407 185.994 117.407C185.555 117.407 185.199 117.763 185.199 118.203C185.199 118.642 185.555 118.998 185.994 118.998Z"
        fill="#15C237"
      />
      <path
        d="M176.857 124.283C177.296 124.283 177.652 123.927 177.652 123.487C177.652 123.048 177.296 122.692 176.857 122.692C176.418 122.692 176.062 123.048 176.062 123.487C176.062 123.927 176.418 124.283 176.857 124.283Z"
        fill="#15C237"
      />
      <path
        d="M185.994 129.556C186.433 129.556 186.789 129.2 186.789 128.761C186.789 128.322 186.433 127.965 185.994 127.965C185.555 127.965 185.199 128.322 185.199 128.761C185.199 129.2 185.555 129.556 185.994 129.556Z"
        fill="#15C237"
      />
      <path
        d="M195.123 124.283C195.562 124.283 195.918 123.927 195.918 123.487C195.918 123.048 195.562 122.692 195.123 122.692C194.684 122.692 194.328 123.048 194.328 123.487C194.328 123.927 194.684 124.283 195.123 124.283Z"
        fill="#15C237"
      />
      <path
        d="M213.377 124.283C213.816 124.283 214.171 123.927 214.171 123.487C214.171 123.048 213.816 122.692 213.377 122.692C212.938 122.692 212.582 123.048 212.582 123.487C212.582 123.927 212.938 124.283 213.377 124.283Z"
        fill="#15C237"
      />
      <path
        d="M195.123 113.725C195.562 113.725 195.918 113.369 195.918 112.93C195.918 112.49 195.562 112.134 195.123 112.134C194.684 112.134 194.328 112.49 194.328 112.93C194.328 113.369 194.684 113.725 195.123 113.725Z"
        fill="#15C237"
      />
      <path
        d="M204.252 118.998C204.691 118.998 205.046 118.642 205.046 118.203C205.046 117.763 204.691 117.407 204.252 117.407C203.813 117.407 203.457 117.763 203.457 118.203C203.457 118.642 203.813 118.998 204.252 118.998Z"
        fill="#15C237"
      />
      <path
        d="M131.217 150.66C131.656 150.66 132.011 150.304 132.011 149.865C132.011 149.426 131.656 149.069 131.217 149.069C130.778 149.069 130.422 149.426 130.422 149.865C130.422 150.304 130.778 150.66 131.217 150.66Z"
        fill="#15C237"
      />
      <path
        d="M170.762 154.411L218.138 181.788L250.017 163.366L202.641 135.989"
        fill="#011906"
      />
      <path
        d="M185.432 149.569L175.668 155.218"
        stroke="#011906"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.102 141.307L170.762 154.411V93.9285L148.102 80.8252V141.307Z"
        fill="#15C237"
      />
      <path
        d="M148.102 80.8253L170.762 93.9286L202.641 75.5067L179.969 62.4033L148.102 80.8253Z"
        fill="#00FF82"
      />
      <path
        d="M202.641 135.989L170.762 154.411V93.9288L202.641 75.5068V135.989Z"
        fill="#011906"
      />
      <path
        d="M170.762 154.411V93.9288L202.641 75.5068"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.973 87.2461L167.889 97.0196V104.929L150.973 95.1558V87.2461Z"
        fill="#011906"
      />
      <path
        d="M150.973 98.2358L167.889 108.009V115.919L150.973 106.146V98.2358Z"
        fill="#011906"
      />
      <path
        d="M150.973 109.237L167.889 119.01V126.909L150.973 117.135V109.237Z"
        fill="#011906"
      />
      <path
        d="M153.527 91.5762L155.389 92.6558"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.52 96.1899L163.381 97.2696"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.504 98.4971L167.366 99.5767"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.523 104.896L159.385 105.964"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.52 107.203L163.381 108.271"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.504 109.509L167.366 110.578"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.527 113.589L155.389 114.669"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.523 115.896L159.385 116.976"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.504 120.51L167.366 121.59"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.547 160.059L203.207 173.162V112.68L180.547 99.5767V160.059Z"
        fill="#15C237"
      />
      <path
        d="M180.547 99.5767L203.207 112.68L235.087 94.2581L212.415 81.1548L180.547 99.5767Z"
        fill="#011906"
      />
      <path
        d="M235.086 154.741L203.207 173.162V112.68L235.086 94.2583V154.741Z"
        fill="#011906"
      />
      <path
        d="M203.207 173.162V112.68L235.086 94.2583"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.418 105.998L200.334 115.771V123.681L183.418 113.907V105.998Z"
        fill="#011906"
      />
      <path
        d="M183.418 116.987L200.334 126.761V134.671L183.418 124.897V116.987Z"
        fill="#011906"
      />
      <path
        d="M183.418 127.988L200.334 137.762V145.66L183.418 135.898V127.988Z"
        fill="#011906"
      />
      <path
        d="M185.973 110.328L187.846 111.407"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.965 114.941L195.827 116.021"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 117.249L199.822 118.328"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.969 123.647L191.831 124.715"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.965 125.954L195.827 127.022"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 128.261L199.822 129.329"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.973 132.341L187.846 133.42"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.969 134.648L191.831 135.728"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 139.262L199.822 140.341"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.547 94.7693L203.207 107.861V67.8694L180.547 54.7773V94.7693Z"
        fill="#15C237"
      />
      <path
        d="M180.547 54.7774L203.207 67.8694L235.087 49.4588L212.415 36.3555L180.547 54.7774Z"
        fill="#00FF82"
      />
      <path
        d="M235.086 89.4509L203.207 107.862V67.8696L235.086 49.459V89.4509Z"
        fill="#011906"
      />
      <path
        d="M203.207 107.862V67.8696L235.086 49.459"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.418 61.1982L200.334 70.9718V78.8815L183.418 69.108V61.1982Z"
        fill="#011906"
      />
      <path
        d="M183.418 72.188L200.334 81.9615V89.8713L183.418 80.0977V72.188Z"
        fill="#011906"
      />
      <path
        d="M183.418 83.189L200.334 92.9511V100.861L183.418 91.0874V83.189Z"
        fill="#011906"
      />
      <path
        d="M185.973 65.5283L187.846 66.6079"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.965 70.1426L195.827 71.2222"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 72.4492L199.822 73.5288"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.969 78.8364L191.831 79.916"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.965 81.1431L195.827 82.2227"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 83.4502L199.822 84.5298"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185.973 87.5415L187.846 88.6098"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.969 89.8486L191.831 90.9282"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 94.4624L199.822 95.542"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.573 153.536L175.666 155.218C167.924 159.695 167.924 167.014 175.666 171.492L190.244 179.913C196.953 183.788 207.92 183.788 214.63 179.913L217.355 178.333C220.454 176.538 222.986 172.139 222.986 168.56V143.342C222.986 139.762 220.488 135.296 217.446 133.42"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.986 161.729V143.33C222.986 139.75 220.488 135.284 217.445 133.409"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.892 158.9V140.5C227.892 136.921 225.394 132.454 222.352 130.579"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.794 156.07V137.671C232.794 134.091 230.297 129.625 227.254 127.75"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M227.891 158.899V166.309C227.891 168.991 229.787 172.276 232.103 173.617L235.997 175.867"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.797 156.07V163.48C232.797 166.162 234.693 169.446 237.009 170.787L240.903 173.037"
        stroke="#00FF82"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10651_19587"
          x1="59.1047"
          y1="87.3024"
          x2="230.842"
          y2="87.3024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FF82" />
          <stop offset="0.05" stopColor="#00FF82" stopOpacity="0.83" />
          <stop offset="0.13" stopColor="#00FF82" stopOpacity="0.58" />
          <stop offset="0.2" stopColor="#00FF82" stopOpacity="0.37" />
          <stop offset="0.28" stopColor="#00FF82" stopOpacity="0.21" />
          <stop offset="0.34" stopColor="#00FF82" stopOpacity="0.09" />
          <stop offset="0.4" stopColor="#00FF82" stopOpacity="0.02" />
          <stop offset="0.45" stopColor="#00FF82" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10651_19587"
          x1="109.25"
          y1="149.865"
          x2="280.998"
          y2="149.865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FF82" />
          <stop offset="0.05" stopColor="#00FF82" stopOpacity="0.83" />
          <stop offset="0.13" stopColor="#00FF82" stopOpacity="0.58" />
          <stop offset="0.2" stopColor="#00FF82" stopOpacity="0.37" />
          <stop offset="0.28" stopColor="#00FF82" stopOpacity="0.21" />
          <stop offset="0.34" stopColor="#00FF82" stopOpacity="0.09" />
          <stop offset="0.4" stopColor="#00FF82" stopOpacity="0.02" />
          <stop offset="0.45" stopColor="#00FF82" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuizDialogIllustration;
