import React from "react";

const TestingOutlinedIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 17V16.425C8 15.6917 8.36667 15.1042 9.1 14.6625C9.83333 14.2209 10.8 14 12 14C13.2 14 14.1667 14.2209 14.9 14.6625C15.6333 15.1042 16 15.6917 16 16.425V17H8ZM12 13C11.45 13 10.9792 12.8042 10.5875 12.4125C10.1958 12.0209 10 11.55 10 11C10 10.45 10.1958 9.97919 10.5875 9.58752C10.9792 9.19586 11.45 9.00002 12 9.00002C12.55 9.00002 13.0208 9.19586 13.4125 9.58752C13.8042 9.97919 14 10.45 14 11C14 11.55 13.8042 12.0209 13.4125 12.4125C13.0208 12.8042 12.55 13 12 13ZM3 9.20002V16.85C3.23333 17.0667 3.51667 17.2542 3.85 17.4125C4.18333 17.5709 4.56667 17.6917 5 17.775V9.85002C4.63333 9.76669 4.27917 9.67086 3.9375 9.56252C3.59583 9.45419 3.28333 9.33336 3 9.20002ZM21 9.17502C20.7167 9.30836 20.4083 9.42919 20.075 9.53752C19.7417 9.64586 19.3833 9.74169 19 9.82502V17.775C19.4333 17.6917 19.8167 17.5709 20.15 17.4125C20.4833 17.2542 20.7667 17.0667 21 16.85V9.17502ZM7 20.15C5.08333 19.9167 3.60417 19.525 2.5625 18.975C1.52083 18.425 1 17.7667 1 17V7.00002C1 6.05002 1.94583 5.31669 3.8375 4.80002C5.72917 4.28336 8.45 4.02502 12 4.02502C15.55 4.02502 18.2708 4.28336 20.1625 4.80002C22.0542 5.31669 23 6.05002 23 7.00002V17C23 17.7667 22.4792 18.425 21.4375 18.975C20.3958 19.525 18.9167 19.9167 17 20.15V8.15002C17.85 8.01669 18.6042 7.85419 19.2625 7.66252C19.9208 7.47086 20.3917 7.28336 20.675 7.10002C19.9583 6.81669 18.7333 6.56252 17 6.33752C15.2667 6.11252 13.6 6.00002 12 6.00002C10.4 6.00002 8.73333 6.11252 7 6.33752C5.26667 6.56252 4.04167 6.81669 3.325 7.10002C3.60833 7.30002 4.07917 7.49169 4.7375 7.67502C5.39583 7.85836 6.15 8.01669 7 8.15002V20.15Z"
        fill="white"
      />
    </svg>
  );
};

export default TestingOutlinedIcon;
