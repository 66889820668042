import React from "react";

const CircleOutlinedIcon = () => {
  return (
    <svg
      role="presentation"
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1iirmgg"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CircleOutlinedIcon"
      fill="#232221"
    >
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"></path>
    </svg>
  );
};

export default CircleOutlinedIcon;
