export const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "60%",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "40%",
  },
];
