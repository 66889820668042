import { getResponsePost } from "../utils";

const postDataReportConfig = async (data) => {
  let dataReportConfig = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost("/data-report-config", data);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const responseData = response.data;

    dataReportConfig.result = responseData;
  } catch (error) {
    dataReportConfig.error = error;
  }

  return dataReportConfig;
};

export default postDataReportConfig;
