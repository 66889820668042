import React from "react";

import {
  CardHeader,
  CardWithLoadingState,
  DonutChart,
  Legend,
} from "../../../..";

const CardMFAProtectionStatus = ({ mfaProtectionStatuses }) => {
  return (
    <CardWithLoadingState loadingVariable={mfaProtectionStatuses}>
      <CardHeader header="MFA Protection Status" />
      <div>
        <p className="description text-gray-surface-contrast">
          Check your organization's adoption of multi-factor authentication
          (MFA) to safeguard critical accounts. Ensure comprehensive protection
          by increasing MFA compliance.
        </p>
      </div>
      <div className="flex justify-center py-0.5">
        <div className="max-w-[400px] w-full">
          <div className="flex items-center gap-1 py-0.5">
            <div className="flex max-w-[224px] w-full">
              <DonutChart
                data={mfaProtectionStatuses}
                title="Total Users"
                minHeight={224}
              />
            </div>
            <div className="w-[164px]">
              <Legend data={mfaProtectionStatuses} />
            </div>
          </div>
          <div className="mt-2">
            <p className="detailed">
              Users without MFA are at higher risk of compromised accounts.
              Focus on expanding MFA coverage for complete protection.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
          {/* <ButtonOutlinedGreen isSmall>Assign Training</ButtonOutlinedGreen>
          <ButtonOutlinedGreen isSmall>Send Reminder</ButtonOutlinedGreen>
          <ButtonOutlinedGreen isSmall>Details</ButtonOutlinedGreen> */}
        </div>
      </div>
    </CardWithLoadingState>
  );
};

export default CardMFAProtectionStatus;
