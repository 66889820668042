import React from "react";

import "./x-icon.css";

const XIcon = ({ size = 16 }) => {
  return (
    <svg
      role="presentation"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className="x-icon"
    >
      <g clipPath="url(#clip0_16040_6514)">
        <path
          d="M9.02235 6.88994L14.6661 0.32959H13.3287L8.42825 6.02585L4.51429 0.32959H0L5.91868 8.94335L0 15.8229H1.33745L6.51244 9.80744L10.6459 15.8229H15.1602L9.02202 6.88994H9.02235ZM7.19053 9.01924L6.59084 8.1615L1.81936 1.33641H3.87361L7.72425 6.84448L8.32394 7.70221L13.3293 14.8619H11.2751L7.19053 9.01957V9.01924Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16040_6514">
          <rect
            width="15.1602"
            height="15.5013"
            fill="white"
            transform="translate(0 0.32959)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XIcon;
