import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import "./maintenance-notification.css";

function MaintenanceNotification() {
  const [notifications] = useState([
    {
      description:
        "We are currently undergoing maintenance. Please check back later.",
    },
  ]);
  const [selectedNotification, setSelectedNotification] = useState({
    description: "",
  });

  useEffect(() => {
    if (notifications.length > 0) {
      setSelectedNotification(notifications[0]);
    }
  }, [notifications]);

  return (
    <Box
      className="banner-notification-box-main"
      display="flex"
      flexWrap={["wrap", "nowrap"]}
      justifyContent="center"
      position="sticky"
      padding="12px 30px"
    >
      <Typography
        variant="description-emphasized"
        component="p"
        className="banner-notification-text-description"
        textAlign="center"
        marginBottom={{
          xs: "10px",
          md: "0",
        }}
      >
        {selectedNotification.description}
      </Typography>
    </Box>
  );
}

export default MaintenanceNotification;
