import React, { useRef } from "react";

import { Box, Stack, Typography } from "@mui/material";

import "./testimonials.css";
import { Translate } from "react-auto-translate";
import { cisoAdvisoryCouncilQuotes } from "../../../utils/constants/website/testimonials";

function Testimonials() {
  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startPos = 0;
  let scrollLeft = 0;

  const handleMouseDown = (e) => {
    isDragging = true;
    startPos = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging = false;
  };

  const handleMouseUp = () => {
    isDragging = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startPos) * 2; // Scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="center">
        <Box
          maxWidth={{
            xs: "100%",
            md: "1600px",
          }}
          width="100%"
        >
          <Box
            tabIndex="0"
            role="button"
            ref={scrollContainerRef}
            className="home-box-ciso-scroll"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <Stack
              direction="row"
              className="home-box-ciso-content"
              width="fit-content"
            >
              {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                <Box
                  width="300px"
                  marginLeft={quotesIndex > 0 ? "20px" : 0}
                  key={`quotes-item-${quotesIndex}`}
                >
                  {quotesItem.cisos.map((ciso, cisoIndex) => (
                    <Box
                      className="home-box-background-gray"
                      marginTop={cisoIndex > 0 ? "20px" : 0}
                      padding="10px"
                      key={`quotes-item-${quotesIndex}-ciso-${cisoIndex}`}
                    >
                      <Box>
                        <Typography variant="body-gray" component="p">
                          <Translate>{ciso.quote}</Translate>
                        </Typography>
                      </Box>
                      <Box marginTop="30px">
                        <Stack direction="row" alignItems="center">
                          <Box>
                            <Box
                              component="img"
                              className="home-image-border-radius"
                              src={ciso.profilePicture}
                              alt={ciso.name}
                              height="40px"
                              width="40px"
                            />
                          </Box>
                          <Box marginLeft="12px">
                            <Box>
                              <Typography
                                variant="description-emphasized-gray"
                                component="span"
                              >
                                {ciso.name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="description-gray"
                                component="span"
                              >
                                {ciso.title} | {ciso.companyName}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
              {cisoAdvisoryCouncilQuotes.map((quotesItem, quotesIndex) => (
                <Box
                  width="300px"
                  marginLeft="20px"
                  key={`quotes-item-duplicate-${quotesIndex}`}
                >
                  {quotesItem.cisos.map((ciso, cisoIndex) => (
                    <Box
                      className="home-box-background-gray"
                      marginTop={cisoIndex > 0 ? "20px" : 0}
                      padding="10px"
                      key={`quotes-item-duplicate-${quotesIndex}-ciso-${cisoIndex}`}
                    >
                      <Box>
                        <Typography variant="body-gray" component="p">
                          <Translate>{ciso.quote}</Translate>
                        </Typography>
                      </Box>
                      <Box marginTop="30px">
                        <Stack direction="row" alignItems="center">
                          <Box>
                            <Box
                              component="img"
                              className="home-image-border-radius"
                              src={ciso.profilePicture}
                              alt={ciso.name}
                              height="40px"
                              width="40px"
                            />
                          </Box>
                          <Box marginLeft="12px">
                            <Box>
                              <Typography
                                variant="description-emphasized-gray"
                                component="span"
                              >
                                {ciso.name}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="description-gray"
                                component="span"
                              >
                                {ciso.title} | {ciso.companyName}
                              </Typography>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Testimonials;
