import React from "react";

const PlayArrowFilledIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21 24"
      fill="none"
    >
      <path
        d="M20 10.2679C21.3333 11.0377 21.3333 12.9622 20 13.732L3.5 23.2583C2.16667 24.0281 0.500003 23.0659 0.500003 21.5263L0.500004 2.47372C0.500004 0.934117 2.16667 -0.0281317 3.5 0.741669L20 10.2679Z"
        fill="#0C0C0B"
      />
    </svg>
  );
};

export default PlayArrowFilledIcon;
