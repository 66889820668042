export const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "500px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "172px",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "172px",
  },
];
