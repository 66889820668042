import React from "react";

const NetlifyLogo = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 228 94"
      fill="none"
    >
      <mask
        id="mask0_15474_4382"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="228"
        height="93"
      >
        <path d="M228 0H0V92.902H228V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_15474_4382)">
        <path
          d="M52.0752 92.4158V68.9473L52.5616 68.4609H58.4288L58.9152 68.9473V92.4158L58.4288 92.903H52.5616L52.0752 92.4158Z"
          fill="white"
        />
        <path
          d="M52.0752 23.9552V0.4864L52.5617 0H58.4288L58.9152 0.4864V23.9552L58.4288 24.4416H52.5617L52.0752 23.9552Z"
          fill="white"
        />
        <path
          d="M31.8822 75.9167H31.0766L27.041 71.8811V71.0755L34.603 63.5059L38.8742 63.5135L39.4442 64.0759V68.3471L31.8822 75.9167Z"
          fill="white"
        />
        <path
          d="M27.041 22.0395V21.2263L31.0766 17.1982H31.8822L39.4442 24.7603V29.0239L38.8742 29.6014H34.603L27.041 22.0395Z"
          fill="white"
        />
        <path
          d="M0.4864 43.0244H33.7364L34.2228 43.5109V49.3856L33.7364 49.8721H0.4864L0 49.3856V43.5109L0.4864 43.0244Z"
          fill="white"
        />
        <path
          d="M227.505 43.0312L227.992 43.5177V49.3849L227.505 49.8713H193.769L193.283 49.3849L195.723 43.5177L196.209 43.0312H227.505Z"
          fill="white"
        />
        <path
          d="M66.5002 58.6797H60.6329L60.1465 58.1934V44.4601C60.1465 42.0129 59.1889 40.1205 56.2402 40.0597C54.7201 40.0217 52.9873 40.0597 51.133 40.1357L50.8517 40.4169V58.1857L50.3653 58.6721H44.4982L44.0117 58.1857V34.7169L44.4982 34.2305H57.6994C62.8294 34.2305 66.9865 38.3877 66.9865 43.5177V58.1857L66.5002 58.6721V58.6797Z"
          fill="white"
        />
        <path
          d="M94.9168 48.4039L94.4305 48.8903H79.2606L78.7742 49.3767C78.7742 50.3571 79.7546 53.2907 83.6686 53.2907C85.1354 53.2907 86.6022 52.8043 87.0962 51.8239L87.5826 51.3375H93.4499L93.9362 51.8239C93.4499 54.7575 91.0025 59.1655 83.661 59.1655C75.3466 59.1655 71.4326 53.2983 71.4326 46.4431C71.4326 39.5879 75.3466 33.7207 83.1746 33.7207C91.0025 33.7207 94.9168 39.5879 94.9168 46.4431V48.4039ZM87.575 43.5095C87.575 43.0231 87.0886 39.5955 83.1746 39.5955C79.2606 39.5955 78.7742 43.0231 78.7742 43.5095L79.2606 43.9959H87.0886L87.575 43.5095Z"
          fill="white"
        />
        <path
          d="M108.611 51.3378C108.611 52.3182 109.098 52.8046 110.078 52.8046H114.479L114.965 53.2911V58.1854L114.479 58.6719H110.078C105.678 58.6719 101.763 56.7111 101.763 51.3303V40.5686L101.277 40.0822H97.85L97.3638 39.5958V34.7014L97.85 34.215H101.277L101.763 33.7286V29.3282L102.25 28.8418H108.117L108.603 29.3282V33.7286L109.09 34.215H114.471L114.957 34.7014V39.5958L114.471 40.0822H109.09L108.603 40.5686V51.3303L108.611 51.3378Z"
          fill="white"
        />
        <path
          d="M126.715 58.6792H120.848L120.361 58.1927V24.92L120.848 24.4336H126.715L127.201 24.92V58.1852L126.715 58.6715V58.6792Z"
          fill="white"
        />
        <path
          d="M139.924 30.3008H134.056L133.57 29.8144V24.92L134.056 24.4336H139.924L140.41 24.92V29.8144L139.924 30.3008ZM139.924 58.6792H134.056L133.57 58.1927V34.7087L134.056 34.2224H139.924L140.41 34.7087V58.1927L139.924 58.6792Z"
          fill="white"
        />
        <path
          d="M162.921 24.9198V29.8142L162.435 30.3006H158.034C157.054 30.3006 156.567 30.787 156.567 31.7674V33.7282L157.054 34.2146H161.948L162.435 34.701V39.5954L161.948 40.0818H157.054L156.567 40.5682V58.1774L156.081 58.6638H150.214L149.727 58.1774V40.5682L149.241 40.0818H145.814L145.327 39.5954V34.701L145.814 34.2146H149.241L149.727 33.7282V31.7674C149.727 26.3866 153.642 24.4258 158.042 24.4258H162.442L162.929 24.9122L162.921 24.9198Z"
          fill="white"
        />
        <path
          d="M181.017 59.1659C179.056 64.0603 177.103 66.9939 170.256 66.9939H167.808L167.322 66.5075V61.613L167.808 61.1267H170.256C172.702 61.1267 173.189 60.6403 173.683 59.1735V58.6871L165.855 39.6035V34.709L166.341 34.2227H170.742L171.228 34.709L177.096 51.3455H177.582L183.449 34.709L183.936 34.2227H188.336L188.822 34.709V39.6035L180.994 59.1735L181.017 59.1659Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default NetlifyLogo;
