import React from "react";

import "./loading-spinner.css";

const LoadingSpinner = ({ className }) => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      style={{
        animation: "spin 1s linear infinite",
        transformOrigin: "center",
      }}
      className={className}
    >
      <mask id="path-1-inside-1_99_12324" fill="white">
        <path d="M44.7109 23.2333C45.1467 23.2333 45.5014 23.5868 45.4862 24.0223C45.3339 28.3626 43.9282 32.5724 41.4307 36.1391C38.7822 39.9215 35.034 42.7975 30.6949 44.3766C26.3558 45.9558 21.6358 46.1617 17.1757 44.9664C12.7155 43.7711 8.73112 41.2325 5.76321 37.6951C2.7953 34.1577 0.987615 29.7928 0.585485 25.1928C0.183354 20.5928 1.20625 15.9805 3.51535 11.9817C5.82446 7.98301 9.30794 4.79156 13.493 2.8405C17.4394 1.00073 21.8295 0.347904 26.1301 0.952123C26.5617 1.01276 26.8481 1.4235 26.7724 1.85268C26.6967 2.28185 26.2875 2.56682 25.8558 2.50733C21.8743 1.95868 17.8127 2.56797 14.1599 4.27088C10.2683 6.08508 7.02917 9.05269 4.88203 12.7709C2.73489 16.4892 1.78374 20.778 2.15766 25.0554C2.53159 29.3327 4.21248 33.3915 6.97221 36.6807C9.73195 39.97 13.4369 42.3306 17.5842 43.442C21.7315 44.5535 26.1204 44.362 30.1551 42.8936C34.1899 41.4252 37.6752 38.751 40.138 35.2338C42.4497 31.9325 43.7554 28.0385 43.907 24.0223C43.9234 23.5868 44.2751 23.2333 44.7109 23.2333Z" />
      </mask>
      <path
        d="M44.7109 23.2333C45.1467 23.2333 45.5014 23.5868 45.4862 24.0223C45.3339 28.3626 43.9282 32.5724 41.4307 36.1391C38.7822 39.9215 35.034 42.7975 30.6949 44.3766C26.3558 45.9558 21.6358 46.1617 17.1757 44.9664C12.7155 43.7711 8.73112 41.2325 5.76321 37.6951C2.7953 34.1577 0.987615 29.7928 0.585485 25.1928C0.183354 20.5928 1.20625 15.9805 3.51535 11.9817C5.82446 7.98301 9.30794 4.79156 13.493 2.8405C17.4394 1.00073 21.8295 0.347904 26.1301 0.952123C26.5617 1.01276 26.8481 1.4235 26.7724 1.85268C26.6967 2.28185 26.2875 2.56682 25.8558 2.50733C21.8743 1.95868 17.8127 2.56797 14.1599 4.27088C10.2683 6.08508 7.02917 9.05269 4.88203 12.7709C2.73489 16.4892 1.78374 20.778 2.15766 25.0554C2.53159 29.3327 4.21248 33.3915 6.97221 36.6807C9.73195 39.97 13.4369 42.3306 17.5842 43.442C21.7315 44.5535 26.1204 44.362 30.1551 42.8936C34.1899 41.4252 37.6752 38.751 40.138 35.2338C42.4497 31.9325 43.7554 28.0385 43.907 24.0223C43.9234 23.5868 44.2751 23.2333 44.7109 23.2333Z"
        stroke="#C2BFB8"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinejoin="round"
        mask="url(#path-1-inside-1_99_12324)"
      />
    </svg>
  );
};

export default LoadingSpinner;
