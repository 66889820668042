import React from "react";

const CrowdstrikeBrandMarkIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 29"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0412 22.9428C34.2371 22.881 32.8454 22.6954 31.0515 23.4995C29.2887 24.3346 28.5773 24.3655 27.7113 24.2727C27.9588 24.7057 28.4845 25.2933 30.0928 25.4171C31.701 25.5098 32.5052 25.5717 31.6392 27.4583C31.6701 26.9016 31.5155 25.7882 29.9072 25.9738C28.299 26.1593 27.9278 27.5202 29.6598 28.1696C29.1031 28.2624 27.8969 28.3243 27.0619 26.283C26.4742 26.5305 25.5773 26.9944 23.9072 25.8191C24.4948 26.0047 25.2062 26.0356 25.2062 26.0356C23.7526 25.3861 22.3299 24.1799 21.4639 23.0356C22.1753 23.5305 22.9485 24.0253 23.7526 24.1181C22.8247 23.0665 20.6598 20.9325 18 18.7676C19.701 19.7882 21.7732 21.4274 25.1134 21.0562C28.4845 20.6851 30.7423 19.9738 35.0412 22.9428Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8452 22.5407C18.5875 21.7056 18.1235 21.52 15.2163 20.9015C12.34 20.252 9.49467 18.953 7.57715 16.8809C8.90705 17.7468 11.6596 19.4479 14.4741 19.2623C14.0411 18.7056 13.2679 18.3035 12.3091 17.8706C13.3607 18.087 16.5771 18.7984 20.8452 22.5407Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1956 20.6544C34.175 20.84 34.0822 21.1183 34.0822 21.6132C33.2471 20.9946 32.1956 20.6544 32.1956 20.6544ZM20.2574 10.4173C11.7214 7.97399 8.3193 4.91213 5.69043 1.72656C6.89662 5.40697 9.74198 6.73687 12.8038 9.2111C15.8657 11.6853 16.0513 13.0152 16.9482 14.4998C18.9585 17.7781 19.2677 18.3039 21.2781 19.7266C23.6286 21.273 26.4739 20.2214 29.5976 20.7163C32.7214 21.2111 35.2884 23.5616 35.8451 24.4585C36.4945 23.3142 34.9482 21.6441 34.5152 21.2111C34.7317 19.6956 31.144 19.0152 29.7523 18.5204C29.4739 18.4276 28.8244 18.273 29.3811 16.9121C30.1543 15.0255 30.9894 13.3864 20.2574 10.4173Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1134 14.9635C15.5567 13.448 14.5979 11.5305 9.95876 8.65418C7.6701 7.20057 4.36082 5.40675 0 0.767578C0.309278 2.00469 1.70103 5.25211 8.62887 9.48923C10.9175 11.0047 13.8866 11.9325 16.1134 14.9635Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1135 17.8085C15.5877 16.5405 14.4743 14.9013 10.2372 12.5817C8.25785 11.4683 4.91765 9.73636 1.88672 6.45801C2.16507 7.63327 3.55682 10.2312 9.58775 13.4786C11.2579 14.4374 14.0723 15.3034 16.1135 17.8085Z"
        fill="white"
      />
    </svg>
  );
};

export default CrowdstrikeBrandMarkIcon;
