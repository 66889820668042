import React, { useEffect, useRef, useState } from "react";

import { DropDownArrowIcon } from "../../../utils/icons";

const MultiSelect = ({ value, options, onChange, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Toggle the dropdown open/close.
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close the dropdown when clicking outside.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle selecting/deselecting an option
  const handleSelectOption = (selectedValue) => {
    onChange(selectedValue);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Dropdown button */}
      <div
        tabIndex="0"
        role="button"
        className="flex items-center justify-between border border-border-color bg-gray-select rounded-small py-0.5 px-1 cursor-pointer"
        onClick={toggleDropdown}
      >
        <span
          className="overflow-hidden whitespace-nowrap text-ellipsis description"
          title={value.map((e) => e.name).join(", ")} // Show full text on hover
        >
          {value.length > 0
            ? [...value.map((e) => e.name)].join(", ")
            : defaultValue || "Select Options"}
        </span>
        <DropDownArrowIcon />
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-10 w-full border shadow-lg bg-gray-select border-border-color rounded-small overflow-y-auto max-h-[500px]">
          {options.map((option, index) => {
            const isOptionSelected = value.some(
              (selected) => selected.value === option.value,
            );

            return (
              <label
                className="flex items-center p-0.25 cursor-pointer description hover:font-medium"
                key={`option-${index}`}
              >
                <input
                  type="checkbox"
                  className="mr-0.5 accent-green"
                  checked={isOptionSelected}
                  onChange={() => handleSelectOption(option)}
                />
                {option.name}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
