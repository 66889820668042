import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import { FadeInSection } from "../../../components";

import { spacing } from "../../../styles";

import { FadedInImage } from "./utils";

import "./ciso-advisory-council.css";
import CisoScrollSection from "../../../components/common/ciso-scrolling/ciso-scrolling";

import { cisos } from "../../../utils/constants/website/ciso-advisory-council";

function CISOAdvisoryCouncil() {
  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop="60px"
          padding="0 30px"
        >
          <Box maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Typography
              role="heading"
              aria-level="1"
              variant="spaced-header"
              component="h1"
              textAlign="center"
            >
              The CISO Advisory Council
            </Typography>
            <Typography
              role="heading"
              aria-level="2"
              variant="h1"
              component="h2"
              textAlign="center"
              marginTop="10px"
            >
              Built{" "}
              <Typography component="span" className="dune-text-italic">
                for
              </Typography>{" "}
              enterprise CISOs,{" "}
              <Typography component="span" className="dune-text-italic">
                by
              </Typography>{" "}
              enterprise CISOs.
            </Typography>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={{ xs: "0 30px", md: "0 15px" }}
        >
          <Box
            maxWidth={spacing.MAX_WIDTH_WEBSITE}
            width="100%"
            marginTop="60px"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <Grid container justifyContent="center" width="100%">
                {cisos.map((ciso, index) => (
                  <Grid
                    item
                    xs={6}
                    md={2.4}
                    display="flex"
                    marginTop={{
                      xs: index > 1 ? "30px" : 0,
                      md: index > 4 ? "30px" : 0,
                    }}
                    paddingLeft={{
                      xs: index % 2 === 0 ? 0 : `${30 / 2}px`,
                      md: "15px",
                    }}
                    paddingRight={{
                      xs: index % 2 === 0 ? `${30 / 2}px` : 0,
                      md: "15px",
                    }}
                    key={`ciso-data-${index}`}
                  >
                    <Box
                      className="dune-box-background-gray"
                      padding="30px 20px"
                      maxWidth={{
                        xs: "100%",
                        md: "180px",
                      }}
                      width="100%"
                    >
                      <FadedInImage
                        alt={ciso.name}
                        src={`https://d3oo9a669kwmx7.cloudfront.net/headshots/${ciso.headshotFileName}`}
                      />
                      <Box marginTop="10px">
                        <Typography
                          variant="body-emphasized"
                          component="p"
                          textAlign="center"
                        >
                          {ciso.name}
                        </Typography>
                        <Typography
                          variant="description"
                          component="p"
                          textAlign="center"
                          marginTop="4px"
                        >
                          {ciso.role} | {ciso.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <div className="mt-10">
          <CisoScrollSection />
        </div>
      </FadeInSection>
    </>
  );
}

export default CISOAdvisoryCouncil;
