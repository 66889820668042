import React from "react";

const PersonFilledIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49997 6C7.49997 4.80653 7.97408 3.66193 8.81799 2.81802C9.66191 1.97411 10.8065 1.5 12 1.5C13.1934 1.5 14.338 1.97411 15.182 2.81802C16.0259 3.66193 16.5 4.80653 16.5 6C16.5 7.19347 16.0259 8.33807 15.182 9.18198C14.338 10.0259 13.1934 10.5 12 10.5C10.8065 10.5 9.66191 10.0259 8.81799 9.18198C7.97408 8.33807 7.49997 7.19347 7.49997 6ZM3.75097 20.105C3.78469 17.9395 4.66862 15.8741 6.21193 14.3546C7.75525 12.8351 9.83419 11.9834 12 11.9834C14.1658 11.9834 16.2447 12.8351 17.788 14.3546C19.3313 15.8741 20.2153 17.9395 20.249 20.105C20.2516 20.2508 20.2116 20.3942 20.134 20.5176C20.0564 20.641 19.9445 20.7392 19.812 20.8C17.3611 21.9237 14.6961 22.5037 12 22.5C9.21397 22.5 6.56697 21.892 4.18797 20.8C4.05546 20.7392 3.94355 20.641 3.86594 20.5176C3.78832 20.3942 3.74837 20.2508 3.75097 20.105Z"
        fill="white"
      />
    </svg>
  );
};

export default PersonFilledIcon;
