import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { ExitToAppOutlined } from "@mui/icons-material";

import { postFeedback } from "../../../api";

import { Avatar } from "../../common";

import "./navigation-platform-top.css";
import { DropDownArrowIcon } from "../../../utils/icons";

function NavigationPlatformTop({
  email,
  firstName,
  initials,
  lastName,
  onLogOut,
}) {
  // Popover: User
  const [anchorElPopoverUser, setAnchorElPopoverUser] = useState(null);
  const [openPopoverUser, setOpenPopoverUser] = useState(false);

  // Popover: Feedback
  const [anchorElPopoverFeedback, setAnchorElPopoverFeedback] = useState(null);
  const [isErrorFeedback, setIsErrorFeedback] = useState(false);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);

  const handleClosePopoverUser = () => {
    setAnchorElPopoverUser(null);
    setOpenPopoverUser(false);
  };

  const handleClickAvatarInitials = (event) => {
    setAnchorElPopoverUser(event.currentTarget);
    setOpenPopoverUser(true);
  };

  const handleClickSubmitFeedback = async (event) => {
    event.preventDefault();

    setIsLoadingFeedback(true);
    setIsErrorFeedback(false);

    const data = new FormData(event.currentTarget);
    const message = data.get("feedback");

    if (!message) {
      setIsErrorFeedback(true);
    } else {
      const requestBody = {
        message,
      };

      // const feedback = await postFeedback(requestBody);
      await postFeedback(requestBody);

      // if (Object.keys(feedback.error).length > 0) {
      //     setMessageError(constants.ERROR_DEFAULT);
      // } else {
      //     setMessageSuccess("Your message has been sent.");
      // }
    }

    setIsLoadingFeedback(false);
  };

  const handleClosePopover = () => {
    setAnchorElPopoverFeedback(null);
  };

  return (
    <>
      <Box
        className="navigation-platform-top-box"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="68px"
        paddingRight="10px"
      >
        <Button
          aria-label={initials}
          onClick={handleClickAvatarInitials}
          endIcon={<DropDownArrowIcon />}
        >
          <Avatar>{initials}</Avatar>
        </Button>
        <Popover
          className="navigation-platform-top-popover-user"
          open={openPopoverUser}
          anchorEl={anchorElPopoverUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClosePopoverUser}
        >
          <Box paddingBottom="10px">
            {firstName && lastName && (
              <Typography
                component="p"
                className="navigation-platform-top-text-bold"
                textAlign="center"
              >
                {firstName} {lastName}
              </Typography>
            )}
            {email && (
              <Typography component="p" textAlign="center" marginTop="4px">
                {email}
              </Typography>
            )}
          </Box>
          <Box marginTop="10px">
            <Divider className="dune-divider-gray" />
            <Box marginTop="10px">
              <Button
                variant="text"
                className="dune-button-text-white navigation-platform-top-button-log-out"
                fullWidth
                title="Log out the platform."
                aria-label="Log out the platform."
                startIcon={
                  <ExitToAppOutlined className="navigation-platform-top-icon-log-out" />
                }
                onClick={onLogOut}
              >
                Log out
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
      <Popover
        className="navigation-platform-top-popover-feedback"
        open={Boolean(anchorElPopoverFeedback)}
        anchorEl={anchorElPopoverFeedback}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {isLoadingFeedback ? (
          <Box width="100%" textAlign="center">
            <CircularProgress
              className="loading-spinner-circular-progress"
              size="5vh"
            />
          </Box>
        ) : (
          <Box component="form" noValidate onSubmit={handleClickSubmitFeedback}>
            <Typography component="p">Feedback/Question</Typography>
            <Box marginTop="20px">
              <TextField
                variant="filled"
                className="dune-text-field-filled dune-text-field-filled-multiline navigation-platform-top-text-field-feedback"
                name="feedback"
                placeholder="Enter your feedback or question."
                type="text"
                error={isErrorFeedback}
                multiline
                rows={6}
                required
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box marginTop="20px">
              <Button
                aria-label="Submit"
                variant="outlined"
                className="dune-button-outlined-white"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Popover>
    </>
  );
}

export default NavigationPlatformTop;
