import React from "react";

const CollapseTransition = ({ isOpen, children, maxHeight = 500 }) => {
  return (
    <div
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        isOpen ? `max-h-[${maxHeight}px] opacity-100` : "max-h-0 opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

export default CollapseTransition;
