import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { createClient } from "contentful";

import { FadeInSection, ResourcesGroup } from "../../../components";

import { Translate } from "react-auto-translate";

function ResourcesViewAll() {
  const location = useLocation();

  const [resources, setResources] = useState([]);

  useEffect(() => {
    const contentTypeMap = {
      "/resources/bootcamp/": "resourcesBootcamp",
      "/resources/team/": "resourcesTeam",
      "/resources/whitepaper/": "whitepaper", // Added whitepaper
    };

    const fetchResources = async () => {
      if (process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        client
          .getEntries({
            content_type: contentTypeMap[location.pathname] || "resourcesTeam",
            order: "-fields.date,fields.title",
          })
          .then((response) => {
            const fetchedResources = response.items.map((item) => {
              const { fields, metadata, sys } = item;

              let href = "";
              if (location.pathname === "/resources/bootcamp/") {
                href = `${location.pathname}${fields.resourceID}`;
              } else if (location.pathname === "/resources/whitepaper/") {
                // Handle whitepaper URL logic
                href = `/resources/whitepaper/${item.referenceId}`;
              } else {
                href = fields.resourceURL || "";
              }

              return {
                id: sys.id,
                title: fields.title,
                href,
                thumbnailURL: fields.thumbnail.fields.file.url,
                date: fields.date,
                tags: metadata.tags.map((tag) => {
                  return {
                    id: tag.id,
                  };
                }),
              };
            });

            setResources(fetchedResources);
          })
          .catch(console.error);
      }
    };

    fetchResources();
  }, [location.pathname]);

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "40px",
            md: "80px",
          }}
          padding="0 28px"
        >
          <Box textAlign="center" maxWidth="800px" width="100%">
            <Typography
              role="heading"
              aria-level="1"
              component="h1"
              className="dune-text-header-uppercase"
            >
              <Translate>Resource hub</Translate>
            </Typography>
            <Typography
              role="heading"
              aria-level="2"
              component="h2"
              className="dune-text-header-main"
              marginTop="40px"
            >
              <Translate>
                {location.pathname === "/resources/bootcamp/"
                  ? "Dune Security Bootcamp"
                  : location.pathname === "/resources/whitepaper/"
                    ? "Dune Security Whitepaper"
                    : "From the Team"}
              </Translate>
            </Typography>
          </Box>
        </Box>
      </FadeInSection>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          marginBottom={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 28px"
        >
          <Box maxWidth="1152px" width="100%">
            <ResourcesGroup resources={resources} />
          </Box>
        </Box>
      </FadeInSection>
    </>
  );
}

export default ResourcesViewAll;
