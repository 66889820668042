import React from "react";

const Link = ({ children, href, title = "", target = "_self" }) => {
  return (
    <a
      className="underline focus:rounded-small focus:outline-none focus:ring-1 focus:ring-green"
      href={href}
      title={title}
      aria-label={title}
      target={target}
    >
      {children}
    </a>
  );
};

export default Link;
