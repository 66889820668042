import React from "react";

const CloseIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M13.6384 5.24119L12.6903 4.29309L8.93151 8.05188L5.17271 4.29309L4.22461 5.24119L7.9834 8.99999L4.22461 12.7588L5.17271 13.7069L8.93151 9.94809L12.6903 13.7069L13.6384 12.7588L9.87961 8.99999L13.6384 5.24119Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon;
