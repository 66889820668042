import React, { useEffect, useState } from "react";

import {
  CardHeader,
  CardWithLoadingState,
  DataTable,
  Tabs,
} from "../../../../../common";

import { LegendBoxIcon, TrainingPieChart } from "./utils";

import { headers } from "../../../../../../utils/constants/platform/card-training-completion";

function CardTrainingCompletion({ trainingCompletion, users, email }) {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedTrainingStatus, setSelectedTrainingStatus] = useState({
    name: "Not Started",
  }); // Default is Not Started.

  const formatToPercentage = (number) => {
    return `${(number * 100).toFixed(1)}%`;
  };

  const handleClickCell = (status) => {
    updateSelectedTrainingStatus(status);
  };

  const handleClickLegendBox = (status) => {
    updateSelectedTrainingStatus(status);
  };

  const updateSelectedTrainingStatus = (status) => {
    if (status) {
      setSelectedTrainingStatus({ name: status });
    }
  };

  useEffect(() => {
    if (selectedTrainingStatus && selectedTrainingStatus.name && users) {
      const filteredUsers = users.filter(
        (user) => user.trainingStatus === selectedTrainingStatus.name,
      );

      setFilteredUsers(filteredUsers);
    }
  }, [selectedTrainingStatus, users]);

  return (
    <CardWithLoadingState loadingVariable={trainingCompletion}>
      <CardHeader
        header="Training Status"
        tooltip="Track how many users have completed their assigned security training, and identify who needs to catch up."
      />
      {trainingCompletion && (
        <div className="flex flex-row">
          <div className="flex flex-col">
            <div className="mt-1 w-[200px]">
              <TrainingPieChart
                trainingCompletion={trainingCompletion}
                onClickCell={handleClickCell}
              />
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col mt-1.25">
                <div
                  tabIndex="0"
                  role="button"
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => handleClickLegendBox("Completed")}
                >
                  <div className="w-[16px] h-[16px]">
                    <LegendBoxIcon color="#00ff82" />
                  </div>
                  <div className="ml-[4px]">
                    <p className="detailed">
                      Completed:{" "}
                      {formatToPercentage(trainingCompletion["Completed"])}
                    </p>
                  </div>
                </div>
                <div
                  tabIndex="0"
                  role="button"
                  className="flex flex-row items-center cursor-pointer mt-0.625"
                  onClick={() => handleClickLegendBox("In Progress")}
                >
                  <div className="w-[16px] h-[16px]">
                    <LegendBoxIcon color="#00994e" />
                  </div>
                  <div className="ml-[4px]">
                    <p className="detailed">
                      In Progress:{" "}
                      {formatToPercentage(trainingCompletion["In Progress"])}
                    </p>
                  </div>
                </div>
                <div
                  tabIndex="0"
                  role="button"
                  className="flex flex-row items-center cursor-pointer mt-0.625"
                  onClick={() => handleClickLegendBox("Not Started")}
                >
                  <div className="w-[16px] h-[16px]">
                    <LegendBoxIcon color="#006634" />
                  </div>
                  <div className="ml-[4px]">
                    <p className="detailed">
                      Not Started:{" "}
                      {formatToPercentage(trainingCompletion["Not Started"])}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full ml-1.25">
            <div>
              <Tabs
                data={[
                  {
                    name: "Completed",
                  },
                  {
                    name: "In Progress",
                  },
                  {
                    name: "Not Started",
                  },
                ]}
                selectedTab={selectedTrainingStatus}
                setSelectedTab={setSelectedTrainingStatus}
              />
            </div>
            {users && (
              <div className="mt-1.25">
                <DataTable
                  data={filteredUsers}
                  headers={headers}
                  defaultOrderBy="name"
                  rowsPerPage={5}
                  fixedTotal={
                    email === "david@dune.demo"
                      ? selectedTrainingStatus && selectedTrainingStatus.name
                        ? selectedTrainingStatus.name === "Completed"
                          ? 9060
                          : selectedTrainingStatus.name === "In Progress"
                            ? 1091
                            : selectedTrainingStatus.name === "Not Started"
                              ? 331
                              : null
                        : null
                      : null
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </CardWithLoadingState>
  );
}

export default CardTrainingCompletion;
