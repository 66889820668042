import React from "react";

const HomeOutlinedIcon = () => {
  return (
    <svg
      role="presentation"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="HomeOutlinedIcon"
      fill="#ffffff"
    >
      <path d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81zM12 3 2 12h3v8h6v-6h2v6h6v-8h3z"></path>
    </svg>
  );
};

export default HomeOutlinedIcon;
