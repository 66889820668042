import { getResponseGet } from "../utils";

/**
 * Fetches users with their progress on a specific compliance framework and calculates compliance metrics.
 *
 * This asynchronous function requests the backend for users related to a specific compliance framework.
 * It constructs an object that contains a detailed breakdown of these users, their compliance status, and overall compliance metrics, or an error if the request fails.
 *
 * @param {string} complianceFramework - The identifier for the compliance framework used to filter users.
 * @returns {Object} An object comprising detailed user data and compliance metrics, or an error:
 *   - The 'result' field is an object containing:
 *     - 'users': an array of user objects, where each includes 'firstName', 'lastName', 'email', 'department', 'compliant', and 'riskScore'.
 *       - 'firstName' and 'lastName' are strings representing the user's first and last name, respectively.
 *       - 'email' is a string detailing the user's email address.
 *       - 'department' is a string indicating the department to which the user belongs.
 *       - 'compliant' is a boolean reflecting the user's compliance with the specified framework.
 *       - 'riskScore' is a number representing the user's risk score, rounded to the nearest integer.
 *     - 'compliantUsers': a count of users who are compliant with the compliance framework.
 *     - 'percentageCompliantUsers': a percentage (as a number) representing the rate of users who are compliant within the dataset.
 *   - The 'error' field contains an error object if the request encounters an issue, detailing the nature of the error.
 */
const getUsersByComplianceFramework = async (complianceFramework) => {
  let usersData = {
    result: {
      users: [],
      compliantUsers: 0,
      percentageCompliantUsers: 0,
    },
    error: {},
  };

  const name = complianceFramework.name.replace(" ", "_");

  try {
    const response = await getResponseGet(`/compliance_data/${name}/`);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data.users && Array.isArray(data.users)) {
      let compliantUsers = 0;

      for (let i = 0; i < data.users.length; i++) {
        const userData = data.users[i];

        const user = {
          firstName: userData.first_name,
          lastName: userData.last_name,
          initials: `${
            userData.first_name && userData.first_name.charAt(0).toUpperCase()
          }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
          email: userData.email,
          department: userData.department,
          compliant: userData.compliant,
          riskScore: +userData.risk_score.toFixed(),
        };

        usersData.result.users.push(user);

        if (userData.compliant) {
          compliantUsers++;
        }
      }

      usersData.result.compliantUsers = compliantUsers;
    }

    if (data.percentage_compliant_users) {
      usersData.result.percentageCompliantUsers =
        data.percentage_compliant_users;
    }
  } catch (error) {
    usersData.error = error;
  }

  return usersData;
};

export default getUsersByComplianceFramework;
