import { getResponseGet } from "../utils";

const getComplianceFrameworks = async () => {
  let complianceFrameworksData = {
    result: {
      complianceFrameworks: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/compliance-frameworks/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && Object.keys(data).length > 0) {
      const complianceFrameworks = [];

      for (const key in data) {
        if (
          data.hasOwnProperty(key) &&
          data[key] !== null &&
          data[key] !== undefined
        ) {
          complianceFrameworks.push({
            name: key.replaceAll("_", " "),
            percentage: data[key],
          });
        }
      }

      complianceFrameworks.sort((a, b) => a.name.localeCompare(b.name));

      complianceFrameworksData.result.complianceFrameworks =
        complianceFrameworks;
    }
  } catch (error) {
    complianceFrameworksData.error = error;
  }

  return complianceFrameworksData;
};

export default getComplianceFrameworks;
