const headers = [
  {
    id: "departmentName",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    onClick: (department, navigate) => {
      if (navigate) {
        navigate("/insights/departments/", {
          state: {
            departmentName:
              department && department.departmentName
                ? department.departmentName
                : "",
          },
        });
      }
    },
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "172px",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "172px",
  },
];

export default headers;
