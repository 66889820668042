import React from "react";

const PersonIcon = () => {
  return (
    <div className="mr-0 md:mr-0.5">
      <svg
        role="presentation"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.2593 12C14.4693 12 16.2593 10.21 16.2593 8C16.2593 5.79 14.4693 4 12.2593 4C10.0493 4 8.25928 5.79 8.25928 8C8.25928 10.21 10.0493 12 12.2593 12ZM12.2593 14C9.58928 14 4.25928 15.34 4.25928 18V19C4.25928 19.55 4.70928 20 5.25928 20H19.2593C19.8093 20 20.2593 19.55 20.2593 19V18C20.2593 15.34 14.9293 14 12.2593 14Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default PersonIcon;
