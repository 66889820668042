import React from "react";

import Card from "../card";

import { LoadingState } from "../..";

const CardWithLoadingState = ({
  loadingVariable, // This is required.
  loadingMessage,
  padding,
  borderColor,
  minHeight,
  children,
}) => {
  return (
    <Card padding={padding} borderColor={borderColor} minHeight={minHeight}>
      {loadingVariable === null || loadingVariable === undefined ? (
        <LoadingState message={loadingMessage} />
      ) : (
        children
      )}
    </Card>
  );
};

export default CardWithLoadingState;
