import React from "react";

function TooltipIcon() {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.41 2.91 0.5 6.5 0.5C10.09 0.5 13 3.41 13 7C13 10.59 10.09 13.5 6.5 13.5C2.91 13.5 0 10.59 0 7ZM7.58533 4.38867C6.992 3.87067 6.008 3.87067 5.41533 4.38867C5.31552 4.47601 5.1851 4.52013 5.05277 4.51132C4.92043 4.5025 4.79701 4.44148 4.70967 4.34167C4.62232 4.24186 4.5782 4.11144 4.58702 3.9791C4.59583 3.84676 4.65686 3.72334 4.75667 3.636C5.726 2.788 7.274 2.788 8.24333 3.636C9.252 4.51867 9.252 5.98133 8.24333 6.864C8.0744 7.01077 7.88677 7.13452 7.68533 7.232C7.23467 7.45067 7 7.748 7 8V8.5C7 8.63261 6.94732 8.75979 6.85355 8.85355C6.75979 8.94732 6.63261 9 6.5 9C6.36739 9 6.24022 8.94732 6.14645 8.85355C6.05268 8.75979 6 8.63261 6 8.5V8C6 7.14733 6.70667 6.59533 7.25 6.332C7.37178 6.27289 7.48356 6.19933 7.58533 6.11133C8.13867 5.62667 8.13867 4.87333 7.58533 4.38867ZM6.5 11C6.63261 11 6.75979 10.9473 6.85355 10.8536C6.94732 10.7598 7 10.6326 7 10.5C7 10.3674 6.94732 10.2402 6.85355 10.1464C6.75979 10.0527 6.63261 10 6.5 10C6.36739 10 6.24022 10.0527 6.14645 10.1464C6.05268 10.2402 6 10.3674 6 10.5C6 10.6326 6.05268 10.7598 6.14645 10.8536C6.24022 10.9473 6.36739 11 6.5 11Z"
        fill="#c2bfb8"
        fillOpacity="0.35"
      />
    </svg>
  );
}

export default TooltipIcon;
