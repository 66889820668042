import React from "react";

const FileUploadIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="174"
      viewBox="0 0 175 174"
      fill="none"
    >
      <mask
        id="mask0_779_7297"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="175"
        height="174"
      >
        <rect x="0.708008" width="173.584" height="173.584" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_779_7297)">
        <path
          d="M47.719 135.249C39.2809 135.249 32.1085 132.31 26.2018 126.431C20.2951 120.551 17.3418 113.364 17.3418 104.872C17.3418 96.5545 20.3253 89.4123 26.2922 83.4453C32.2592 77.4784 39.0398 74.4949 46.6341 74.4949C47.8395 64.1281 52.3298 55.5091 60.1049 48.6381C67.8801 41.7671 77.0113 38.3315 87.4987 38.3315C98.9829 38.3315 108.725 42.3312 116.726 50.3305C124.725 58.3311 128.725 68.0735 128.725 79.5577V88.9602H134.511C141.02 88.9602 146.505 91.1903 150.965 95.6504C155.425 100.111 157.656 105.595 157.656 112.105C157.656 118.614 155.486 124.099 151.146 128.559C146.807 133.019 141.382 135.249 134.873 135.249H95.8162C92.6821 135.249 90.0904 134.225 88.0411 132.175C85.9919 130.126 84.9672 127.534 84.9672 124.4V81.0043L68.6937 97.2778L65.0774 93.8423L87.4987 71.421L109.92 93.8423L106.304 97.2778L90.0301 81.0043V124.4C90.0301 125.847 90.6328 127.173 91.8383 128.378C93.0437 129.584 94.3697 130.186 95.8162 130.186H134.511C139.574 130.186 143.853 128.439 147.349 124.943C150.845 121.447 152.593 117.168 152.593 112.105C152.593 107.042 150.845 102.763 147.349 99.2668C143.853 95.771 139.574 94.0231 134.511 94.0231H123.662V79.5577C123.662 69.5526 120.136 61.024 113.084 53.9722C106.032 46.9203 97.5038 43.3944 87.4987 43.3944C77.4935 43.3944 68.965 46.9203 61.9131 53.9722C54.8613 61.024 51.3353 69.5526 51.3353 79.5577H47.3574C40.7274 79.5577 34.9111 82.0289 29.9086 86.9712C24.906 91.9135 22.4047 97.8805 22.4047 104.872C22.4047 111.864 24.8758 117.831 29.8181 122.773C34.7605 127.715 40.7274 130.186 47.719 130.186H65.8007V135.249H47.719Z"
          fill="#4B4B4A"
        />
      </g>
    </svg>
  );
};

export default FileUploadIcon;
