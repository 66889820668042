import React from "react";

const Card = ({
  padding,
  borderColor = "rgba(194, 191, 184, 0.35)",
  minHeight,
  children,
}) => {
  return (
    <div
      className={`flex flex-col h-full border w-full ${
        padding ? padding : "p-1"
      } bg-gray-card rounded-medium transition-all duration-1000`}
      style={{
        border: `1px solid ${borderColor}`,
        minHeight: minHeight ? minHeight : 0,
      }}
    >
      {children}
    </div>
  );
};

export default Card;
