import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import ArrowBackIosNewOutlined from "@mui/icons-material/ArrowBackIosNewOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import { FadeInSection } from "../../../components";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { formatDateFive } from "../../../utils/helper-functions/formatDate";
import { DownloadIcon } from "../../../utils/icons";
import { saveAs } from "file-saver";

const InfographicDetails = () => {
  const [infographic, setInfographic] = useState(null);
  const [allInfographics, setAllInfographics] = useState([]);

  useEffect(() => {
    const url = window.location.href;
    const lastPart = url.substring(url.lastIndexOf("/infographics/") + 14);

    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    if (lastPart) {
      client
        .getEntries({
          content_type: "infographics",
          "fields.referenceId": lastPart,
        })
        .then((response) => {
          if (response.items.length > 0) {
            setInfographic(response.items[0].fields);
          }
        })
        .catch((error) => {
          console.error("Error fetching infographics:", error);
        });
    }
  }, []);

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    const fetchInfographics = async () => {
      client
        .getEntries({
          content_type: "infographics",
          limit: 3,
          order: "-fields.date,fields.title",
        })
        .then((response) => {
          const fetchedInfographics = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              href: `/resources/infographics/${fields.referenceId}`,
              infographic:
                `https:${fields.infographic?.fields?.file?.url}` || "",
              date: fields.date,
            };
          });

          setAllInfographics([...fetchedInfographics]);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchInfographics();
  }, []);

  if (!infographic) {
    return (
      <div className="text-white flex justify-center items-center mt-10">
        Loading...
      </div>
    );
  }

  const downloadInfographic = (url) => {
    saveAs(url);
  };

  return (
    <div className="bg-transparent text-white">
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{ xs: "60px", md: "-80px" }}
          padding="0 28px"
        >
          <div className="w-full md:max-w-78.75 mx-auto md:pt-10 md:pl-3">
            <Link to="/resources/infographics">
              <div className="duration-200 ease-in transform hover:text-gray">
                <span className="pr-2.5">
                  <ArrowBackIosNewOutlined className="resource-icon-resource-hub" />
                </span>
                Resource Hub
              </div>
            </Link>
            <div role="heading" aria-level="2" className="text-h2 my-2.5">
              {infographic.title}
            </div>

            <div>
              {infographic.infographic && (
                <div className="flex justify-center my-10 scale-150">
                  <img
                    src={infographic.infographic.fields.file.url}
                    alt={infographic.title}
                  />
                </div>
              )}
            </div>

            <div className="flex items-center justify-between">
              <div
                role="heading"
                aria-level="5"
                className="flex items-center text-h5"
              >
                <span className="pr-1">
                  <CalendarTodayOutlined className="resource-icon-info" />
                </span>
                {formatDateFive(infographic.date)}
              </div>
              <div
                tabIndex="0"
                role="button"
                onClick={() =>
                  downloadInfographic(infographic.infographic.fields.file.url)
                }
                className="flex items-center space-x-0.25 cursor-pointer"
              >
                <DownloadIcon />
                <div>Download</div>
              </div>
            </div>
          </div>
        </Box>
      </FadeInSection>

      <div className="max-w-78.75 w-full mx-auto my-2.5 mt-10">
        <FadeInSection>
          <div role="heading" aria-level="2" className="mb-5 text-h2">
            Infographics
          </div>
          <div className="max-w-78.75 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            {allInfographics.map((resource) => (
              <a
                key={resource.id}
                href={resource.href}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer group"
              >
                <div className="w-full overflow-hidden rounded-lg image-container">
                  <img
                    src={resource.infographic}
                    alt={resource.title}
                    className="object-cover w-full h-full transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="mt-2 ml-0 md:ml-1">
                  <h3 className="text-leading-tight">{resource.title}</h3>
                  <p className="mt-2 text-gray-400">
                    {formatDateFive(resource.date)}
                  </p>
                </div>
              </a>
            ))}
          </div>
          <div className="flex justify-end max-w-78.75 mt-4 pb-4">
            <Link
              to="/resources/infographics/"
              className="inline-flex items-center text-lg duration-100 ease-in-out transform hover:text-gray"
            >
              View all <span className="ml-2">&rarr;</span>
            </Link>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

export default InfographicDetails;
