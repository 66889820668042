import React from "react";

const SignalCellularFilledIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.375 2.25C17.34 2.25 16.5 3.09 16.5 4.125V19.875C16.5 20.91 17.34 21.75 18.375 21.75H19.125C20.16 21.75 21 20.91 21 19.875V4.125C21 3.089 20.16 2.25 19.125 2.25H18.375ZM9.75 8.625C9.75 7.589 10.59 6.75 11.625 6.75H12.375C13.411 6.75 14.25 7.59 14.25 8.625V19.875C14.25 20.91 13.41 21.75 12.375 21.75H11.625C11.1277 21.75 10.6508 21.5525 10.2992 21.2008C9.94754 20.8492 9.75 20.3723 9.75 19.875V8.625ZM3 13.125C3 12.089 3.84 11.25 4.875 11.25H5.625C6.661 11.25 7.5 12.09 7.5 13.125V19.875C7.5 20.91 6.66 21.75 5.625 21.75H4.875C4.37772 21.75 3.90081 21.5525 3.54917 21.2008C3.19754 20.8492 3 20.3723 3 19.875V13.125Z"
        fill="white"
      />
    </svg>
  );
};

export default SignalCellularFilledIcon;
