import React from "react";

const SearchIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.2994 14.0309H14.5549L14.2911 13.7765C14.88 13.0924 15.3104 12.2865 15.5515 11.4166C15.7926 10.5467 15.8384 9.63426 15.6857 8.74458C15.2428 6.12496 13.0567 4.03304 10.4182 3.71265C9.49062 3.59531 8.54848 3.69171 7.66387 3.99449C6.77927 4.29727 5.97565 4.7984 5.31452 5.45953C4.65338 6.12067 4.15225 6.92429 3.84947 7.80889C3.54669 8.69349 3.45029 9.63564 3.56764 10.5632C3.88802 13.2017 5.97994 15.3878 8.59956 15.8307C9.48924 15.9834 10.4017 15.9376 11.2716 15.6965C12.1415 15.4554 12.9473 15.025 13.6315 14.4361L13.8859 14.7V15.4444L17.8907 19.4492C18.2771 19.8355 18.9084 19.8355 19.2948 19.4492C19.6811 19.0628 19.6811 18.4315 19.2948 18.0452L15.2994 14.0309ZM9.64552 14.0309C7.29918 14.0309 5.40514 12.1369 5.40514 9.79054C5.40514 7.44419 7.29918 5.55015 9.64552 5.55015C11.9919 5.55015 13.8859 7.44419 13.8859 9.79054C13.8859 12.1369 11.9919 14.0309 9.64552 14.0309Z"
        fill="white"
      />
    </svg>
  );
};

export default SearchIcon;
