import React, { useState } from "react";

import { FiltersIcon } from "..";

import { ButtonContainedGreen, ButtonOutlinedGray, Select } from "../../..";

const Filters = ({
  selectedDepartment,
  selectedRiskLevel,
  departments,
  onChangeDepartment,
  onChangeRiskLevel,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const riskLevels = [
    {
      name: "Low",
    },
    {
      name: "Moderate",
    },
    {
      name: "High",
    },
    {
      name: "Severe",
    },
  ];

  const handleChangeDepartment = (event) => {
    onChangeDepartment(event);
  };

  const handleChangeRiskLevel = (event) => {
    onChangeRiskLevel(event);
  };

  return (
    <div className="ml-0.5 relative">
      {selectedDepartment || selectedRiskLevel ? (
        <ButtonContainedGreen
          padding="p-0.5"
          title="Filter users."
          onClick={() => setShowFilters(true)}
        >
          <div tabIndex="0" role="button" className="w-[24px] h-[24px]">
            <FiltersIcon color="#000000" />
          </div>
        </ButtonContainedGreen>
      ) : (
        <ButtonOutlinedGray
          padding="p-0.5"
          title="Filter users."
          onClick={() => setShowFilters(true)}
        >
          <div tabIndex="0" role="button" className="w-[24px] h-[24px]">
            <FiltersIcon />
          </div>
        </ButtonOutlinedGray>
      )}
      {showFilters && (
        <div
          className="absolute right-0 top-full border border-border-color description p-[8px] z-50 bg-gray-tooltip max-w-[300px] w-max rounded-small"
          style={{
            boxShadow:
              "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
          }}
          onMouseLeave={() => setShowFilters(false)}
        >
          <div>
            <div>
              <p className="description-emphasized">Departments</p>
            </div>
            <div className="mt-0.25 max-w-[400px]">
              <Select
                value={selectedDepartment}
                options={[
                  { name: "All Departments", value: "" },
                  ...departments.map((department) => ({
                    name: department.name,
                    value: department.name,
                  })),
                ]}
                onChange={handleChangeDepartment}
              />
            </div>
          </div>
          <div className="mt-0.625">
            <div>
              <p className="description-emphasized">Risk Level</p>
            </div>
            <div className="mt-0.25 max-w-[400px]">
              <Select
                value={selectedRiskLevel}
                options={[
                  { name: "All Risk Levels", value: "" },
                  ...riskLevels.map((riskLevel) => ({
                    name: riskLevel.name,
                    value: riskLevel.name,
                  })),
                ]}
                onChange={handleChangeRiskLevel}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
