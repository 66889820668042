import React from "react";

const GridIcon = ({ isActive }) => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 11.0098V3.00977H11V11.0098H3ZM3 21.0098V13.0098H11V21.0098H3ZM13 11.0098V3.00977H21V11.0098H13ZM13 21.0098V13.0098H21V21.0098H13Z"
        fill={isActive ? "#00FF82" : "#FFFFFF"}
      />
    </svg>
  );
};

export default GridIcon;
