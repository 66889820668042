import {
  ZoomLogoIcon,
  SMSIcon,
  PhoneIcon,
  OktaLogoIcon,
  MicrosoftEntraIDIcon,
  MicrosoftDefenderIcon,
  GoogleIcon,
  EnvelopeIcon,
  UserSpecificTestingLogo,
  BasicUserDataLogo,
  AccessPermissionLogo,
  BlastRadiusAnalysisLogo,
  AnomalousRiskyActivityLogo,
  WorkdayIcon,
  HRISIcon,
  ERPIcon,
  PayrollIcon,
  CustomAssetIcon,
  AbnormalIcon,
  CrowdstrikeIcon,
  PaloAltoIcon,
  ProofpointIcon,
  SentinelOneIcon,
  ZScalerIcon,
  NetskapeIcon,
  AWSIcon,
  AzureIcon,
  AILanguageModelIcon,
  StandardizeLevelFunctionIcon,
  SWGIcon,
} from "../../icons/";
import MimecastIcon from "../../icons/mimecast-icon/mimecast-icon";

export const ecosystemInputs = [
  {
    title: "Basic User Data",
    icon: <BasicUserDataLogo />,
    logoFirstRow: [
      <MicrosoftEntraIDIcon />,
      <OktaLogoIcon />,
      <GoogleIcon />,
      <WorkdayIcon />,
    ],
  },
  {
    title: "Blast Radius Analysis",
    icon: <BlastRadiusAnalysisLogo />,
    logoFirstRow: [<StandardizeLevelFunctionIcon />],
    logoSecondRow: [<AILanguageModelIcon />],
  },
  {
    title: "User Specific Testing",
    icon: <UserSpecificTestingLogo />,
    logoFirstRow: [
      <HRISIcon />,
      <ERPIcon />,
      <PayrollIcon />,
      <CustomAssetIcon />,
    ],
    logoSecondRow: [
      <EnvelopeIcon />,
      <SMSIcon />,
      <PhoneIcon />,
      <ZoomLogoIcon />,
    ],
  },
  {
    title: "Anomalous & Risky Activity",
    icon: <AnomalousRiskyActivityLogo />,
    logoFirstRow: [<MicrosoftDefenderIcon />, <SentinelOneIcon />],
    logoSecondRow: [
      <MimecastIcon />,
      <OktaLogoIcon />,
      <PaloAltoIcon />,
      <ProofpointIcon />,
    ],
    logoThirdRow: [
      <AbnormalIcon />,
      <CrowdstrikeIcon />,
      <MicrosoftEntraIDIcon />,
    ],
  },
  {
    title: "Access & Permssions",
    icon: <AccessPermissionLogo />,
    logoFirstRow: [<SWGIcon />],
    logoSecondRow: [
      <NetskapeIcon />,
      <AWSIcon />,
      <GoogleIcon />,
      <AzureIcon />,
    ],
    logoThirdRow: [
      // <SWGIcon />,
      <MicrosoftEntraIDIcon />,
      <OktaLogoIcon />,
      <ZScalerIcon />,
      <PaloAltoIcon />,
    ],
  },
];
