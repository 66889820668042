import React from "react";

const CircleFilledOrangeIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
    >
      <circle cx="4.60632" cy="4.19666" r="4" fill="#FFA100" />
    </svg>
  );
};

export default CircleFilledOrangeIcon;
