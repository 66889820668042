import React, { useState, useRef, useEffect } from "react";
import { DoubleArrowDropdownIcon } from "../../../utils";
import "./multi-select-dropdown.css";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const MultiSelectDropdown = ({
  uniqueKey,
  options,
  onSelect,
  label = "Select options",
  description,
  placeholder = "Select",
  width = "100%",
  reset,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions((prev) => prev.filter((item) => item !== option));
      onSelect && onSelect(selectedOptions.filter((item) => item !== option));
    } else {
      const newSelectedOptions = [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onSelect && onSelect(newSelectedOptions);
    }
  };

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }
  }, [reset]);

  return (
    <div className="lg:flex flex-row space-y-1 mb-2">
      <div className="w-1/2">
        {label && <div className="text-h5">{label}</div>}
        {description && (
          <div className="text-description text-gray-surface-secondary">
            {description}
          </div>
        )}
      </div>
      <div
        className="relative w-1/2 max-w-35 mr-3.625"
        ref={dropdownRef}
        style={{ width }}
      >
        {/* Selected Options */}
        <div
          onClick={() => setIsOpen((prevState) => !prevState)}
          className={`flex flex-wrap items-center gap-0.25 border ${
            isOpen ? "border-green" : "border-gray border-opacity-35"
          } px-1 text-gray text-opacity-65 py-0.75 rounded-medium cursor-pointer`}
        >
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option, index) => (
              <div
                key={index}
                className="border border-white text-white flex items-center bg-gray-200 space-x-0.625 px-0.625 rounded-lg text-h5 cursor-auto"
              >
                <div>{option}</div>
                <span
                  className="cursor-pointer text-gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOption(option);
                  }}
                >
                  ✕
                </span>
              </div>
            ))
          ) : (
            <span className="text-gray text-opacity-65">{placeholder}</span>
          )}
          <div className="ml-auto">
            <DoubleArrowDropdownIcon />
          </div>
        </div>

        {/* Dropdown Options */}
        {isOpen && (
          <div className="absolute top-full left-0 w-full bg-black border border-gray border-opacity-35 rounded-medium shadow-lg z-10 text-gray text-opacity-65 mt-0.5">
            <ul>
              {options.map((option, index) => (
                <div key={`${uniqueKey}-${index}`}>
                  <li
                    onClick={() => toggleOption(option)}
                    className={`flex justify-between items-center px-1 py-0.5 cursor-pointer text-gray-surface-contrast hover:bg-gray hover:bg-opacity-35 ${index === options.length - 1 ? "rounded-b-medium" : ""} ${index === 0 ? "rounded-t-medium" : ""} ${index === 0 ? "rounded-t-medium" : ""}`}
                  >
                    <div className="cursor-pointer">
                      {capitalizeFirstWords(option)}
                    </div>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option)}
                      onChange={() => toggleOption(option)}
                      className="custom-checkbox"
                    />
                  </li>
                  {index !== options.length - 1 && (
                    <div className="text-gray text-opacity-35">
                      <hr />
                    </div>
                  )}
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
