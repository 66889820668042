import React from "react";
import axios from "axios";

import { Download } from "@mui/icons-material";

const complianceFrameworks = [
    "GDPR",
    "GLBA",
    "HIPAA",
    "ISO 27001",
    "NIST",
    "SOC 2 Type 2"
];

const formatAreasToImprove = async (threats) => {
    let areasToImprove = [];
    let actualAreasToImprove = [];

    for (let i = 0; i < threats.length; i++) {
        const areaToImprove = threats[i];

        if (areaToImprove.threat === "link manipulation") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Link manipulation"
            });
        } else if (areaToImprove.threat === "c suite impersonation") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "C-suite impersonation"
            });
        } else if (areaToImprove.threat === "internal processes") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Impersonation of internal processes"
            });
        } else if (areaToImprove.point_of_failure === "display name deception") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Display name deception"
            });
        } else if (areaToImprove.point_of_failure === "request for action") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Request for action"
            });
        } else if (areaToImprove.point_of_failure === "financial transactions") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Unexpected financial transactions"
            });
        } else if (areaToImprove.point_of_failure === "tech notifs") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Fake technical notifications"
            });
        } else if (areaToImprove.point_of_failure === "pretexting") {
            actualAreasToImprove.push({
                count: areaToImprove.count,
                threat: "Pretexting"
            });
        }
    }

    actualAreasToImprove.sort((a, b) =>
        (b.count - a.count)
        && a.threat.toUpperCase().localeCompare(b.threat.toUpperCase())
    );

    areasToImprove = actualAreasToImprove.map((areaToImprove) => areaToImprove.threat);

    return areasToImprove;
};

const formatCompanyAverage = async (companyAverage) => {
    let formattedCompanyAverage = companyAverage;
    formattedCompanyAverage /= 100;
    formattedCompanyAverage = parseFloat(formattedCompanyAverage.toFixed(2));

    return formattedCompanyAverage;
};

const formatDataAssignedTraining = async (assignedTraining) => {
    let formattedDataAssignedTraining = [];

    assignedTraining.forEach((training) => {
        if (!complianceFrameworks.includes(training.category)) {
            formattedDataAssignedTraining.push({
                name: training.title,
                percent: training.status === "Not started" ? 0 : 1
            });
        }
    });

    return formattedDataAssignedTraining;
};

const formatDataCompliance = async (dataCompliance) => {
    let formattedDataCompliance = {};

    for (const key in dataCompliance) {
        if (dataCompliance.hasOwnProperty(key) && dataCompliance[key] !== null) {
            const formattedKey = key.replaceAll("_", " ");

            formattedDataCompliance[formattedKey] = !isNaN(dataCompliance[key]) && typeof dataCompliance[key] !== "boolean"
                ? +(parseFloat(dataCompliance[key]) / 100).toFixed(2) : dataCompliance[key];
        }
    }

    return formattedDataCompliance;
};

const formatDataDescription = async (dataProfile, timeSeries) => {
    let formattedDataDescription = {};

    formattedDataDescription.firstName = dataProfile.first_name || "";

    const sortedTimeSeries = [...timeSeries];
    sortedTimeSeries.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        return dateA - dateB;
    });

    if (sortedTimeSeries.length !== 0) {
        const points = Math.ceil((sortedTimeSeries[sortedTimeSeries.length - 1].risk_score - sortedTimeSeries[0].risk_score)) || 0;

        const firstTimestamp = sortedTimeSeries[0].timestamp ? new Date(sortedTimeSeries[0].timestamp) : new Date(sortedTimeSeries[0].date);
        const month = firstTimestamp.toLocaleString("default", {
            month: "long"
        });

        const date = firstTimestamp.getDate();
        let suffix = "";

        const j = date % 10;
        const k = date % 100;

        if (j === 1 && k !== 11) {
            suffix = "st";
        } else if (j === 2 && k !== 12) {
            suffix = "nd";
        } else if (j === 3 && k !== 13) {
            suffix = "rd";
        } else {
            suffix = "th";
        }

        formattedDataDescription.points = points;
        formattedDataDescription.firstDate = `${month} ${date}${suffix}`;
    } else {
        formattedDataDescription.points = 0;
        formattedDataDescription.firstDate = "";
    }

    return formattedDataDescription;
};

const formatDataPointsOfFailure = async (threats) => {
    let highRiskAreas = [];

    for (let i = 0; i < threats.length; i++) {
        const highRiskArea = threats[i];

        if (highRiskArea.point_of_failure === "link manipulation") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Link manipulation"
            });
        } else if (highRiskArea.point_of_failure === "c suite impersonation") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "C-suite impersonation"
            });
        } else if (highRiskArea.point_of_failure === "internal processes") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Impersonation of internal processes"
            });
        } else if (highRiskArea.point_of_failure === "display name deception") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Display name deception"
            });
        } else if (highRiskArea.point_of_failure === "request for action") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Request for action"
            });
        } else if (highRiskArea.point_of_failure === "financial transactions") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Unexpected financial transactions"
            });
        } else if (highRiskArea.point_of_failure === "tech notifs") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Fake technical notifications"
            });
        } else if (highRiskArea.point_of_failure === "pretexting") {
            highRiskAreas.push({
                count: highRiskArea.count,
                name: "Pretexting"
            });
        }
    }

    highRiskAreas.sort((a, b) =>
        (b.count - a.count)
        && a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    );

    return highRiskAreas;
};

const formatDataProfile = async (dataProfile) => {
    let formattedDataProfile = {};

    formattedDataProfile.name = `${dataProfile.first_name || ""} ${dataProfile.last_name || ""}`;
    formattedDataProfile.email = dataProfile.email || "";
    formattedDataProfile.department = dataProfile.department || "";
    formattedDataProfile.role = dataProfile.role || "";
    formattedDataProfile.directManager = dataProfile.manager || "";

    return formattedDataProfile;
};

const formatDataRecentTestingActivity = async (pastAttacks) => {
    let dataRecentTestingActivity = [];

    const sortedPastAttacks = [...pastAttacks];
    sortedPastAttacks.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        return dateA - dateB;
    });

    for (let i = sortedPastAttacks.length < 10 ? 0 : sortedPastAttacks.length - 10; i < sortedPastAttacks.length; i++) {
        const element = sortedPastAttacks[i];

        // Date
        const date = new Date(element.timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}/${month}/${day}`;

        // Method of attack
        let methodOfAttack = "N/A";

        if (element.threats && Array.isArray(element.threats) && element.threats.length > 0) {
            if (element.threats.includes("internal processes")) {
                methodOfAttack = "Impersonation of internal processes";
            } else if (element.threats.includes("c suite impersonation")) {
                methodOfAttack = "C-suite impersonation";
            } else if (element.threats.includes("display name deception")) {
                methodOfAttack = "Display name deception";
            } else if (element.threats.includes("request for action")) {
                methodOfAttack = "Request for action";
            } else if (element.threats.includes("financial transactions")) {
                methodOfAttack = "Unexpected financial transactions";
            } else if (element.threats.includes("tech notifs")) {
                methodOfAttack = "Fake technical notifications";
            } else if (element.threats.includes("pretexting")) {
                methodOfAttack = "Pretexting";
            } else if (element.threats.includes("link manipulation")) {
                methodOfAttack = "Link manipulation";
            }
        }

        dataRecentTestingActivity.push({
            date: formattedDate,
            passed: !element.link_clicked,
            source: {
                isURLClicked: (element.source && element.source.url_clicked) ? element.source.url_clicked : null,
                isQRScanned: (element.source && element.source.qr_scanned) ? element.source.qr_scanned : null
            },
            imposterPortal: {
                subdomain: (element.imposter_portal && element.imposter_portal.subdomain) ? element.imposter_portal.subdomain : "",
                isVisited: (element.imposter_portal && element.imposter_portal.visited) ? element.imposter_portal.visited : false,
                isKeyDown: (element.imposter_portal && element.imposter_portal.keydown) ? element.imposter_portal.keydown : false,
                isDataEntered: (element.imposter_portal && element.imposter_portal.data_entered) ? element.imposter_portal.data_entered : false
            },
            internalOrExternal: (element.scope && element.scope === "i") ? "Internal" : "External",
            difficulty: element.fidelity ? element.fidelity.charAt(0).toUpperCase() + element.fidelity.slice(1) : "N/A",
            attackVector: element.attack_vector
                ? (element.attack_vector === "phishing" ? "Email phishing"
                    : (element.attack_vector === "smishing" ? "SMS smishing" : "N/A"))
                : "N/A",
            methodOfAttack
        });
    }

    return dataRecentTestingActivity;
};

const formatDataRiskTrend = async (timeSeries) => {
    let dataRiskTrend = [];

    // Sort the time series data by date first.
    const sortedTimeSeries = [...timeSeries];
    sortedTimeSeries.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        return dateA - dateB;
    });

    for (let i = 0; i < sortedTimeSeries.length; i++) {
        const element = sortedTimeSeries[i];

        const date = element.timestamp ? new Date(element.timestamp) : new Date(element.date);
        const options = {
            month: "short",
            day: "numeric"
        };
        const formattedDate = date.toLocaleDateString("en-US", options);

        let count = 1;
        let riskScore = element.risk_score;

        if (i < sortedTimeSeries.length - 1) {
            let nextElement = sortedTimeSeries[i + 1];

            // Check if the next data point has the same date.
            while (i <= sortedTimeSeries.length - 1) {
                const dateNextElement = nextElement.timestamp ? new Date(nextElement.timestamp) : new Date(nextElement.date);

                if (date.getMonth() === dateNextElement.getMonth()
                    && date.getDate() === dateNextElement.getDate()
                    && date.getMonth() === dateNextElement.getMonth()) {
                    count++;
                    riskScore += nextElement.risk_score;
                    i++;
                } else {
                    break;
                }
            }
        }

        dataRiskTrend.push({
            date: formattedDate,
            riskScore: +(riskScore / count).toFixed()
        });
    }

    return dataRiskTrend;
};

const formatImprovementFromFirstScore = async (riskScore, firstScore) => {
    let formattedImprovement = 0;

    formattedImprovement = (riskScore && firstScore)
        ? ((firstScore - riskScore) / firstScore) : 0;
    formattedImprovement = parseFloat(formattedImprovement.toFixed(2));

    return formattedImprovement;
};

const formatImprovementFromTimeSeries = async (timeSeries) => {
    let formattedImprovement = 0;

    const sortedTimeSeries = [...timeSeries];
    sortedTimeSeries.sort((a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);

        return dateA - dateB;
    });

    const firstPoint = (sortedTimeSeries[0] && sortedTimeSeries[0].risk_score)
        ? sortedTimeSeries[0].risk_score : 0;
    const lastPoint = (sortedTimeSeries[sortedTimeSeries.length - 1] && sortedTimeSeries[sortedTimeSeries.length - 1].risk_score)
        ? sortedTimeSeries[sortedTimeSeries.length - 1].risk_score : 0;

    if (firstPoint && lastPoint) {
        formattedImprovement = (firstPoint - lastPoint) / firstPoint;
        formattedImprovement = parseFloat(formattedImprovement.toFixed(2));
    }

    return formattedImprovement;
};

const formatRiskScore = async (riskScore) => {
    const formattedRiskScore = +riskScore.toFixed();

    return formattedRiskScore;
};

const formatUserInteractions = async (pastAttacks) => {
    // Links clicked
    const filteredAttacksByClickingLink = pastAttacks.filter((pastAttack) =>
        pastAttack.attack_vector === "phishing" && pastAttack.link_clicked);

    // Entered sensitive info
    const filteredAttacksByEnteringInfo = pastAttacks.filter((pastAttack) =>
        pastAttack.attack_vector === "phishing" && (pastAttack.imposter_portal && pastAttack.imposter_portal.data_entered));

    // Submitted sensitive info
    const filteredAttacksBySubmittingInfo = pastAttacks.filter((pastAttack) =>
        pastAttack.attack_vector === "phishing" && (pastAttack.imposter_portal && pastAttack.imposter_portal.keydown));

    const formattedUserInteractions = [
        {
            attackVector: "phishing",
            dataInteractions: [
                {
                    name: "Links clicked",
                    failure: filteredAttacksByClickingLink.length / pastAttacks.length
                },
                {
                    name: "Entered sensitive info",
                    failure: filteredAttacksByEnteringInfo.length / pastAttacks.length
                },
                {
                    name: "Submitted sensitive info",
                    failure: filteredAttacksBySubmittingInfo.length / pastAttacks.length
                }
            ]
        }
    ];

    return formattedUserInteractions;
};

const formatVideosTrainingAndCompliance = async (assignedTraining) => {
    const formattedVideosTraining = [];
    const formattedVideosCompliance = [];
    const videosCompliance = [];

    for (let i = 0; i < assignedTraining.length; i++) {
        const video = assignedTraining[i];

        if (complianceFrameworks.includes(video.category)) {
            videosCompliance.push({
                id: video.assigned_training_id,
                category: video.category,
                status: video.status,
                title: video.title,
                fileName: video.file_name
            });
        } else if (video.status !== "completed") {
            formattedVideosTraining.push({
                id: video.assigned_training_id,
                status: video.status,
                title: video.title,
                fileName: video.file_name
            });
        }
    }

    if (videosCompliance.length > 0) {
        const selectedComplianceFrameworks = [...new Set(videosCompliance.map((video) => video.category))]
        selectedComplianceFrameworks.sort((a, b) => a.localeCompare(b));

        for (let i = 0; i < selectedComplianceFrameworks.length; i++) {
            const setVideosCompliance = {
                name: selectedComplianceFrameworks[i],
                videos: videosCompliance.filter((video) => video.category === selectedComplianceFrameworks[i])
            };

            setVideosCompliance.videos.sort((a, b) => a.fileName.localeCompare(b.fileName));
            formattedVideosCompliance.push(setVideosCompliance);
        }
    }

    return {
        formattedVideosTraining,
        formattedVideosCompliance
    };
};

const getIDToken = async () => {
    let idToken = "";

    return idToken;
};

const getResponseFetchGet = async (path) => {
    const idToken = await getIDToken();

    const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}${path}`;
    const headers = {
        "Authorization": `Bearer ${idToken}`,
        "Content-Type": "application/json"
    };

    const response = await axios.get(url, { headers });

    return response;
};

const getResponseFetchPost = async (path, requestBody) => {
    const idToken = await getIDToken();

    const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}${path}`;
    const headers = {
        "Authorization": `Bearer ${idToken}`,
        "Content-Type": "application/json"
    };

    const response = await axios.post(url, requestBody, { headers });

    return response;
};

// Scope: https://www.googleapis.com/auth/userinfo.email
export const fetchGoogleEndUser = async (accessToken) => {
    let endUser = {
        result: {
            email: ""
        },
        error: {}
    };

    try {
        const response = await axios.get("https://people.googleapis.com/v1/people/me?personFields=emailAddresses", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && data.emailAddresses && Array.isArray(data.emailAddresses) && data.emailAddresses.length > 0) {
            const filteredEmailsByPrimary = data.emailAddresses.filter((email) => email.metadata && email.metadata.primary);

            if (filteredEmailsByPrimary.length > 0) {
                endUser.result.email = filteredEmailsByPrimary[0].value;
            }
        }
    } catch (error) {
        endUser.error = error;
    }

    return endUser;
};

export const fetchGoogleGroups = async (accessToken) => {
    let groups = {
        result: [],
        error: {}
    };

    try {
        const response = await axios.get("https://admin.googleapis.com/admin/directory/v1/groups?customer=my_customer", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data.groups || [];

        if (data && Array.isArray(data)) {
            const groupsData = [];

            for (let i = 0; i < data.length; i++) {
                const groupID = data[i].id || "";

                const responseMembers = await axios.get(`https://admin.googleapis.com/admin/directory/v1/groups/${groupID}/members`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                if (responseMembers.status !== 200) {
                    throw new Error(`HTTP Error! Status: ${responseMembers.status}`);
                }

                const dataMembers = responseMembers.data.members || [];

                const group = {};
                group.name = data[i].name || "";
                group.memberIDs = dataMembers.map((member) => member.id);

                groupsData.push(group);
            }

            groupsData.sort((a, b) => a.memberIDs.length - b.memberIDs.length);
            groups.result = groupsData;
        }
    } catch (error) {
        groups.error = error;
    }

    return groups;
};

// Scope: https://www.googleapis.com/auth/admin.directory.user.readonly
export const fetchGoogleUsers = async (accessToken) => {
    let users = {
        result: [],
        error: {}
    };

    const usersGoogle = [];
    let isFirstPage = true;
    let nextPageToken = "";

    try {
        while (nextPageToken !== "" || isFirstPage) {
            if (isFirstPage) {
                isFirstPage = false;
            }

            let url = "https://admin.googleapis.com/admin/directory/v1/users?customer=my_customer&maxResult=500";

            if (nextPageToken) {
                url += `&pageToken=${nextPageToken}`;
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (response.status !== 200) {
                throw new Error(`HTTP Error! Status: ${response.status}`);
            }

            const data = response.data;

            if (data && data.users && Array.isArray(data.users)) {
                data.users.forEach((userData) => {
                    if (!userData.archived) {
                        const user = {};

                        // Name
                        user.firstName = (userData.name && userData.name.givenName) ? userData.name.givenName : "";
                        user.lastName = (userData.name && userData.name.familyName) ? userData.name.familyName : "";

                        // Email
                        user.email = userData.primaryEmail || "";

                        // Phone number
                        if (userData.phones && Array.isArray(userData.phones) && userData.phones.length > 0) {
                            if (userData.phones.length === 1) {
                                user.phoneNumber = userData.phones[0].value || "";
                            } else {
                                const filteredPhonesByMobile = userData.phones.filter((phone) => phone.type === "mobile");

                                if (filteredPhonesByMobile.length > 0) {
                                    user.phoneNumber = filteredPhonesByMobile[0].value || "";
                                } else {
                                    user.phoneNumber = userData.phones[0].value || "";
                                }
                            }
                        } else {
                            user.phoneNumber = "";
                        }

                        // Department
                        if (userData.orgUnitPath) {
                            // For department, check orgUnitPath and then check organizations.
                            const pathNames = userData.orgUnitPath.split("/");

                            // Get the first path name as the department.
                            user.department = pathNames[0];
                        }

                        // Role (and department if there is no orgUnitPath)
                        if (userData.organizations && Array.isArray(userData.organizations) && userData.organizations.length > 0) {
                            // Get the primary department.
                            const filteredOrganizationsByPrimary = userData.organizations.filter((organization) => organization.primary && organization.department);

                            if (filteredOrganizationsByPrimary.length > 0) {
                                user.department = filteredOrganizationsByPrimary[0].department || "Staff";
                                user.role = filteredOrganizationsByPrimary[0].title;
                            }
                        } else {
                            user.role = "";
                        }

                        // If there is no department, get the default department.
                        if (!user.department) {
                            user.department = "Staff";
                        }

                        // Direct manager
                        if (userData.relations && Array.isArray(userData.relations) && userData.relations.length > 0) {
                            const managers = userData.relations.filter((relation) => relation.type === "manager");

                            if (managers.length > 0) {
                                user.manager = managers[0].value || "";
                            } else {
                                user.manager = "";
                            }
                        } else {
                            user.manager = "";
                        }

                        // ID used for matching groups.
                        user.id = userData.id || "";

                        usersGoogle.push(user);
                    }
                });

                nextPageToken = data.nextPageToken ? data.nextPageToken : "";
            }
        }

        users.result = usersGoogle;
    } catch (error) {
        users.error = error;
    }

    return users;
};

export const fetchMicrosoftGroups = async (accessToken) => {
    let groups = {
        result: [],
        error: {}
    };

    try {
        const response = await axios.get("https://graph.microsoft.com/v1.0/groups", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data.value || [];

        if (data && Array.isArray(data)) {
            const groupsData = [];

            for (let i = 0; i < data.length; i++) {
                const groupID = data[i].id || "";

                const responseMembers = await axios.get(`https://graph.microsoft.com/v1.0/groups/${groupID}/members`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                if (responseMembers.status !== 200) {
                    throw new Error(`HTTP Error! Status: ${responseMembers.status}`);
                }

                const dataMembers = responseMembers.data.value || [];

                const group = {};
                group.name = data[i].displayName || "";
                group.memberIDs = dataMembers.map((member) => member.id);

                groupsData.push(group);
            }

            groupsData.sort((a, b) => a.memberIDs.length - b.memberIDs.length);
            groups.result = groupsData;
        }
    } catch (error) {
        groups.error = error;
    }

    return groups;
};

export const fetchMicrosoftUsers = async (accessToken) => {
    let users = {
        result: [],
        error: {}
    };

    try {
        const response = await axios.get("https://graph.microsoft.com/v1.0/users", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data.value || [];

        if (data && Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                const userData = data[i];
                const user = {};

                // First name, last name, email, role
                user.firstName = userData.givenName || "";
                user.lastName = userData.surname || "";
                user.email = userData.mail || "";
                user.role = userData.jobTitle || "";

                // Phone number (Mobile phone takes priority)
                if (userData.mobilePhone) {
                    user.phoneNumber = userData.mobilePhone;
                } else if (userData.businessPhones && Array.isArray(userData.businessPhones) && userData.businessPhones.length > 0) {
                    user.phoneNumber = userData.businessPhones[0];
                } else {
                    user.phoneNumber = "";
                }

                // Manager (no way to find manager)
                user.manager = "";

                // Department (set default value to Staff for now)
                user.department = "Staff";

                // ID used for matching groups.
                user.id = userData.id || "";

                users.result.push(user);
            }
        }
    } catch (error) {
        users.error = error;
    }

    return users;
};

export const fetchOktaGroups = async (oktaDomain, accessToken) => {
    let groups = {
        result: [],
        error: {}
    };

    try {
        const response = await axios.get(`${oktaDomain}/api/v1/groups`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && Array.isArray(data)) {
            data.forEach(async (groupData) => {
                const type = groupData.type || "";

                // Only use user-created groups.
                if (type && type !== "BUILT_IN") {
                    const groupName = (groupData.profile && groupData.profile.name) ? groupData.profile.name : "";

                    if (groupName) {
                        const usersURL = (groupData._links && groupData._links.users && groupData._links.users.href)
                            ? groupData._links.users.href : "";

                        if (usersURL) {
                            const responseUsers = await axios.get(usersURL, {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            });

                            if (responseUsers.status !== 200) {
                                throw new Error(`HTTP Error! Status: ${responseUsers.status}`);
                            }

                            const dataUsers = responseUsers.data;

                            if (dataUsers && Array.isArray(dataUsers)) {
                                const group = {
                                    name: groupName,
                                    userIDs: dataUsers.map((user) => user.id)
                                };

                                groups.result.push(group);
                            }
                        }
                    }
                }
            });
        }
    } catch (error) {
        groups.error = error;
    }

    return groups;
};

export const fetchOktaUsers = async (oktaDomain, apiToken) => {
    let users = {
        result: [],
        error: {}
    };

    try {
        const response = await getResponseFetchGet(`/okta_user_provisioning/?okta_url=https://${oktaDomain}/api/v1/users&okta_auth_token=SSWS ${apiToken}`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && Array.isArray(data)) {
            data.forEach((userData) => {
                const user = {};

                user.firstName = (userData.profile && userData.profile.firstName) ? userData.profile.firstName : "";
                user.lastName = (userData.profile && userData.profile.lastName) ? userData.profile.lastName : "";
                user.email = (userData.profile && userData.profile.email) ? userData.profile.email : "";
                user.phoneNumber = (userData.profile && userData.profile.mobilePhone) ? userData.profile.mobilePhone : "";
                user.role = (userData.profile && userData.profile.title) ? userData.profile.title : "";
                user.manager = (userData.profile && userData.profile.manager) ? userData.profile.manager : "";

                // Use the department field for getting department data, but groups is first priority.
                if (!user.department) {
                    user.department = (userData.profile && userData.profile.department) ? userData.profile.department : "Staff";
                }

                // ID used for matching groups.
                user.id = userData.id || "";

                users.result.push(user);
            });
        }
    } catch (error) {
        users.error = error;
    }

    return users;
};

export const fetchBellCurvesDepartment = async () => {
    let bellCurvesDepartment = {
        result: {
            dataBellCurves: []
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/department_risk_score_bellcurve/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && typeof data === "object" && Object.keys(data).length > 0) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const bellCurve = {
                        mean: data[key].mean_risk_score || 0,
                        standardDeviation: data[key].standard_deviation || 0,
                        name: key
                    };

                    bellCurvesDepartment.result.dataBellCurves.push(bellCurve);
                }
            }
        }
    } catch (error) {
        bellCurvesDepartment.error = error;
    }

    return bellCurvesDepartment;
};

export const fetchBellCurveUser = async () => {
    let bellCurveUser = {
        result: {
            mean: 0,
            standardDeviation: 0,
            userRiskScore: 0
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/user_on_org_risk_score_bellcurve/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && typeof data === "object" && Object.keys(data).length > 0) {
            bellCurveUser.result.mean = data.mean_risk_score || 0;
            bellCurveUser.result.standardDeviation = data.standard_deviation || 0;
            bellCurveUser.result.userRiskScore = data.user_risk_score || 0;
        }
    } catch (error) {
        bellCurveUser.error = error;
    }

    return bellCurveUser;
};

export const fetchCompany = async () => {
    let company = {
        result: [],
        error: {}
    };

    company = {
        result: {
            companyName: "",
            industry: ""
        },
        error: {}
    };

    return company;
};

export const fetchComplianceFrameworks = async () => {
    let complianceFrameworksData = {
        result: {
            complianceFrameworks: []
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/compliance_frameworks/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data.compliance_frameworks && typeof data.compliance_frameworks === "object" && Object.keys(data.compliance_frameworks).length > 0) {
            for (const key in data.compliance_frameworks) {
                if (data.compliance_frameworks.hasOwnProperty(key) && data.compliance_frameworks[key] !== null) {
                    if (key === "SOC2_TYPE2") { // Just in case it is not "SOC_2_TYPE_2"
                        complianceFrameworksData.result.complianceFrameworks.push("SOC 2 Type 2");
                    } else {
                        complianceFrameworksData.result.complianceFrameworks.push(key.replaceAll("_", " "));
                    }
                }
            }
        }
    } catch (error) {
        complianceFrameworksData.error = error;
    }

    return complianceFrameworksData;
};

export const fetchDepartments = async () => {
    let departments = {
        result: [],
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/list_departments/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        departments.result = data.departments || [];
        departments.result.sort();
    } catch (error) {
        departments.error = error;
    }

    return departments;
};

export const fetchInsightsDepartment = async (department) => {
    let insightsDepartment = {
        result: {
            riskScore: 0,
            improvement: 0,
            dataRiskTrend: [],
            highRiskAreas: [],
            dataCompliance: {}
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet(`/department_data/${department}/`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        // Risk score
        if (data.average_risk_score && data.average_risk_score.average_risk_score) {
            insightsDepartment.result.riskScore = await formatRiskScore(data.average_risk_score.average_risk_score);
        }

        if (data.three_month_time_series && Array.isArray(data.three_month_time_series) && data.three_month_time_series.length > 0) {
            // Improvement
            insightsDepartment.result.improvement = await formatImprovementFromTimeSeries(data.three_month_time_series);

            // Data risk trend
            insightsDepartment.result.dataRiskTrend = await formatDataRiskTrend(data.three_month_time_series);
        }

        // High risk areas
        if (data.points_of_failure && Array.isArray(data.points_of_failure) && data.points_of_failure.length > 0) {
            insightsDepartment.result.highRiskAreas = await formatAreasToImprove(data.points_of_failure);
        }

        // Compliance
        if (data.percentage_compliance && typeof data.percentage_compliance === "object" && Object.keys(data.percentage_compliance).length > 0) {
            insightsDepartment.result.dataCompliance = await formatDataCompliance(data.percentage_compliance);
        }
    } catch (error) {
        insightsDepartment.error = error;
    }

    return insightsDepartment;
};

export const fetchInsightsEndUser = async () => {
    let insightsEndUser = {
        result: {
            dataDescription: {
                firstName: "",
                points: 0,
                firstDate: ""
            },
            dataProfile: {
                name: "",
                email: "",
                department: "",
                role: "",
                directManager: ""
            },
            riskScore: 0,
            improvement: 0,
            companyAverage: 0,
            videosTraining: [],
            videosCompliance: [],
            dataRiskTrend: [],
            dataPointsOfFailure: [],
            dataCompliance: {},
            dataRecentTestingActivity: []
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet(`/personal_user_risk_data/`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        // Description
        if (data.user_information && data.time_series && Array.isArray(data.time_series) && data.time_series.length > 0) {
            insightsEndUser.result.dataDescription = await formatDataDescription(data.user_information, data.time_series);
        }

        // Profile
        if (data.user_information) {
            insightsEndUser.result.dataProfile = await formatDataProfile(data.user_information);
        }

        if (data.risk_score && data.risk_score.current_risk_score) {
            // Risk score
            insightsEndUser.result.riskScore = await formatRiskScore(data.risk_score.current_risk_score);

            // Improvement
            if (data.risk_score.first_risk_score) {
                insightsEndUser.result.improvement = await formatImprovementFromFirstScore(data.risk_score.current_risk_score, data.risk_score.first_risk_score);
            }
        }

        // Company average
        if (data.percentage_above_company_average) {
            insightsEndUser.result.companyAverage = await formatCompanyAverage(data.percentage_above_company_average);
        } else if (data.percentage_below_company_average) {
            insightsEndUser.result.companyAverage = (await formatCompanyAverage(data.percentage_below_company_average)) * -1;
        }

        // Training videos
        if (data.assigned_training && Array.isArray(data.assigned_training) && data.assigned_training.length > 0) {
            const {
                formattedVideosTraining,
                formattedVideosCompliance
            } = await formatVideosTrainingAndCompliance(data.assigned_training);

            insightsEndUser.result.videosTraining = formattedVideosTraining;
            insightsEndUser.result.videosCompliance = formattedVideosCompliance;
        }

        // Data risk trend
        if (data.time_series && Array.isArray(data.time_series) && data.time_series.length > 0) {
            insightsEndUser.result.dataRiskTrend = await formatDataRiskTrend(data.time_series);
        }

        // Areas to improve
        if (data.points_of_failure && Array.isArray(data.points_of_failure) && data.points_of_failure.length > 0 && data.total_emails) {
            insightsEndUser.result.dataPointsOfFailure = await formatDataPointsOfFailure(data.points_of_failure);
        }

        // Compliance
        if (data.compliance && typeof data.compliance === "object" && Object.keys(data.compliance).length > 0) {
            insightsEndUser.result.dataCompliance = await formatDataCompliance(data.compliance);
        }

        // Recent testing activity
        if (data.past_attacks && Array.isArray(data.past_attacks) && data.past_attacks.length > 0) {
            insightsEndUser.result.dataRecentTestingActivity = await formatDataRecentTestingActivity(data.past_attacks);
        }
    } catch (error) {
        insightsEndUser.error = error;
    }

    return insightsEndUser;
};

export const fetchInsightsOrganization = async () => {
    let insightsOrganization = {
        result: {
            riskScore: 0,
            improvement: 0,
            dataRiskTrend: [],
            areasToImprove: [],
            dataCompliance: {}
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/organization_data/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        // Risk score
        if (data.average_risk_score && data.average_risk_score.average_risk_score) {
            insightsOrganization.result.riskScore = await formatRiskScore(data.average_risk_score.average_risk_score);
        }

        if (data.three_month_time_series && Array.isArray(data.three_month_time_series) && data.three_month_time_series.length > 0) {
            // Improvement
            insightsOrganization.result.improvement = await formatImprovementFromTimeSeries(data.three_month_time_series);

            // Data risk trend
            insightsOrganization.result.dataRiskTrend = await formatDataRiskTrend(data.three_month_time_series);
        }

        // High risk areas
        if (data.points_of_failure && Array.isArray(data.points_of_failure) && data.points_of_failure.length > 0) {
            insightsOrganization.result.areasToImprove = await formatAreasToImprove(data.points_of_failure);
        }

        // Compliance
        if (data.percentage_compliance && typeof data.percentage_compliance === "object" && Object.keys(data.percentage_compliance).length > 0) {
            insightsOrganization.result.dataCompliance = await formatDataCompliance(data.percentage_compliance);
        }
    } catch (error) {
        insightsOrganization.error = error;
    }

    return insightsOrganization;
};

export const fetchInsightsUser = async (email) => {
    let insightsUser = {
        result: {
            dataProfile: {
                name: "",
                email: "",
                department: "",
                role: "",
                directManager: ""
            },
            riskScore: 0,
            improvement: 0,
            companyAverage: 0,
            dataRiskTrend: [],
            dataPointsOfFailure: [],
            dataCompliance: {},
            dataAssignedTraining: [],
            userInteractions: [],
            dataRecentTestingActivity: []
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet(`/other_user_risk_data/${email}/`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        // Profile
        if (data.user_information) {
            insightsUser.result.dataProfile = await formatDataProfile(data.user_information);
        }

        if (data.risk_score && data.risk_score.current_risk_score) {
            // Risk score
            insightsUser.result.riskScore = await formatRiskScore(data.risk_score.current_risk_score);

            // Improvement
            if (data.risk_score.first_risk_score) {
                insightsUser.result.improvement = await formatImprovementFromFirstScore(data.risk_score.current_risk_score, data.risk_score.first_risk_score);
            }
        }

        // Company average
        if (data.percentage_above_company_average) {
            insightsUser.result.companyAverage = await formatCompanyAverage(data.percentage_above_company_average);
        } else if (data.percentage_below_company_average) {
            insightsUser.result.companyAverage = (await formatCompanyAverage(data.percentage_below_company_average)) * -1;
        }

        // Data risk trend
        if (data.time_series && Array.isArray(data.time_series) && data.time_series.length > 0) {
            insightsUser.result.dataRiskTrend = await formatDataRiskTrend(data.time_series);
        }

        // Areas to improve
        if (data.points_of_failure && Array.isArray(data.points_of_failure) && data.points_of_failure.length > 0 && data.total_emails) {
            insightsUser.result.dataPointsOfFailure = await formatDataPointsOfFailure(data.points_of_failure);
        }

        // Compliance
        if (data.compliance && typeof data.compliance === "object" && Object.keys(data.compliance).length > 0) {
            insightsUser.result.dataCompliance = await formatDataCompliance(data.compliance);
        }

        // Assigned training
        if (data.assigned_training && Array.isArray(data.assigned_training) && data.assigned_training.length > 0) {
            insightsUser.result.dataAssignedTraining = await formatDataAssignedTraining(data.assigned_training);
        }

        if (data.past_attacks && Array.isArray(data.past_attacks) && data.past_attacks.length > 0) {
            // User interactions
            insightsUser.result.userInteractions = await formatUserInteractions(data.past_attacks);

            // Recent testing activity
            insightsUser.result.dataRecentTestingActivity = await formatDataRecentTestingActivity(data.past_attacks);
        }
    } catch (error) {
        insightsUser.error = error;
    }

    return insightsUser;
};

export const fetchUser = async () => {
    let user = {
        result: {
            firstName: "",
            lastName: "",
            initials: "",
            email: "",
            role: "",
            organization: "",
            hasPaid: false,
            isInCampaign: false,
            hasBRAStarted: false,
            isBRACompleted: false,
            startTimestamp: null,
            isMSSP: false
        },
        error: {}
    };

    try {
        const response = await getResponseFetchGet("/personal_user_data/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        user.result.firstName = data.first_name ? data.first_name : "";
        user.result.lastName = data.last_name ? data.last_name : "";
        user.result.initials = `${user.result.firstName ? user.result.firstName.charAt(0) : ""}${user.result.lastName ? user.result.lastName.charAt(0) : ""}`;
        user.result.email = data.email ? data.email : "";
        user.result.role = data.role ? data.role : "";
        user.result.organization = data.company ? data.company : "";
        user.result.hasPaid = data.has_paid_access ? data.has_paid_access : false;
        user.result.isInCampaign = (data.started_assessment !== null && data.is_30_days_past !== null)
            ? (data.started_assessment && !data.is_30_days_past) : false;
        user.result.hasBRAStarted = data.started_assessment && data.started_assessment;
        user.result.isBRACompleted = data.is_30_days_past && data.is_30_days_past;
        user.result.startTimestamp = data.start_timestamp && data.start_timestamp;
    } catch (error) {
        user.error = error;
    }

    return user;
};

export const fetchUsers = async (hasRiskScore = false) => {
    let users = {
        result: [],
        error: {}
    };

    try {
        let response = {};

        if (hasRiskScore) {
            response = await getResponseFetchGet("/all_users_table/");
        } else {
            response = await getResponseFetchGet("/check_users_exist/");
        }

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        data.forEach((userData) => {
            const user = {
                name: `${userData.first_name} ${userData.last_name}`,
                email: userData.email,
                department: userData.department,
                compliant: userData.compliant,
            };

            if (hasRiskScore) {
                user.riskScore = +userData.risk_score.toFixed();
                user.pdf = <Download />;
            }

            users.result.push(user);
        });
    } catch (error) {
        users.error = error;
    }

    return users;
};

export const postComplianceFrameworks = async (requestBody) => {
    let complianceFrameworks = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponseFetchPost("/update_compliance/", requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        complianceFrameworks.result = response.data;
    } catch (error) {
        complianceFrameworks.error = error;
    }

    return complianceFrameworks;
};

export const postContactMessage = async (requestBody) => {
    let message = {
        result: {},
        error: {}
    };

    try {
        const url = "https://doo42a73n7.execute-api.us-east-1.amazonaws.com/latest/submit-contact-us";

        const response = await axios.post(url, requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        message.result = response.data;
    } catch (error) {
        message.error = error;
    }

    return message;
};

export const postFeedback = async (requestBody) => {
    let feedback = {
        result: {},
        error: {}
    };

    try {
        const idToken = await getIDToken();

        const url = "https://4fyoihdlcd.execute-api.us-east-1.amazonaws.com/latest/submit-feedback-lambda";
        const headers = {
            "Authorization": `Bearer ${idToken}`,
            "Content-Type": "application/json"
        };

        const response = await axios.post(url, requestBody, { headers });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        feedback.result = response.data;
    } catch (error) {
        feedback.error = error;
    }

    return feedback;
};

// Create a CISO (chief information security officer) user or a superadmin user.
export const postUser = async (requestBody) => {
    let user = {
        result: {},
        error: {}
    };

    try {
        const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}/ciso_register/`;
        const headers = {
            "Content-Type": "application/json"
        };

        const response = await axios.post(url, requestBody, { headers });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        user.result = response.data;
    } catch (error) {
        user.error = error;
    }

    return user;
};

// Create users that are non-CISO users.
export const postUsers = async (requestBody) => {
    let users = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponseFetchPost("/upload_users/", requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        users.result = response.data;
    } catch (error) {
        users.error = error;
    }

    return users;
};

export const postCampaignLaunch = async () => {
    let campaignLaunch = {
        result: {},
        error: {}
    };

    try {
        const idToken = await getIDToken();

        const url = "https://28naztltjg.execute-api.us-east-1.amazonaws.com/latest/notify-campaign-start-lambda";
        const headers = {
            "Authorization": `Bearer ${idToken}`,
            "Content-Type": "application/json"
        };

        const response = await axios.post(url, {}, { headers });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        campaignLaunch.result = response.data;
    } catch (error) {
        campaignLaunch.error = error;
    }

    return campaignLaunch;
};

export const postTemporaryPasswordReset = async (requestBody) => {
    let temporaryPasswordReset = {
        result: {},
        error: {}
    };

    try {
        const url = "https://evo5exw0tl.execute-api.us-east-1.amazonaws.com/latest/reset-cognito-temporary-password-lambda";
        const response = await axios.post(url, requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        temporaryPasswordReset.result = response.data;
    } catch (error) {
        temporaryPasswordReset.error = error;
    }

    return temporaryPasswordReset;
};

export const postVideoTrainingCompletion = async (requestBody) => {
    let videoTrainingCompletion = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponseFetchPost("/complete_training/", requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        videoTrainingCompletion.result = response.data;
    } catch (error) {
        videoTrainingCompletion.error = error;
    }

    return videoTrainingCompletion;
};

export const removeUser = async (email) => {
    let user = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponseFetchPost(`/set_user_inactive/${email}/`, {});

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        user.result = response.data;
    } catch (error) {
        user.error = error;
    }

    return user;
};