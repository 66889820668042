import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getInsightsDepartment,
  getTrainingCompletion,
  getUsersByDepartment,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatform } from "../../../constants";

/**
 * Component for displaying insights of a specific department.
 * Handles department selection and fetches relevant insights data.
 */
function InsightsDepartment({
  isInitialLoading,
  email,
  setNewTitle,
  setBreadcrumbs,
}) {
  const { state } = useLocation();
  const navigate = useNavigate();

  // Insights
  const [totalUsers, setTotalUsers] = useState(null);
  const [riskScore, setRiskScore] = useState(null);
  const [scoreChange, setScoreChange] = useState(null);
  const [riskScoreOverTime, setRiskScoreOverTime] = useState(null);
  const [areasToImprove, setAreasToImprove] = useState(null);
  const [complianceFrameworks, setComplianceFrameworks] = useState(null);
  const [employeesByDepartment, setEmployeesByDepartment] = useState(null);
  const [trainingCompletion, setTrainingCompletion] = useState(null);
  const [trainingCompletionUsers, setTrainingCompletionUsers] = useState(null);

  const [riskScoreOverAllTime, setRiskScoreOverAllTime] = useState([]); // Master set (All Time)

  const handleChangeSelectedDays = (days) => {
    // For risk score over time card
    updateRiskScoreOverTime(days);
  };

  const updateRiskScoreOverTime = useCallback(
    (days) => {
      if (days === "All Time") {
        // If all time, use the master set riskScoreOverAllTime.
        setRiskScoreOverTime(riskScoreOverAllTime);
      } else {
        const today = new Date();

        const cutOffDate = new Date();
        cutOffDate.setDate(today.getDate() - days);

        const filteredRiskScoreOverTime = riskScoreOverAllTime.filter(
          (element) => {
            const [month, day] = element.date.split(" ");
            let year = today.getFullYear();
            const dateForYearCheck = new Date(`${month} ${day}, ${year}`);

            if (dateForYearCheck > today) {
              year--;
            }

            const date = new Date(`${month} ${day}, ${year}`);

            return date >= cutOffDate;
          },
        );

        setRiskScoreOverTime(filteredRiskScoreOverTime);
      }
    },
    [riskScoreOverAllTime],
  );

  const loadInsightsDepartment = useCallback(
    async (department) => {
      // Fetch new data as it is not in cache.
      if (email !== "david@dune.demo") {
        const insightsDepartment = await getInsightsDepartment(department);

        if (Object.keys(insightsDepartment.error).length > 0) {
          console.error(insightsDepartment.error.message);
        } else {
          const {
            totalUsers,
            riskScore,
            scoreChange,
            riskScoreOverTime,
            areasToImprove,
            complianceFrameworks,
          } = insightsDepartment.result;

          console.log(insightsDepartment.result);

          setTotalUsers(totalUsers);
          setRiskScore(riskScore);
          setScoreChange(scoreChange);
          setRiskScoreOverAllTime(riskScoreOverTime);
          setAreasToImprove(areasToImprove);
          setComplianceFrameworks(complianceFrameworks);

          console.log(totalUsers);

          updateRiskScoreOverTime(90); // Default value is 90.
        }
      } else {
        setTotalUsers(0); // To change

        let updatedRiskScore = 0;

        if (department === "Capital") {
          updatedRiskScore = 61;
        } else if (department === "Founder Scouting") {
          // Engineering
          updatedRiskScore = 26;
        } else if (department === "Global Support") {
          updatedRiskScore = 9;
        } else if (department === "Technology") {
          // Information Technology
          updatedRiskScore = 43;
        } else if (department === "Investment") {
          updatedRiskScore = 36;
        } else if (department === "Legal & Finance") {
          // Legal
          updatedRiskScore = 52;
        } else if (department === "Marketing & Communications") {
          updatedRiskScore = 8;
        } else if (department === "Portfolio") {
          updatedRiskScore = 9;
        } else if (department === "Program") {
          updatedRiskScore = 15;
        }

        setRiskScore(updatedRiskScore);

        // Demo account
        let riskScoreOverTime = [];

        const currentDate = new Date(); // Start with today's date.
        currentDate.setDate(currentDate.getDate() - 90);

        let currentRiskScore = 0;
        const targetScore = updatedRiskScore;

        if (targetScore >= 50) {
          // Higher risk
          currentRiskScore = 30;
        } else {
          // Lower risk
          currentRiskScore = 75;
        }

        const totalDays = 90;

        const options = {
          month: "short",
          day: "numeric",
        };

        for (let i = 0; i < totalDays; i++) {
          const formattedDate = currentDate.toLocaleDateString(
            "en-US",
            options,
          );

          let stepChange = 0;

          if (targetScore >= 50) {
            stepChange = (targetScore - currentRiskScore) / (totalDays - i);
          } else {
            stepChange = (currentRiskScore - targetScore) / (totalDays - i);
          }

          let variability = (Math.random() - 0.5) * stepChange;

          let fluctuation =
            Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

          if (targetScore >= 50) {
            currentRiskScore += stepChange + variability + fluctuation;
          } else {
            currentRiskScore -= stepChange + variability + fluctuation;
          }

          if (targetScore >= 50) {
            if (i === totalDays - 1 && currentRiskScore < targetScore) {
              currentRiskScore = targetScore;
            } else if (updatedRiskScore > targetScore) {
              currentRiskScore = targetScore - Math.random();
            }
          } else {
            if (i === totalDays - 1 && currentRiskScore > targetScore) {
              currentRiskScore = targetScore;
            } else if (updatedRiskScore < targetScore) {
              currentRiskScore = targetScore + Math.random();
            }
          }

          riskScoreOverTime.push({
            date: formattedDate,
            riskScore: Math.round(currentRiskScore),
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        let scoreChange = 0;

        if (
          riskScoreOverTime.length &&
          riskScoreOverTime[0].riskScore &&
          updatedRiskScore
        ) {
          scoreChange = updatedRiskScore - riskScoreOverTime[0].riskScore;
        }

        setScoreChange(scoreChange);

        setRiskScoreOverTime(riskScoreOverTime);
        setRiskScoreOverAllTime(riskScoreOverTime);

        setAreasToImprove([
          {
            name: "Impersonation of internal processes",
          },
          {
            name: "Link manipulation",
          },
        ]);

        setComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
            progress: 0.92,
          },
          {
            name: "HIPAA",
            progress: 0.94,
          },
          {
            name: "ISO 27001",
            progress: 0.91,
          },
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email],
  );

  const loadTrainingCompletion = async (department) => {
    const trainingCompletionStatuses = await getTrainingCompletion(department);

    if (Object.keys(trainingCompletionStatuses.error).length > 0) {
      console.error(trainingCompletionStatuses.error.message);
    } else {
      const { trainingCompletion, users } = trainingCompletionStatuses.result;

      setTrainingCompletion(trainingCompletion);
      setTrainingCompletionUsers(users);
    }
  };

  const loadUsersByDepartment = async (department) => {
    const usersData = await getUsersByDepartment(department);

    if (Object.keys(usersData.error).length > 0) {
      console.error(usersData.error.message);
    } else {
      const { users } = usersData.result;

      setEmployeesByDepartment(users);
    }
  };

  useEffect(() => {
    const updateComponent = async () => {
      if (state && state.departmentName) {
        let departmentName = state.departmentName;

        if (email === "david@dune.demo") {
          const demoDepartmentMappings = {
            Engineering: "Founder Scouting",
            Legal: "Legal & Finance",
            "Information Technology": "Technology",
          };

          departmentName =
            demoDepartmentMappings[departmentName] || departmentName;
        }

        loadInsightsDepartment(departmentName);
        loadTrainingCompletion(departmentName);
        await loadUsersByDepartment(departmentName);

        setNewTitle(state.departmentName);
        setBreadcrumbs([
          {
            name: "Departments",
            path: "/insights/departments/search/",
          },
          {
            name: state.departmentName,
          },
        ]);
      } else {
        // If there is no department name in location, redirect the user to the search department page.
        navigate("/insights/departments/search/");
      }
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [
    isInitialLoading,
    email,
    setNewTitle,
    setBreadcrumbs,
    state,
    navigate,
    loadInsightsDepartment,
  ]);

  useEffect(() => {
    if (riskScoreOverAllTime.length > 0) {
      updateRiskScoreOverTime(90); // Default value is 90.
    }
  }, [riskScoreOverAllTime, updateRiskScoreOverTime]);

  return (
    <Dashboard
      pageTitle={pagesPlatform.INSIGHTS.subpages.DEPARTMENTS}
      totalUsers={totalUsers}
      riskScore={riskScore}
      scoreChange={scoreChange}
      riskScoreOverTime={riskScoreOverTime}
      areasToImprove={areasToImprove}
      employeesByDepartment={employeesByDepartment}
      trainingCompletion={trainingCompletion}
      trainingCompletionUsers={trainingCompletionUsers}
      complianceFrameworks={complianceFrameworks}
      email={email}
      onChangeSelectedDays={handleChangeSelectedDays}
    />
  );
}

export default InsightsDepartment;
