import { getResponseGet } from "../utils";

const getSlackAuthentication = async () => {
    let getSlackAuthenticationResponse = {
        result: {
            redirectUrl: ""
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/slack-auth/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data && data.auth_url) {
            getSlackAuthenticationResponse.result.redirectUrl = data.auth_url;
        } else {
            throw new Error("Redirect URL not found in the response headers");
        }
    } catch (error) {
        getSlackAuthenticationResponse.error = error;
    }

    return getSlackAuthenticationResponse;
};

export default getSlackAuthentication;