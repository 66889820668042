import { getResponsePatch } from "../utils";

export const patchChangeTrainingAssetStatus = async (requestBody) => {
  let changeTrainingAssetStatus = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePatch("/training-dashboard", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    changeTrainingAssetStatus.result = response;
    console.log(changeTrainingAssetStatus.result);
  } catch (error) {
    changeTrainingAssetStatus.error = error;
  }
};

export default patchChangeTrainingAssetStatus;
