const pagesWebsiteLeft = [
  {
    name: "Product",
    path: "/product/",
    tooltip: "Go to the Product page.",
    subpages: [
      {
        name: "Quantify Risk",
        path: "/product/#quantify-risk",
        tooltip: "Go to the Quantify Risk section.",
      },
      {
        name: "Remediate Risk",
        path: "/product/#remediate-risk",
        tooltip: "Go to the Remediate Risk section.",
      },
      {
        name: "User Adaptive Protection",
        path: "/product/#adaptive-protection",
        tooltip: "Go to the User Adaptive Protection section.",
      },
      {
        name: "Behavioral Insights",
        path: "/product/#behavioral-insights",
        tooltip: "Go to the Behavioral Insights section.",
      },
    ],
  },
  {
    name: "Resources",
    path: "/resources/",
    tooltip: "Go to the Resource Hub page.",
    subpages: [
      {
        name: "Resource Hub",
        path: "/resources/",
        tooltip: "Go to the Resource Hub page.",
      },
      {
        name: "Bootcamp",
        path: "/resources/bootcamp/",
        tooltip: "Go to the Bootcamp Resources page.",
      },
      {
        name: "Whitepapers",
        path: "/resources/whitepaper/",
        tooltip: "Go to the Whitepapers page.",
      },
      {
        name: "Infographics",
        path: "/resources/infographics/",
        tooltip: "Go to the Infographics page.",
      },
      {
        name: "From the Team",
        path: "/resources/team/",
        tooltip: "Go to the Team Resources page.",
      },
    ],
  },
  {
    name: "Company",
    path: "/company/",
    tooltip: "Go to the About page.",
    subpages: [
      {
        name: "About",
        path: "/company/",
        tooltip: "Go to the About page.",
      },
      {
        name: "CISO Advisory Council",
        path: "/company/ciso-advisory-council/",
        tooltip: "Go to the CISO Advisory Council page.",
      },
      {
        name: "Careers",
        path: "/careers/",
        tooltip: "Go to the Careers page.",
      },
      {
        name: "Contact Us",
        path: "/company/contact-us/",
        tooltip: "Go to the Contact Us page.",
      },
      {
        name: "Compliance",
        path: "/company/security-and-compliance/",
        tooltip: "Go to the Security and Compliance page.",
      },
    ],
  },
  {
    name: "Partners",
    path: "https://on.dunesecurity.io/dune-security-partner-portal-0",
    tooltip: "Go to the Partners portal.",
  },
];

export default pagesWebsiteLeft;
