import React from "react";

import Button from "../button";

const ButtonContainedGreen = ({
  children,
  padding,
  title,
  isSmall,
  isEndButton,
  isRounded,
  type,
  disabled,
  onClick,
}) => {
  return (
    <Button
      padding={padding}
      title={title}
      isSmall={isSmall}
      isEndButton={isEndButton}
      isRounded={isRounded}
      type={type}
      disabled={disabled}
      onClick={onClick}
      backgroundColor="green"
      boxShadow="0px 0px 25px 0px #009900 inset"
      hoverBoxShadow="0px 0px 10px 0px #00FF82, 0px 0px 10px 0px #009900 inset"
      textColor="gray-dark"
    >
      {children}
    </Button>
  );
};

export default ButtonContainedGreen;
