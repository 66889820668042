import React from "react";

const AccessPermissionLogo = () => {
  return (
    <svg
      role="presentation"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_232_23031"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="27"
        height="27"
      >
        <rect
          x="0.314331"
          y="0.655243"
          width="26.0825"
          height="26.0825"
          fill="#00FF82"
        />
      </mask>
      <g mask="url(#mask0_232_23031)">
        <path
          d="M4.66078 20.2171V7.17583V11.8489V11.5229V20.2171ZM4.66078 22.3906C4.06306 22.3906 3.55137 22.1778 3.12572 21.7521C2.70007 21.3265 2.48724 20.8148 2.48724 20.2171V7.17583C2.48724 6.57811 2.70007 6.06642 3.12572 5.64077C3.55137 5.21511 4.06306 5.00229 4.66078 5.00229H11.1814L13.3549 7.17583H22.0491C22.6468 7.17583 23.1585 7.38865 23.5842 7.81431C24.0098 8.23996 24.2226 8.75164 24.2226 9.34937V12.9085C23.8966 12.6731 23.5525 12.4693 23.1902 12.2972C22.828 12.1252 22.4476 11.9757 22.0491 11.8489V9.34937H12.4584L10.2848 7.17583H4.66078V20.2171H12.3497C12.404 20.5974 12.4901 20.9687 12.6078 21.331C12.7255 21.6933 12.8659 22.0465 13.0289 22.3906H4.66078ZM15.5285 22.3906V21.7929C15.5285 20.9778 15.927 20.3303 16.7239 19.8503C17.5209 19.3703 18.5714 19.1303 19.8756 19.1303C21.1797 19.1303 22.2302 19.3703 23.0272 19.8503C23.8242 20.3303 24.2226 20.9778 24.2226 21.7929V22.3906H15.5285ZM19.8756 18.0435C19.2778 18.0435 18.7661 17.8307 18.3405 17.405C17.9148 16.9794 17.702 16.4677 17.702 15.87C17.702 15.2723 17.9148 14.7606 18.3405 14.3349C18.7661 13.9093 19.2778 13.6964 19.8756 13.6964C20.4733 13.6964 20.985 13.9093 21.4106 14.3349C21.8363 14.7606 22.0491 15.2723 22.0491 15.87C22.0491 16.4677 21.8363 16.9794 21.4106 17.405C20.985 17.8307 20.4733 18.0435 19.8756 18.0435Z"
          fill="#00FF82"
        />
      </g>
    </svg>
  );
};

export default AccessPermissionLogo;
