import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import {
  BannerNotification,
  CookieBar,
  Footer,
  NavigationWebsite,
} from "../../layout";

import { Translator } from "react-auto-translate";

function WebsiteContainer({
  mainPage,
  title = "Dune Security | User Adaptive Risk Management",
  description = "Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies.",
}) {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const loadFingerprintJS = async () => {
      const fpPromise = FingerprintJS.load({
        apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY,
        endpoint: ["https://fp.dunesecurity.io", FingerprintJS.defaultEndpoint],
        scriptUrlPattern: [
          "https://fp.dunesecurity.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJS.defaultScriptUrlPattern,
        ],
      });

      fpPromise
        .then((fp) =>
          fp.get({
            linkedId: "Anonymous user",
          }),
        )
        .catch((error) => {
          console.error("Error tagging user with FingerprintJS:", error);
        });
    };

    loadFingerprintJS();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  const [language, setLanguage] = useState("en");

  const cacheProvider = {
    get: (language, key) => {
      const translations =
        JSON.parse(localStorage.getItem("translations")) || {};
      return translations[key]?.[language];
    },
    set: (language, key, value) => {
      const translations =
        JSON.parse(localStorage.getItem("translations")) || {};
      translations[key] = { ...translations[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(translations));
    },
  };

  return (
    <>
      <Translator
        cacheProvider={cacheProvider}
        from="en"
        to={language}
        googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxWidth="100vw"
          width="100%"
          paddingBottom="120px"
        >
          <BannerNotification />
          <NavigationWebsite onLanguageChange={setLanguage} />{" "}
          <Box role="main">{mainPage}</Box>
          <Footer />
          <CookieBar />
        </Box>
      </Translator>
    </>
  );
}

export default WebsiteContainer;
