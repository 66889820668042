import React from "react";

import Badge from "../badge";

const BadgeRed = ({ text, icon }) => {
  return (
    <Badge background="bg-red" textColor="text-black" text={text} icon={icon} />
  );
};

export default BadgeRed;
