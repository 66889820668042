import React from "react";

const GreenDownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <mask
        id="mask0_1634_5710"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <rect y="0.5" width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1634_5710)">
        <path
          d="M11 15.1667L6.41667 10.5833L7.70001 9.25416L10.0833 11.6375V4.16666H11.9167V11.6375L14.3 9.25416L15.5833 10.5833L11 15.1667ZM5.50001 18.8333C4.99584 18.8333 4.56424 18.6538 4.20521 18.2948C3.84619 17.9358 3.66667 17.5042 3.66667 17V14.25H5.50001V17H16.5V14.25H18.3333V17C18.3333 17.5042 18.1538 17.9358 17.7948 18.2948C17.4358 18.6538 17.0042 18.8333 16.5 18.8333H5.50001Z"
          fill="#00FF82"
        />
      </g>
    </svg>
  );
};

export default GreenDownloadIcon;
