import React from "react";

const FunnelIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_1760_22793"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.081665" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1760_22793)">
        <path
          d="M11.0001 20.0817C10.7167 20.0817 10.4792 19.9858 10.2876 19.7942C10.0959 19.6025 10.0001 19.365 10.0001 19.0817V13.0817L4.20008 5.68167C3.95008 5.34833 3.91258 4.99833 4.08758 4.63167C4.26258 4.265 4.56675 4.08167 5.00008 4.08167H19.0001C19.4334 4.08167 19.7376 4.265 19.9126 4.63167C20.0876 4.99833 20.0501 5.34833 19.8001 5.68167L14.0001 13.0817V19.0817C14.0001 19.365 13.9042 19.6025 13.7126 19.7942C13.5209 19.9858 13.2834 20.0817 13.0001 20.0817H11.0001ZM12.0001 12.3817L16.9501 6.08167H7.05008L12.0001 12.3817Z"
          fill="#CBCBCA"
        />
      </g>
    </svg>
  );
};

export default FunnelIcon;
