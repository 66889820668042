// To truncate any string to a certain length, you can use the following function:

//Example usage:
// import { truncate } from "./truncate";
//
// const longString = "This is a very long string";
// const shortString = truncate(longString, 10);
// console.log(shortString); // "This is a..."
// console.log(longString); // "This is a very long string"

export const truncate = (string, length) => {
  return string.length > length ? string.slice(0, length) + "..." : string;
};
