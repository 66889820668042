import React from "react";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import "./logo-item.css";

function LogoItem({
    isColorWhite = false,
    height,
    link
}) {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // If there is a given height, use the specified height.
    if (height) {
        return (
            <Link className="logo-item-link" to={link ? link : "/"} onClick={scrollToTop}>
                <Box display="inline-block">
                    <Box
                        component="img"
                        alt="Dune Logo"
                        src={
                            isColorWhite
                                ? "https://d3oo9a669kwmx7.cloudfront.net/dune-logo-white-and-white-brand-mark.png"
                                : "https://d3oo9a669kwmx7.cloudfront.net/dune-logo-white-and-green-brand-mark.png"
                        }
                        sx={{
                            height: `${height} !important`
                        }}
                    />
                </Box>
            </Link>
        )
    }

    // If there is no given height, use the default height of 32px (and 24px for smaller screens).
    return (
        <Link to={link ? link : "/"} onClick={scrollToTop}>
            <Box display="inline-block">
                <Box
                    component="img"
                    className="logo-item-image"
                    alt="Dune Logo"
                    src={
                        isColorWhite
                            ? "https://d3oo9a669kwmx7.cloudfront.net/dune-logo-white-and-white-brand-mark.png"
                            : "https://d3oo9a669kwmx7.cloudfront.net/dune-logo-white-and-green-brand-mark.png"
                    }
                />
            </Box>
        </Link>
    );
}

export default LogoItem;