import React from "react";

const Badge = ({ background = "", textColor = "", text, icon }) => {
  return (
    <div
      className={`flex flex-row items-center ${background && background} py-[5px] px-[20px] rounded-[4px] ml-[10px]`}
    >
      {icon && <div className="w-[12px] h-[12px]">{icon}</div>}
      <div className={icon && "ml-[4px]"}>
        <p className={`${textColor && textColor} detailed`}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default Badge;
