import { getResponsePost } from "../utils";

export const postTrainingCompleted = async (requestBody) => {
    let trainingCompleted = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponsePost("/complete_training/", requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        trainingCompleted.result = response.data;
    } catch (error) {
        trainingCompleted.error = error;
    }

    return trainingCompleted;
};

export default postTrainingCompleted;