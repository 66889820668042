import { getResponsePost } from '../utils';

export const postEmailPaywall = async (requestBody) => {
  let contactUs = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost(
      '/whitepaper-email-paywall/',
      requestBody
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    contactUs.result = response.data;
  } catch (error) {
    contactUs.error = error;
  }
};

export default postEmailPaywall;
