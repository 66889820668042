import React from "react";

const DropDownArrowIcon = ({ width, height }) => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "10px"}
      height={height ? height : "10px"}
      viewBox="0 0 10 6"
      fill="none"
    >
      <path
        d="M9 5L5 1L1 5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropDownArrowIcon;
