const formatComplianceFrameworks = (dataCompliance) => {
    if (!dataCompliance || typeof dataCompliance !== "object" || Object.keys(dataCompliance).length === 0) {
        return [];
    }

    let complianceFrameworks = [];

    for (const key in dataCompliance) {
        if (dataCompliance.hasOwnProperty(key) && dataCompliance[key] !== null && dataCompliance[key] !== undefined) {
            const formattedKey = key.replaceAll("_", " ");

            complianceFrameworks.push({
                name: formattedKey,
                progress: !isNaN(dataCompliance[key]) && typeof dataCompliance[key] !== "boolean"
                    ? +(parseFloat(dataCompliance[key]) / 100).toFixed(2)
                    : dataCompliance[key]
            });
        }
    }

    return complianceFrameworks;
};

export default formatComplianceFrameworks;