import React from "react";

const ArrowsUpDownIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.84494 12.8533V5.67827L5.26994 8.25327L3.84494 6.85327L8.84494 1.85327L13.8449 6.85327L12.4199 8.25327L9.84494 5.67827V12.8533H7.84494ZM14.8449 21.8533L9.84494 16.8533L11.2699 15.4533L13.8449 18.0283V10.8533H15.8449V18.0283L18.4199 15.4533L19.8449 16.8533L14.8449 21.8533Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowsUpDownIcon;
