import {
    formatComplianceFrameworks,
    getResponseGet
} from "../utils";

const getInsightsOrganizationComplianceFrameworks = async () => {
    let insightsOrganizationComplianceFrameworks = {
        result: {
            complianceFrameworks: {}
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/organization_data/?component=percentage_compliance");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        insightsOrganizationComplianceFrameworks.result = {
            complianceFrameworks: formatComplianceFrameworks(data.percentage_compliance)
        };
    } catch (error) {
        insightsOrganizationComplianceFrameworks.error = error;
    }

    return insightsOrganizationComplianceFrameworks;
};

export default getInsightsOrganizationComplianceFrameworks;