import React, { useState } from "react";

import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  ButtonContainedGreen,
  FadeInSection,
  GreenItalicText,
} from "../../../components";

import { LinkedInIcon } from "../../../utils/icons";

import { Translate } from "react-auto-translate";

import "./company.css";
import { spacing } from "../../../styles";

import {
  investors,
  leadership,
} from "../../../utils/constants/website/company";

function Company() {
  const [imageLoaded, setImageLoaded] = useState({});

  const handleImageLoaded = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "80px",
            md: "120px",
          }}
          padding="0 28px"
        >
          <Grid container maxWidth={spacing.MAX_WIDTH_WEBSITE} width="100%">
            <Grid item xs={12}>
              <Typography
                role="heading"
                aria-level="1"
                component="h1"
                className="dune-text-header-uppercase"
              >
                <Translate>About</Translate>
              </Typography>
              <Typography
                role="heading"
                aria-level="2"
                variant="h2"
                component="h2"
                className="dune-text-header-main"
                marginTop="20px"
              >
                Dune Security{" "}
                <Translate>is reducing the attack surface for</Translate>{" "}
                <GreenItalicText text="every enterprise " />
              </Typography>
              <Typography variant="body" component="p" marginTop="20px">
                <Translate>
                  Led by a team of industry experts, we're on a mission to fight
                  AI with AI. We know that for today's enterprises, CISOs'
                  biggest risk lies in their users, not their systems. By
                  utilizing contextualized testing and training to significantly
                  reduce employee error, our platform is eliminating social
                  engineering risk with automated remediation.
                </Translate>
              </Typography>
              <Box marginTop="40px">
                <Link to="/demo/">
                  <ButtonContainedGreen title="Meet the team">
                    Meet the team
                  </ButtonContainedGreen>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FadeInSection>
      <Box display="flex" justifyContent="center" padding="0 28px">
        <Grid
          container
          width="100%"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          height="100%"
        >
          <Grid
            item
            xs={12}
            marginTop={{
              xs: "80px",
              md: "120px",
            }}
          >
            <Typography
              role="heading"
              aria-level="2"
              variant="h2"
              component="h2"
              className="dune-text-header-main"
            >
              <Translate>Our team</Translate>
            </Typography>
            <Box display="flex" justifyContent="center">
              <Grid
                container
                marginTop={{
                  xs: "40px",
                  md: "80px",
                }}
                maxWidth="1080px"
              >
                {leadership.map((leader, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    marginTop={{
                      xs: index === 0 ? 0 : "40px",
                      md: index === 0 || index === 1 ? 0 : "40px",
                    }}
                    paddingLeft={{
                      xs: 0,
                      md: index % 2 === 1 ? "20px" : 0,
                    }}
                    paddingRight={{
                      xs: 0,
                      md: index % 2 === 0 ? "20px" : 0,
                    }}
                    key={`leader-${index}`}
                  >
                    <Stack direction="row" alignItems="center">
                      <Box maxWidth="200px" width="100%">
                        <Box
                          component="img"
                          className="company-image-leader"
                          src={leader.picture}
                          alt={leader.name}
                          onLoad={() => handleImageLoaded(index)}
                          sx={{
                            filter: imageLoaded[index] ? "none" : "blur(8px)",
                            transition: "filter 0.5s ease-in-out",
                          }}
                        />
                      </Box>
                      <Box marginLeft="40px">
                        <Typography
                          variant="body-emphasized"
                          component="p"
                          className="company-text-name"
                        >
                          {<Translate>{leader.name}</Translate>}
                        </Typography>
                        <Typography
                          variant="body"
                          component="p"
                          marginTop="20px"
                        >
                          {<Translate>{leader.role}</Translate>}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          marginTop="20px"
                          marginLeft="-2px"
                        >
                          <IconButton
                            className="company-icon-button"
                            title={`Go to ${leader.name}'s LinkedIn page.`}
                            aria-label={`Go to ${leader.name}'s LinkedIn page.`}
                            href={leader.linkLinkedIn}
                            target="_blank"
                          >
                            <LinkedInIcon size="24px" />
                          </IconButton>
                          {leader.email && (
                            <IconButton
                              className="company-icon-button"
                              title={`Email ${leader.name}.`}
                              aria-label={`Email ${leader.name}.`}
                              href={`mailto:${leader.email}`}
                            >
                              <EmailOutlined />
                            </IconButton>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className="company-box-mission"
        display="flex"
        justifyContent="center"
        marginTop={{
          xs: "80px",
          md: "120px",
        }}
        padding="0 28px"
      >
        <Grid
          container
          alignItems="center"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          padding="48px 0"
        >
          <Grid item xs={12} md paddingRight={{ xs: 0, md: "40px" }}>
            <Box maxWidth="544px" width="100%">
              <Box
                component="img"
                className="company-image-team"
                width="100%"
                alt="Dune Security team"
                src="https://d3oo9a669kwmx7.cloudfront.net/dune-team-small.jpg"
              />
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md
            justifyContent={{
              xs: "flex-start",
              md: "flex-end",
            }}
            marginTop={{
              xs: "8px",
              md: 0,
            }}
            paddingLeft={{ xs: 0, md: "40px" }}
          >
            <Typography variant="body" component="p">
              <Translate>
                Our mission is to strengthen cybersecurity by
              </Translate>{" "}
              <GreenItalicText text="equipping and educating users, " />
              <Translate>
                transforming them into the most effective defense against
                threats.
              </Translate>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop={{
          xs: "80px",
          md: "120px",
        }}
        marginBottom={{
          xs: "80px",
          md: "120px",
        }}
        padding="0 28px"
      >
        <Box
          display="grid"
          justifyContent="center"
          maxWidth={spacing.MAX_WIDTH_WEBSITE}
          width="100%"
        >
          <Typography
            role="heading"
            aria-level="2"
            variant="h2"
            component="h2"
            className="dune-text-header-main"
            textAlign="center"
          >
            <Translate>We're backed by incredible investors</Translate>
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            marginTop="80px"
            maxWidth="100%"
          >
            {investors.map((investor, index) => (
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={4}
                lg
                justifyContent="center"
                marginTop={{
                  xs: index > 0 ? "40px" : 0,
                  sm: index > 1 ? "40px" : 0,
                  md: index > 2 ? "40px" : 0,
                  lg: 0,
                }}
              >
                <Box
                  component="img"
                  src={investor.picture}
                  alt={investor.name}
                  maxHeight="36px"
                  height="100%"
                  marginLeft={{
                    xs: 0,
                    sm: index % 2 !== 0 ? "28px" : 0,
                    md: index % 3 !== 0 ? "28px" : 0,
                    lg: index > 0 ? "28px" : 0,
                  }}
                  key={`investor-${index}`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Company;
