import axios from "axios";

import { getToken } from "../utils";

const postFeedback = async (requestBody) => {
    let feedback = {
        result: {},
        error: {}
    };

    try {
        const token = await getToken();

        const url = "https://4fyoihdlcd.execute-api.us-east-1.amazonaws.com/latest/submit-feedback-lambda";
        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        };

        const response = await axios.post(url, requestBody, { headers });

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        feedback.result = response.data;
    } catch (error) {
        feedback.error = error;
    }

    return feedback;
};

export default postFeedback;