import React, { useState, useEffect } from "react";
import { ButtonOutlinedGreen, DropdownIcon } from "../../../components";
import { DeleteDumpsterIcon } from "../../../utils";
import { Link } from "react-router-dom";
import {
  getTrainingDashboardData,
  postTrainingDashboardData,
} from "../../../api";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";
import "./configure-training-frequency.css";

const ConfigureTrainingsFrequency = ({ setBreadcrumbs }) => {
  const [minimumTrainingsPerQuarter, setMinimumTrainingsPerQuarter] =
    useState(1);
  const [maximumTrainingsPerQuarter, setMaximumTrainingsPerQuarter] =
    useState(2);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [riskGroupCount, setRiskGroupCount] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [trainingQuarterCount, setTrainingQuarterCount] = useState([
    minimumTrainingsPerQuarter,
    maximumTrainingsPerQuarter,
  ]);
  const [selectUserGroupDropdown, setSelectUserGroupDropdown] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupMinTest, setSelectedGroupMinTest] = useState("");
  const [selectedGroupMaxTest, setSelectedGroupMaxTest] = useState("");
  const [error, setError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const groupOrder = ["high", "medium", "low"];

  const sortedRiskGroupCount = [...riskGroupCount].sort((a, b) => {
    const groupA = Object.keys(a)[0];
    const groupB = Object.keys(b)[0];
    return groupOrder.indexOf(groupA) - groupOrder.indexOf(groupB);
  });

  useEffect(() => {
    if (window.location.pathname === "/configure/trainings/frequency/") {
      setEditMode(true);
      setIsAccordionOpen(true);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await getTrainingDashboardData();
      if (response.data.config) {
        if (response.data.config) {
          if (
            response.data.config.risk_group_count &&
            response.data.config.risk_group_count.group_data
          ) {
            setRiskGroupCount(response.data.config.risk_group_count.group_data);
          }

          if (response.data.config.training_count_quarter) {
            const [minTrainings, maxTrainings] =
              response.data.config.training_count_quarter;
            setMinimumTrainingsPerQuarter(minTrainings);
            setMaximumTrainingsPerQuarter(maxTrainings);
            setTrainingQuarterCount([minTrainings, maxTrainings]);
          }
        }
      }
    }
    fetchData();

    setBreadcrumbs([
      { name: "Trainings", path: "/configure/trainings/" },
      { name: "Configure Training Frequency" },
    ]);
  }, [setBreadcrumbs]);

  const handleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const handleCancel = () => {
    setEditMode(false);
    setIsAccordionOpen(false);
    setHasChanges(false);
  };

  const handleSave = async () => {
    // Validate min < max for each risk group on submission
    for (const group of riskGroupCount) {
      const riskLevel = Object.keys(group)[0];
      const [minValue, maxValue] = group[riskLevel];
      if (minValue >= maxValue) {
        setError(
          "Minimum value must be less than maximum value for each group.",
        );
        return;
      }

      if (minValue > 70 || maxValue > 70) {
        setError("Tests must be less than 70.");
        return;
      } else {
        setError("");
      }
    }

    if (minimumTrainingsPerQuarter > maximumTrainingsPerQuarter) {
      setError("Minimum trainings must be less than maximum trainings.");
      return;
    }

    const response = await postTrainingDashboardData({
      training_count_quarter: [
        minimumTrainingsPerQuarter,
        maximumTrainingsPerQuarter,
      ],
      risk_group_count: { group_data: riskGroupCount },
    });
    setError("");

    if (response.error?.length > 0) {
      console.error("Error saving data", response.error);
    } else {
      setEditMode(false);
      setHasChanges(false);
    }
  };

  const handleEditMode = () => {
    setIsAccordionOpen(true);
    setEditMode(true);
  };

  const handleInputChange = (riskLevel, index, value, isMin) => {
    setRiskGroupCount((prev) =>
      prev.map((group, i) => {
        if (i === index) {
          const updatedValues = isMin
            ? [Number(value) || 1, group[riskLevel][1] || 1]
            : [group[riskLevel][0] || 1, Number(value) || 1];
          setHasChanges(true);
          return { ...group, [riskLevel]: updatedValues };
        }
        return group;
      }),
    );
  };

  const handleDeleteRiskGroup = (index) => {
    setHasChanges(true);
    setRiskGroupCount((prev) => prev.filter((_, i) => i !== index));
  };

  const toggleSelectUserGroupDropdown = () => {
    setSelectUserGroupDropdown(!selectUserGroupDropdown);
  };

  const handleSelectUserGroup = (group) => {
    const minTest = Number(selectedGroupMinTest) || 1;
    const maxTest = Number(selectedGroupMaxTest) || 1;

    if (minTest > maxTest) {
      setError("Minimum tests must be less than maximum tests.");
      return;
    } else {
      setError("");
    }

    if (minTest > 70 || maxTest > 70) {
      setError("Tests must be less than 70.");
    } else {
      setError("");
    }

    setRiskGroupCount((prev) => [
      ...prev,
      { [group]: [minTest || 1, maxTest || 1] },
    ]);

    setSelectedGroup(null);
    setSelectedGroupMinTest("");
    setSelectedGroupMaxTest("");
    setSelectUserGroupDropdown(false);
    setHasChanges(true);
  };

  const availableUserGroups = ["high", "medium", "low"].filter(
    (group) =>
      !riskGroupCount.some(
        (existingGroup) => Object.keys(existingGroup)[0] === group,
      ),
  );

  const handleWheelDisable = (e) => e.preventDefault();

  return (
    <div className="text-white">
      <div className="flex justify-between">
        <div>
          <h3 className="h3">Configure Training Frequency</h3>
        </div>
        {window.location.pathname === "/configure/trainings/frequency/" ? (
          // Automatically handles enabling editMode
          editMode ? null : (
            <ButtonOutlinedGreen onClick={handleEditMode}>
              Modify/Add
            </ButtonOutlinedGreen>
          )
        ) : (
          <Link to="/configure/trainings/frequency/">
            <ButtonOutlinedGreen>Edit / Add Rules</ButtonOutlinedGreen>
          </Link>
        )}
      </div>

      <div className="border border-gray border-opacity-35 bg-gray-background rounded-medium p-1 mt-0.75">
        <div className="font-medium text-h5">Organization Rules</div>

        <div className="flex items-center mt-2.5">
          <div className="flex items-center justify-between w-1/2">
            <div className="w-12">Minimum trainings per quarter</div>
            {editMode ? (
              <input
                type="number"
                min={0}
                className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                value={minimumTrainingsPerQuarter}
                onFocus={(e) =>
                  e.target.addEventListener("wheel", handleWheelDisable)
                }
                onBlur={(e) =>
                  e.target.removeEventListener("wheel", handleWheelDisable)
                }
                onChange={(e) => {
                  setMinimumTrainingsPerQuarter(Number(e.target.value));
                  setTrainingQuarterCount([
                    Number(e.target.value),
                    maximumTrainingsPerQuarter,
                  ]);
                  setHasChanges(true);
                }}
                required
              />
            ) : (
              <div className="mr-8 text-h2">{minimumTrainingsPerQuarter}</div>
            )}
          </div>
          <div className="flex items-center justify-between w-1/2">
            <div className="w-12">Maximum trainings per quarter</div>
            {editMode ? (
              <input
                type="number"
                min={0}
                className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-5"
                value={maximumTrainingsPerQuarter}
                onChange={(e) => {
                  setMaximumTrainingsPerQuarter(Number(e.target.value));
                  setTrainingQuarterCount([
                    minimumTrainingsPerQuarter,
                    Number(e.target.value),
                  ]);
                  setHasChanges(true);
                }}
                onFocus={(e) =>
                  e.target.addEventListener("wheel", handleWheelDisable)
                }
                onBlur={(e) =>
                  e.target.removeEventListener("wheel", handleWheelDisable)
                }
                required
              />
            ) : (
              <div className="mr-8 text-h2">{maximumTrainingsPerQuarter}</div>
            )}
          </div>
        </div>

        <div
          onClick={editMode ? null : handleAccordion}
          className={` flex justify-between items-center ${!editMode ? "border-y border-gray border-opacity-35 p-1 cursor-pointer" : "border-none p-0"} mt-3`}
        >
          <div className="font-medium text-h5">
            Custom rules for User Groups
          </div>
          <div
            className={`duration-300 ${isAccordionOpen ? "rotate-180" : ""}`}
          >
            {editMode ? null : <DropdownIcon />}
          </div>
        </div>

        {isAccordionOpen && (
          <div>
            {riskGroupCount.length > 0 ? (
              sortedRiskGroupCount.map((group, index) => {
                const [riskLevel] = Object.keys(group);
                const [minValue, maxValue] = group[riskLevel];
                return (
                  <div key={index}>
                    <div className="flex items-center mt-2.5 mb-2">
                      <div className="flex items-center justify-between w-1/2">
                        <div>
                          <div className="capitalize text-h5">
                            {riskLevel} Risk Users
                          </div>
                          <div className="text-h6">
                            Minimum trainings per quarter
                          </div>
                        </div>
                        {editMode ? (
                          <input
                            min={0}
                            type="number"
                            value={minValue}
                            onChange={(e) =>
                              handleInputChange(
                                riskLevel,
                                index,
                                e.target.value,
                                true,
                              )
                            }
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                handleWheelDisable,
                              )
                            }
                            onBlur={(e) =>
                              e.target.removeEventListener(
                                "wheel",
                                handleWheelDisable,
                              )
                            }
                            className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                          />
                        ) : (
                          <div className="mr-8 text-h2">{minValue}</div>
                        )}
                      </div>

                      <div className="flex items-center justify-between w-1/2">
                        <div>
                          <div className="capitalize text-h5">
                            {riskLevel} Risk Users
                          </div>
                          <div className="text-h6">
                            Maximum trainings per quarter
                          </div>
                        </div>
                        {editMode ? (
                          <div className="flex items-center">
                            <input
                              min={0}
                              type="number"
                              value={maxValue}
                              onChange={(e) =>
                                handleInputChange(
                                  riskLevel,
                                  index,
                                  e.target.value,
                                  false,
                                )
                              }
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  handleWheelDisable,
                                )
                              }
                              onBlur={(e) =>
                                e.target.removeEventListener(
                                  "wheel",
                                  handleWheelDisable,
                                )
                              }
                              className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                            />
                            <div
                              className="cursor-pointer"
                              onClick={() => handleDeleteRiskGroup(index)}
                            >
                              <DeleteDumpsterIcon />
                            </div>
                          </div>
                        ) : (
                          <div className="mr-8 text-h2">{maxValue}</div>
                        )}
                      </div>
                    </div>
                    <div className="text-gray text-opacity-35">
                      <hr />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="py-1 mt-2 text-white">
                No user groups set. Add a user group to configure custom rules.
              </div>
            )}

            {editMode && availableUserGroups.length > 0 && (
              <div>
                <div className="relative flex items-center justify-center">
                  <div className="flex items-center justify-between w-1/2">
                    <button
                      onClick={toggleSelectUserGroupDropdown}
                      className="flex items-center justify-between border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3 text-gray text-opacity-75"
                    >
                      <div className="mr-1">
                        {selectedGroup !== null ? (
                          <div>
                            {capitalizeFirstWords(selectedGroup)} Risk Users
                          </div>
                        ) : (
                          <div>Select User Group</div>
                        )}
                      </div>
                      <div tabIndex="0" role="button" className="mr-0.5">
                        <DropdownIcon />
                      </div>
                    </button>

                    {selectUserGroupDropdown && (
                      <div
                        className={`absolute z-10 px-1 bg-black border border-gray border-opacity-35 rounded-medium min-h-2 w-12 ${availableUserGroups.length === 3 ? "mt-11" : availableUserGroups.length === 2 ? "mt-8.25" : "mt-5.5"}`}
                      >
                        {availableUserGroups.map((group) => (
                          <div
                            key={group}
                            className="py-0.5 border-y border-gray border-opacity-35 cursor-pointer"
                            onClick={() => handleSelectUserGroup(group)}
                          >
                            {capitalizeFirstWords(group)} Risk Users
                          </div>
                        ))}
                      </div>
                    )}

                    <input
                      type="number"
                      disabled
                      placeholder="Minimum tests"
                      className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                      value={selectedGroupMinTest}
                      onChange={(e) => setSelectedGroupMinTest(e.target.value)}
                      onFocus={(e) =>
                        e.target.addEventListener("wheel", handleWheelDisable)
                      }
                      onBlur={(e) =>
                        e.target.removeEventListener(
                          "wheel",
                          handleWheelDisable,
                        )
                      }
                    />
                  </div>

                  <div className="w-1/2">
                    <div className="flex items-center justify-between pt-3 pb-2 pr-5">
                      <div>
                        <div className="text-h5 text-[#848483]">
                          {selectedGroup !== null ? (
                            <div className="text-white">
                              {capitalizeFirstWords(selectedGroup)} Risk Users
                            </div>
                          ) : (
                            <div>Selected User Group</div>
                          )}
                        </div>
                        <div className="text-h6">Maximum tests per Quarter</div>
                      </div>
                      <input
                        disabled
                        type="number"
                        className="number-input bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                        value={selectedGroupMaxTest}
                        placeholder="Maximum tests"
                        onChange={(e) =>
                          setSelectedGroupMaxTest(e.target.value)
                        }
                        onFocus={(e) =>
                          e.target.addEventListener("wheel", handleWheelDisable)
                        }
                        onBlur={(e) =>
                          e.target.removeEventListener(
                            "wheel",
                            handleWheelDisable,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {editMode && (
          <div className="flex justify-end mt-2 space-x-1">
            <Link to="/configure/trainings/">
              <ButtonOutlinedGreen onClick={handleCancel}>
                Cancel
              </ButtonOutlinedGreen>
            </Link>
            {hasChanges && (
              <ButtonOutlinedGreen onClick={handleSave}>
                Save
              </ButtonOutlinedGreen>
            )}
          </div>
        )}
      </div>
      {error && <div className="text-red mt-0.5">{error}</div>}
    </div>
  );
};

export default ConfigureTrainingsFrequency;
