import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CardWithLoadingState, DataTable } from "../../../components";

import { getUsers } from "../../../api";

import { headers } from "../../../utils/constants/platform/search-employee";

function SearchUser({ isInitialLoading, email, setIsPlatformLoading }) {
  // Users selector
  const [users, setUsers] = useState(null);

  // Filters
  const [riskLevelFilter, setRiskLevelFilter] = useState({});

  // Order
  const [defaultOrder, setDefaultOrder] = useState("desc"); // Default is desc.
  const [defaultOrderBy, setDefaultOrderBy] = useState("riskScore"); // Default is risk score.

  const { state } = useLocation();

  useEffect(() => {
    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        // Remove double occurrence of Duncan Idaho demo account.
        if (email === "david@dune.demo") {
          // Demo account
          const usersDemoEndUser = users.filter(
            (user) => user.email === "duncan.idaho@dune.demo",
          );
          const usersDemoManager = users.filter(
            (user) => user.email === "aaron.chavez@dune.demo",
          );
          let updatedUsers = users.filter(
            (user) =>
              user.email !== "duncan.idaho@dune.demo" &&
              user.email !== "aaron.chavez@dune.demo",
          );

          if (usersDemoEndUser.length > 0) {
            usersDemoEndUser[0].riskScore = 97;
            usersDemoEndUser[0].compliant = false;

            updatedUsers = [...updatedUsers, usersDemoEndUser[0]];
          }

          if (usersDemoManager.length > 0) {
            usersDemoManager[0].riskScore = 14;
            usersDemoManager[0].compliant = true;
            usersDemoManager[0].role = "Chief Financial Officer";

            updatedUsers = [...updatedUsers, usersDemoManager[0]];
          } else {
            updatedUsers = [
              {
                firstName: "Aaron",
                lastName: "Chavez",
                initials: "AC",
                email: "aaron.chavez@dune.demo",
                department: "Finance",
                role: "Chief Financial Officer",
                compliant: true,
                complianceFrameworks: [],
                riskScore: 14,
              },
              ...updatedUsers,
            ];
          }

          // Add more servere users.
          updatedUsers = [
            {
              firstName: "Alex",
              lastName: "Rivera",
              initials: "AR",
              email: "alex.rivera@dune.demo",
              department: "Program",
              role: "Program Analyst",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 76,
            },
            {
              firstName: "Jack",
              lastName: "Turner",
              initials: "JT",
              email: "jack.turner@dune.demo",
              department: "Technology",
              role: "Senior Software Engineer",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 78,
            },
            {
              firstName: "Logan",
              lastName: "Murphy",
              initials: "LM",
              email: "logan.murphy@dune.demo",
              department: "Program",
              role: "Program Analyst",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 79,
            },
            {
              firstName: "Casey",
              lastName: "Lee",
              initials: "CL",
              email: "casey.lee@dune.demo",
              department: "Investment",
              role: "Intern",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 80,
            },
            {
              firstName: "Sam",
              lastName: "Jordan",
              initials: "SJ",
              email: "sam.jordan@dune.demo",
              department: "Program",
              role: "Program Analyst",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 81,
            },
            {
              firstName: "Mason",
              lastName: "Wright",
              initials: "MW",
              email: "mason.wright@dune.demo",
              department: "Investment",
              role: "Program & Community Analyst",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 83,
            },
            {
              firstName: "Avery",
              lastName: "Brooks",
              initials: "AB",
              email: "avery.brooks@dune.demo",
              department: "Investment",
              role: "Program & Community Analyst",
              compliant: false,
              complianceFrameworks: [],
              riskScore: 90,
            },
            ...updatedUsers,
          ];

          setUsers(updatedUsers);
        } else {
          // Check if the signed in user is in the users list. If they are, note that it is them.
          const updatedUsers = users.map(({ lastName, ...user }) => {
            if (user.email === email) {
              return {
                lastName: `${lastName} (You)`,
                ...user,
              };
            }

            return {
              lastName,
              ...user,
            };
          });

          setUsers(updatedUsers);
        }
      }
    };

    const updateComponent = async () => {
      setIsPlatformLoading(true);
      await loadUsers();
      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      updateComponent().finally(() => {
        if (state) {
          if (state.riskLevel) {
            // Filter by risk level if it exists in state.
            setRiskLevelFilter(state.riskLevel);
          }

          if (state.defaultOrder) {
            // Update the default order if it exists in state.
            setDefaultOrder(state.defaultOrder);
          }

          if (state.defaultOrderBy) {
            // Update the default order by if it exists in state.
            setDefaultOrderBy(state.defaultOrderBy);
          }
        }
      });
    }
  }, [isInitialLoading, email, state, setIsPlatformLoading]);

  return (
    <CardWithLoadingState loadingVariable={users} minHeight="585px">
      <DataTable
        data={users}
        headers={headers}
        defaultOrder={defaultOrder}
        defaultOrderBy={defaultOrderBy}
        defaultFilters={{
          riskLevel: riskLevelFilter,
        }}
        fixedTotal={email === "david@dune.demo" ? 10483 : null}
      />
    </CardWithLoadingState>
  );
}

export default SearchUser;
