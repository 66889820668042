import {
  GraduationCapFilledIcon,
  GraduationCapOutlinedIcon,
  SignalCellularFilledIcon,
  SignalCellularOutlinedIcon,
} from "../utils/icons";

const pagesPlatformEndUser = {
  INSIGHTS: {
    name: "Insights",
    path: "/dashboard/insights/",
    icon: <SignalCellularOutlinedIcon />,
    activeIcon: <SignalCellularFilledIcon />,
    tooltip: "Go to the insights page.",
  },
  TRAININGS: {
    name: "Trainings",
    path: "/dashboard/trainings/",
    icon: <GraduationCapOutlinedIcon />,
    activeIcon: <GraduationCapFilledIcon />,
    tooltip: "Go to the trainings page.",
  },
};

export default pagesPlatformEndUser;
