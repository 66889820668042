export const userRiskInteractionDataHeaders = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "208px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: false,
    isSearchable: false,
    align: "left",
    width: "164px",
  },
];

export const complianceData = [
  {
    id: "select",
    label: "Select",
    isSortable: false,
    isSearchable: false,
    align: "center",
    width: "20px",
  },
];

export const trainingDataHeaders = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "100px",
  },
  {
    id: "email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "200px",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "200px",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "200px",
  },
  {
    id: "status",
    label: "Training Status",
    isSortable: true,
    align: "left",
    width: "200px",
  },
];

export const daysOfWeek = [
  {
    idx: 0,
    day: "Monday",
  },
  {
    idx: 1,
    day: "Tuesday",
  },
  {
    idx: 2,
    day: "Wednesday",
  },
  {
    idx: 3,
    day: "Thursday",
  },
  {
    idx: 4,
    day: "Friday",
  },
];

export const data = [
  {
    name: "USER RISK DATA",
    value: "users-risk-interaction-data",
  },
  {
    name: "TRAINING COMPLETION DATA",
    value: "training-completion-data",
  },
  {
    name: "COMPLIANCE DATA",
    value: "compliance-data",
  },
];
