import React from "react";

const List = ({ data = [], keyName = "", textSize = "description" }) => {
  return (
    <ol className="w-full list-none">
      {data.map((dataItem, index) => (
        <li
          className={`flex items-center mt-1 first:mt-1 ${textSize}`}
          key={`list-${index}`}
        >
          <span className="inline-block font-semibold border-b-2 border-green pb-0.125 max-w-1.25 w-full text-center mr-0.5">
            {index + 1}
          </span>
          <span className="inline-block">{dataItem[keyName]}</span>
        </li>
      ))}
    </ol>
  );
};

export default List;
