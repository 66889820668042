import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const formatAreasToImproveOrganization = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  const formattedAreasToImproveOrganization = Object.entries(areasToImprove)
    .sort(([, a], [, b]) => b - a) // Sort by value (descending).
    .slice(0, 5) // Limit to top 5.
    .map(([name, count]) => ({ name: capitalizeFirstWords(name), count })); // Map to the desired format.

  // Calculate the percentages of the top 6 areas to improve and add value for the green circle in the radar chart.
  const totalCount = formattedAreasToImproveOrganization.reduce(
    (acc, obj) => acc + obj.count,
    0,
  );
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < formattedAreasToImproveOrganization.length; i++) {
    formattedAreasToImproveOrganization[i].percentage =
      formattedAreasToImproveOrganization[i].count / totalCount;
    formattedAreasToImproveOrganization[i].letter =
      alphabet[i % alphabet.length];
  }

  return formattedAreasToImproveOrganization;
};

export default formatAreasToImproveOrganization;
