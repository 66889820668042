const complianceFrameworks = [
  "CCPA",
  "GDPR",
  "GLBA",
  "HIPAA",
  "ISO 27001",
  "NIST",
  "PCI DSS",
  "PII",
  "SOC 2",
  "SOC 2 Type 2", // To be replaced by SOC 2
];

const formatTrainings = (assignedTraining) => {
  if (!(assignedTraining && Array.isArray(assignedTraining))) {
    return {
      learningModules: [],
      complianceModules: [],
      completedModules: [],
    };
  }

  const learningModules = [];
  const complianceModules = [];
  const completedModules = [];

  const assignedTrainingCompliance = [];

  assignedTraining.sort((a, b) => {
    const dateA = new Date(a.start_date);
    const dateB = new Date(b.start_date);

    return dateA - dateB;
  });

  for (let i = 0; i < assignedTraining.length; i++) {
    const video = assignedTraining[i];

    if (complianceFrameworks.includes(video.compliance_framework)) {
      assignedTrainingCompliance.push({
        id: video.assigned_training_id,
        category: video.compliance_framework,
        status: video.status,
        title: video.title,
        fileName: video.file_name,
        startDate: video.start_date,
      });
    } else if (video.status !== "completed") {
      learningModules.push({
        id: video.assigned_training_id,
        status: video.status,
        title: video.title,
        fileName: video.file_name,
        startDate: video.start_date,
      });
    } else {
      completedModules.push({
        id: video.assigned_training_id,
        status: video.status,
        title: video.title,
        fileName: video.file_name,
        startDate: video.start_date,
      });
    }
  }

  if (assignedTrainingCompliance.length > 0) {
    const selectedComplianceFrameworks = [
      ...new Set(assignedTrainingCompliance.map((video) => video.category)),
    ];
    selectedComplianceFrameworks.sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < selectedComplianceFrameworks.length; i++) {
      const setVideosCompliance = {
        name: selectedComplianceFrameworks[i],
        videos: assignedTrainingCompliance.filter(
          (video) => video.category === selectedComplianceFrameworks[i],
        ),
      };

      setVideosCompliance.videos.sort((a, b) =>
        a.fileName.localeCompare(b.fileName),
      );
      complianceModules.push(setVideosCompliance);
    }
  }

  return {
    learningModules,
    complianceModules,
    completedModules,
  };
};

export default formatTrainings;
