import React from "react";

const AnalyticsFilledIcon = () => {
  return (
    <svg
      role="presentation"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="AnalyticsIcon"
      fill="#ffffff"
    >
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M9 17H7v-5h2zm4 0h-2v-3h2zm0-5h-2v-2h2zm4 5h-2V7h2z"></path>
    </svg>
  );
};

export default AnalyticsFilledIcon;
