import React from "react";

import { CardHeader, CardWithLoadingState, DataTable } from "../../../..";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    align: "left",
  },
  {
    id: "activity",
    label: "Activity",
    isSortable: true,
    align: "center",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    align: "left",
  },
];

const CardAnomalousLoginBehavior = ({ anomalousLoginBehavior }) => {
  return (
    <CardWithLoadingState loadingVariable={anomalousLoginBehavior}>
      <CardHeader header="Anomalous Login Behavior" />
      <DataTable
        data={anomalousLoginBehavior}
        headers={headers}
        rowsPerPage={5}
        defaultOrder="desc"
        defaultOrderBy="riskScore"
        disablePagination
        disableSearchBar
      />
      {/* <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row">
          <ButtonOutlinedGreen isSmall>
            View all
          </ButtonOutlinedGreen>
        </div>
      </div> */}
    </CardWithLoadingState>
  );
};

export default CardAnomalousLoginBehavior;
