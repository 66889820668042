import { getResponseGet } from "../utils";

const getCurrentMSSPOrganization = async (msspOrganizationID) => {
    let currentMSSPOrganizationData = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponseGet(`/mssp_company_list/${msspOrganizationID}/`);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        if (data) {
            currentMSSPOrganizationData.result = data;
        }
    } catch (error) {
        currentMSSPOrganizationData.error = error;
    }

    return currentMSSPOrganizationData;
};

export default getCurrentMSSPOrganization;