import { getResponsePost } from "../utils";

const postUserGuardRails = async (requestBody) => {
  let userGuardRailsData = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost(
      "/post-user-guardrails/",
      requestBody,
    );

    if (response.status === 200) {
      userGuardRailsData.result.userGuardRails = response.data.user_guard_rails;
    } else {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }
  } catch (error) {
    userGuardRailsData.error = error;
  }

  return userGuardRailsData;
};

export default postUserGuardRails;
