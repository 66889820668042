import React from "react";

import { ButtonOutlinedGreen } from "../buttons";

const FormActionSection = ({
  successMessage,
  errorMessage,
  onSubmit,
  buttonText = "Submit",
}) => {
  return (
    <div className="flex flex-col-reverse md:flex-row md:justify-between">
      <div className="mt-1 md:mt-0">
        {successMessage && <p className="body text-green">{successMessage}</p>}
        {errorMessage && !successMessage && (
          <p className="body text-red">{errorMessage}</p>
        )}
      </div>
      <div>
        <ButtonOutlinedGreen title="Submit request." onClick={onSubmit}>
          {buttonText}
        </ButtonOutlinedGreen>
      </div>
    </div>
  );
};

export default FormActionSection;
