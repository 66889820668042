export const MSSP_ORGANIZATIONS = [
  {
    id: "0",
    name: "Dune Security",
    email: "david@dune.demo",
  },
  {
    id: "1",
    name: "Apple",
    email: "david@dune.demo",
  },
  {
    id: "2",
    name: "Amazon",
    email: "david@dune.demo",
  },
  {
    id: "3",
    name: "Google",
    email: "david@dune.demo",
  },
  {
    id: "4",
    name: "Microsoft",
    email: "david@dune.demo",
  },
  {
    id: "5",
    name: "Nvidia",
    email: "david@dune.demo",
  },
];
