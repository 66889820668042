import React from "react";

const ArrowUpIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g mask="url(#mask0_553_132561)">
        <path
          d="M11 18V8.8L7.4 12.4L6 11L12 5L18 11L16.6 12.4L13 8.8V18H11Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowUpIcon;
