import React from "react";

const TrainingOutlinedIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.25959 10.147C3.98453 12.2526 3.82064 14.3712 3.76859 16.494C6.64737 17.6966 9.40374 19.1734 11.9996 20.904C14.5957 19.1733 17.3525 17.6965 20.2316 16.494C20.1795 14.3712 20.0156 12.2526 19.7406 10.147M19.7406 10.147C20.6191 9.85179 21.5054 9.58036 22.3986 9.33304C19.1352 7.04529 15.6516 5.08892 11.9996 3.49304C8.34758 5.08926 4.86398 7.04596 1.60059 9.33404C2.49387 9.58064 3.3802 9.85174 4.25859 10.147C6.92724 11.0442 9.51653 12.162 11.9996 13.489C14.4823 12.1621 17.0723 11.0442 19.7406 10.147ZM6.74959 15C6.9485 15 7.13926 14.921 7.27992 14.7804C7.42057 14.6397 7.49959 14.449 7.49959 14.25C7.49959 14.0511 7.42057 13.8604 7.27992 13.7197C7.13926 13.5791 6.9485 13.5 6.74959 13.5C6.55067 13.5 6.35991 13.5791 6.21926 13.7197C6.0786 13.8604 5.99959 14.0511 5.99959 14.25C5.99959 14.449 6.0786 14.6397 6.21926 14.7804C6.35991 14.921 6.55067 15 6.74959 15ZM6.74959 15V11.325C8.44632 10.2704 10.1989 9.30831 11.9996 8.44304M4.99259 19.993C5.55056 19.4364 5.99302 18.7751 6.29455 18.0469C6.59608 17.3187 6.75072 16.5382 6.74959 15.75V14.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrainingOutlinedIcon;
