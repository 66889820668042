import { getResponseGet } from "../utils";

const getAnomalousActivites = async () => {
  let getAnomalousActivitesResponse = {
    result: {
      anomalousActivities: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/anomaly-remediation/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      const anomalousActivities = data;

      // Sort by the latest date (descending order).
      anomalousActivities.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      );

      getAnomalousActivitesResponse.result.anomalousActivities =
        anomalousActivities;
    }
  } catch (error) {
    getAnomalousActivitesResponse.error = error;
  }

  return getAnomalousActivitesResponse;
};

export default getAnomalousActivites;
