import React from "react";

const DropdownIcon = ({ stroke }) => {
  return (
    <svg
      role="presentation"
      className="ml-0.313"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3125 1.64844L5.07207 5.40801L8.83164 1.64844"
        stroke={stroke ? stroke : "white"}
        strokeWidth="1.40401"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropdownIcon;
