import React from "react";

import "../../../App.css";
import { Translate } from "react-auto-translate";

const GreenItalicText = ({ text, className }) => {
  return (
    <div
      className={`text-green italic leading-[1.2] italic-font ${className}`}
      style={{
        display: "inline-block",
        margin: 0,
        textAlign: "center", // Center-align text to keep it consistent
      }}
    >
      <Translate>{text}</Translate>
    </div>
  );
};

export default GreenItalicText;
