import React from "react";

const ReduceLogo = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.5 24.7872C12.1807 24.7872 11.8763 24.7274 11.5869 24.6076C11.2975 24.4879 11.033 24.3182 10.7935 24.0987L1.18323 14.4884C0.963682 14.2489 0.79403 13.9844 0.674276 13.695C0.554521 13.4056 0.494644 13.1012 0.494644 12.7819C0.494644 12.4625 0.554521 12.1532 0.674276 11.8538C0.79403 11.5544 0.963682 11.2949 1.18323 11.0754L10.7935 1.46511C11.033 1.2256 11.2975 1.05096 11.5869 0.941182C11.8763 0.831407 12.1807 0.77652 12.5 0.77652C12.8194 0.77652 13.1287 0.831407 13.4281 0.941182C13.7275 1.05096 13.987 1.2256 14.2065 1.46511L23.8168 11.0754C24.0563 11.2949 24.2309 11.5544 24.3407 11.8538C24.4505 12.1532 24.5054 12.4625 24.5054 12.7819C24.5054 13.1012 24.4505 13.4056 24.3407 13.695C24.2309 13.9844 24.0563 14.2489 23.8168 14.4884L14.2065 24.0987C13.987 24.3182 13.7275 24.4879 13.4281 24.6076C13.1287 24.7274 12.8194 24.7872 12.5 24.7872ZM11.3025 13.9794H13.6975V6.79417H11.3025V13.9794ZM12.5 17.5721C12.8393 17.5721 13.1237 17.4573 13.3533 17.2278C13.5828 16.9982 13.6975 16.7138 13.6975 16.3745C13.6975 16.0352 13.5828 15.7508 13.3533 15.5213C13.1237 15.2917 12.8393 15.177 12.5 15.177C12.1607 15.177 11.8763 15.2917 11.6468 15.5213C11.4172 15.7508 11.3025 16.0352 11.3025 16.3745C11.3025 16.7138 11.4172 16.9982 11.6468 17.2278C11.8763 17.4573 12.1607 17.5721 12.5 17.5721Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default ReduceLogo;
