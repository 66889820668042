import React, { useEffect, useState } from "react";

import { ButtonTextWhite } from "../../buttons";

import { BackIcon, NextIcon } from "../../../../utils/icons";

const CalendarOrganization = ({
  dates,
  selectedDate,
  onChangeSelectedDate,
}) => {
  const defaultCurrentYear = new Date().getFullYear();
  const defaultCurrentMonth = new Date().getMonth();

  const [currentYear, setCurrentYear] = useState(defaultCurrentYear);
  const [currentMonth, setCurrentMonth] = useState(defaultCurrentMonth);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const handleClickCalendarDay = (selectedDay) => {
    const updatedSelectedDate = [
      currentYear,
      currentMonth + 1,
      String(selectedDay).padStart(2, "0"),
    ].join("-");

    onChangeSelectedDate({ date: updatedSelectedDate });
  };

  const handleClickNext = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleClickPrevious = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const generateCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfWeek = firstDayOfMonth.getDay();

    const calendarDays = [];

    // Add empty slots for days before the first day of the month.
    for (let i = 0; i < firstDayOfWeek; i++) {
      calendarDays.push(<div key={`empty-${i}`} />);
    }

    // Add the days of the month.
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedCurrentDate = `${currentYear}-${String(
        currentMonth + 1,
      ).padStart(2, "0")}-${String(day).padStart(2, "0")}`; // Format: YYYY/MM/DD

      let calendarDay = <></>;

      let testExists = dates.some((element) => {
        return element.date === formattedCurrentDate;
      });

      if (testExists) {
        let isSelectedDate = false;

        if (selectedDate && selectedDate.date) {
          // Extract day, month, and year from selectedDate.
          const [year, month, date] = selectedDate.date.split("-").map(Number);

          isSelectedDate =
            year === currentYear && month === currentMonth + 1 && date === day;
        }

        calendarDay = (
          <div
            button="role"
            className="description-emphasized text-center p-[10px] cursor-pointer font-bold"
            style={{
              background: isSelectedDate
                ? "#00ff82"
                : "rgba(0, 255, 130, 0.20)",
              color: isSelectedDate ? "#0a3d23" : "#00974d",
            }}
            onClick={() => handleClickCalendarDay(day)}
            key={`calendar-day-${day}`}
          >
            {day}
          </div>
        );
      } else {
        calendarDay = (
          <div
            className="description-emphasized text-center font-bold p-[10px]"
            style={{
              color: "#737272",
            }}
            key={`calendar-day-${day}`}
          >
            {day}
          </div>
        );
      }

      calendarDays.push(calendarDay);
    }

    return calendarDays;
  };

  useEffect(() => {
    if (selectedDate && selectedDate.date) {
      const dateObject = new Date(selectedDate.date.replace("-", "/"));
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth();

      setCurrentYear(year);
      setCurrentMonth(month);
    }
  }, [selectedDate]);

  return (
    <div className="flex min-h-[326px] w-[310.23px]">
      <div className="">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="body-emphasized">
              {monthNames[currentMonth]} {currentYear}
            </h4>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex">
              <ButtonTextWhite
                isSmall
                title="Go to the previous month."
                onClick={handleClickPrevious}
                className="detailed"
              >
                <BackIcon />
              </ButtonTextWhite>
            </div>
            <div className="flex">
              <ButtonTextWhite
                isSmall
                title="Go to the next month."
                onClick={handleClickNext}
                className="detailed"
              >
                <NextIcon />
              </ButtonTextWhite>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-0.25 mt-2.5">
          {daysOfWeek.map((day, index) => (
            <div
              key={`days-of-week-${index}`}
              className="text-center description-emphasized mb-0.5"
            >
              {day}
            </div>
          ))}
          {generateCalendar()}
        </div>
      </div>
    </div>
  );
};

export default CalendarOrganization;
