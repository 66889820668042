import React, { useEffect, useState } from "react";
import {
  ButtonContainedGreen,
  ButtonOutlinedGray,
  LogoItem,
} from "../../common";
import DuneSecurityLogo from "../../../utils/icons/dune-security-logo/dune-security-logo";
import { Link } from "react-router-dom";

function CookieBar() {
  const [cookiebar] = useState([
    {
      description_one:
        "We use Cookies, among other things, to improve your experience on our website.",
      description_two: "To know more about our cookies, please read our",
      link: "Privacy Policy",
    },
  ]);

  const [selectedCookieBar, setSelectedCookieBar] = useState({
    description: "",
  });

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = getCookie("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }

    if (cookiebar.length > 0) {
      setSelectedCookieBar(cookiebar[0]);
    }
  }, [cookiebar]);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleAccept = () => {
    setCookie("cookieConsent", "accepted", 365);
    setIsVisible(false);
    console.log("Accepted all cookies.");
  };

  const handleReject = () => {
    setCookie("cookieConsent", "rejected", 365);
    setIsVisible(false);
    console.log("Rejected all cookies.");
  };

  const handleClose = () => {
    setIsVisible(false);
    console.log("Closed cookie bar.");
  };

  return (
    isVisible && (
      <div className="w-[90%] md:w-78.75 mx-auto bg-black text-white sticky bottom-1 left-1 rounded-medium p-0.625 md:p-0 pt-1 text-sm z-10 md:block sm:flex shadow-[0px_0px_20px_0px_rgba(255,255,255,0.10)]">
        <div className="flex justify-end translate transform translate-y-0.75 md:-translate-y-0.5 mt-0 md:mt-1.5 mr-0.5 md:mr-1">
          <button aria-label="Close the cookie bar" onMouseDown={handleClose}>
            <svg
              role="presentation"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.7785 0.22908C9.70833 0.158765 9.62498 0.10298 9.53323 0.0649179C9.44147 0.0268556 9.34311 0.00726357 9.24377 0.00726357C9.14443 0.00726357 9.04607 0.0268556 8.95431 0.0649179C8.86255 0.10298 8.7792 0.158765 8.70903 0.22908L5 3.93053L1.29097 0.221495C1.22075 0.151273 1.13738 0.0955689 1.04563 0.0575646C0.95388 0.0195603 0.855543 7.39916e-10 0.756233 0C0.656923 -7.39917e-10 0.558585 0.0195603 0.466835 0.0575646C0.375085 0.0955689 0.291718 0.151273 0.221495 0.221495C0.151273 0.291718 0.0955689 0.375085 0.0575646 0.466835C0.0195603 0.558585 -7.39916e-10 0.656923 0 0.756233C7.39917e-10 0.855543 0.0195603 0.95388 0.0575646 1.04563C0.0955689 1.13738 0.151273 1.22075 0.221495 1.29097L3.93053 5L0.221495 8.70903C0.151273 8.77925 0.0955689 8.86262 0.0575646 8.95437C0.0195603 9.04612 0 9.14446 0 9.24377C0 9.34308 0.0195603 9.44141 0.0575646 9.53316C0.0955689 9.62492 0.151273 9.70828 0.221495 9.7785C0.291718 9.84873 0.375085 9.90443 0.466835 9.94244C0.558585 9.98044 0.656923 10 0.756233 10C0.855543 10 0.95388 9.98044 1.04563 9.94244C1.13738 9.90443 1.22075 9.84873 1.29097 9.7785L5 6.06947L8.70903 9.7785C8.77925 9.84873 8.86262 9.90443 8.95437 9.94244C9.04612 9.98044 9.14446 10 9.24377 10C9.34308 10 9.44141 9.98044 9.53316 9.94244C9.62492 9.90443 9.70828 9.84873 9.7785 9.7785C9.84873 9.70828 9.90443 9.62492 9.94244 9.53316C9.98044 9.44141 10 9.34308 10 9.24377C10 9.14446 9.98044 9.04612 9.94244 8.95437C9.90443 8.86262 9.84873 8.77925 9.7785 8.70903L6.06947 5L9.7785 1.29097C10.0667 1.00274 10.0667 0.517308 9.7785 0.22908Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-wrap justify-between items-center md:mx-1.25 md:mb-2 md:mt-0">
          <div className="flex flex-wrap justify-center items-center mb-1.25 md:mb-0">
            <div className="mr-13.25 md:mr-2.5 mb-1 md:mb-0 flex">
              <LogoItem logo={<DuneSecurityLogo />} />
            </div>
            <p role="heading" aria-level="6" className="text-h6 md:text-h5">
              {selectedCookieBar.description_one}
              <br />
              {selectedCookieBar.description_two}
              <Link to="/privacy-policy">
                <span className="underline ml-0.25 text-green">
                  {selectedCookieBar.link}
                </span>
              </Link>
              <span className="text-white">.</span>
            </p>
          </div>
          <div className="flex justify-center items-center space-x-1 md:space-x-0.625">
            <div className="w-1/2 md:w-auto">
              <ButtonOutlinedGray
                title="Reject All Cookies"
                isSmall
                onClick={handleReject}
              >
                Reject All Cookies
              </ButtonOutlinedGray>
            </div>
            <div className="w-1/2 md:w-auto">
              <ButtonContainedGreen
                title="Accept All Cookies"
                isSmall
                onClick={handleAccept}
              >
                Accept All Cookies
              </ButtonContainedGreen>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CookieBar;
