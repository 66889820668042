import React, { useEffect, useRef, useState } from "react";

import {
  CardAnomalousActivityTracker,
  CardAnomalousLoginBehavior,
  CardAreasToImprove,
  CardAverageHourlyUserLogin,
  CardDeviceProtectionStatus,
  CardEmployeesByDepartment,
  CardInactiveUsers,
  CardInteractions,
  CardMFAProtectionStatus,
  CardProfileDepartment,
  CardProfileUser,
  CardRecentTestingActivity,
  CardRecentTrainingActivity,
  CardRiskEmployees,
  CardRiskScore,
  CardRiskScoreOverTime,
  CardScoreChange,
  CardTestTracker,
  CardTrainingCompletion,
  CardUserActivityHeatMap,
  HeaderRiskInsights,
  HeaderTesting,
  HeaderTestingAndTraining,
  HeaderTraining,
  HeaderUserActivity,
  HeaderUsers,
  TwoColumn,
} from "./utils";

import { headers } from "../../../utils/constants/platform/dashboard";

import { DataTable, Select } from "..";

import { CardHeadsUp } from "../../features";

import {
  pagesPlatform,
  pagesPlatformEndUser,
  pagesPlatformManager,
} from "../../../constants";

import "./dashboard.css";

function Dashboard({
  // All pages
  pageTitle,
  riskScore,
  scoreChange,
  riskScoreOverTime, // Excluding direct reports
  areasToImprove, // Excluding direct reports
  onChangeSelectedDays,

  // Organization
  interactions,
  usersInteraction,
  highestRiskUsers,
  lowestRiskUsers,
  departments, // Also for department
  testDates,
  selectedDateForTestTracker,
  onChangeSelectedDateForTestTracker,
  testAssets,
  trainingCompletion, // Also for department
  trainingCompletionUsers, // Also for department
  anomalousActivityStatuses,
  mfaProtectionStatuses,
  deviceProtectionStatuses,
  userActivityHeatmap,
  averageHourlyUserLogin,
  anomalousLoginBehavior,
  inactiveUsers,
  onClickInteraction,
  email, // Also for department

  // Departments
  totalUsers,
  employeesByDepartment,

  // Users
  profileData, // Also for end user
  blastRadius, // Also for end user
  recentTestingActivity,
  learningModulesActivity,
  onLoadTestHTML, // Also for end user

  // End user
  showHeadsUp,

  // Manager
  users,
}) {
  const sections = [
    {
      name: "Risk Insights",
      ref: useRef(null),
    },
    {
      name: "User Activity",
      ref: useRef(null),
    },
    {
      name: "Testing and Training",
      ref: useRef(null),
    },
  ]; // Only used for organization

  const [color, setColor] = useState(null);
  const [riskLevel, setRiskLevel] = useState("");
  const [riskInsightsCopyForUsers, setRiskInsightsCopyForUsers] =
    useState(null);

  // Days filter
  const [selectedDays, setSelectedDays] = useState(90); // Default value is 90.

  // Tabs
  // const [selectedTab, setSelectedTab] = useState(sections[0]); // Default value is Risk Insights.

  const handleChangeSelectedDays = async (event) => {
    console.log(event.target.value);
    setSelectedDays(event.target.value);
    await onChangeSelectedDays(event.target.value);
  };

  // Only for organization
  // const handleClickTabOrganization = (tab) => {
  //   setSelectedTab(tab);

  //   // Scroll to the corresponding section with an offset
  //   const targetSection = sections.find((section) => section.name === tab.name);

  //   if (targetSection?.ref?.current) {
  //     const sectionTop = targetSection.ref.current.offsetTop;

  //     window.scrollTo({
  //       top: sectionTop - 140,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   const handleIntersection = (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         const visibleSection = sections.find(
  //           (section) => section.ref.current === entry.target,
  //         );
  //         if (visibleSection) {
  //           setSelectedTab(visibleSection);
  //         }
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, {
  //     root: null,
  //     threshold: 0,
  //   });

  //   sections.forEach((section) => {
  //     if (section.ref.current) {
  //       observer.observe(section.ref.current);
  //     }
  //   });

  //   return () => {
  //     sections.forEach((section) => {
  //       if (section.ref.current) {
  //         observer.unobserve(section.ref.current);
  //       }
  //     });
  //   };
  // }, []);

  useEffect(() => {
    if (riskScore) {
      if (riskScore > 75) {
        // Severe
        setColor("#EF3C1F");
        setRiskLevel("Severe");
      } else if (riskScore > 50) {
        // High
        setColor("#F59A31");
        setRiskLevel("High");
      } else if (riskScore > 25) {
        // Moderate
        setColor("#FEE442");
        setRiskLevel("Moderate");
      } else {
        // Low
        setColor("#00FF82");
        setRiskLevel("Low");
      }
    }
  }, [riskScore]);

  useEffect(() => {
    if (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS) {
      // User
      let riskInsightsCopyForUsers = "";

      // Check if they have a blast radius as well as role.
      if (
        blastRadius &&
        blastRadius.level &&
        blastRadius.function &&
        profileData &&
        profileData.role
      ) {
        let isDefault = false; // Used for any other level and function.

        if (blastRadius.level === "C-Suite") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This C-suite technology likely has admin access and directly oversees the engineering systems/strategy, making them a high-profile target for cyber attacks.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This C-suite executive leader likely directs corporate governance and strategy, making them a primary target for high-stakes cyber and insider threats.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This C-suite finance executive likely oversees financial operations, making them a key target for financial fraud.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This C-suite HR executive likely shapes workforce policies and manages sensitive data, making them highly vulnerable to targeted attacks.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This C-suite executive in legal/compliance likely has admin access to everything and makes decisions on compliance strategy, increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This executive overseeing operations is likely a high-profile target for sophisticated disruptions and espionage.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This C-suite executive in Sales accesses crucial market and customer data, likely making them highly vulnerable to targeted cyber espionage.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This C-suite executive in various capacities likely oversees diverse operations, exposing them to significant cyber and operational threats.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Contractor") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This engineering contractor likely accesses sensitive data and systems, placing them at a heightened risk for targeted cyber threats.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This executive management contractor likely engages with confidential strategic projects, increasing their exposure to security risks and corporate espionage.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This finance contractor likely accesses detailed financial data and reports, increasing their risk of involvement in financial irregularities.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This HR contractor is likely involved with confidential HR processes and data, creating moderately high risk for privacy and security breaches.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This legal/compliance contractor is likely involved with sensitive compliance issues, placing them at moderately high risk to the organization.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This operations contractor likely accesses critical operational processes, particularly vulnerable to targeted operational and security risks.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This sales contractor likely has access to significant sales insights, facing specific risks related to data security breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This contractor in various roles likely accesses confidential information across departments, presenting moderate risk of targeted data breaches.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Entry-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This entry-level engineer likely works with end-user systems and sensitive data, posing a moderate risk of data breaches.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This entry-level executive management trainee is likely exposed to high-level decision-making processes, introducing a moderate risk of data breach.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This entry-level finance staff likely has access to financial records and transactions, which places them at moderate exposure to cybersecurity risk.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This entry-level HR staff likely has access to personal user data, increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This entry-level legal/compliance officer likely handles sensitive data, posing moderate risk of cybersecurity breaches.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This entry-level operations staff likely interacts with various operational processes, exposing them to low risk of data integrity issues.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This entry-level sales associate likely handles customer data, posing minimal risk of security threats.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This entry-level staff in various roles likely accesses department-specific data, introducing minimal risks of information misuse.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Mid-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This mid-level engineer likely contributes to critical projects, their heightened exposure to sensitive data and systems increases their risk of cyber threats.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This mid-level executive in management likely handles strategic planning and sensitive data, increasing their vulnerability to security breaches.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This mid-level finance professional is likely involved in financial planning and transactions, increasing their risk of being targeted in financial fraud attacks.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This mid-level HR professional likely manages sensitive user information, significantly increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This mid-level legal/compliance professional likely oversees compliance processes, slightly increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This mid-level operations manager likely handles key parts of operations, slightly increasing their exposure to operational disruptions and security breaches.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This mid-level sales professional likely shapes strategy and access customer data, slightly increasing their threat of customer information breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Senior Management") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "This senior engineering leader likely has extensive access to user data and core systems, significantly elevating their risk of being targeted by sophisticated cyber attacks.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "This senior executive likely oversees strategic information and decision-making processes, significantly escalating their risk of targeted corporate espionage.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "This senior finance leader likely controls financial strategies and assets, significantly raising their risk of being targeted in financial fraud attacks.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "This senior HR leader likely has extensive access to user records and strategic HR decisions, increasing their exposure to cybersecurity risk.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "This senior legal/compliance leader likely has comprehensive access to sensitive legal documents and compliance data, increasing their exposure to cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "This senior operations leader likely oversees comprehensive operational areas, moderately increasing their risk of strategic data losses and cyber threats.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "This senior sales leader likely handles strategic information, increasing their risk profile of customer information breaches.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "This senior leader in various roles likely oversees multiple departmental functions, presenting moderate risk of data and security challenges.";
              break;

            default:
              isDefault = true;
          }
        } else {
          isDefault = true;
        }

        if (isDefault) {
          riskInsightsCopyForUsers =
            "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
        }
      } else {
        riskInsightsCopyForUsers =
          "This mid-level professional in various departments likely accesses broader sensitive information, presenting minimal risk of data breaches.";
      }

      setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
    } else if (pageTitle === pagesPlatformEndUser.INSIGHTS) {
      // End user
      let riskInsightsCopyForUsers = "";

      if (blastRadius && blastRadius.level && blastRadius.function) {
        let isDefault = false; // Used for any other level and function.

        if (blastRadius.level === "C-Suite") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access and directly oversee the engineering department, elevating your security exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely direct corporate governance and sensitive decisions, significantly increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely oversee financial operations, making you a primary target for economic threats.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely shape workforce policies and manage sensitive HR operations, elevating your security exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely control legal and/or compliance, placing you at very high cybersecurity risk.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely a high-profile target for sophisticated cyber threats, significantly increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to crucial market and customer data, significantly increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may oversee diverse operations, exposing you to a high level of security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Contractor") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access to sensitive data and systems, elevating your security exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely engage with confidential strategic information, increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely have access to detailed financial data and reports, elevating your risk level.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You are likely involved with confidential HR processes, increasing your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely handle sensitive legal tasks, presenting a substantial risk to the organization.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You likely have access to critical operational processes, posing a considerable security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to significant sales insights, facing a heightened security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to confidential information across departments, elevating your security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Entry-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You are likely to work with end-user systems and sensitive data, increasing your security risk exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You are likely exposed to high-level decision-making information, placing you at moderate risk.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely have access to financial records and transactions, posing a moderate risk.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely have access to personal user data, increasing your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely handle some sensitive data, posing a low risk of cybersecurity breaches";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely to interact with various operational data, increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You are likely to handle customer data, which increases your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to department-specific data, introducing a moderate risk.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Mid-Level") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You are likely to contribute to critical projects, increasing your risk of security breaches.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely handle strategic planning and sensitive executive information, increasing your risk substantially.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You are likely involved in financial planning and management, which heightens your security risk exposure.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely manage sensitive user information and decisions, raising your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You are likely involved in more sensitive legal matters, moderately increasing your security risk exposure.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You are likely to handle key parts of operations, increasing your security risk exposure.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You likely have access to customer data, slightly increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may have access to broader sensitive information, presenting an elevated risk.";
              break;

            default:
              isDefault = true;
          }
        } else if (blastRadius.level === "Senior Management") {
          switch (blastRadius.function) {
            case "Engineering/IT":
              riskInsightsCopyForUsers =
                "You likely have access to user data and core systems, significantly increasing your security risk exposure.";
              break;

            case "Executive Management":
              riskInsightsCopyForUsers =
                "You likely oversee strategic information and sensitive corporate data, greatly increasing your security risk exposure.";
              break;

            case "Finance":
              riskInsightsCopyForUsers =
                "You likely control financial strategies and access sensitive financial information, significantly increasing your security risk exposure.";
              break;

            case "HR":
              riskInsightsCopyForUsers =
                "You likely have access to user records and strategic data, which significantly heightens your security risk exposure.";
              break;

            case "Legal/Compliance":
              riskInsightsCopyForUsers =
                "You likely oversee high-stakes legal decisions, significantly elevating your security risk exposure.";
              break;

            case "Operations":
              riskInsightsCopyForUsers =
                "You likely oversee operations, moderately increasing your risk of security breaches.";
              break;

            case "Sales/Marketing":
              riskInsightsCopyForUsers =
                "You are likely to handle strategic information, increasing your security risk exposure.";
              break;

            case "Other":
              riskInsightsCopyForUsers =
                "You may oversee multiple departmental functions, significantly increasing your security risk exposure.";
              break;

            default:
              isDefault = true;
          }
        } else {
          isDefault = true;
        }

        if (isDefault) {
          riskInsightsCopyForUsers =
            "You may have access to department-specific data, introducing a moderate risk.";
        }
      } else {
        riskInsightsCopyForUsers =
          "You may have access to department-specific data, introducing a moderate risk.";
      }

      setRiskInsightsCopyForUsers(riskInsightsCopyForUsers);
    }
  }, [blastRadius, profileData, pageTitle]);

  return (
    <div>
      {
        // <div className="sticky z-40 -mt-2 pt-1 top-[69px] bg-gray-dark hidden md:block">
        //   <Tabs
        //     data={sections}
        //     selectedTab={selectedTab}
        //     setSelectedTab={handleClickTabOrganization}
        //   />
        // </div>
      }
      {/* Department Information (Department Profile) Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mb-2">
          <CardProfileDepartment
            totalUsers={totalUsers}
            description={
              riskLevel === "Low"
                ? "The department is at low risk due to users consistently demonstrating strong awareness and low vulnerability."
                : riskLevel === "Moderate"
                  ? "The department is at moderate risk due to inconsistent security practices and moderate exposure to threats among users."
                  : riskLevel === "High"
                    ? "The department is at high risk due to users often lacking security awareness and significant exposure to potential breaches."
                    : riskLevel === "Severe"
                      ? "The department is under severe risk of social engineering as many users display critical gaps in security knowledge and are highly vulnerable to attacks."
                      : ""
            }
          />
        </div>
      )}
      {/* Heads Up Card */}
      {pageTitle === pagesPlatformEndUser.INSIGHTS && showHeadsUp && (
        <div className="mb-2">
          <CardHeadsUp />
        </div>
      )}
      {/* User Profile Card */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.USERS ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="mb-2">
          <CardProfileUser
            profileData={profileData}
            description={riskLevel ? riskInsightsCopyForUsers : ""}
          />
        </div>
      )}
      {/* Current Date and Days Filter */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        (pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div
          className="flex items-center justify-between -mt-2"
          ref={sections[0].ref}
        >
          <HeaderRiskInsights
            secondColumn={
              <div>
                <div className="flex items-center justify-end">
                  <div className="relative ml-2">
                    <Select
                      value={selectedDays}
                      options={[
                        {
                          name: "Last 30 days",
                          value: 30,
                        },
                        {
                          name: "Last 60 days",
                          value: 60,
                        },
                        {
                          name: "Last 90 days",
                          value: 90,
                        },
                        {
                          name: "All Time",
                          value: "All Time",
                        },
                      ]}
                      onChange={handleChangeSelectedDays}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}
      {/* Share Report Section */}
      {/* {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS) && (
        <div className="mt-0.75">
          <button
            className="text-white bg-transparent"
            aria-label="Share report"
            title="Download the report."
            onClick={handleClickShareReport}
          >
            Share report
            <ArrowOutward />
          </button>
        </div>
      )} */}
      {/* Risk Insights and Trends (Risk Score, Score Change, Risk Score Over Time, Areas to Improve Cards) */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS ||
        pageTitle === pagesPlatform.INSIGHTS.subpages.USERS ||
        pageTitle === pagesPlatformManager.YOUR_REPORTS ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="flex flex-col w-full mt-2 md:flex-row">
          <div className="flex flex-col max-w-full md:max-w-[210px] mb-2 md:mb-0 md:mr-1 w-full">
            <div className="flex justify-between flex-grow w-full md:flex-col">
              <div className="w-full">
                <CardRiskScore
                  riskScore={riskScore}
                  riskLevel={riskLevel}
                  color={color}
                  description={
                    riskScore !== 0
                      ? pageTitle ===
                        pagesPlatform.INSIGHTS.subpages.ORGANIZATION
                        ? riskLevel === "Low"
                          ? "The organization has a low risk score, reflecting strong security overall. Keep up the training."
                          : riskLevel === "Moderate"
                            ? "The organization is doing well, but focused training across teams can help lower risk further."
                            : riskLevel === "High"
                              ? "The organization's risk is high. Targeted training is necessary to reduce vulnerabilities quickly."
                              : "The organization is at severe risk. Immediate action is needed to address these vulnerabilities."
                        : pageTitle ===
                            pagesPlatform.INSIGHTS.subpages.DEPARTMENTS
                          ? riskLevel === "Low"
                            ? "Great job! This department is showing strong security practices. Keep up the training to maintain this level."
                            : riskLevel === "Moderate"
                              ? "The department is on track. A bit more focused training can help lower their risk further."
                              : riskLevel === "High"
                                ? "This department's risk is high. Prioritizing targeted training will help reduce their vulnerabilities."
                                : "This department faces severe risks. Immediate training is needed to improve their security posture."
                          : pageTitle === pagesPlatform.INSIGHTS.subpages.USERS
                            ? riskLevel === "Low"
                              ? "This user has a low risk score. Encourage them to maintain their good habits by continuing regular training."
                              : riskLevel === "Moderate"
                                ? "This user's risk score is moderate. Additional training will help them strengthen their security posture."
                                : riskLevel === "High"
                                  ? "This user's risk score is high. Prompt them to complete targeted training to reduce risks effectively."
                                  : "Immediate action is needed and recommend focused training to mitigate their vulnerabilities."
                            : pageTitle === pagesPlatformManager.YOUR_REPORTS
                              ? riskLevel === "Low"
                                ? "The team has a low risk score, reflecting strong security overall. Keep up the training."
                                : riskLevel === "Moderate"
                                  ? "The team is doing well, but focused training can help lower risk further."
                                  : riskLevel === "High"
                                    ? "The team's risk is high. Targeted training is necessary to reduce vulnerabilities quickly."
                                    : "The team is at severe risk. Immediate action is needed to address these vulnerabilities."
                              : pageTitle === pagesPlatformEndUser.INSIGHTS
                                ? riskLevel === "Low"
                                  ? "Your score is low, reflecting strong security habits. Stay engaged with training to maintain this level."
                                  : riskLevel === "Moderate"
                                    ? "You're on the right track. A bit more focus on training can help reduce your risk further."
                                    : riskLevel === "High"
                                      ? "Your score is high. Completing targeted training will help you quickly lower your risk."
                                      : "Your score is severe, but with focused training, you can significantly improve."
                                : ""
                      : "Your risk score will update once you take some tests."
                  }
                />
              </div>
              <div className="w-full ml-0.5 md:ml-0 md:mt-0.5">
                <CardScoreChange
                  scoreChange={scoreChange}
                  selectedDays={selectedDays}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow w-full">
            <div className="flex flex-col flex-grow md:flex-row">
              <div className="w-full mb-0.5 md:mb-0">
                <CardRiskScoreOverTime
                  riskScoreOverTime={riskScoreOverTime}
                  color={color}
                />
              </div>
              <div className="ml-0 md:ml-0.5 md:max-w-[200px] w-full">
                <CardAreasToImprove areasToImprove={areasToImprove} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Highest Risk Users and Lowest Risk Users Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <TwoColumn
          firstCard={
            <CardRiskEmployees users={highestRiskUsers} enableDescendingOrder />
          }
          secondCard={<CardRiskEmployees users={lowestRiskUsers} />}
        />
      )}
      {/* User Activity Header */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        anomalousActivityStatuses &&
        mfaProtectionStatuses &&
        deviceProtectionStatuses && (
          <div className="mt-2" ref={sections[1].ref}>
            <HeaderUserActivity />
          </div>
        )}

      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        anomalousActivityStatuses && (
          <div className="mt-2">
            <CardAnomalousActivityTracker
              anomalousActivityStatuses={anomalousActivityStatuses}
            />
          </div>
        )}

      {/* MFA Protection Status and Device Protection Status Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        mfaProtectionStatuses &&
        deviceProtectionStatuses && (
          <TwoColumn
            firstCard={
              <CardMFAProtectionStatus
                mfaProtectionStatuses={mfaProtectionStatuses}
              />
            }
            secondCard={
              <CardDeviceProtectionStatus
                deviceProtectionStatuses={deviceProtectionStatuses}
              />
            }
          />
        )}
      {/* User Activity Heat Map and Average Hourly User Login Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        userActivityHeatmap &&
        averageHourlyUserLogin && (
          <TwoColumn
            firstCard={
              <CardUserActivityHeatMap
                userActivityHeatmap={userActivityHeatmap}
              />
            }
            secondCard={
              <CardAverageHourlyUserLogin
                averageHourlyUserLogin={averageHourlyUserLogin}
              />
            }
          />
        )}
      {/* Anomalous Login Behavior and Inactive Users Cards */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        anomalousLoginBehavior &&
        inactiveUsers && (
          <TwoColumn
            firstCard={
              <CardAnomalousLoginBehavior
                anomalousLoginBehavior={anomalousLoginBehavior}
              />
            }
            secondCard={<CardInactiveUsers inactiveUsers={inactiveUsers} />}
          />
        )}
      {/* Users Header */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <HeaderUsers />
      )}
      {/* Users by Department Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS && (
        <div className="mt-2">
          <CardEmployeesByDepartment users={employeesByDepartment} />
        </div>
      )}
      {/* Testing and Training Header */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        (email !== "david@dune.demo" &&
          pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS)) && (
        <div ref={sections[2].ref}>
          <HeaderTestingAndTraining />
        </div>
      )}
      {/* User Interactions Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION && (
        <div className="mt-2">
          <CardInteractions
            interactions={interactions}
            usersInteraction={usersInteraction}
            selectedDays={selectedDays}
            onClickInteraction={onClickInteraction}
            email={email}
          />
        </div>
      )}
      {/* Test Tracker Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION &&
        testDates && (
          <div className="mt-2">
            <CardTestTracker
              testDates={testDates}
              selectedDate={selectedDateForTestTracker}
              onChangeSelectedDate={onChangeSelectedDateForTestTracker}
              testAssets={testAssets}
            />
          </div>
        )}
      {/* Training Status Card (for organization and departments) */}
      {(pageTitle === pagesPlatform.INSIGHTS.subpages.ORGANIZATION ||
        (email !== "david@dune.demo" &&
          pageTitle === pagesPlatform.INSIGHTS.subpages.DEPARTMENTS)) && (
        <div className="mt-2">
          <CardTrainingCompletion
            trainingCompletion={trainingCompletion}
            users={trainingCompletionUsers}
            email={email}
          />
        </div>
      )}
      {/* Testing Header */}
      {((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && <HeaderTesting />}
      {/* Testing Activity Card */}
      {((pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && riskScore) ||
        pageTitle === pagesPlatformEndUser.INSIGHTS) && (
        <div className="mt-2">
          <CardRecentTestingActivity
            recentTestingActivity={recentTestingActivity}
            showHeadsUp={showHeadsUp}
            firstName={
              pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
              profileData &&
              profileData.firstName
                ? profileData.firstName
                : ""
            }
            onLoadTestHTML={onLoadTestHTML}
          />
        </div>
      )}
      {/* Training Header */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS && (
        <HeaderTraining />
      )}
      {/* Testing Activity Card */}
      {pageTitle === pagesPlatform.INSIGHTS.subpages.USERS &&
        Array.isArray(learningModulesActivity) &&
        learningModulesActivity.length > 0 && (
          <div className="mt-2">
            <CardRecentTrainingActivity
              learningModulesActivity={learningModulesActivity}
            />
          </div>
        )}
      {/* Users (Manager) */}
      {pageTitle === pagesPlatformManager.YOUR_REPORTS && (
        <>
          <div className="mt-2">
            <div className="text-2xl font-bold">Users</div>
          </div>
          {users && (
            <div className="mt-0.5">
              <DataTable data={users} headers={headers} defaultOrderBy="name" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
