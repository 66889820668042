export const headersUsersTable = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "role",
    label: "Role",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
];
