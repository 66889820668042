import React from "react";

const FailIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.4867 2H5.51333L2 5.51333V10.4867L5.51333 14H10.4867L14 10.4867V5.51333L10.4867 2ZM11.3333 10.4933L10.4933 11.3333L8 8.84L5.50667 11.3333L4.66667 10.4933L7.16 8L4.66667 5.50667L5.50667 4.66667L8 7.16L10.4933 4.66667L11.3333 5.50667L8.84 8L11.3333 10.4933Z"
        fill="#ef3c1f"
      />
    </svg>
  );
};

export default FailIcon;
