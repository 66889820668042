import React from "react";

const ProofpointBrandMarkIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        d="M16.1999 10.4993C16.1999 13.6975 14.2999 15.5948 11.3665 15.5948C8.19987 15.5948 6.36654 13.7788 6.36654 10.4993C6.36654 6.97577 8.09987 5.15981 11.2665 5.15981C14.4332 5.15981 16.2332 7.00288 16.1999 10.4993ZM0.533203 25.8943H6.3332V16.8416C7.79987 18.6304 10.0999 19.552 12.9665 19.552C18.1999 19.552 22.1665 15.9471 22.1665 10.2824C22.1665 4.78035 18.2999 1.17554 12.8999 1.17554C9.99987 1.17554 7.96654 2.09707 6.2332 4.10276V1.77183H0.533203V25.8943Z"
        fill="white"
      />
    </svg>
  );
};

export default ProofpointBrandMarkIcon;
