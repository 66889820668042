import React from "react";

const CheckmarkIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <mask
        id="mask0_1338_5468"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="23"
      >
        <rect y="0.0100098" width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1338_5468)">
        <path
          d="M8.7543 16.51L3.5293 11.285L4.83555 9.97873L8.7543 13.8975L17.1647 5.48706L18.471 6.79331L8.7543 16.51Z"
          fill="#47FFA5"
        />
      </g>
    </svg>
  );
};

export default CheckmarkIcon;
