import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material";

import { AuthProvider } from "@descope/react-sdk";

// Website
import HeadsUp from "./pages/other/heads-up/heads-up";

// Login
import Login from "./pages/login/login";

import { PlatformContainer, WebsiteContainer } from "./components";

import {
  // Website
  Home,

  // Product,
  CISOAdvisoryCouncil,
  Company,
  ContactUs,
  Demo,
  FulfillmentPolicy,
  PrivacyPolicy,
  ResourceBootcamp,
  Resources,
  ResourcesViewAll,
  ResourceWebinarRecapBlog,
  SecurityAndCompliance,
  Error,

  // Platform
  AdaptiveControls,
  Campaigns,
  Careers,
  Certificate,
  Compliance,
  ComplianceOnboarding,
  ConfigureIntegrations,
  ConfigureIntegrationsService,
  ConfigureTestingSchedule,
  ConfigureTrainingsFrequency,
  DuneGuardian,
  Employees,
  InsightsDepartments,
  InsightsEndUser,
  InsightsOrganization,
  InsightsUsers,
  InsightsYourReports,
  Reporting,
  RequestNewAsset,
  RequestNewTraining,
  SearchDepartment,
  SearchUser,
  SetupSSOAndSCIM,
  SSOSettings,
  TestingDashboard,
  TrainingDashboard,
  Trainings,
  TrainingsVideoPlayer,
  Welcome,
  WelcomeOld,
} from "./pages";

import {
  ResourceWhitepaper,
  ResourceWhitepapers,
  ResourceInfographics,
  InfographicDetails,
} from "./pages/website/";

import { theme } from "./styles";

import "./App.css";

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}
        baseUrl="https://auth.dune.security"
      >
        <BrowserRouter basename="/">
          <Routes>
            {/* Website */}
            <Route
              path="/"
              element={
                <WebsiteContainer
                  mainPage={<Home {...props} />}
                  title="Dune Security | User Adaptive Risk Management"
                  description="Dune Security’s AI-powered, User Adaptive Risk Management platform identifies and trains your highest-risk users against social engineering, delivering targeted, real-time interventions, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/product/"
              element={
                <WebsiteContainer
                  mainPage={<Home {...props} />}
                  title="Dune Security | User Adaptive Risk Management"
                  description="Dune Security leverages advanced AI-powered insights to identify, assess, and reduce human risk. Through personalized training, dynamic testing, and automated interventions, we pinpoint and train your highest-risk users against sophisticated social engineering threats while enhancing user cybersecurity awareness."
                />
              }
            />
            <Route
              path="/resources/"
              element={
                <WebsiteContainer
                  mainPage={<Resources {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access cutting-edge cyber defense resources: news, tips, blogs, whitepapers, infographics, and more to combat social engineering threats like phishing, smishing, and vishing, curated by Dune Security's experts."
                />
              }
            />
            <Route
              path="/resources/bootcamp/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: expert-led blogs, breaking news, trends, and tips on combating phishing, smishing, deepfakes, lateral movement, and other social engineering threats, curated by Dune Security's cybersecurity specialists."
                />
              }
            />
            <Route
              path="/resources/bootcamp/:resourceID"
              element={
                <WebsiteContainer
                  mainPage={<ResourceBootcamp {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: expert-led blogs, breaking news, trends, and tips on combating phishing, smishing, deepfakes, lateral movement, and other social engineering threats, curated by Dune Security's cybersecurity specialists."
                />
              }
            />
            <Route
              path="/resources/team/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: expert-led blogs, breaking news, trends, and tips on combating phishing, smishing, deepfakes, lateral movement, and other social engineering threats, curated by Dune Security's cybersecurity specialists."
                />
              }
            />
            <Route
              path="/resources/whitepaper/"
              element={
                <WebsiteContainer
                  mainPage={<ResourceWhitepapers />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Dune Security's whitepapers offer expert insights into the latest cybersecurity trends, strategies, and technologies, including user adaptive risk management, AI-driven defenses, and protection against threats like phishing, smishing, deepfakes, and lateral movement."
                />
              }
            />
            <Route
              path="/resources/whitepaper/:resourceID"
              element={
                <WebsiteContainer
                  mainPage={<ResourceWhitepaper />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Explore Dune Security's whitepapers for in-depth insights on cybersecurity trends, advanced defense strategies, and protection against threats like phishing, smishing, deepfakes, and lateral movement, designed to strengthen your organization’s security posture."
                />
              }
            />
            <Route
              path="/resources/infographics/"
              element={
                <WebsiteContainer
                  mainPage={<ResourceInfographics />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Explore Dune Security's infographics for visual insights into cybersecurity trends, attack methods, threat statistics, and defense strategies, including phishing, smishing, vishing, and other social engineering threats."
                />
              }
            />
            <Route
              path="/resources/infographics/:infographicID"
              element={
                <WebsiteContainer
                  mainPage={<InfographicDetails />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Explore Dune Security's infographics for visual insights into cybersecurity trends, attack methods, threat statistics, and defense strategies, including phishing, smishing, vishing, and other social engineering threats."
                />
              }
            />
            <Route
              path="/resources/webinar-recap-blog/"
              element={
                <WebsiteContainer
                  mainPage={<ResourcesViewAll {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/resources/webinar-recap-blog/:resourceID"
              element={
                <WebsiteContainer
                  mainPage={<ResourceWebinarRecapBlog {...props} />}
                  title="Cybersecurity Insights & Anti-Phishing Resources | Dune Security"
                  description="Access the latest in cyber defense: news, tips, and templates for combating phishing, smishing, and vishing, curated by Dune Security's cybersecurity experts."
                />
              }
            />
            <Route
              path="/company/"
              element={
                <WebsiteContainer
                  mainPage={<Company {...props} />}
                  title="Our Team: Leading Cybersecurity Innovators | Dune Security"
                  description="Discover Dune Security's mission, values, and leadership team of cybersecurity veterans driving innovative user adaptive risk management, AI-driven solutions, and advanced defense strategies to protect organizations against human error and social engineering threats."
                />
              }
            />
            <Route
              path="/careers/"
              element={
                <WebsiteContainer
                  mainPage={<Careers {...props} />}
                  title="Careers | Dune Security"
                  description="Explore exciting career opportunities at Dune Security, a leading cybersecurity company specializing in user adaptive risk management and AI-driven defense solutions, and join our mission to combat social engineering threats and shape the future of cybersecurity."
                />
              }
            />
            <Route
              path="/company/ciso-advisory-council/"
              element={
                <WebsiteContainer
                  mainPage={<CISOAdvisoryCouncil {...props} />}
                  title="CISO Advisory Council: Pioneering AI-Driven Cybersecurity Defense | Dune Security"
                  description="Dune Security’s CISO Advisory Council, composed of top enterprise CISOs, actively shapes our user adaptive risk management solutions, ensuring they address real-world challenges with AI-driven defenses. Built by CISOs for CISOs, we deliver cybersecurity strategies that truly protect against evolving social engineering threats."
                />
              }
            />
            <Route
              path="/company/contact-us/"
              element={
                <WebsiteContainer
                  mainPage={<ContactUs {...props} />}
                  title="Contact Dune Security for AI-Powered Cybersecurity Defense Solutions | Dune Security"
                  description="Contact Dune Security to discover how our AI-driven, user adaptive risk management solutions protect against social engineering threats like phishing, smishing, and deepfakes. Connect with our experts to elevate your organization's cybersecurity with tailored, cutting-edge defense strategies."
                />
              }
            />
            <Route
              path="/company/security-and-compliance/"
              element={
                <WebsiteContainer
                  mainPage={<SecurityAndCompliance {...props} />}
                  title="Ensuring Top-Tier Security & Compliance Standards | Dune Security"
                  description="Dune Security ensures top-tier security and compliance with SOC 2 Type II certification and AWS-hosted infrastructure, delivering AI-driven risk management solutions that meet the highest industry standards and protect against evolving threats."
                />
              }
            />
            <Route
              path="/demo/"
              element={
                <WebsiteContainer
                  mainPage={<Demo {...props} />}
                  title="AI Cybersecurity Solutions | Dune Security"
                  description="Book a demo to explore Dune Security's AI-driven, user adaptive risk management platform that defends against phishing, smishing, and social engineering threats, providing advanced, real-time cybersecurity solutions."
                />
              }
            />
            <Route
              path="/privacy-policy/"
              element={
                <WebsiteContainer
                  mainPage={<PrivacyPolicy {...props} />}
                  title="Privacy Policy | Dune Security"
                  description="Dune Security's privacy policy explains how we collect, store, use, and share your information when you access our services, going above and beyond to ensure data privacy, secure your organization's sensitive information, and maintain compliance with industry standards."
                />
              }
            />
            <Route
              path="/fulfillment-policy/"
              element={
                <WebsiteContainer
                  mainPage={<FulfillmentPolicy {...props} />}
                  title="Fulfillment Policy | Dune Security"
                  description="Dune Security's fulfillment policy details how we deliver our AI-driven cybersecurity solutions and user adaptive risk management services, ensuring timely, accurate implementation for your organization. We prioritize seamless onboarding, training, and ongoing support to meet and exceed industry standards for a secure and effective experience."
                />
              }
            />

            {/* Login */}
            <Route path="/login/" element={<Login {...props} />} />
            {/* Login */}
            <Route path="/login/" element={<Login {...props} />} />

            {/* Platform */}
            <Route
              path="/insights/organization/"
              element={
                <PlatformContainer
                  mainPage={<InsightsOrganization {...props} />}
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/departments/search/"
              element={
                <PlatformContainer
                  mainPage={<SearchDepartment {...props} />}
                  title="Departments"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/departments/"
              element={
                <PlatformContainer
                  mainPage={<InsightsDepartments {...props} />}
                  title="Departments"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/users/search/"
              element={
                <PlatformContainer
                  mainPage={<SearchUser {...props} />}
                  title="Users"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/users/"
              element={
                <PlatformContainer
                  mainPage={<InsightsUsers {...props} />}
                  title="User"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/insights/"
              element={
                <PlatformContainer
                  mainPage={<InsightsEndUser {...props} />}
                  title="Insights"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/"
              element={
                <PlatformContainer
                  mainPage={<Trainings {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/learning/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/compliance/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/dashboard/trainings/completed/"
              element={
                <PlatformContainer
                  mainPage={<TrainingsVideoPlayer {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/compliance/"
              element={
                <PlatformContainer
                  mainPage={<Compliance {...props} />}
                  title="Compliance Frameworks"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/employees/"
              element={
                <PlatformContainer
                  mainPage={<Employees {...props} />}
                  title="Users"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/reporting/"
              element={
                <PlatformContainer
                  mainPage={<Reporting {...props} />}
                  title="Reporting"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/testing/"
              element={
                <PlatformContainer
                  mainPage={<TestingDashboard {...props} />}
                  title="Testing"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/testing/request/"
              element={
                <PlatformContainer
                  mainPage={<RequestNewAsset {...props} />}
                  title="Request New Custom Test"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/testing/schedule/"
              element={
                <PlatformContainer
                  mainPage={<ConfigureTestingSchedule {...props} />}
                  title="Configure Testing Schedule"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/trainings/"
              element={
                <PlatformContainer
                  mainPage={<TrainingDashboard {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/trainings/request/"
              element={
                <PlatformContainer
                  mainPage={<RequestNewTraining {...props} />}
                  title="Request New Custom Training"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/trainings/frequency/"
              element={
                <PlatformContainer
                  mainPage={<ConfigureTrainingsFrequency {...props} />}
                  title="Trainings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/integrations/"
              element={
                <PlatformContainer
                  mainPage={<ConfigureIntegrations {...props} />}
                  title="Integrations"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/configure/integrations/:serviceID"
              element={
                <PlatformContainer
                  mainPage={<ConfigureIntegrationsService {...props} />}
                  title="Integrations"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/certificate/"
              element={
                <PlatformContainer
                  mainPage={<Certificate {...props} />}
                  title="Training Completion Certificate"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/campaigns/"
              element={
                <PlatformContainer
                  mainPage={<Campaigns {...props} />}
                  title="Campaigns"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/welcome/"
              element={
                <PlatformContainer
                  mainPage={<WelcomeOld {...props} />}
                  title="Welcome to Dune Security"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/sso-settings/"
              element={
                <PlatformContainer
                  mainPage={<SSOSettings {...props} />}
                  title="SSO Settings"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/compliance-onboarding/"
              element={
                <PlatformContainer
                  mainPage={<ComplianceOnboarding {...props} />}
                  title="Compliance"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/insights/your-reports/"
              element={
                <PlatformContainer
                  mainPage={<InsightsYourReports {...props} />}
                  title="Your Reports"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/settings/dune-nudge/"
              element={
                <PlatformContainer
                  mainPage={<DuneGuardian {...props} />}
                  title="Dune Guardian"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/settings/dune-nudge/:status"
              element={
                <PlatformContainer
                  mainPage={<DuneGuardian {...props} />}
                  title="Dune Guardian"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/adaptive-controls/"
              element={
                <PlatformContainer
                  mainPage={<AdaptiveControls {...props} />}
                  title="Adaptive Controls"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/onboarding/"
              element={
                <PlatformContainer
                  mainPage={<Welcome {...props} />}
                  title="Welcome to Dune Security!"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />
            <Route
              path="/onboarding/setup-sso-and-scim/"
              element={
                <PlatformContainer
                  mainPage={<SetupSSOAndSCIM {...props} />}
                  title="Setup SSO and SCIM"
                  description="Dune Security leverages advanced AI-powered testing to pinpoint and train your highest-risk users against social engineering, ensuring personalized, effective defense strategies."
                />
              }
            />

            {/* Other */}
            <Route
              path="/heads-up/1password/"
              element={
                <HeadsUp
                  imageURL="https://d3oo9a669kwmx7.cloudfront.net/email-screenshot-1password.png"
                  {...props}
                />
              }
            />
            <Route
              path="/heads-up/draft-kings/"
              element={
                <HeadsUp
                  imageURL="https://d3oo9a669kwmx7.cloudfront.net/email-screenshot-draft-kings.png"
                  {...props}
                />
              }
            />

            {/* Error */}
            <Route
              path="/error/"
              element={
                <WebsiteContainer
                  mainPage={<Error {...props} />}
                  title="Page not found | Dune Security"
                  description="Dune Security identifies your highest-risk users at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />
            <Route
              path="*"
              element={
                <WebsiteContainer
                  mainPage={<Error {...props} />}
                  title="Page not found | Dune Security"
                  description="Dune Security identifies your highest-risk users at their points of weakness through AI-based contextualized testing, and automatically remediates with personalized training."
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
