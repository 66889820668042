import React from "react";

const dayColors = {
  "Average hourly login": "#00ff82",
  Monday: "rgba(61, 66, 132, 0.80)",
  Tuesday: "rgba(124, 54, 42, 0.80)",
  Wednesday: "rgba(12, 129, 98, 0.80)",
  Thursday: "rgba(127, 76, 182, 0.80)",
  Friday: "rgba(162, 106, 64, 0.80)",
  Saturday: "rgba(26, 114, 130, 0.80)",
  Sunday: "#823d51",
};

const CustomLegend = () => {
  return (
    <>
      {Object.entries(dayColors).map(([key, value]) => (
        <div className="flex flex-col">
          <div className="w-[36px] h-[2px]" style={{ background: value }} />
          <span className="note mt-0.25">{key}</span>
        </div>
      ))}
    </>
  );
};

export default CustomLegend;
