import React from "react";

const SectionHeader = ({ title = "", secondColumn }) => {
  return (
    <div className="w-full mt-2">
      <div className="flex items-center justify-between">
        <div className="flex">
          <h2 className="h2">{title}</h2>
        </div>
        {secondColumn && secondColumn}
      </div>
    </div>
  );
};

export default SectionHeader;
