import React, { useEffect, useRef, useState } from "react";

const Tabs = ({ data = [], selectedTab = { name: "" }, setSelectedTab }) => {
  const containerRef = useRef(null);
  const tabRefs = useRef([]);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleClickTab = (tab) => {
    if (setSelectedTab) {
      setSelectedTab(tab);
    }
  };

  // Scroll the selected tab into view whenever it changes
  useEffect(() => {
    const selectedIndex = data.findIndex(
      (tab) => tab.name === selectedTab?.name,
    );
    if (selectedIndex !== -1 && tabRefs.current[selectedIndex]) {
      const selectedElement = tabRefs.current[selectedIndex];
      const { left, right } = selectedElement.getBoundingClientRect();
      const { left: containerLeft, right: containerRight } =
        containerRef.current.getBoundingClientRect();

      // Scroll horizontally without triggering vertical scroll
      if (left < containerLeft || right > containerRight) {
        containerRef.current.scrollLeft += left - containerLeft;
      }
    }
  }, [selectedTab, data]);

  // Check if tabs container is overflowing
  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth } = containerRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [data]);

  return (
    <div
      ref={containerRef}
      className="w-full overflow-x-hidden hover:overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-white scrollbar-track-gray-card scrollbar-gutter-stable"
    >
      <div
        className={`flex border-b-2 ${
          isOverflowing ? "w-max" : "w-full"
        } border-border-color`}
      >
        {data.map((tab, index) => (
          <button
            ref={(el) => (tabRefs.current[index] = el)}
            className={`body uppercase pb-0.5 ${
              selectedTab?.name === tab?.name
                ? "border-b-2 border-green -mb-[2px] backdrop-blur-sm"
                : "text-gray-placeholder"
            } ${index > 0 ? "ml-2" : ""}`}
            onClick={() => handleClickTab(tab)}
            key={`tab-${index}`}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
