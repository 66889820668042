import React, { useState } from "react";

const Button = ({
  children,
  padding,
  title = "",
  borderColor,
  backgroundColor = "",
  boxShadow = "",
  hoverBoxShadow = "",
  textColor = "",
  isSmall,
  isEndButton,
  isRounded,
  isText,
  type,
  disabled = false,
  onClick,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={`whitespace-nowrap text-${textColor}
        ${isText ? "description" : "description-emphasized"}
        ${padding ? padding : isSmall ? (isRounded ? "p-0.25" : "py-0.25 px-0.5") : isRounded ? "p-0.5" : "py-0.5 px-1"}
        ${backgroundColor && `bg-${backgroundColor}`}
        ${borderColor && `border border-${borderColor}`}
        ${isEndButton ? "rounded-br-small rounded-tr-small" : isRounded ? "rounded-full" : "rounded-small"}
        ${borderColor && "hover:bg-gray-hover"}
        focus:outline-none focus:ring-1 focus:ring-green
        ${!isRounded && "focus:rounded-small"}
        ${(backgroundColor || borderColor) && "focus:ring-offset-4 focus:ring-offset-black"}`}
      title={title}
      aria-label={title}
      onClick={onClick ? onClick : null}
      type={type}
      disabled={disabled}
      style={{
        boxShadow: isFocused ? "" : isHovered ? hoverBoxShadow : boxShadow,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </button>
  );
};

export default Button;
