import React from "react";
import { EyeIcon } from "../../../utils";

const ConfigureTrainingCard = ({
  title,
  duration,
  toggleOn,
  onClick,
  toggleVideoPlayer,
  file_name_thumbnail,
}) => {
  return (
    <div
      className="border border-gray border-opacity-35 rounded-medium cursor-pointer"
      onClick={toggleVideoPlayer}
    >
      <div className="cursor-pointer">
        <img
          aria-label="Cover"
          src={`https://d36cattz2ccgpt.cloudfront.net/general/${file_name_thumbnail}`}
          alt="Cover"
          className="w-full h-9 rounded-t-medium"
        />
      </div>
      <div className="flex justify-start items-center text-h4.5 px-0.5 pt-1 pb-0.75">
        <div className="mr-0.25">{title}</div>
        <div>
          <div
            tabIndex="0"
            role="button"
            className="w-[16px] h-[16px] cursor-pointer"
          >
            <EyeIcon />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-start pr-1 pl-0.5 pb-1">
        <div>
          <div className="tracking-wider uppercase text-gray text-h6">
            completion time
          </div>
          <div>{duration} mins</div>
        </div>
        <div>
          <div className="tracking-wider uppercase text-gray text-h6">
            status
          </div>
          <div className="flex items-center space-x-0.5">
            <div className="text-sm font-medium text-gray-700">
              {toggleOn ? "Active" : "Inactive"}
            </div>
            <div
              onClick={onClick}
              className={`w-2.75 h-1.5 flex items-center rounded-full p-0.25 cursor-pointer transition-colors duration-300 ${
                toggleOn ? "bg-green" : "bg-gray"
              }`}
            >
              <div
                className={`bg-white w-1 h-1 rounded-full shadow-md transform transition-transform duration-300 ${
                  toggleOn ? "translate-x-1.25" : "-translate-x-.125"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureTrainingCard;
