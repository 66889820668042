import { getResponseGet } from "../utils";

const getDisplayAsset = async (fileName) => {
  let displayAssetData = {
    result: {
      asset: {},
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/display-asset/?asset_file_name=${fileName}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && Object.keys(data).length > 0) {
      displayAssetData.result.asset = data;
    }
  } catch (error) {
    displayAssetData.error = error;
  }

  return displayAssetData.result.asset;
};

export default getDisplayAsset;
