import React from "react";

const NodesFilledIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 4.50001C15.7499 3.80263 15.9928 3.12702 16.4369 2.58933C16.881 2.05163 17.4986 1.68546 18.1834 1.55375C18.8682 1.42204 19.5776 1.53303 20.1894 1.86764C20.8013 2.20225 21.2775 2.73957 21.5361 3.38722C21.7947 4.03487 21.8196 4.75238 21.6065 5.41641C21.3934 6.08044 20.9557 6.64949 20.3685 7.02572C19.7813 7.40195 19.0814 7.56187 18.3891 7.47797C17.6968 7.39407 17.0553 7.07161 16.575 6.56601L8.15399 11.245C8.28265 11.7401 8.28265 12.2599 8.15399 12.755L16.575 17.434C17.0782 16.9049 17.7572 16.5775 18.4846 16.5132C19.2119 16.4489 19.9378 16.6521 20.5261 17.0847C21.1144 17.5173 21.5247 18.1496 21.6801 18.8631C21.8355 19.5766 21.7253 20.3223 21.3702 20.9603C21.0151 21.5984 20.4395 22.0851 19.7513 22.3291C19.063 22.5731 18.3094 22.5577 17.6317 22.2858C16.954 22.0139 16.3987 21.5042 16.07 20.8521C15.7412 20.2001 15.6616 19.4506 15.846 18.744L7.42499 14.066C7.01184 14.501 6.47776 14.8021 5.89174 14.9305C5.30571 15.0589 4.69465 15.0086 4.13748 14.7862C3.58032 14.5638 3.10263 14.1794 2.76613 13.6827C2.42962 13.1861 2.24976 12.5999 2.24976 12C2.24976 11.4001 2.42962 10.8139 2.76613 10.3173C3.10263 9.82061 3.58032 9.43624 4.13748 9.21382C4.69465 8.99139 5.30571 8.94112 5.89174 9.0695C6.47776 9.19788 7.01184 9.49902 7.42499 9.93401L15.846 5.25501C15.7821 5.00844 15.7498 4.75473 15.75 4.50001Z"
        fill="white"
      />
    </svg>
  );
};

export default NodesFilledIcon;
