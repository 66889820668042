const colors = {
  green: "#00ff82",
  greenFade: "rgba(0, 255, 130, 0.50)",
  greenText: "#014d10",
  orange: "#f59a31",
  orangeFade: "rgba(245, 154, 49, 0.50)",
  orangeText: "#693900",
  red: "#ef3c1f",
  redFade: "rgba(239, 60, 31, 0.50)",
  redText: "#520b00",
  yellow: "#fee442",
  yellowFade: "rgba(254, 228, 66, 0.50)",
  yellowText: "#493f01",
};

export default colors;
