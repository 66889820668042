import React from "react";

const ListIcon = ({ isActive }) => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 21.0098C4.45 21.0098 3.97917 20.8139 3.5875 20.4223C3.19583 20.0306 3 19.5598 3 19.0098V5.00977C3 4.45977 3.19583 3.98893 3.5875 3.59727C3.97917 3.2056 4.45 3.00977 5 3.00977H19C19.55 3.00977 20.0208 3.2056 20.4125 3.59727C20.8042 3.98893 21 4.45977 21 5.00977V19.0098C21 19.5598 20.8042 20.0306 20.4125 20.4223C20.0208 20.8139 19.55 21.0098 19 21.0098H5ZM5 8.33477H19V5.00977H5V8.33477ZM5 13.6848H19V10.3348H5V13.6848ZM5 19.0098H19V15.6848H5V19.0098ZM6 7.65977V5.65977H8V7.65977H6ZM6 13.0098V11.0098H8V13.0098H6ZM6 18.3598V16.3598H8V18.3598H6Z"
        fill={isActive ? "#00FF82" : "white"}
      />
    </svg>
  );
};

export default ListIcon;
