import React from "react";

import { Divider, TextField } from "../../../../../components";

const CustomIntegrationForm = ({ inputs, onChangeValue }) => {
  const handleChangeValue = (event, input) => {
    if (onChangeValue) {
      onChangeValue(event, input.id);
    }
  };

  return (
    <>
      {inputs.map((input, index) => (
        <div
          className={index > 0 ? "pt-2" : ""}
          key={`integration-input-${index}`}
        >
          <div className="flex justify-between max-w-[832px] py-2">
            <div className="max-w-[308px] w-full">
              <p className="body">{input.name}*</p>
            </div>
            <div className="w-full ml-1">
              <TextField
                value={input.value}
                onChangeValue={(event) => handleChangeValue(event, input)}
                placeholder={input.name}
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default CustomIntegrationForm;
