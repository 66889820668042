const formatRiskScore = (riskScore) => {
    if (!riskScore) {
        return 0;
    }

    const formattedRiskScore = +riskScore.toFixed();

    return formattedRiskScore;
};

export default formatRiskScore;