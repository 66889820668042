import React from "react";

const LeftArrowIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M9.69512 1.74449C9.62739 1.67659 9.54693 1.62271 9.45834 1.58595C9.36975 1.54919 9.27478 1.53027 9.17887 1.53027C9.08296 1.53027 8.98799 1.54919 8.89941 1.58595C8.81082 1.62271 8.73036 1.67659 8.66262 1.74449L3.81512 6.59199C3.76105 6.64596 3.71814 6.71006 3.68887 6.78063C3.6596 6.8512 3.64453 6.92685 3.64453 7.00324C3.64453 7.07964 3.6596 7.15529 3.68887 7.22586C3.71814 7.29643 3.76105 7.36053 3.81512 7.41449L8.66262 12.262C8.94846 12.5478 9.40929 12.5478 9.69512 12.262C9.98096 11.9762 9.98096 11.5153 9.69512 11.2295L5.47179 7.00033L9.70096 2.77116C9.98096 2.49116 9.98096 2.02449 9.69512 1.74449Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftArrowIcon;
