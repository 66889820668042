import { AnalyticsFilledIcon, AnalyticsOutlinedIcon } from "../utils/icons";

const pagesPlatformManager = {
  YOUR_REPORTS: {
    name: "Your Reports",
    path: "/insights/your-reports/",
    icon: <AnalyticsOutlinedIcon />,
    activeIcon: <AnalyticsFilledIcon />,
    tooltip: "Go to the insights page.",
  },
};

export default pagesPlatformManager;
