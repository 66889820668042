export const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "email",
    label: "Email Address",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "riskScore",
    label: "Risk Score",
    isSortable: true,
    isSearchable: false,
    align: "left",
  },
  {
    id: "riskLevel",
    label: "Risk Level",
    isSortable: true,
    isSearchable: false,
    align: "left",
  },
];
