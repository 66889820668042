import React from "react";

const BackIcon = () => {
  return (
    <svg
      role="presentation"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <path
          id="Stroke 128.1"
          d="M9.83203 2L3.83203 8L9.83203 14"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default BackIcon;
