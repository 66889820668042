import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import "./resources-group.css";
import { Translate } from "react-auto-translate/lib/commonjs";
import { formatDateFive } from "../../../utils/helper-functions/formatDate";

function ResourcesGroup({ resources, isLight = false }) {
  const formatWhitepaperDate = (dateString) => {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Grid container>
      {resources.map((resource, index) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={6}
          lg={4}
          marginTop={{
            xs: index !== 0 ? "80px" : 0,
            sm: index !== 0 && index !== 1 ? "80px" : 0,
            lg: index > 2 ? "80px" : 0,
          }}
          paddingLeft={{
            xs: 0,
            sm: index % 2 === 1 ? "20px" : 0,
            lg:
              index % 3 === 2
                ? `${(60 / 3) * 2}px`
                : index % 3 === 1
                  ? `${60 / 3}px`
                  : 0,
          }}
          paddingRight={{
            xs: 0,
            sm: index % 2 === 0 ? "20px" : 0,
            lg:
              index % 3 === 0
                ? `${(60 / 3) * 2}px`
                : index % 3 === 1
                  ? `${60 / 3}px`
                  : 0,
          }}
        >
          <Link
            className="resources-group-link"
            href={resource.href}
            title={`Go to the resource named "${(
              <Translate>{resource.title}</Translate>
            )}".`}
            target="_blank"
          >
            <Card className={"resources-group-card"}>
              <Box className="resources-group-box-image" overflow="hidden">
                <CardMedia
                  component="img"
                  className="resources-group-card-media"
                  alt={<Translate>{resource.title}</Translate>}
                  image={resource.thumbnailURL}
                />
              </Box>
              <CardContent className="resources-group-card-content">
                <Typography
                  component="p"
                  className="resources-group-text-title"
                >
                  {<Translate>{resource.title}</Translate>}
                </Typography>
                <Typography
                  component="p"
                  marginTop="20px"
                  className="resources-group-text-date"
                >
                  {
                    <Translate>
                      {formatWhitepaperDate(resource.date) ||
                        formatDateFive(resource.date)}
                    </Translate>
                  }
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default ResourcesGroup;
