import {
  SettingsOutlinedIcon,
  SettingsFilledIcon,
  PersonFilledIcon,
  PersonOutlinedIcon,
  CampaignFilledIcon,
  CampaignOutlinedIcon,
  GPPGoodFilledIcon,
  GPPGoodOutlinedIcon,
  HomeFilledIcon,
  HomeOutlinedIcon,
} from "../utils/icons/";

const pagesPlatformBeforeBRA = {
  WELCOME: {
    name: "Welcome",
    path: "/welcome/",
    icon: <HomeOutlinedIcon />,
    activeIcon: <HomeFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  SSO_SETTINGS: {
    name: "SSO settings",
    path: "/sso-settings/",
    icon: <SettingsOutlinedIcon />,
    activeIcon: <SettingsFilledIcon />,
    tooltip: "Go to the SSO settings page.",
  },
  COMPLIANCE: {
    name: "Compliance",
    path: "/compliance-onboarding/",
    icon: <GPPGoodOutlinedIcon />,
    activeIcon: <GPPGoodFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  USERS: {
    name: "Users",
    path: "/employees/",
    icon: <PersonOutlinedIcon />,
    activeIcon: <PersonFilledIcon />,
    tooltip: "Go to the users page.",
  },
  CAMPAIGNS: {
    name: "Campaigns",
    path: "/campaigns/",
    icon: <CampaignOutlinedIcon />,
    activeIcon: <CampaignFilledIcon />,
    tooltip: "Go to the campaign page.",
  },
};

export default pagesPlatformBeforeBRA;
