import React from "react";

import Badge from "../badge";

const BadgeOrange = ({ text, icon }) => {
  return (
    <Badge
      background="bg-orange"
      textColor="text-black"
      text={text}
      icon={icon}
    />
  );
};

export default BadgeOrange;
