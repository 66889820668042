import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { CollapseTransition, Select } from "../../../../common";

import { DropDownArrowIcon, DropUpArrowIcon } from "../../../../../utils/icons";

function NavigationList({
  header,
  pages,
  open,
  pathname,
  msspOrganizations,
  selectedMSSPOrganization,
  onClickExpand,
  onClickPageWithSubpages,
  onClickMSSPOrganization,
  padding = "",
}) {
  return (
    <div className={padding ? padding : ""}>
      <div className="flex items-center">
        {(header === "Company Metrics" || header === "Onboarding") &&
        msspOrganizations.length > 1 ? (
          <>
            <div className="mr-0.25">
              <Select
                value={selectedMSSPOrganization}
                options={msspOrganizations.map((msspOrganization) => ({
                  name: msspOrganization.name,
                  value: msspOrganization.name,
                }))}
                onChange={(event) =>
                  onClickMSSPOrganization(event.target.value)
                }
              />
            </div>
            <h2 className="text-white card-header">Metrics</h2>
          </>
        ) : (
          <h2 className="text-white card-header">{header}</h2>
        )}
      </div>
      {Object.keys(pages).map((pageKey, pageIndex) => {
        const page = pages[pageKey];

        let isPageSelected = false;

        if (
          pathname.includes("/dashboard/trainings/") &&
          page.path === "/dashboard/trainings/"
        ) {
          isPageSelected = true;
        } else if (page.subpages) {
          // If the current page has subpages
          Object.keys(page.subpages).forEach((subpageKey) => {
            const subpage = page.subpages[subpageKey];

            if (
              pathname === subpage.path ||
              (pathname === "/insights/users/" &&
                subpage.path === "/insights/users/search/") ||
              (pathname === "/insights/departments/" &&
                subpage.path === "/insights/departments/search/")
            ) {
              isPageSelected = true;
            }
          });
        } else {
          isPageSelected = pathname === page.path;
        }

        return (
          <Fragment key={`navigation-platform-list-item-${pageIndex}`}>
            <div>
              {page.subpages ? (
                <button
                  className={`mt-0.25 description flex rounded-small items-center w-full p-0.625 ${
                    isPageSelected
                      ? !page.subpages
                        ? "bg-background-container font-medium"
                        : "font-medium"
                      : ""
                  }`}
                  aria-label={page.name}
                  onClick={() => onClickPageWithSubpages(page.path)}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="mr-[12px] w-[24px] h-[24px]">
                        {isPageSelected ? page.activeIcon : page.icon}
                      </div>
                      <span>{page.name}</span>
                    </div>
                    {page.subpages && (
                      <>
                        {open[page.name] ? (
                          <div
                            className="w-[8px] h-[8px]"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click from triggering the button click
                              onClickExpand(page.name);
                            }}
                          >
                            <DropUpArrowIcon />
                          </div>
                        ) : (
                          <div
                            tabIndex="0"
                            role="button"
                            className="w-[8px] h-[8px]"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click from triggering the button click
                              onClickExpand(page.name);
                            }}
                          >
                            <DropDownArrowIcon />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </button>
              ) : (
                <Link
                  to={page.path}
                  className={`mt-0.25 description flex rounded-small items-center w-full p-0.625 ${
                    isPageSelected ? "bg-background-container font-medium" : ""
                  }`}
                  aria-label={page.name}
                >
                  <div className="flex items-center">
                    <div className="mr-[12px] w-[24px] h-[24px]">
                      {isPageSelected ? page.activeIcon : page.icon}
                    </div>
                    <span>{page.name}</span>
                  </div>
                </Link>
              )}
            </div>
            {page.subpages && (
              <CollapseTransition isOpen={open[page.name]}>
                {Object.keys(page.subpages).map((subpageKey, subpageIndex) => {
                  const subpage = page.subpages[subpageKey];

                  const isSubpageSelected =
                    subpage.path === pathname ||
                    (pathname === "/insights/users/" &&
                      subpage.path === "/insights/users/search/") ||
                    (pathname === "/insights/departments/" &&
                      subpage.path === "/insights/departments/search/");

                  return (
                    <Link
                      to={subpage.path}
                      className={`mt-0.25 description flex rounded-small items-center w-full py-0.625 pl-1.625 pr-0.625 ${
                        isSubpageSelected
                          ? "bg-background-container font-medium"
                          : ""
                      }`}
                      key={`page-${pageIndex}-subpage-${subpageIndex}`}
                    >
                      <div className="flex items-center">
                        <div className="mr-[12px] w-[24px] h-[24px]">
                          {isSubpageSelected
                            ? subpage.activeIcon
                            : subpage.icon}
                        </div>
                        <span>{subpage.name}</span>
                      </div>
                    </Link>
                  );
                })}
              </CollapseTransition>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export default NavigationList;
