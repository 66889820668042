import React from "react";

const GPPGoodFilledIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.516 2.17001C12.3767 2.03792 12.192 1.96429 12 1.96429C11.808 1.96429 11.6233 2.03792 11.484 2.17001C9.36128 4.18589 6.53413 5.29134 3.60701 5.25001C3.44754 5.24794 3.29157 5.29675 3.16173 5.38936C3.0319 5.48197 2.93496 5.61356 2.88501 5.76501C2.46298 7.05118 2.24862 8.39638 2.25001 9.75001C2.25001 15.692 6.31401 20.683 11.813 22.098C11.9357 22.1296 12.0643 22.1296 12.187 22.098C17.686 20.683 21.75 15.692 21.75 9.75001C21.75 8.36001 21.527 7.02001 21.115 5.76501C21.0652 5.61337 20.9684 5.48157 20.8385 5.38877C20.7087 5.29597 20.5526 5.24701 20.393 5.24901L20.25 5.25001C17.254 5.25001 14.533 4.08001 12.516 2.17001ZM15.61 10.186C15.67 10.1061 15.7134 10.0149 15.7377 9.91796C15.762 9.82099 15.7666 9.72015 15.7514 9.62137C15.7361 9.52258 15.7013 9.42784 15.6489 9.34271C15.5965 9.25758 15.5276 9.18379 15.4463 9.12566C15.3649 9.06754 15.2728 9.02625 15.1753 9.00424C15.0778 8.98223 14.9769 8.97993 14.8785 8.99747C14.7801 9.01502 14.6862 9.05206 14.6023 9.10642C14.5184 9.16078 14.4462 9.23136 14.39 9.31401L11.154 13.844L9.53001 12.22C9.38783 12.0875 9.19979 12.0154 9.00548 12.0188C8.81118 12.0223 8.6258 12.101 8.48839 12.2384C8.35097 12.3758 8.27226 12.5612 8.26883 12.7555C8.2654 12.9498 8.33753 13.1378 8.47001 13.28L10.72 15.53C10.797 15.6069 10.8898 15.6662 10.992 15.7037C11.0942 15.7411 11.2033 15.7559 11.3118 15.7469C11.4202 15.738 11.5255 15.7056 11.6201 15.6519C11.7148 15.5982 11.7967 15.5245 11.86 15.436L15.61 10.186Z"
        fill="white"
      />
    </svg>
  );
};

export default GPPGoodFilledIcon;
