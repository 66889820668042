import React from "react";

const EcosystemBgIcon = () => {
  return (
    <svg
      role="presentation"
      className="w-20 h-20 md:w-32 md:h-32"
      width="492"
      height="434"
      viewBox="0 0 492 434"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M367.551 217.09C367.551 276.7 354.498 330.637 333.424 369.651C312.338 408.688 283.307 432.647 251.389 432.647C219.471 432.647 190.44 408.688 169.354 369.651C148.28 330.637 135.227 276.7 135.227 217.09C135.227 157.479 148.28 103.542 169.354 64.5285C190.44 25.4912 219.471 1.53194 251.389 1.53194C283.307 1.53194 312.338 25.4912 333.424 64.5285C354.498 103.542 367.551 157.479 367.551 217.09Z"
        stroke="#0A3D23"
        strokeWidth="1.19754"
      />
      <path
        d="M318.183 312.761C254.693 357.467 189.718 387.245 136.009 399.267C109.153 405.278 85.1438 406.843 65.5584 403.636C45.9759 400.43 30.8643 392.464 21.7034 379.454C12.5425 366.444 10.1364 349.532 13.7185 330.015C17.301 310.495 26.8668 288.418 41.5785 265.16C71.0005 218.646 120.938 167.51 184.427 122.804C247.917 78.0989 312.892 48.3202 366.602 36.2983C393.458 30.2871 417.467 28.7223 437.052 31.929C456.635 35.1353 471.746 43.1012 480.907 56.1112C490.068 69.1213 492.474 86.0335 488.892 105.551C485.31 125.071 475.744 147.148 461.032 170.406C431.61 216.92 381.673 268.055 318.183 312.761Z"
        stroke="#0A3D23"
        strokeWidth="1.19754"
      />
      <path
        d="M307.348 113.064C370.619 158.079 420.306 209.457 449.5 256.114C464.099 279.444 473.557 301.567 477.044 321.105C480.53 340.639 478.042 357.539 468.818 370.505C459.593 383.47 444.443 391.362 424.845 394.473C405.244 397.584 381.243 395.902 354.417 389.76C300.767 377.476 235.938 347.38 172.667 302.365C109.396 257.351 59.7091 205.972 30.5145 159.315C15.9164 135.985 6.45837 113.862 2.97116 94.3247C-0.515554 74.7902 1.97305 57.8898 11.1973 44.9246C20.4216 31.9594 35.5718 24.0674 55.1698 20.9567C74.7705 17.8456 98.7716 19.5275 125.598 25.6697C179.248 37.9535 244.077 68.049 307.348 113.064Z"
        stroke="#0A3D23"
        strokeWidth="1.19754"
      />
    </svg>
  );
};

export default EcosystemBgIcon;
