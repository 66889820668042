import {
  BuildingFilledIcon,
  BuildingOutlinedIcon,
  GPPGoodFilledIcon,
  GPPGoodOutlinedIcon,
  NodesFilledIcon,
  NodesOutlinedIcon,
  PersonFilledIcon,
  PersonOutlinedIcon,
  ReportingFilledIcon,
  ReportingOutlinedIcon,
  SettingsFilledIcon,
  SettingsOutlinedIcon,
  SignalCellularFilledIcon,
  SignalCellularOutlinedIcon,
  TestingFilledIcon,
  TestingOutlinedIcon,
  TrainingFilledIcon,
  TrainingOutlinedIcon,
} from "../utils/icons/";

const pagesPlatform = {
  INSIGHTS: {
    name: "Insights",
    path: "/insights/organization/",
    icon: <SignalCellularOutlinedIcon />,
    activeIcon: <SignalCellularFilledIcon />,
    tooltip: "Go to the insights page.",
    subpages: {
      ORGANIZATION: {
        name: "Organization",
        path: "/insights/organization/",
        icon: <BuildingOutlinedIcon />,
        activeIcon: <BuildingFilledIcon />,
        tooltip: "Go to the organization insights page.",
      },
      DEPARTMENTS: {
        name: "Departments",
        path: "/insights/departments/search/",
        icon: <NodesOutlinedIcon />,
        activeIcon: <NodesFilledIcon />,
        tooltip: "Go to the department insights page.",
      },
      USERS: {
        name: "Users",
        path: "/insights/users/search/",
        icon: <PersonOutlinedIcon />,
        activeIcon: <PersonFilledIcon />,
        tooltip: "Go to the users insights page.",
      },
    },
  },
  COMPLIANCE: {
    name: "Compliance",
    path: "/compliance/",
    icon: <GPPGoodOutlinedIcon />,
    activeIcon: <GPPGoodFilledIcon />,
    tooltip: "Go to the compliance page.",
  },
  REPORTING: {
    name: "Reporting",
    path: "/reporting/",
    icon: <ReportingOutlinedIcon />,
    activeIcon: <ReportingFilledIcon />,
    tooltip: "Go to the reporting page.",
  },
  CONFIGURE: {
    name: "Configure",
    path: "/configure/testing/",
    icon: <SettingsOutlinedIcon />,
    activeIcon: <SettingsFilledIcon />,
    tooltip: "Go to the configure page.",
    subpages: {
      TESTING: {
        name: "Testing",
        path: "/configure/testing/",
        icon: <TestingOutlinedIcon />,
        activeIcon: <TestingFilledIcon />,
        tooltip: "Go to the testing configuration page.",
      },

      TRAINING: {
        name: "Trainings",
        path: "/configure/trainings/",
        icon: <TrainingOutlinedIcon />,
        activeIcon: <TrainingFilledIcon />,
        tooltip: "Go to the training configuration page.",
      },
    },
  },
};

export default pagesPlatform;
