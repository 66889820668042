import React, {
    useState
} from "react";

import {
    Box,
    Skeleton
} from "@mui/material";

import "./faded-in-image.css";

function FadedInImage({
    src,
    alt,
    width,
    height
}) {
    const [loaded, setLoaded] = useState(false);

    return (
        <Box>
            {
                !loaded &&
                <Skeleton variant="rectangular" width={width} height={height} />
            }
            <Box
                component="img"
                className="faded-in-image-image-ciso"
                alt={alt}
                src={src}
                loading="lazy"
                onLoad={() => setLoaded(true)}
                sx={{
                    filter: loaded ? "none" : "blur(8px)",
                    transition: "filter 0.5s ease-in-out",
                }}
            />
        </Box>
    );
}

export default FadedInImage;