import React from "react";
import { Modal } from "../";

const VideoPlayer = ({ isOpen, onClose, title, videoURL }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <div>
        {videoURL ? (
          <video className="w-full" controls>
            <source
              src={`https://d2v6yf73kbwpa3.cloudfront.net/general/${videoURL}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>No video URL available</p>
        )}
      </div>
    </Modal>
  );
};

export default VideoPlayer;
