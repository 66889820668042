import { getResponseGet } from "../utils";

const getSettingsSSO = async () => {
    let settingsSSO = {
        result: {
            acsURL: "",
            entityIDDune: "",
            ssoURL: "",
            entityIDIDP: "",
            certificate: "",
            domains: []
        },
        error: {}
    };

    try {
        const response = await getResponseGet("/settings/sso/");

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        const data = response.data;

        const {
            acsURL,
            entityIDDune,
            metadataURL,
            ssoURL,
            entityIDIDP,
            certificate,
            domains
        } = data;

        settingsSSO.result.acsURL = acsURL;
        settingsSSO.result.entityIDDune = entityIDDune;
        settingsSSO.result.metadataURL = metadataURL;
        settingsSSO.result.ssoURL = ssoURL;
        settingsSSO.result.entityIDIDP = entityIDIDP;
        settingsSSO.result.certificate = certificate;
        settingsSSO.result.domains = domains;
    } catch (error) {
        settingsSSO.error = error;
    }

    return settingsSSO;
};

export default getSettingsSSO;