import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.30488 12.2555C4.37261 12.3234 4.45307 12.3773 4.54166 12.414C4.63025 12.4508 4.72522 12.4697 4.82113 12.4697C4.91704 12.4697 5.01201 12.4508 5.10059 12.414C5.18918 12.3773 5.26964 12.3234 5.33738 12.2555L10.1849 7.40801C10.239 7.35404 10.2819 7.28994 10.3111 7.21937C10.3404 7.1488 10.3555 7.07315 10.3555 6.99676C10.3555 6.92036 10.3404 6.84471 10.3111 6.77414C10.2819 6.70357 10.239 6.63947 10.1849 6.58551L5.33738 1.738C5.05154 1.45217 4.59071 1.45217 4.30488 1.738C4.01904 2.02384 4.01904 2.48467 4.30488 2.7705L8.52821 6.99967L4.29904 11.2288C4.01904 11.5088 4.01904 11.9755 4.30488 12.2555Z"
        fill="white"
      />
    </svg>
  );
};

export default RightArrowIcon;
