import React, { useState } from "react";

import { PlayIcon } from "../../../../../utils/icons";

import "./training-video.css";

function TrainingVideo({ selectedVideo, pathname, onEndedVideo }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [supposedCurrentTime, setSupportedCurrentTime] = useState(0);

  const getThumbnailFileName = (isCompliance = false) => {
    if (!(selectedVideo && selectedVideo.fileName)) {
      return "";
    }

    let extension = ".jpg"; // Default is .jpg.

    if (selectedVideo.startDate) {
      const dateUpdate = new Date("2024-06-04T00:00:00");
      const dateModule = new Date(selectedVideo.startDate);

      if (dateModule < dateUpdate) {
        extension = ".png";
      }
    }

    return `https://d36cattz2ccgpt.cloudfront.net/${selectedVideo.fileName.slice(0, -4)}${extension}`;
  };

  const handleClickPlay = () => {
    setIsPlaying(true);
    const video = document.getElementById("training-video-video");
    video.play();
  };

  const handleEndedVideo = async () => {
    onEndedVideo();
  };

  const handlePlayVideo = () => {
    window.addEventListener("blur", () => {
      const video = document.getElementById("training-video-video");
      if (video) {
        video.pause();
      }
    });
  };

  const handleRateChangeVideo = (event) => {
    const maxPlaybackRate = 1.25;
    const newRate = event.target.playbackRate;
    if (newRate > maxPlaybackRate) {
      event.target.playbackRate = maxPlaybackRate;
    }
  };

  const handleSeekingVideo = () => {
    const video = document.getElementById("training-video-video");
    const delta = video.currentTime - supposedCurrentTime;
    if (delta > 0 && Math.abs(delta) > 0.01) {
      video.currentTime = supposedCurrentTime;
    }
  };

  const handleTimeUpdateVideo = () => {
    const video = document.getElementById("training-video-video");
    if (!video.seeking) {
      setSupportedCurrentTime(video.currentTime);
    }
  };

  const handleVolumeChangeVideo = () => {
    const video = document.getElementById("training-video-video");
    video.muted = false;
    video.volume = 1;
  };

  return (
    <div className="relative w-full h-full">
      {!isPlaying && (
        <div
          className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full cursor-pointer rounded-small"
          aria-label="Play the video."
          title="Play the video."
          style={{
            backgroundImage: `url(${getThumbnailFileName()})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handleClickPlay}
        >
          <div className="flex items-center justify-center w-full h-full training-video-box-thumbnail-background">
            <button
              className="p-0.625 rounded-full bg-green training-video-icon-button-play"
              aria-label="Play the video."
              title="Play the video."
            >
              <div className="w-[24px] h-[24px]">
                <PlayIcon />
              </div>
            </button>
          </div>
        </div>
      )}
      <video
        id="training-video-video"
        className={`w-full h-full rounded-small ${isPlaying ? "block" : "hidden"}`}
        controls
        onEnded={handleEndedVideo}
        onSeeking={handleSeekingVideo}
        onTimeUpdate={handleTimeUpdateVideo}
        onVolumeChange={handleVolumeChangeVideo}
        onPlay={handlePlayVideo}
        onRateChange={handleRateChangeVideo}
        controlsList="nodownload"
        disablePictureInPicture
        key={selectedVideo.id}
      >
        <source
          src={
            pathname === "/dashboard/trainings/compliance/"
              ? `https://d2v6yf73kbwpa3.cloudfront.net/${selectedVideo.fileName}`
              : `https://d2v6yf73kbwpa3.cloudfront.net/general/${selectedVideo.fileName}`
          }
          type="video/mp4"
        />
        Your browser does not support this video.
      </video>
    </div>
  );
}

export default TrainingVideo;
