import React, { useEffect, useState } from 'react';

import { Box, Link, Typography } from '@mui/material';

import './banner-notification.css';

function BannerNotification() {
  const [notifications] = useState([
    {
      description:
        'Dune Security raises $2MM from Craft, Alumni Ventures, Firestreak, Antler, Sequoia Scout',
      link: 'Read more',
    },
  ]);
  const [selectedNotification, setSelectedNotification] = useState({
    description: '',
  });

  useEffect(() => {
    if (notifications.length > 0) {
      setSelectedNotification(notifications[0]);
    }
  }, [notifications]);

  return (
    <Box
      className="banner-notification-box-main"
      display="flex"
      flexWrap={['wrap', 'nowrap']}
      justifyContent="center"
      position="sticky"
      padding="12px 30px">
      <Typography
        variant="description-emphasized"
        component="p"
        className="banner-notification-text-description"
        textAlign="center"
        marginBottom={{
          xs: '10px',
          md: '0',
        }}>
        {selectedNotification.description}
      </Typography>
      <Link
        href="https://www.linkedin.com/posts/daviddellapelle_cybersecurity-ai-socialengineering-activity-7183144103335895040-NzJZ/?utm_source=share&utm_medium=member_desktop"
        title="Read about Dune Security raising $2MM from Craft, Alumni Ventures, Firestreak, Antler, Sequoia Scout."
        target="_blank"
        sx={{
          color: '#000000',
        }}>
        <Typography
          variant="description-emphasized"
          component="p"
          className="banner-notification-text-link"
          textAlign="center">
          {selectedNotification.link}
        </Typography>
      </Link>
    </Box>
  );
}

export default BannerNotification;
