import React from "react";

import { Translate } from "react-auto-translate";

import { FadeInSection } from "../../../components";

import { AICPASOCLogo, ISO27001Logo } from "../../../utils/images/logos";

import "./security-and-compliance.css";
import { strategyPoints } from "../../../utils/constants/website/security-and-compliance";

function SecurityAndCompliance() {
  return (
    <div
      className="bg-[url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/security-and-compliance-background.png')] bg-cover bg-center"
      style={{
        backgroundSize: "min(100vw, 1600px)",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <FadeInSection>
        <div className="flex justify-center pt-2.5">
          <div className="w-full max-w-website">
            <h1 className="dune-text-header-uppercase">
              <Translate>Company</Translate>
            </h1>
            <h2 className="h2 mt-1.25">
              <Translate>Security and Compliance</Translate>
            </h2>
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className="flex justify-center mt-[2.5rem] md:mt-[5rem]">
          <div className="w-full max-w-website">
            <div className="security-and-compliance-box-white-border p-[2.5rem] md:p-[5rem]">
              <p className="body">
                <Translate>
                  At Dune Security, we're proud to be pioneers in developing the
                  world's first User Adaptive Risk Management solution. Our
                  commitment extends beyond innovation; we prioritize the
                  protection of our clients' data with the highest standards of
                  security and compliance in the industry. Our solutions not
                  only meet but exceed the world-class data security
                  expectations of our clients, earning recognition from highly
                  security-conscious organizations worldwide.
                </Translate>
              </p>
              <p className="body mt-1.25">
                <Translate>
                  We are ISO 27001 certified, demonstrating our adherence to
                  internationally recognized best practices for information
                  security management. This certification underscores our
                  commitment to maintaining the highest standards of information
                  security management. and validates that we have implemented a
                  comprehensive Information Security Management System (ISMS)
                  that effectively manages and protects sensitive information.
                </Translate>
              </p>
              <p className="body mt-1.25">
                <Translate>
                  Understanding the critical nature of our customers' data, we
                  deploy an unwavering approach to its management, security, and
                  storage. Our dedicated security team orchestrates a holistic
                  information security strategy that encompasses:
                </Translate>
              </p>
              <div className="mt-1.25">
                <ul className="security-and-compliance-list">
                  {strategyPoints.map((strategyPointItem, index) => (
                    <li
                      className="security-and-compliance-list-item"
                      key={`strategy-point-${index}`}
                    >
                      <span className="security-and-compliance-list-item-text">
                        <Translate>{strategyPointItem.point}</Translate>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <p className="body mt-1.25">
                <Translate>
                  As part of our ISO 27001 compliance, we have implemented a
                  risk-based approach to information security, ensuring that we
                  identify, assess, and mitigate potential threats to our
                  clients' data. This proactive stance allows us to adapt to
                  evolving security risks and maintain the confidentiality,
                  integrity, and availability of information entrusted to us.
                </Translate>
              </p>
              <p className="body mt-1.25">
                <Translate>
                  Our infrastructure, entirely hosted on AWS, undergoes regular
                  scans and annual penetration tests to swiftly identify and
                  rectify vulnerabilities. We process and store customer data
                  securely within specified AWS regions, ensuring strict access
                  controls, vigilant monitoring, and auditing for compliance. We
                  utilize TLS 1.3 encryption for the utmost security of data in
                  transit and at rest. Our AWS-hosted solutions benefit from
                  independent SOC 2 Type II audits, affirming our adherence to
                  top-tier security standards.
                </Translate>
              </p>
              <p className="body mt-1.25">
                <Translate>
                  Our ISO 27001 certification is subject to annual audits by an
                  accredited certification body, ensuring that we maintain our
                  commitment to information security excellence over time. This
                  ongoing process of evaluation and improvement allows us to
                  stay at the forefront of cybersecurity practices and provide
                  our clients with the highest level of data protection.
                </Translate>
              </p>
              <p className="body mt-1.25">
                <Translate>
                  We urge our customers to practice vigilant security
                  management, including the maintenance of strong passwords and
                  access restrictions. Should any account credential compromises
                  occur within your Identity Provider, we request immediate
                  notification through our support team at
                </Translate>{" "}
                <a
                  className="security-and-compliance-link-email"
                  href="mailto:support@dune.security"
                  title="Email support@dune.security."
                >
                  support@dune.security
                </a>
                <Translate>
                  , ensuring prompt and effective resolution.
                </Translate>
              </p>
            </div>
          </div>
        </div>
      </FadeInSection>
      <div className="bg-white">
        <FadeInSection>
          <div className="flex justify-center mt-[7.5rem] pt-[5rem] pb-[7.5rem] px-7">
            <div className="w-full max-w-website">
              <h3 className="security-and-compliance-text-heading">
                Dune Security's <Translate>Compliance</Translate>
              </h3>
              <div className="security-and-compliance-box-black-border mt-[2.5rem] md:mt-[5rem] p-[2.5rem] md:p-[5rem]">
                <div className="flex justify-center">
                  <div className="h-15 w-15">
                    <ISO27001Logo />
                  </div>
                </div>
                <h4 className="security-and-compliance-text-heading mt-1.25">
                  ISO 27001 Examination
                </h4>
                <p className="mt-1.25">
                  <Translate>
                    Dune Security has successfully achieved ISO 27001
                    certification, following a comprehensive audit conducted by
                    an accredited certification body. This certification
                    validates Dune Security's commitment to and implementation
                    of the highest standards of information security management,
                    encompassing our internal infrastructure, controls, and
                    protection of customer data.
                  </Translate>
                </p>
                <p className="mt-1.25">
                  <Translate>
                    Dune Security's ISO 27001 certification demonstrates its
                    adherence to best practices in information security,
                    including risk management, security controls, and continuous
                    improvement. This certification is subject to annual audits,
                    ensuring our ongoing compliance and dedication to
                    maintaining robust security measures.
                  </Translate>
                </p>
                <p className="mt-1.25">
                  <Translate>
                    Dune Security's customers can request more information about
                    our ISO 27001 certification through our customer support
                    team.
                  </Translate>
                </p>
              </div>
              <div className="security-and-compliance-box-black-border mt-[2.5rem] md:mt-[5rem] p-[2.5rem] md:p-[5rem]">
                <div className="flex justify-center">
                  <div className="h-15 w-15">
                    <AICPASOCLogo />
                  </div>
                </div>
                <h4 className="security-and-compliance-text-heading mt-1.25">
                  SOC 2 Type II Examination
                </h4>
                <p className="mt-1.25">
                  <Translate>
                    Dune Security has been independently audited against SOC 2
                    Security, Availability, and Confidentiality Trust Services
                    Criteria (TSC) by Dr. Vinod Singh with Accorp Partners. This
                    examination affirms Dune Security's commitment to and
                    maintenance of the highest levels of information security,
                    availability, and confidentiality of our internal
                    infrastructure, controls, and care to customer data.
                  </Translate>
                </p>
                <p className="mt-1.25">
                  <Translate>
                    SOC 2 is an industry standard examination that was developed
                    and maintained by the American Institute of Certified Public
                    Accountants (AICPA).
                  </Translate>
                </p>
                <p className="mt-1.25">
                  <Translate>
                    Dune Security's customers can submit a request for SOC 2
                    report through our customer support team.
                  </Translate>
                </p>
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default SecurityAndCompliance;
