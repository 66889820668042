import React from "react";

const DoubleArrowDropdownIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_884_2923"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.00976562" width="23.9999" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_884_2923)">
        <path
          d="M11.9999 21.0099L7.49988 16.5099L8.94987 15.0599L11.9999 18.1099L15.0498 15.0599L16.4998 16.5099L11.9999 21.0099ZM8.94987 9.05986L7.49988 7.60986L11.9999 3.10986L16.4998 7.60986L15.0498 9.05986L11.9999 6.00986L8.94987 9.05986Z"
          fill="#848483"
        />
      </g>
    </svg>
  );
};

export default DoubleArrowDropdownIcon;
