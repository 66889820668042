import React from "react";

import { DropDownArrowIcon } from "../../../utils/icons";

const Select = ({ value, options, onChange, defaultValue }) => {
  return (
    <div className="flex items-center cursor-pointer rounded-small border border-border-color bg-gray-select py-0.5 px-1">
      <select
        className="w-full normal-case appearance-none cursor-pointer description text-ellipsis bg-inherit pr-0.5 focus:ring-0 focus:outline-none focus:border-green"
        value={value}
        onChange={onChange}
      >
        {defaultValue && (
          <option value="" disabled>
            {defaultValue}
          </option>
        )}
        {options &&
          options.map((option, index) => (
            <option
              value={
                option.value !== undefined && option.value !== null
                  ? option.value
                  : option.name
              }
              style={{ textTransform: "none !important" }}
              key={`option-${index}`}
            >
              {option.name}
            </option>
          ))}
      </select>
      <DropDownArrowIcon />
    </div>
  );
};

export default Select;
