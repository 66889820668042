import { getResponseGet } from "../utils";

const getTrainingsOptional = async () => {
  let trainings = {
    result: {
      optionalModules: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/optional-trainings/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.training_modules && Array.isArray(data.training_modules)) {
      const optionalModules = [];

      for (let i = 0; i < data.training_modules.length; i++) {
        const module = data.training_modules[i];

        optionalModules.push({
          moduleID: module._id || "",
          title: module.title || "",
          fileNameVideo: module.file_name_video || "",
          fileNameThumbnail: module.file_name_thumbnail || "",
        });
      }

      optionalModules.sort((a, b) => a.title.localeCompare(b.title));
      trainings.result.optionalModules = optionalModules;
    }
  } catch (error) {
    trainings.error = error;
  }

  return trainings;
};

export default getTrainingsOptional;
