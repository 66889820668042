const pagesWebsiteRight = [
    {
        name: "Log in",
        path: "/login/",
        tooltip: "Go to the Log in page."
    },
    {
        name: "Book a demo",
        path: "/demo/",
        tooltip: "Go to the Book a demo page.",
        isCTA: true
    }
];

export default pagesWebsiteRight;