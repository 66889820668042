import axios from "axios";

import getToken from "./get-token";

const getResponsePatch = async (path, requestBody) => {
  const token = await getToken();

  const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}${path}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const response = await axios.patch(url, requestBody, { headers });

  return response;
};

export default getResponsePatch;
