import React from "react";

const ImportantNotes = () => {
  const importantNotes = [
    {
      description:
        "Keep your Client Secret and Application IDs secure and only share them with authorized personnel.",
    },
    {
      description:
        "Regularly review and update permissions to adhere to security best practices.",
    },
  ];

  return (
    <div className="mt-4">
      <p className="body-emphasized">Important Notes:</p>
      <ul className="pl-2 list-disc">
        {importantNotes.map((note, index) => (
          <li className="body-emphasized" key={`important-note-${index}`}>
            {note.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImportantNotes;
