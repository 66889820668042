import { getResponsePost } from "../utils";

export const postTrainingCompleted = async (requestBody) => {
  let postTrainingCompleteResponse = {
    result: {
      id: "",
    },
    error: {},
  };

  try {
    const response = await getResponsePost("/optional-trainings/", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.result) {
      postTrainingCompleteResponse.result.id = data.result;
    }
  } catch (error) {
    postTrainingCompleteResponse.error = error;
  }

  return postTrainingCompleteResponse;
};

export default postTrainingCompleted;
