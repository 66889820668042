import { getResponsePost } from "../utils";

export const postSettingsSSOSAMLByMetadata = async (requestBody) => {
    let settingsSSOSAML = {
        result: {},
        error: {}
    };

    try {
        const response = await getResponsePost("/settings/sso/saml-by-metadata/", requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        settingsSSOSAML.result = response.data;
    } catch (error) {
        settingsSSOSAML.error = error;
    }

    return settingsSSOSAML;
};

export default postSettingsSSOSAMLByMetadata;