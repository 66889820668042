import React from "react";

const WarningIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
    >
      <path
        d="M30 22.5V31.875M52.5 30C52.5 32.9547 51.918 35.8806 50.7873 38.6104C49.6566 41.3402 47.9992 43.8206 45.9099 45.9099C43.8206 47.9992 41.3402 49.6566 38.6104 50.7873C35.8806 51.918 32.9547 52.5 30 52.5C27.0453 52.5 24.1194 51.918 21.3896 50.7873C18.6598 49.6566 16.1794 47.9992 14.0901 45.9099C12.0008 43.8206 10.3434 41.3402 9.21271 38.6104C8.08198 35.8806 7.5 32.9547 7.5 30C7.5 24.0326 9.87053 18.3097 14.0901 14.0901C18.3097 9.87053 24.0326 7.5 30 7.5C35.9674 7.5 41.6903 9.87053 45.9099 14.0901C50.1295 18.3097 52.5 24.0326 52.5 30ZM30 39.375H30.02V39.395H30V39.375Z"
        stroke="#C2BFB8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
