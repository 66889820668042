import React from "react";
import { Link } from "react-router-dom";

import { ButtonContainedGreen, GreenItalicText, LogoItem } from "../..";

const PlatformMobile = () => {
  return (
    <>
      <div className="relative flex items-center justify-center h-screen login-box-main">
        <div
          className="flex flex-col justify-between w-full max-w-[450px] rounded-[32px] py-3.75 px-1.875 h-[550px]"
          style={{
            border: "1.401px solid rgba(194, 191, 184, 0.35)",
            background: "rgba(12, 12, 11, 0.85)",
            boxShadow:
              "0px 0px 61.664px 0px rgba(2, 2, 2, 0.25) inset, 16.818px 0px 33.635px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <div className="flex justify-center">
              <LogoItem height="40px" />
            </div>
            <div className="mt-0.625 text-center">
              <h1 className="body">
                User Adaptive Risk Management,
                <br />
                <GreenItalicText text="powered by AI" />
              </h1>
            </div>
          </div>
          <div className="flex justify-center ">
            <svg
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              width="100"
              height="100"
            >
              <rect
                x="15"
                y="20"
                width="70"
                height="45"
                rx="5"
                ry="5"
                fill="#00FF82"
              />

              <rect x="20" y="25" width="60" height="35" fill="black" />

              <rect x="40" y="70" width="20" height="5" fill="#00FF82" />

              <rect x="35" y="75" width="30" height="5" fill="#00FF82" />
            </svg>
          </div>
          <div
            role="heading"
            aria-level="4"
            className="w-full mx-auto text-center text-white text-h4"
          >
            Dune Platform is currently not available on mobile.
          </div>
          <div
            role="heading"
            aria-level="4"
            className="w-full mx-auto text-center text-white text-h4"
          >
            Please visit us on desktop for the full experience.
          </div>
          <div className="flex justify-center">
            <Link to="/">
              <ButtonContainedGreen>Visit Our Website</ButtonContainedGreen>
            </Link>
          </div>
          <div className="mt-1.25 text-center">
            <p className="detailed text-gray">
              Copyright © {new Date().getFullYear()} Dune Security
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformMobile;
