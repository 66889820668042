import React from "react";

const MicrosoftEntraIDBrandMarkIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 33"
      fill="none"
    >
      <path
        d="M7.49976 26.4261C8.26513 26.9035 9.53746 27.4341 10.8828 27.4341C12.1078 27.4341 13.246 27.079 14.1889 26.4734C14.1889 26.4734 14.1908 26.4734 14.1928 26.4715L17.7534 24.2464V32.2808C17.1892 32.2808 16.6211 32.1269 16.1279 31.8192L7.49976 26.4261Z"
        fill="#225086"
      />
      <path
        d="M15.4909 1.71929L0.696324 18.4076C-0.445817 19.6977 -0.147953 21.6466 1.33348 22.5717C1.33348 22.5717 6.80944 25.9942 7.49985 26.4262C8.26523 26.9036 9.53756 27.4342 10.8829 27.4342C12.1079 27.4342 13.2461 27.0792 14.189 26.4736C14.189 26.4736 14.1909 26.4736 14.1929 26.4716L17.7535 24.2465L9.14501 18.8652L17.7554 9.15208V0.719177C16.9191 0.719177 16.0827 1.05255 15.4909 1.71929Z"
        fill="#66DDFF"
      />
      <path
        d="M9.14502 18.8651L9.2476 18.9283L17.7535 24.2464H17.7555V9.15398L17.7535 9.15201L9.14502 18.8651Z"
        fill="#CBF8FF"
      />
      <path
        d="M34.1734 22.5717C35.6548 21.6465 35.9527 19.6976 34.8105 18.4075L25.1033 7.45754C24.3202 7.09261 23.4424 6.88351 22.5133 6.88351C20.6886 6.88351 19.0573 7.67059 17.9724 8.90741L17.7573 9.15004L26.3658 18.8632L17.7554 24.2444V32.2789C18.3215 32.2789 18.8857 32.125 19.3788 31.8173L34.1734 22.5697V22.5717Z"
        fill="#074793"
      />
      <path
        d="M17.7554 0.719177V9.15208L17.9704 8.90945C19.0553 7.67262 20.6867 6.88555 22.5114 6.88555C23.4424 6.88555 24.3183 7.09662 25.1014 7.45958L20.014 1.72126C19.4242 1.05452 18.5878 0.72115 17.7534 0.72115L17.7554 0.719177Z"
        fill="#0294E4"
      />
      <path
        d="M26.3638 18.8651L17.7554 9.15399V24.2445L26.3638 18.8651Z"
        fill="#96BCC2"
      />
    </svg>
  );
};

export default MicrosoftEntraIDBrandMarkIcon;
