import Papa from "papaparse";

export const exportToCSV = (data, fileName, transformRow) => {
  // Transform data if a transformation function is provided
  const transformedData = data.map(transformRow);

  // Generate CSV string using PapaParse
  const csv = Papa.unparse(transformedData);

  // Create a Blob for the CSV data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a temporary link element for downloading the file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Trigger the download and clean up
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
