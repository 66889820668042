import { getResponseGet } from "../utils";

const getInsightsOrganizationLowestRiskUsers = async () => {
  let insightsOrganizationLowestRiskUsers = {
    result: {
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/users/lowest-risk/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    for (let i = 0; i < Math.min(data.length, 5); i++) {
      const userData = data[i];

      const user = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        initials: `${
          userData.first_name && userData.first_name.charAt(0).toUpperCase()
        }${userData.last_name && userData.last_name.charAt(0).toUpperCase()}`,
        email: userData.email,
        riskScore: userData.risk_score ? +userData.risk_score.toFixed() : 0,
      };

      insightsOrganizationLowestRiskUsers.result.users.push(user);
    }
  } catch (error) {
    insightsOrganizationLowestRiskUsers.error = error;
  }

  return insightsOrganizationLowestRiskUsers;
};

export default getInsightsOrganizationLowestRiskUsers;
