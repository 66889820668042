export const strategyPoints = [
  {
    point: "Advanced network and application security protocols",
  },
  {
    point: "Rigorous identity and access management",
  },
  {
    point: "Proactive change and vulnerability management",
  },
  {
    point: "External third-party penetration testing",
  },
  {
    point: "Detailed logging",
  },
  {
    point: "Vendor risk management",
  },
  {
    point: "Thorough physical and endpoint security measures",
  },
  {
    point: "Comprehensive governance, compliance, and HR security",
  },
  {
    point: "Well-prepared disaster recovery plans",
  },
  {
    point:
      "Advanced Cloud and Infrastructure Security including Longbow's Security Risk Remediation Platform for AWS",
  },
  {
    point: "Additional security controls",
  },
];
