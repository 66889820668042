import React from "react";
import { Link } from "react-router-dom";

import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Typography
} from "@mui/material";
import { ReportGmailerrorredOutlined } from "@mui/icons-material";

import "./card-heads-up.css";

function CardHeadsUp() {
    const handleClickSeeDetails = () => {
        const element = document.getElementById("card-recent-testing-activity");

        if (element) {
            const offsetTop = element.getBoundingClientRect().top + window.scrollY - 100; // Adjust scroll position 400px before the element

            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <Card className="dune-card-dashboard">
            <CardContent className="card-heads-up-card-content">
                <Stack
                    direction={{
                        xs: "column",
                        md: "row"
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction={{
                            xs: "column",
                            md: "row"
                        }}
                        alignItems={{
                            xs: "flex-start",
                            md: "center"
                        }}
                        width="100%"
                    >
                        <ReportGmailerrorredOutlined className="card-heads-up-icon-report" />
                        <Box marginLeft="40px">
                            <Typography component="p" className="card-heads-up-text-message">
                                Heads up. You failed a phishing test.
                            </Typography>
                            <Typography component="p" className="card-heads-up-text-description" marginTop="4px">
                                Your risk score has <Typography component="span" className="card-heads-up-text-increased">increased</Typography> because you clicked on a link.
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction={{
                            xs: "column",
                            md: "row"
                        }}
                        justifyContent="flex-end"
                        alignItems={{
                            xs: "flex-start",
                            md: "center"
                        }}
                        paddingLeft={{
                            xs: 0,
                            md: "20px"
                        }}
                        width="100%"
                    >
                        <Box maxWidth="204px" width="100%">
                            <Button
                                variant="contained"
                                component={Link}
                                className="dune-button-contained-white card-heads-up-button-lower"
                                fullWidth
                                to="/dashboard/trainings/learning/"
                                aria-label="Go to the trainings page."
                                title="Go to the trainings page."
                            >
                                Lower your risk score
                            </Button>
                        </Box>
                        <Box maxWidth="128px" width="100%" marginLeft="20px">
                            <Button
                                variant="contained"
                                className="dune-button-contained-gray card-heads-up-button-see"
                                fullWidth
                                aria-label="See your details."
                                title="See your details."
                                onClick={handleClickSeeDetails}
                            >
                                See details
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default CardHeadsUp;