import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  CalendarTodayOutlined,
  PermIdentityOutlined,
  PlayArrowOutlined,
} from "@mui/icons-material";

import { createClient } from "contentful";

import { FadeInSection, ResourcesGroup } from "../../../components";

import "./resource-webinar-recap-blog.css";
import { Translate } from "react-auto-translate";

import { formatDateFive } from "../../../utils/helper-functions/formatDate";

function ResourceWebinarRecapBlog() {
  // Resource
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [author, setAuthor] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [tableOfContents, setTableOfContents] = useState([]);
  const [webinarHighlights, setWebinarHighlights] = useState([]);
  const [keyTakeaways, setKeyTakeaways] = useState([]);
  const [expertInsights, setExpertInsights] = useState([]);
  const [conclusion, setConclusion] = useState("");
  const [webinarURL, setWebinarURL] = useState("");
  const [featuredSpeakersNames, setFeaturedSpeakersNames] = useState(null);
  const [featuredSpeakersTitles, setFeaturedSpeakersTitles] = useState([]);
  const [featuredSpeakersPictures, setFeaturedSpeakersPictures] = useState([]);

  // Article not found
  const [isArticleNotFound, setIsArticleNotFound] = useState(false);

  // Resources (Read more)
  const [resources, setResources] = useState([]);

  const { resourceID } = useParams();

  const client = useMemo(
    () =>
      createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      }),
    [],
  );

  const renderBodyContent = (bodyContent, slug) => {
    return (
      <Box color={"white"}>
        {bodyContent.map((contentItem, contentItemIndex) => {
          if (contentItem.nodeType === "heading-3") {
            return (
              <Typography
                role="heading"
                aria-level="3"
                component="h3"
                className="resource-webinar-recap-blog-text-header-3"
                marginTop="28px"
                key={`${slug}-content-${contentItemIndex}`}
              >
                {<Translate> {contentItem.content[0].value}</Translate>}
              </Typography>
            );
          }

          if (contentItem.nodeType === "heading-4") {
            return (
              <Typography
                role="heading"
                aria-level="4"
                component="h4"
                className="resource-text-header-4"
                marginTop="28px"
                key={`${slug}-content-${contentItemIndex}`}
              >
                {<Translate> {contentItem.content[0].value}</Translate>}
              </Typography>
            );
          }

          if (contentItem.nodeType === "hyperlink") {
            return (
              <Link
                className="resource-webinar-recap-blog-link"
                to={contentItem.data.uri}
                aria-label="Go to the page."
                title="Go to the page."
                target="_blank"
                key={`${slug}-content-${contentItemIndex}`}
              >
                {<Translate> {contentItem.content[0].value}</Translate>}
              </Link>
            );
          }

          if (contentItem.nodeType === "unordered-list") {
            return (
              <ul className="resource-webinar-recap-blog-unordered-list">
                {contentItem.content.map((listItem, listItemIndex) => {
                  if (
                    listItem.content.length > 0 &&
                    listItem.content[0].content.length > 0 &&
                    listItem.content[0].content[0].value
                  ) {
                    return (
                      <li
                        className="resource-webinar-recap-blog-list-item"
                        key={`${slug}-content-${contentItemIndex}-list-item-${listItemIndex}`}
                      >
                        {listItem.content[0].content.map(
                          (listItemContent, listItemContentIndex) => {
                            if (
                              listItemContent.marks &&
                              listItemContent.marks.length > 0
                            ) {
                              if (
                                listItemContent.marks[0].type &&
                                listItemContent.marks[0].type === "bold"
                              ) {
                                return (
                                  <Typography
                                    component="span"
                                    className="resource-webinar-recap-blog-text-bold"
                                    key={`${slug}-content-${contentItemIndex}-list-item-${listItemIndex}-list-item-content-${listItemContentIndex}`}
                                  >
                                    {
                                      <Translate>
                                        {listItemContent.value}
                                      </Translate>
                                    }
                                  </Typography>
                                );
                              }
                            }

                            if (listItemContent.nodeType === "hyperlink") {
                              return (
                                <Link
                                  className="resource-webinar-recap-blog-link"
                                  to={listItemContent.data.uri}
                                  aria-label="Go to the page."
                                  title="Go to the page."
                                  target="_blank"
                                  key={`${slug}-content-${contentItemIndex}-list-item-${listItemIndex}-list-item-content-${listItemContentIndex}`}
                                >
                                  {
                                    <Translate>
                                      {listItemContent.content[0].value}
                                    </Translate>
                                  }
                                </Link>
                              );
                            }

                            return (
                              <Fragment
                                key={`${slug}-content-${contentItemIndex}-list-item-${listItemIndex}-list-item-content-${listItemContentIndex}`}
                              >
                                {<Translate>{listItemContent.value}</Translate>}
                              </Fragment>
                            );
                          },
                        )}
                      </li>
                    );
                  }

                  return <></>;
                })}
              </ul>
            );
          }

          return (
            <Typography
              component="p"
              marginTop="28px"
              key={`${slug}-content-${contentItemIndex}`}
            >
              {contentItem.content.map(
                (listItemContent, listItemContentIndex) => {
                  if (
                    listItemContent.marks &&
                    listItemContent.marks.length > 0
                  ) {
                    if (
                      listItemContent.marks[0].type &&
                      listItemContent.marks[0].type === "bold"
                    ) {
                    }

                    return (
                      <Typography
                        component="span"
                        className="resource-webinar-recap-blog-text-bold"
                        key={`${slug}-content-${contentItemIndex}-list-item-content-${listItemContentIndex}`}
                      >
                        {<Translate>{listItemContent.value}</Translate>}
                      </Typography>
                    );
                  }

                  return (
                    <Fragment
                      key={`${slug}-content-${contentItemIndex}-list-item-content-${listItemContentIndex}`}
                    >
                      {<Translate>{listItemContent.value}</Translate>}
                    </Fragment>
                  );
                },
              )}
            </Typography>
          );
        })}
      </Box>
    );
  };

  useEffect(() => {
    const fetchResource = async () => {
      await client
        .getEntries({
          content_type: "webinarRecapBlog",
          "fields.resourceID": resourceID,
          limit: 1,
        })
        .then((response) => {
          if (response.items.length > 0) {
            const resource = response.items[0].fields;

            setTitle(resource.title);
            setThumbnailURL(resource.thumbnail.fields.file.url);
            setDate(resource.date);
            setAuthor(resource.author);
            setIntroduction(resource.introduction);
            setTableOfContents(resource.tableOfContents);
            setWebinarHighlights(resource.webinarHighlights.content);
            setKeyTakeaways(resource.keyTakeaways.content);
            setExpertInsights(resource.expertInsights.content);
            setConclusion(resource.conclusion);
            setWebinarURL(resource.webinarURL);
            setFeaturedSpeakersNames(resource.featuredSpeakersNames);
            setFeaturedSpeakersTitles(resource.featuredSpeakersTitles);

            if (
              resource.featuredSpeakersPictures &&
              Array.isArray(resource.featuredSpeakersPictures) &&
              resource.featuredSpeakersPictures.length > 0
            ) {
              let featuredSpeakersPictures = [];

              for (
                let i = 0;
                i < resource.featuredSpeakersPictures.length;
                i++
              ) {
                if (resource.featuredSpeakersPictures[i].fields.file.url) {
                  featuredSpeakersPictures.push({
                    url: resource.featuredSpeakersPictures[i].fields.file.url,
                  });
                }
              }

              setFeaturedSpeakersPictures(featuredSpeakersPictures);
            }

            return response.items[0];
          } else {
            setIsArticleNotFound(true);
          }
        })
        .catch(console.error);
    };

    const fetchResources = async () => {
      let fetchedResourcesBootcamp = [];
      let fetchedResourcesTeam = [];
      let fetchedResourcesWebinarRecapBlog = [];

      await client
        .getEntries({
          content_type: "resourcesBootcamp",
          limit: 3,
          order: "-fields.date,fields.title",
        })
        .then((response) => {
          fetchedResourcesBootcamp = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              href: `/resources/bootcamp/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
            };
          });
        })
        .catch(console.error);

      await client
        .getEntries({
          content_type: "resourcesTeam",
          limit: 3,
          order: "-fields.date,fields.title",
        })
        .then((response) => {
          fetchedResourcesTeam = response.items.map((item) => {
            const { fields, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              pathname: `/resources/team/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
            };
          });
        })
        .catch(console.error);

      await client
        .getEntries({
          content_type: "webinarRecapBlog",
          limit: 3,
          order: "-fields.date,fields.title",
        })
        .then((response) => {
          fetchedResourcesWebinarRecapBlog = response.items.map((item) => {
            const { fields, metadata, sys } = item;

            return {
              id: sys.id,
              title: fields.title,
              href: `/resources/webinar-recap-blog/${fields.resourceID}`,
              thumbnailURL: fields.thumbnail.fields.file.url,
              date: fields.date,
              tags: metadata.tags.map((tag) => {
                return {
                  id: tag.id,
                };
              }),
            };
          });
        })
        .catch(console.error);

      const fetchedResourcesMostRecent = [
        ...fetchedResourcesBootcamp,
        ...fetchedResourcesTeam,
        ...fetchedResourcesWebinarRecapBlog,
      ];

      fetchedResourcesMostRecent.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) {
          return 1;
        } else if (dateA > dateB) {
          return -1;
        }

        // Dates are the same, so sort by title.
        return a.title.localeCompare(b.title);
      });

      setResources(fetchedResourcesMostRecent.slice(0, 3));
    };

    fetchResource();
    fetchResources();
  }, [client, resourceID]);

  console.log("Conclusion:", conclusion);

  if (isArticleNotFound) {
    return <Navigate to="/error/" />;
  }

  return (
    <>
      <Box
        className="resource-webinar-recap-blog-box-main-content"
        marginTop="-80px"
        paddingTop="80px"
      >
        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={{
              xs: "60px",
              md: "80px",
            }}
            padding="0 28px"
          >
            <Box maxWidth="1152px" width="100%">
              <Box>
                <Button
                  aria-label="Go to the resources hub page."
                  variant="text"
                  component={Link}
                  className="dune-button-text-white resource-button-resource-hub"
                  startIcon={
                    <ArrowBackIosNewOutlined className="resource-icon-resource-hub" />
                  }
                  to="/resources/"
                  title="Go to the resources hub page."
                >
                  <Translate>Resource hub</Translate>
                </Button>
              </Box>
              <Typography
                role="heading"
                aria-level="1"
                component="h1"
                className="dune-text-header-main-white resource-text-header-main"
                marginTop="40px"
              >
                {<Translate>{title}</Translate>}
              </Typography>
            </Box>
          </Box>
        </FadeInSection>
        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={{
              xs: "60px",
              md: "80px",
            }}
          >
            <Box position="relative" width="100%" height="600px">
              <Box
                component={Link}
                className="training-video-box-thumbnail-main"
                to={webinarURL}
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top={0}
                left={0}
                height="100%"
                width="100%"
                zIndex={1}
                aria-label="Go to the webinar."
                title="Go to the webinar."
                target="_blank"
                style={{
                  backgroundImage: `url(${thumbnailURL})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Box
                  className="training-video-box-thumbnail-background"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                >
                  <IconButton
                    className="training-video-icon-button-play"
                    aria-label={<Translate>{"Go to the webinar."}</Translate>}
                    title={<Translate>{"Go to the webinar."}</Translate>}
                  >
                    <PlayArrowOutlined className="training-video-icon-play" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </FadeInSection>
        <FadeInSection>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop="40px"
            paddingLeft="28px"
            paddingRight="28px"
          >
            <Box maxWidth="1152px" width="100%">
              <Box>
                <Stack direction="row" alignItems="center">
                  {date && (
                    <>
                      <CalendarTodayOutlined className="resource-icon-info" />
                      <Typography
                        component="p"
                        className="resource-text-small"
                        marginLeft="40px"
                      >
                        {<Translate>{formatDateFive(date)}</Translate>}
                      </Typography>
                    </>
                  )}
                  {author && (
                    <>
                      <Box display="flex" alignItems="center" marginLeft="60px">
                        <PermIdentityOutlined className="resource-icon-info" />
                      </Box>
                      <Typography
                        component="p"
                        className="resource-text-small"
                        marginLeft="40px"
                      >
                        {<Translate>{author}</Translate>}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Box>
              <Divider className="resource-divider" />
              <Box>
                {introduction && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Introduction</Translate>
                    </Typography>
                    <Box>
                      <Typography component="p" marginTop="28px">
                        {
                          <div
                            role="heading"
                            aria-level="4"
                            className="text-h4"
                          >
                            <Translate>{introduction}</Translate>
                          </div>
                        }
                      </Typography>
                    </Box>
                  </Box>
                )}
                {tableOfContents && tableOfContents.length > 0 && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      {<Translate>Table of contents</Translate>}
                    </Typography>
                    <Box>
                      <Typography component="p" marginTop="28px">
                        <ol className="resource-webinar-recap-blog-ordered-list">
                          {tableOfContents.map((content, index) => (
                            <li
                              key={index}
                              className="resource-webinar-recap-blog-list-item"
                            >
                              {<Translate>{content}</Translate>}
                            </li>
                          ))}
                        </ol>
                      </Typography>
                    </Box>
                  </Box>
                )}
                {webinarHighlights && webinarHighlights.length > 0 && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Webinar highlights</Translate>
                    </Typography>
                    {renderBodyContent(webinarHighlights, "webinar-highlights")}
                  </Box>
                )}
                {keyTakeaways && keyTakeaways.length > 0 && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Key takeaways</Translate>
                    </Typography>
                    {renderBodyContent(keyTakeaways, "key-takeaways")}
                  </Box>
                )}
                {expertInsights && expertInsights.length > 0 && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Expert insights</Translate>
                    </Typography>
                    {renderBodyContent(expertInsights, "expert-insights")}
                  </Box>
                )}

                {conclusion && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Conclusion</Translate>
                    </Typography>
                    <Box marginTop="28px">
                      <Typography component="p">
                        {<Translate>{conclusion}</Translate>}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {webinarURL && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Watch the webinar</Translate>
                    </Typography>
                    <Box>
                      <Typography component="p" marginTop="28px">
                        <Translate>Missed the live session?</Translate>
                      </Typography>
                    </Box>
                    <Box marginTop="12px">
                      <Button
                        component={Link}
                        variant="contained"
                        className="dune-button-contained-green"
                        to={webinarURL}
                        aria-label="Go to the webinar page."
                        title="Go to the webinar page."
                        target="_blank"
                      >
                        <Translate>Watch the recorded podcast.</Translate>
                      </Button>
                    </Box>
                  </Box>
                )}
                {featuredSpeakersNames && (
                  <Box marginTop="80px">
                    <Typography
                      role="heading"
                      aria-level="2"
                      component="h2"
                      className="resource-webinar-recap-blog-text-heading-2"
                    >
                      <Translate>Featured speakers</Translate>
                    </Typography>
                    <Box marginTop="28px">
                      <Grid container>
                        {featuredSpeakersNames.map((speakerName, index) => (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            marginTop={{
                              xs: index === 0 ? 0 : "40px",
                              md: index === 0 || index === 1 ? 0 : "40px",
                            }}
                            paddingLeft={{
                              xs: 0,
                              md: index % 2 === 1 ? "20px" : 0,
                            }}
                            paddingRight={{
                              xs: 0,
                              md: index % 2 === 0 ? "20px" : 0,
                            }}
                            key={`featured-speaker-${index}`}
                          >
                            <Stack direction="row" alignItems="center">
                              <Box maxWidth="200px" width="100%">
                                <Box
                                  component="img"
                                  className="company-image-leader"
                                  src={featuredSpeakersPictures[index].url}
                                  alt={<Translate>{speakerName}</Translate>}
                                />
                              </Box>
                              <Box marginLeft="40px">
                                <Typography
                                  component="p"
                                  className="company-text-name"
                                >
                                  {<Translate>{speakerName}</Translate>}
                                </Typography>
                                <Typography component="p" marginTop="20px">
                                  {
                                    <Translate>
                                      {featuredSpeakersTitles[index]}
                                    </Translate>
                                  }
                                </Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </FadeInSection>
        <FadeInSection>
          {resources.length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              marginTop={{
                xs: "80px",
                md: "120px",
              }}
              padding={{
                xs: "0 28px 80px",
                md: "0 28px 120px",
              }}
            >
              <Box maxWidth="1152px" width="100%">
                <Typography
                  role="heading"
                  aria-level="2"
                  component="h2"
                  className="resource-text-header-secondary"
                >
                  {<Translate>Read more</Translate>}
                </Typography>
                <Box marginTop="80px">
                  <ResourcesGroup resources={resources} isLight />
                </Box>
                <Box display="flex" justifyContent="flex-end" marginTop="80px">
                  <Button
                    aria-label="Go to the resources hub page."
                    variant="text"
                    component={Link}
                    className="dune-button-text-white resource-button-view-all"
                    endIcon={
                      <ArrowBackIosNewOutlined className="resource-icon-view-all" />
                    }
                    to="/resources/"
                    title={
                      <Translate>{"Go to the resources hub page."}</Translate>
                    }
                  >
                    <Translate>View all</Translate>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </FadeInSection>
      </Box>
    </>
  );
}

export default ResourceWebinarRecapBlog;
