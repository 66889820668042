const formatDescriptionData = (userInformation) => {
    let descriptionData = {};

    descriptionData.firstName = userInformation.first_name || "";

    // Calculate the points and the first date of the risk trend in InsightsEndUser now.

    return descriptionData;
};

export default formatDescriptionData;