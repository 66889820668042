import axios from "axios";

import { getToken } from "../utils";

export const postIntegrationDetails = async (file, id) => {
  let postIntegrationDetailsResponse = {
    result: {},
    error: {},
  };

  const secretTypesMap = {
    "microsoft-defender": "defender_integration_enabled",
    "microsoft-entra-id": "microsoft_dmi_enabled",
    crowdstrike: "crowdstrike_integration_enabled",
    google: "google_dmi_enabled",
    okta: "okta_integration_enabled",
  };

  console.log(file);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", secretTypesMap[id] || id); // Adding the type field

  try {
    const token = await getToken();

    const url = `${process.env.REACT_APP_BASE_URL || "https://api.dunesecurity.io"}/integration-token-upload/`;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    const response = await axios.post(url, formData, { headers });

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    postIntegrationDetailsResponse.result = response.data;
  } catch (error) {
    postIntegrationDetailsResponse.error = error;
  }

  return postIntegrationDetailsResponse;
};

export default postIntegrationDetails;
