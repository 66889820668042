import React, { useEffect, useState } from "react";

import { getAnomalousActivites } from "../../../api";

import {
  ButtonOutlinedGreen,
  Card,
  Divider,
  DotLabelGray,
  DotLabelGreen,
  DotLabelRed,
  DotLabelYellow,
  List,
  Modal,
  Select,
  TextField,
} from "../../../components";

import { DropDownArrowIcon, DropUpArrowIcon } from "../../../utils";
import { useNavigate } from "react-router-dom";

const AdaptiveControls = ({ isInitialLoading, email }) => {
  const navigate = useNavigate();

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [anomalousActivities, setAnomalousActivities] = useState([]);
  const [expandedBreakdowns, setExpandedBreakdowns] = useState({});

  // Create ticket fields
  const [ticketEmail, setTicketEmail] = useState("");
  const [ticketPriority, setTicketPriority] = useState("");

  const passwordAttempts = 33;
  const filesShared = 27;

  const actionItemsCommonThemes = [
    {
      theme: "Brute Force Password Attempts",
      actionItems: [
        {
          description: "Enforce stronger passwords",
          buttonText: "Update password policy",
        },
      ],
    },
    {
      theme: "File Sharing",
      actionItems: [
        {
          description: "Monitor DLP more closely",
          buttonText: "Check DLP",
        },
      ],
    },
  ];

  const eventIgnoredPoints = [
    {
      description: "It has been removed from your active queue.",
    },
    {
      description: "No security measures will be applied to this event.",
    },
    {
      description:
        "The event has been logged for auditing purposes and can be reviewed later if needed.",
    },
  ];

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatTimestampToEST = (timestamp) => {
    // Convert the UTC timestamp to a Date object.
    const utcDate = new Date(timestamp);

    // Convert UTC to EST timezone by subtracting 5 hours (EST is UTC-5).
    const estDate = new Date(utcDate.getTime() - 5 * 60 * 60 * 1000);

    // Extract components.
    const month = (estDate.getMonth() + 1).toString().padStart(2, "0");
    const day = estDate.getDate().toString().padStart(2, "0");
    const year = estDate.getFullYear();

    let hours = estDate.getHours();
    const minutes = estDate.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "p.m." : "a.m.";

    // Convert 24-hour time to 12-hour time.
    hours = hours % 12 || 12;

    // Format the date and time.
    return `${month}-${day}-${year} ${hours}:${minutes} ${period} EST`;
  };

  const handleChangeTicketEmail = (event) => {
    setTicketEmail(event.target.value);
  };

  const handleClickAssignTraining = () => {
    navigate("/configure/trainings/");
  };

  const handleClickCreateTicket = (activity) => {
    setSelectedActivity(activity);
    setTicketEmail(activity.user_email);
    setTicketPriority({ name: activity.risk.level });
  };

  const handleClickIgnore = (index) => {
    setAnomalousActivities((prevData) =>
      prevData.map((item, i) =>
        i === index
          ? {
              ...item,
              status: {
                ...item.status,
                current_state: "ignored",
              },
            }
          : item,
      ),
    );
  };

  const handleCloseModal = () => {
    setSelectedActivity(null);
    setTicketEmail("");
  };

  const handleToggleBreakdown = (index) => {
    setExpandedBreakdowns((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {
    const loadAnomalousActivities = async () => {
      const getAnomalousActivitesResponse = await getAnomalousActivites();

      if (Object.keys(getAnomalousActivitesResponse.error).length > 0) {
        console.error(getAnomalousActivitesResponse.error.message);
      } else {
        const { anomalousActivities } = getAnomalousActivitesResponse.result;

        setAnomalousActivities(anomalousActivities);
      }
    };

    if (!isInitialLoading && email) {
      loadAnomalousActivities();
    }
  }, [isInitialLoading, email]);

  return (
    <>
      <div>
        <Card>
          <div>
            <h3 className="h3">Common Themes</h3>
          </div>
          <div className="flex justify-between md:max-w-[548px] w-full my-2">
            <div className="md:max-w-[168px] w-full">
              <p className="h2">
                {passwordAttempts
                  ? passwordAttempts.toLocaleString("en-US")
                  : 0}
              </p>
              <p className="body mt-0.75">
                Brute Force Password Attempt
                {passwordAttempts === null ||
                  (passwordAttempts !== null && passwordAttempts !== 1 && "s")}
              </p>
            </div>
            <div className="md:max-w-[168px] w-full ml-1">
              <p className="h2">
                {filesShared ? filesShared.toLocaleString("en-US") : 0}
              </p>
              <p className="body mt-0.75">
                File
                {filesShared === null ||
                  (filesShared !== null && filesShared !== 1 && "s")}{" "}
                Shared Outside of Organization
              </p>
            </div>
          </div>
          <Divider />
          <div className="p-1">
            <h4 className="body-emphasized">Action Items</h4>
          </div>
          <Divider />
          <div className="py-2">
            {actionItemsCommonThemes.map((item, index) => (
              <>
                <div
                  className={index > 0 ? "my-2" : "mb-2"}
                  key={`action-item-common-theme-${index}`}
                >
                  <h5 className="body">{item.theme}</h5>
                  <div className="flex">
                    <ol className="w-full list-none">
                      {item.actionItems.map((dataItem, index) => (
                        <li
                          className="flex items-center mt-1 body first:mt-1"
                          key={`list-${index}`}
                        >
                          <span className="inline-block font-semibold border-b-2 border-green pb-0.125 max-w-1.25 w-full text-center mr-0.5">
                            {index + 1}
                          </span>
                          <div className="flex items-center w-full">
                            <div className="md:max-w-[336px] w-full">
                              <span className="inline-block">
                                {dataItem.description}
                              </span>
                            </div>
                            <div className="ml-2">
                              <ButtonOutlinedGreen isSmall>
                                {dataItem.buttonText}
                              </ButtonOutlinedGreen>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
                <Divider />
              </>
            ))}
          </div>
        </Card>
        <div className="mt-2">
          <h3 className="h3">Anomalous Activity</h3>
          {anomalousActivities.map((activity, index) => (
            <div className="mt-1">
              <Card key={`anomalous-activity-${index}`}>
                {activity.status.current_state !== "ignored" ? (
                  <div>
                    <div className="bg-[#201F1E] rounded-small p-1">
                      <div className="flex items-center justify-between">
                        {activity.user_email && (
                          <p className="body-emphasized">
                            Email:{" "}
                            <span className="body">{activity.user_email}</span>
                          </p>
                        )}
                        {activity.timestamp && (
                          <p className="body-emphasized">
                            Timestamp:{" "}
                            <span className="body">
                              {formatTimestampToEST(activity.timestamp)}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="mt-1">
                        <h5 className="card-subheader">Event Type</h5>
                        <p className="mt-0.5 body">{activity.event_type}</p>
                      </div>
                      <div className="mt-1">
                        <h5 className="card-subheader">Event Narrative</h5>
                        <p className="mt-0.5 body">
                          {activity.event_narrative}
                        </p>
                      </div>
                      <div className="mt-1">
                        <h5 className="card-subheader">Risk Level</h5>
                        <p className="mt-0.5 body">
                          {activity.risk.level === "low" ? (
                            <DotLabelGreen
                              label={capitalizeFirstLetter(activity.risk.level)}
                            />
                          ) : activity.risk.level === "medium" ? (
                            <DotLabelYellow
                              label={capitalizeFirstLetter(activity.risk.level)}
                            />
                          ) : activity.risk.level === "high" ? (
                            <DotLabelRed
                              label={capitalizeFirstLetter(activity.risk.level)}
                            />
                          ) : (
                            <DotLabelGray
                              label={capitalizeFirstLetter(activity.risk.level)}
                            />
                          )}
                        </p>
                      </div>
                      <div className="mt-1">
                        <h5 className="card-subheader">Reasoning</h5>
                        <ol className="w-full list-none mt-0.5">
                          {activity.risk.reasoning.map((dataItem, index) => (
                            <li
                              className={`flex items-center mt-1 first:mt-0 body`}
                              key={`list-${index}`}
                            >
                              <span className="inline-block font-semibold border-b-2 border-green pb-0.125 max-w-1.25 w-full text-center mr-0.5">
                                {index + 1}
                              </span>
                              <span className="inline-block">{dataItem}</span>
                            </li>
                          ))}
                        </ol>
                      </div>
                      <div className="mt-1">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => handleToggleBreakdown(index)}
                        >
                          <h5 className="mr-0.5 select-none card-subheader focus:outline-none">
                            Breakdown
                          </h5>
                          <div className="w-[8px] h-[8px]">
                            {expandedBreakdowns[index] ? (
                              <DropUpArrowIcon />
                            ) : (
                              <DropDownArrowIcon />
                            )}
                          </div>
                        </div>
                        {expandedBreakdowns[index] && (
                          <div className="-mt-0.5">
                            <List
                              data={activity.sequence_breakdown}
                              keyName="action"
                              textSize="body"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-end items-center gap-0.5 mt-2">
                      <ButtonOutlinedGreen
                        isSmall
                        onClick={handleClickAssignTraining}
                      >
                        Assign Training
                      </ButtonOutlinedGreen>
                      <ButtonOutlinedGreen
                        isSmall
                        onClick={() => handleClickCreateTicket(activity)}
                      >
                        Create Ticket
                      </ButtonOutlinedGreen>
                      <ButtonOutlinedGreen
                        isSmall
                        onClick={() => handleClickIgnore(index)}
                      >
                        Ignore
                      </ButtonOutlinedGreen>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <p className="text-center body-emphasized">Event Ignored</p>
                    <p className="body mt-0.5 text-center">
                      The selected event has been successfully ignored.
                    </p>
                    <List
                      data={eventIgnoredPoints}
                      keyName="description"
                      textSize="body"
                    />
                  </div>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title="Create Ticket"
        isOpen={selectedActivity !== null}
        onClose={handleCloseModal}
      >
        <div className="p-1">
          <div>
            <p className="description">Email *</p>
            <div className="mt-0.5">
              <TextField
                value={ticketEmail}
                placeholder="Email"
                onChangeValue={handleChangeTicketEmail}
              />
            </div>
          </div>
          <div className="mt-1">
            <p className="description">Priority *</p>
            <div className="mt-0.5">
              <Select
                value={ticketPriority}
                options={[
                  {
                    name: "High",
                  },
                  {
                    name: "Medium",
                  },
                  {
                    name: "Low",
                  },
                ]}
              />
            </div>
          </div>
          <div className="mt-1">
            <p className="description">Task *</p>
            <div className="mt-0.5">
              <TextField value="Review Login Behavior" placeholder="Task" />
            </div>
          </div>
          <div className="mt-1">
            <p className="description">Additional Notes</p>
            <div className="mt-0.5">
              <TextField
                value="The user exhibited unusual login behavior within a short timeframe, including successfully verifying with IWA, ending the session, and attempting AD/MFA methods before becoming inactive. This pattern suggests potential confusion or minor probing rather than a deliberate attack.
Recommended actions:
Temporarily enforce stricter login monitoring for this user.
Provide targeted training on proper authentication processes, focusing on IWA and MFA workflows.
Notify the user's manager to confirm there are no legitimate reasons for this behavior (e.g., testing multiple methods).
Continue monitoring for further unusual activity over the next 7 days."
                rows={4}
                placeholder="Additional Notes"
                multiline
              />
            </div>
          </div>
          <div className="flex justify-end mt-1">
            <ButtonOutlinedGreen isSmall>Create</ButtonOutlinedGreen>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdaptiveControls;
