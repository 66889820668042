import React from "react";

import {
    Backdrop,
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";

import "./loading-spinner.css";

function LoadingSpinner({ isLoading, message }) {
    return (
        <Backdrop open={isLoading}>
            <Grid container>
                <Grid item container xs={12} justifyContent="center">
                    <CircularProgress className="loading-spinner-circular-progress" size="5vh" />
                </Grid>
                {
                    message &&
                    <Grid item container xs={12} justifyContent="center" marginTop="20px">
                        <Typography component="p" className="loading-spinner-text-message">{message}</Typography>
                    </Grid>
                }
            </Grid>
        </Backdrop>
    )
}

export default LoadingSpinner;