import React, { useEffect, useState } from "react";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

function DonutChart({ data, onClickCell, title, minHeight = "200px" }) {
  const COLORS = ["#00ff82", "#00994e", "#006634", "#d92501"];

  const [chartData, setChartData] = useState([]);

  const handleClickCell = (status) => {
    if (onClickCell) {
      onClickCell(status);
    }
  };

  useEffect(() => {
    if (data) {
      const chartData = Object.entries(data).map(([name, percentage]) => ({
        name,
        percentage,
      }));

      setChartData(chartData);
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={minHeight}>
      <PieChart width={minHeight} height={minHeight}>
        {/* Chart Title */}
        <text
          x="50%"
          y="50%"
          className="body"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#ffffff"
        >
          {title}
        </text>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius="70%" // Adjust for a smaller inner radius.
          outerRadius="100%"
          labelLine={false}
          fill="#8884d8"
          stroke="none"
          startAngle={90} // Start at the top.
          endAngle={-270} // Complete the full circle.
          dataKey="percentage"
        >
          {chartData.map((entry, index) => (
            <Cell
              tabIndex="0"
              role="button"
              className="cursor-pointer focus:outline-none"
              fill={COLORS[index % COLORS.length]}
              onClick={() => handleClickCell(entry.name)}
              key={`cell-${index}`}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default DonutChart;
