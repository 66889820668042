import axios from "axios";

/**
 * Submits a knowledge check response for an end user and processes the response.
 * This asynchronous function sends a knowledge check response, encapsulated within the requestBody parameter,
 * to a designated URL. It aims to post the user's answers for evaluation and fetch feedback based on their responses.
 * Upon successful submission, detailed feedback and results are returned within the 'result' field. If the submission
 * encounters an error, the 'error' object will detail the failure encountered during the process.
 *
 * The function leverages the axios library to facilitate the HTTP POST request, ensuring that the user's answers are
 * transmitted securely to the server. It includes comprehensive error handling to manage scenarios such as non-200 HTTP responses
 * and exceptions during the request execution.
 *
 * @param {Object} requestBody - The payload for the POST request, containing the user's answers and the associated training ID.
 *                               Example: {
 *                                          "user_answer": ">:(",
 *                                          "assigned_training_id": "65eb3e6f5fc7466d0a8c2d30"
 *                                        }
 * @returns {Object} An object that either contains the user's feedback and results in the 'result' field upon a successful request,
 *                   or an error object in the 'error' field detailing the issue encountered if the request fails.
 *   - The 'result' object includes:
 *     - feedback: An object providing specific feedback on the user's answers, highlighting areas of strength and aspects needing improvement.
 *     - correctness: A boolean indicating the overall correctness of the user's answers.
 *     - additionalResources: An array of resources suggested for the user to further understand the subject matter.
 *   - The 'error' field contains an error object detailing the nature of the error encountered during the request.
 */
const postKnowledgeCheck = async (requestBody) => {
    let knowledgeCheck = {
        result: {},
        error: {}
    };

    try {
        const url = "https://v1zsm7k91e.execute-api.us-east-1.amazonaws.com/latest/llm-knowledge-check-lambda";

        const response = await axios.post(url, requestBody);

        if (response.status !== 200) {
            throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        knowledgeCheck.result = response.data;
    } catch (error) {
        knowledgeCheck.error = error;
    }

    return knowledgeCheck;
};

export default postKnowledgeCheck;