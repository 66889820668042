export const cisoAdvisoryCouncilQuotes = [
  {
    cisos: [
      {
        name: "Jeannine Gaudreau",
        title: "Technical CISO",
        companyName: "HealthEdge",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/jeannine-gaudreau.png",
        quote:
          "Finally! A learning platform that's as dynamic as the threat landscape.",
      },
      {
        name: "Bradley Schaufenbuel",
        title: "VP, CISO",
        companyName: "Paychex",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/bradley-schaufenbuel.png",
        quote:
          "I chose to join the Dune Security CISO Advisory Council because of Dune's holistic and context-sensitive approach to improving the security awareness of users.",
      },
    ],
  },
  {
    cisos: [
      {
        name: "Benjamin Corll",
        title: "CISO Americas",
        companyName: "Zscaler",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/benjamin-corll.png",
        quote:
          "When it comes to Dune and their product, their vision, their mission, just yes. I am fully on board. They are taking a fresh approach to security awareness, using data, insights, and AI capabilities to provide advanced contextualized testing and training, which will actually solve this problem for enterprises.",
      },
      {
        name: "Diego Souza",
        title: "EVP, CISO",
        companyName: "Prog Holdings",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/diego-souza.png",
        quote:
          "Dune Security isn't just a provider; they're pioneers in safeguarding tomorrow's digital world. With their best-in-class solutions and forward-looking cyber awareness services, they're shaping the future of cybersecurity.",
      },
    ],
  },
  {
    cisos: [
      {
        name: "Alicia Lynch",
        title: "CISO",
        companyName: "TD SYNNEX",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/alicia-lynch.png",
        quote:
          "Companies often wait until after a breach to invest in preventive products. Dune's powerful AI-driven platform modifies employee behavior by training them to identify and prevent threat actor actions, reducing catastrophic risks.",
      },
      {
        name: "Roy Foster",
        title: "CISO",
        companyName: "University of Chicago",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/leroy-foster.png",
        quote:
          "I joined the Dune Security CISO Advisory Council because Technology alone isn't enough; cybersecurity is a human endeavor. Dune Security empowers your team with the knowledge and tools to defend against phishing threats.",
      },
    ],
  },
  {
    cisos: [
      {
        name: "Jim Motes",
        title: "CISO",
        companyName: "Ryan",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/jim-motes.png",
        quote:
          "Dune offers a solution perfectly aligned with industry needs, focusing on the biggest threat: employee behavior. Their approach provides CISOs with precise tools to target and train at-risk users.",
      },
      {
        name: "Dr. Tyrone Grandison",
        title: "CTO, Apps, Infra & Security",
        companyName: "Microsoft",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/dr-tyrone-grandison.png",
        quote:
          "I joined the Dune Security CISO Advisory Council because of the vision of tailored, AI-driven cybersecurity readiness.",
      },
    ],
  },
  {
    cisos: [
      {
        name: "Marcos Marrero",
        title: "CTO",
        companyName: "H.I.G. Capital",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/marcos-marrero.png",
        quote:
          "I joined the CISO Advisory Council because I firmly believe in Dune's mission to revolutionize cybersecurity by accurately measuring and drastically reducing Human Cyber Risk. Dune is setting new standards in the industry.",
      },
      {
        name: "Murtaza Nisar",
        title: "VP, CISO",
        companyName: "Clario",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/murtaza-nisar.png",
        quote:
          "I chose to join the Dune CISO Advisory Council because in the fight against cyber threats, there's an opportunity to convert people from one of the key risks to biggest assets",
      },
    ],
  },
  {
    cisos: [
      {
        name: "Konrad Fellmann",
        title: "CISO",
        companyName: "Cubic Corporation",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/konrad-fellmann.png",
        quote:
          "Most breaches occur due to human error. I joined the CISO Advisory Council because Dune's innovative approach is transforming the human side of security, significantly reducing risks and enhancing protection.",
      },
      {
        name: "Thanh Thai",
        title: "CISO",
        companyName: "Constellis",
        profilePicture:
          "https://d3oo9a669kwmx7.cloudfront.net/headshots/thanh-thai.png",
        quote:
          "I joined the Dune Security Advisory Council after seeing their solution. Dune elevates phishing defense with personalized campaigns that effectively fill knowledge gaps. It's like having a white hat hacker on your team!",
      },
    ],
  },
];
