import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardWithLoadingState,
  Divider,
} from "../../../components";

import {
  CheckmarkIcon,
  CrowdstrikeBrandMarkIcon,
  GoogleBrandMarkIcon,
  MicrosoftDefenderBrandMarkIcon,
  MicrosoftEntraIDBrandMarkIcon,
  OktaBrandMarkIcon,
} from "../../../utils";

const ConfigureIntegrations = ({ isInitialLoading, email }) => {
  const navigate = useNavigate();

  const handleClickService = (service) => {
    navigate(`/configure/integrations/${service.id}`);
  };

  const integrations = [
    {
      type: "IAM",
      services: [
        {
          id: "microsoft-entra-id",
          name: "Microsoft Entra ID",
          icon: <MicrosoftEntraIDBrandMarkIcon />,
          isLinked: false,
        },
        {
          id: "okta",
          name: "Okta",
          icon: <OktaBrandMarkIcon />,
          isLinked: false,
        },
        {
          id: "google",
          name: "Google",
          icon: <GoogleBrandMarkIcon />,
          isLinked: false,
        },
      ],
    },
    {
      type: "EDR",
      services: [
        {
          id: "crowdstrike",
          name: "Crowdstrike",
          icon: <CrowdstrikeBrandMarkIcon />,
          isLinked: false,
        },
        {
          id: "microsoft-defender",
          name: "Microsoft Defender",
          icon: <MicrosoftDefenderBrandMarkIcon />,
          isLinked: false,
        },
      ],
    },
    // {
    //   type: "SEG",
    //   services: [
    //     {
    //       id: "proofpoint",
    //       name: "Proofpoint",
    //       icon: <ProofpointBrandMarkIcon />,
    //       isLinked: false,
    //     },
    //   ],
    // },
  ];

  return (
    <CardWithLoadingState loadingVariable={true}>
      <div className="py-1">
        {integrations.map((integration, integrationIndex) => (
          <div
            className={integrationIndex > 0 && "mt-4"}
            key={`integration-${integrationIndex}`}
          >
            <h2 className="h4">{integration.type}</h2>
            <div className="w-full grid-cols-3 gap-3 md:grid">
              {integration.services.map((service, serviceIndex) => (
                <div
                  className="mt-2"
                  key={`integration-${integrationIndex}-service-${serviceIndex}`}
                >
                  <div className="flex mb-1.5">
                    <div className="w-[24px]">{service.icon}</div>
                    <div className="ml-1.5">
                      <h3 className="font-medium h4">{service.name}</h3>
                      <div className="mt-1.5">
                        <ButtonOutlinedGreen
                          isSmall
                          onClick={() => handleClickService(service)}
                        >
                          <div className="flex items-center">
                            {service.isLinked && (
                              <div className="mr-0.5">
                                <CheckmarkIcon />
                              </div>
                            )}
                            {service.isLinked ? "Linked" : "Link"}
                          </div>
                        </ButtonOutlinedGreen>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </CardWithLoadingState>
  );
};

export default ConfigureIntegrations;
