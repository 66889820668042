import React from "react";
import { SearchIcon } from "../../../utils";
import { LoadingSpinner } from "../../common";

const SearchInput = ({
  searchQuery,
  onSearchChange,
  didFetchAllPages,
  placeholderTextWhenLoaded,
  placeholderTextWhenLoading,
}) => {
  return (
    <form className="flex justify-between items-center mt-0.5 mr-1.25">
      <div className="relative w-35">
        <input
          type="text"
          value={searchQuery}
          onChange={onSearchChange}
          disabled={!didFetchAllPages}
          placeholder={
            didFetchAllPages
              ? placeholderTextWhenLoaded
              : placeholderTextWhenLoading
          }
          className="placeholder:italic bg-transparent py-0.625 px-0.5 pr-8 border border-gray border-opacity-35 rounded-small text-h5 focus:outline-none w-full"
        />
        {!didFetchAllPages && (
          <div className="absolute left-12.5 mr-1 top-1/2 transform -translate-y-1/2 text-h5">
            <LoadingSpinner className={`h-1 w-1 mr-2`} />
          </div>
        )}
        <div className="absolute inset-y-0 right-1 flex items-center pointer-events-none border-l border-gray border-opacity-35 pl-0.625">
          <SearchIcon />
        </div>
      </div>
    </form>
  );
};

export default SearchInput;
