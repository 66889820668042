import React from "react";

import { OnboardingTemplate } from "../../../components";

const SetupSSOAndSCIM = () => {
  return (
    <OnboardingTemplate
      title="Setup SSO and SCIM"
      mainContent={
        <div>
          <div>
            <h3 className="h4">
              Set Up Your Identity and Access Management (IAM) Integration
            </h3>
          </div>
          <div className="mt-1">
            <p className="body text-gray-surface-secondary">
              Ensure Seamless and Secure access for your users by configuring
              Single Sign On
            </p>
          </div>
        </div>
      }
    />
  );
};

export default SetupSSOAndSCIM;
