import React from "react";
import { Translate } from "react-auto-translate";
import "./ciso-scrolling.css";
import { cisoAdvisoryCouncilQuotes } from "../../../utils/constants/website/ciso-scrolling";

function CisoScrollSection() {
  const infiniteQuotes = [
    ...cisoAdvisoryCouncilQuotes,
    ...cisoAdvisoryCouncilQuotes,
    ...cisoAdvisoryCouncilQuotes,
    ...cisoAdvisoryCouncilQuotes,
    ...cisoAdvisoryCouncilQuotes,
    ...cisoAdvisoryCouncilQuotes,
  ];

  return (
    <div className="ciso-scroll-section overflow-x-auto no-scrollbar w-full overflow-hidden relative py-1.25 px-0">
      <div className="flex flex-nowrap ciso-scroller">
        {infiniteQuotes.map((quotesItem, quotesIndex) => (
          <div
            className=" flex flex-col min-w-18.75 mr-1.25"
            key={`quotes-item-${quotesIndex}`}
          >
            {quotesItem.cisos.map((ciso, cisoIndex) => (
              <div
                className="ciso-item p-1 text-white bg-[#141414] mt-1 border border-gray border-opacity-35 rounded-small"
                key={`quotes-item-${quotesIndex}-ciso-${cisoIndex}`}
              >
                <p className="quote">
                  <Translate>{ciso.quote}</Translate>
                </p>
                <div className="ciso-info flex items-center mt-1">
                  <img
                    className="profile-picture rounded-small h-2.5 w-2.5"
                    src={ciso.profilePicture}
                    alt={ciso.name}
                  />
                  <div className="ml-0.75">
                    <span
                      role="heading"
                      aria-level="6"
                      className="font-semibold text-h6"
                    >
                      {ciso.name}
                    </span>
                    <span className="text-h6 block">
                      {ciso.title} | {ciso.companyName}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CisoScrollSection;
