import React from "react";

import { CircleFilledGreenIcon } from "../../../utils/icons";

const DotLabel = ({ icon = <CircleFilledGreenIcon />, label = "" }) => {
  return (
    <div className="max-w-[156px] w-full">
      <div className="flex items-center body">
        {icon}
        <span className="ml-[5px]">{label}</span>
      </div>
    </div>
  );
};

export default DotLabel;
