import React from "react";

import { Translate } from "react-auto-translate";

function Careers() {
  return (
    <div className="mt-1.875">
      <div
        className="flex flex-row justify-center py-[80px] px-0.625"
        style={{
          background:
            "url('https://d3oo9a669kwmx7.cloudfront.net/backgrounds/home-main-background.png')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full max-w-full md:max-w-website">
          <h1 className="h1">
            <Translate>Careers</Translate>
          </h1>
        </div>
      </div>
      <div className="px-0.625 mt-2.5">
        <div id="ashby_embed" />
      </div>
    </div>
  );
}

export default Careers;
