import React from "react";

const MicrosoftDefenderIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 37"
      fill="none"
    >
      <path
        d="M19.4049 0.436805C19.2641 0.433616 19.2184 0.457486 19.1995 0.508524V16.6684L35.1415 16.6108C35.2988 16.4194 35.3425 14.9712 35.3236 10.0014L35.3046 4.92907L34.8961 4.91641C30.1274 4.75692 27.5287 4.0812 24.641 2.24384C23.6785 1.63138 23.4837 1.52902 22.7728 1.23556C21.955 0.903809 21.0682 0.661483 20.1811 0.527509C19.7816 0.470091 19.5456 0.439995 19.4049 0.436805ZM16.7773 0.450868C16.6577 0.450868 16.2618 0.495352 15.897 0.559149C14.255 0.839858 13.0972 1.29914 11.6817 2.21782C10.197 3.18116 9.21532 3.64713 7.68657 4.10009C6.20814 4.54029 3.72348 4.86538 1.52157 4.91641L0.89227 4.92907L0.879614 10.0331C0.867032 13.695 0.885572 15.3541 0.942192 15.9027L1.01813 16.6684H16.9973V0.463524L16.7773 0.450868ZM34.707 18.8052C34.707 18.9584 19.2122 18.8502 19.2122 18.8502L19.2558 36.7961C19.3376 36.7961 19.5829 36.6626 20.3316 36.2224C20.47 36.1394 21.2001 35.6604 21.9551 35.1564C24.5345 33.4275 26.3838 31.9033 28.2586 29.9638C30.4291 27.7118 31.8765 25.7399 33.1221 23.3348C33.9274 21.7717 34.707 19.6284 34.707 18.9585V18.8052ZM16.9973 18.8376L1.52157 18.8692C1.47124 18.9585 1.58455 19.5455 1.77329 20.1644C2.42757 22.2761 3.69787 24.7259 5.15743 26.6845C6.82459 28.911 9.09572 31.201 11.7443 33.3318C13.4178 34.6716 16.5319 36.7321 16.8905 36.7321L16.9973 18.8376Z"
        fill="#0177D7"
      />
    </svg>
  );
};

export default MicrosoftDefenderIcon;
