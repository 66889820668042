import React from "react";

import { colors } from "../../../../../../../../styles";

function RedPlusIcon() {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M20 12.8571H20.5V12.3571V9.64286V9.14286H20H12.3571V1.5V1H11.8571H9.14286H8.64286V1.5V9.14286H1H0.5V9.64286V12.3571V12.8571H1H8.64286V20.5V21H9.14286H11.8571H12.3571V20.5V12.8571H20Z"
        fill={colors.red}
        stroke={colors.red}
      />
    </svg>
  );
}

export default RedPlusIcon;
