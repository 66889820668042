import React from "react";

const MicrosoftPurviewBrandMarkIcon = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 22"
      fill="none"
    >
      <g clip-path="url(#clip0_1674_19538)">
        <path
          d="M16.6819 10.6255C15.3373 10.6255 14.2466 9.79704 14.245 8.77454C12.603 8.78827 11.2863 9.81202 11.3044 11.0606C11.3225 12.3092 12.6687 13.3104 14.3107 13.2967C15.9526 13.2829 17.2694 12.2592 17.2513 11.0106C17.2491 10.867 17.2294 10.7242 17.1916 10.5835C17.0241 10.611 16.8533 10.6251 16.6819 10.6255Z"
          fill="url(#paint0_linear_1674_19538)"
        />
        <path
          d="M20.115 13.4032C20.006 12.588 19.6699 11.7995 19.1295 11.0905C18.9724 13.0269 16.864 14.5377 14.3121 14.541C14.1965 14.541 14.0881 14.5215 13.9754 14.5148C11.4213 14.4894 8.97396 13.73 7.12506 12.3895C5.53567 11.2283 4.35691 9.78074 3.69772 8.18121C2.82774 8.78239 2.00431 9.42104 1.23069 10.0938C0.676623 10.4839 0.642678 11.1417 1.15568 11.563C1.17977 11.583 1.20496 11.6018 1.23069 11.6201C2.89344 13.1363 4.85294 14.4499 7.03965 15.514C9.23895 16.6601 11.8428 17.2738 14.5092 17.2746C16.242 17.2821 17.9518 16.9728 19.4947 16.3733C19.5921 16.3392 19.6765 16.2934 19.7728 16.2592C20.1144 15.3312 20.2305 14.3628 20.115 13.4032Z"
          fill="url(#paint1_linear_1674_19538)"
        />
        <path
          d="M10.0423 12.5682C9.01626 11.1314 9.3798 9.35079 10.9303 8.21838C12.1485 6.97148 13.8556 6.05139 15.8063 5.59052C16.8696 5.34363 17.9733 5.21124 19.0848 5.1975C18.1901 4.90274 17.2539 4.68792 16.2947 4.55761C13.6673 4.22122 10.967 4.56011 8.62096 5.52099C7.30861 6.0389 6.06962 6.6584 4.9248 7.3699C5.46519 8.93862 6.5558 10.3662 8.08004 11.5003C8.66805 11.9212 9.32833 12.2805 10.0423 12.5682Z"
          fill="url(#paint2_linear_1674_19538)"
        />
        <path
          d="M27.4299 10.1448C25.9615 8.80719 24.2659 7.6244 22.3874 6.62688C20.423 6.21888 18.3496 6.22679 16.3907 6.64978C15.8027 6.78633 15.2338 6.96952 14.6973 7.19808C15.5366 7.24304 16.3458 7.45412 17.0444 7.8105C17.1068 7.82424 17.167 7.84464 17.2218 7.87087C19.5591 9.06448 21.1342 10.9471 21.5656 13.0629C21.7031 13.8839 21.6861 14.7149 21.5158 15.5321C23.7425 14.4559 25.7354 13.1237 27.4239 11.582C27.9473 11.2115 27.9768 10.5882 27.489 10.1902C27.4699 10.1744 27.4501 10.1594 27.4299 10.1448Z"
          fill="url(#paint3_linear_1674_19538)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1674_19538"
          x1="14.2778"
          y1="13.2968"
          x2="14.2778"
          y2="8.77454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#29BADE" />
          <stop offset="1" stop-color="#50E6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1674_19538"
          x1="10.4752"
          y1="17.2748"
          x2="10.4752"
          y2="4.41837"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.041" stop-color="#32BEDD" />
          <stop offset="0.495" stop-color="#50E6FF" />
          <stop offset="0.92" stop-color="#C3F1FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1674_19538"
          x1="12.0048"
          y1="12.0504"
          x2="12.0048"
          y2="0.861071"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.244" stop-color="#0078D4" />
          <stop offset="0.6" stop-color="#2E8CE1" />
          <stop offset="0.933" stop-color="#589EED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1674_19538"
          x1="21.2669"
          y1="17.2825"
          x2="21.2669"
          y2="5.36185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#005BA1" />
          <stop offset="0.399" stop-color="#0078D4" />
          <stop offset="0.857" stop-color="#5EA0EF" />
        </linearGradient>
        <clipPath id="clip0_1674_19538">
          <rect
            width="27.3749"
            height="20.8164"
            fill="white"
            transform="translate(0.625 0.445984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftPurviewBrandMarkIcon;
