import React from "react";

import { Tooltip } from "..";

const CardHeader = ({ header = "", tooltip = "" }) => {
  return (
    <div className="flex flex-row items-center mb-1.5">
      <h3 className="h4">{header}</h3>
      {tooltip && (
        <div className="flex items-center ml-0.25">
          <Tooltip text={tooltip} />
        </div>
      )}
    </div>
  );
};

export default CardHeader;
