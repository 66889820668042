import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonTextWhite } from "../buttons";

const Breadcrumbs = ({ pages }) => {
  const navigate = useNavigate();

  const handleClickPage = (path) => {
    navigate(path);
  };

  return (
    <nav className="-ml-0.5">
      <ul className="flex">
        {pages.map((page, index) => (
          <li className="flex items-center" key={`breadcrumb-${index}`}>
            {page.path ? (
              <ButtonTextWhite
                onClick={() => handleClickPage(page.path)}
                isSmall
              >
                {page.name}
              </ButtonTextWhite>
            ) : (
              <span className="description px-0.5 py-0.25">{page.name}</span>
            )}
            {index < pages.length - 1 && (
              <span className="description">{">"}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
