import React from "react";

const EmailIcon = ({ size = 21 }) => {
  return (
    <svg
      role="presentation"
      width={size}
      height={size}
      viewBox="0 0 21 17"
      fill="none"
    >
      <path
        d="M18.1602 0.0800781H2.16016C1.06016 0.0800781 0.170156 0.980078 0.170156 2.08008L0.160156 14.0801C0.160156 15.1801 1.06016 16.0801 2.16016 16.0801H18.1602C19.2602 16.0801 20.1602 15.1801 20.1602 14.0801V2.08008C20.1602 0.980078 19.2602 0.0800781 18.1602 0.0800781ZM17.7602 4.33008L10.6902 8.75008C10.3702 8.95008 9.95016 8.95008 9.63016 8.75008L2.56016 4.33008C2.45988 4.27379 2.37208 4.19774 2.30204 4.10653C2.23201 4.01532 2.18122 3.91086 2.15273 3.79945C2.12424 3.68804 2.11866 3.57201 2.13631 3.45838C2.15397 3.34476 2.19449 3.23589 2.25544 3.13838C2.31638 3.04087 2.39648 2.95673 2.49088 2.89107C2.58528 2.82541 2.69203 2.77959 2.80465 2.75638C2.91728 2.73316 3.03344 2.73304 3.14611 2.75603C3.25878 2.77901 3.36562 2.82461 3.46016 2.89008L10.1602 7.08008L16.8602 2.89008C16.9547 2.82461 17.0615 2.77901 17.1742 2.75603C17.2869 2.73304 17.403 2.73316 17.5157 2.75638C17.6283 2.77959 17.735 2.82541 17.8294 2.89107C17.9238 2.95673 18.0039 3.04087 18.0649 3.13838C18.1258 3.23589 18.1663 3.34476 18.184 3.45838C18.2017 3.57201 18.1961 3.68804 18.1676 3.79945C18.1391 3.91086 18.0883 4.01532 18.0183 4.10653C17.9482 4.19774 17.8604 4.27379 17.7602 4.33008Z"
        fill="white"
      />
    </svg>
  );
};

export default EmailIcon;
