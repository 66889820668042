import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FadeInSection } from "../../../components"; // Assuming ResourcesGroup isn't used anymore
import { Translate } from "react-auto-translate";

const ResourceInfographics = () => {
  const [infographics, setInfographics] = useState([]);

  const navigate = useNavigate();

  const redirectToInfographic = (referenceId) => {
    console.log("Redirecting to infographic with referenceId:", referenceId);
    if (referenceId) {
      navigate(`/resources/infographics/${referenceId}`);
    }
  };

  const baseURL = "https:";

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    client
      .getEntries({ content_type: "infographics" })
      .then((response) => {
        console.log("Infographics", response.items);
        setInfographics(response.items);
      })
      .catch((error) => {
        console.error("Error fetching infographics:", error);
      });
  }, []);

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "40px",
            md: "80px",
          }}
          padding="0 28px"
          sx={{
            color: "white",
          }}
        >
          <Box textAlign="center" maxWidth="800px" width="100%">
            <Typography
              role="heading"
              aria-level="1"
              component="h1"
              className="dune-text-header-uppercase"
            >
              <Translate>Resource Hub</Translate>
            </Typography>
            <Typography
              role="heading"
              aria-level="2"
              component="h2"
              className="dune-text-header-main"
              marginTop="40px"
            >
              <Translate>Infographics</Translate>
            </Typography>
          </Box>
        </Box>
      </FadeInSection>

      <div className="flex justify-center items-center h-full pt-5 text-white max-w-78.75 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3">
          {infographics.map((infographic) => (
            <div
              key={infographic.fields.displayName}
              className="p-3 bg-gray-800 rounded-md shadow-md cursor-pointer"
              onClick={() =>
                redirectToInfographic(infographic.fields.referenceId)
              }
            >
              <div className="flex justify-center items-center overflow-hidden">
                <img
                  src={`${baseURL}${infographic.fields.infographic.fields.file.url}`}
                  alt={infographic.title}
                  className="w-40 h-40 object-cover transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              <div
                role="heading"
                aria-level="4"
                className="w-full mx-auto text-h4 hover:text-gray transform ease-in duration-300 cursor-pointer mb-2 mt-1 text-center items-center"
              >
                {infographic.fields.displayName}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ResourceInfographics;
