import React from "react";

import DotLabel from "../dot-label";

import { CircleFilledOrangeIcon } from "../../../../utils/icons";

const DotLabelOrange = ({ label = "" }) => {
  return <DotLabel icon={<CircleFilledOrangeIcon />} label={label} />;
};

export default DotLabelOrange;
