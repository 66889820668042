import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const formatAreasToImproveDepartment = (areasToImprove) => {
  if (!areasToImprove) {
    return [];
  }

  const formattedAreasToImproveDepartment = areasToImprove
    .sort((a, b) => b.count - a.count) // Sort by value (descending).
    .slice(0, 6) // Limit to top 6.
    .map(([name, count]) => ({ name: capitalizeFirstWords(name), count })); // Map to the desired format.

  // Calculate the percentages of the top 6 areas to improve and add value for the green circle in the radar chart.
  const totalCount = formattedAreasToImproveDepartment.reduce(
    (acc, obj) => acc + obj.count,
    0,
  );
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < formattedAreasToImproveDepartment.length; i++) {
    formattedAreasToImproveDepartment[i].percentage =
      formattedAreasToImproveDepartment[i].count / totalCount;
    formattedAreasToImproveDepartment[i].letter = alphabet[i % alphabet.length];
  }

  return formattedAreasToImproveDepartment;
};

export default formatAreasToImproveDepartment;
