import React from "react";

const DuneSecurityLogo = () => {
  return (
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      width="144px"
      height="144px"
      viewBox="0 0 145 145"
      fill="none"
    >
      <path
        d="M0.0654297 144.575V49.4247C33.1224 52.1929 51.3917 64.12 83.6862 97C102.965 117.148 115.029 126.766 137.718 137.779H144.15V144.575H0.0654297Z"
        fill="#00FF82"
      />
      <path
        d="M144.15 0.490234V95.6407C111.093 92.8725 92.8241 80.9454 60.5296 48.0655C41.2508 27.9174 29.1867 18.2995 6.49779 7.28669H0.0654297V0.490234H144.15Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default DuneSecurityLogo;
