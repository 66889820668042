import React from "react";

const ProofpointIcon = () => {
  return (
    <svg
      role="presentation"
      width="42"
      height="15"
      viewBox="0 0 39 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_232_23005)">
        <path
          d="M35.3944 6.10398V5.34536H35.1381C34.8585 5.34536 34.7944 5.30387 34.7944 5.10237V3.01617H35.4002V2.3109H34.7944V1.28558H33.7575V2.3109H33.2391V3.01617H33.7575V5.04903C33.7575 5.83728 33.9439 6.12176 34.8061 6.12176C34.9109 6.12768 35.109 6.1099 35.3944 6.10398Z"
          fill="white"
        />
        <path
          d="M29.4411 6.08002H30.4896V3.84566C30.4896 3.34782 30.8042 3.05148 31.2935 3.05148C31.742 3.05148 31.8993 3.29448 31.8993 3.80417V6.08002H32.9478V3.51376C32.9478 2.64847 32.5226 2.21582 31.6605 2.21582C31.1654 2.21582 30.7693 2.39955 30.4722 2.76108V2.31657H29.447V6.08002H29.4411Z"
          fill="white"
        />
        <path
          d="M28.8004 0.982941H27.7519V1.89565H28.8004V0.982941Z"
          fill="white"
        />
        <path
          d="M28.8004 2.31125H27.7519V6.07469H28.8004V2.31125Z"
          fill="white"
        />
        <path
          d="M24.4432 4.18914C24.4432 3.43646 24.781 3.02751 25.3636 3.02751C25.9461 3.02751 26.2781 3.43646 26.2781 4.18914C26.2781 4.94183 25.9461 5.3567 25.3636 5.3567C24.781 5.3567 24.4432 4.94183 24.4432 4.18914ZM23.383 4.18914C23.383 5.4456 24.1345 6.20422 25.3636 6.20422C26.5927 6.20422 27.3441 5.4456 27.3441 4.18914C27.3441 2.93269 26.5927 2.17407 25.3636 2.17407C24.1345 2.17407 23.383 2.93269 23.383 4.18914Z"
          fill="white"
        />
        <path
          d="M22.0082 4.21993C22.0082 4.91928 21.6762 5.33415 21.1636 5.33415C20.6102 5.33415 20.2898 4.93706 20.2898 4.21993C20.2898 3.44946 20.5927 3.05238 21.1461 3.05238C21.6879 3.05238 22.0082 3.45539 22.0082 4.21993ZM19.2646 7.58629H20.284V5.60678C20.5403 5.99794 20.9422 6.19945 21.4432 6.19945C22.3577 6.19945 23.0509 5.4112 23.0509 4.17252C23.0509 2.9694 22.3752 2.18115 21.4316 2.18115C20.9248 2.18115 20.5694 2.38266 20.2665 2.82124V2.31154H19.2704V7.58629H19.2646Z"
          fill="white"
        />
        <path
          d="M17.1385 6.08106H18.1637V3.02289H18.8103V2.31761H18.1521V2.05684C18.1521 1.81384 18.2453 1.77236 18.5365 1.77236H18.8103V0.972254C18.6239 0.9604 18.4666 0.948547 18.3094 0.948547C17.5055 0.948547 17.1385 1.26266 17.1385 1.96794V2.31761H16.5793V3.02289H17.1385V6.08106Z"
          fill="white"
        />
        <path
          d="M13.6143 4.18914C13.6143 3.43646 13.9522 3.02751 14.5347 3.02751C15.1172 3.02751 15.4492 3.43646 15.4492 4.18914C15.4492 4.94183 15.1172 5.3567 14.5347 5.3567C13.9522 5.36263 13.6143 4.94183 13.6143 4.18914ZM12.5542 4.18914C12.5542 5.4456 13.3056 6.20422 14.5347 6.20422C15.7638 6.20422 16.5152 5.4456 16.5152 4.18914C16.5152 2.93269 15.7638 2.17407 14.5347 2.17407C13.2998 2.18 12.5483 2.93861 12.5542 4.18914Z"
          fill="white"
        />
        <path
          d="M9.40865 4.18914C9.40865 3.43646 9.7465 3.02751 10.329 3.02751C10.9115 3.02751 11.2436 3.43646 11.2436 4.18914C11.2436 4.94183 10.9115 5.3567 10.329 5.3567C9.7465 5.3567 9.40865 4.94183 9.40865 4.18914ZM8.34848 4.18914C8.34848 5.4456 9.09992 6.20422 10.329 6.20422C11.5581 6.20422 12.3095 5.4456 12.3095 4.18914C12.3095 2.93269 11.5581 2.17407 10.329 2.17407C9.09992 2.17407 8.34848 2.93861 8.34848 4.18914Z"
          fill="white"
        />
        <path
          d="M5.97763 6.08082H7.02615V4.08945C7.02615 3.52049 7.32323 3.23601 7.88826 3.23601H8.23194V2.21662C8.17952 2.21069 8.12709 2.21069 8.07467 2.21069C7.57371 2.21069 7.21838 2.44183 6.9446 2.96338V2.31737H5.97763V6.08082Z"
          fill="white"
        />
        <path
          d="M4.51554 4.21993C4.51554 4.91928 4.18351 5.33415 3.6709 5.33415C3.11751 5.33415 2.79713 4.93706 2.79713 4.21993C2.79713 3.44946 3.10004 3.05238 3.65342 3.05238C4.20681 3.05238 4.52136 3.45539 4.51554 4.21993ZM1.77774 7.58629H2.79131V5.60678C3.04761 5.99794 3.44954 6.19945 3.9505 6.19945C4.86504 6.19945 5.55823 5.4112 5.55823 4.17252C5.55823 2.9694 4.88252 2.18115 3.93885 2.18115C3.43207 2.18115 3.07674 2.38266 2.77383 2.82124V2.31154H1.77774V7.58629Z"
          fill="white"
        />
        <path
          d="M36.7266 5.76494C36.7324 5.99551 36.5581 6.18469 36.3315 6.1906C36.1049 6.19652 35.9189 6.01916 35.9131 5.78859C35.9073 5.55803 36.0816 5.36884 36.3082 5.36293C36.314 5.36293 36.3198 5.36293 36.3257 5.36293C36.5407 5.35702 36.7208 5.54029 36.7266 5.76494ZM36.0119 5.76494C36.0061 5.93639 36.1397 6.08419 36.3082 6.0901C36.314 6.0901 36.314 6.0901 36.3198 6.0901C36.4884 6.0901 36.622 5.94821 36.622 5.78268C36.622 5.77677 36.622 5.77677 36.622 5.77086C36.6278 5.59941 36.5 5.45161 36.3315 5.4457C36.163 5.43979 36.0177 5.56985 36.0119 5.7413C36.0119 5.74721 36.0119 5.75903 36.0119 5.76494ZM36.2559 5.97777H36.163V5.57576C36.2153 5.56394 36.2617 5.56394 36.314 5.56394C36.3605 5.55803 36.407 5.56985 36.4535 5.5935C36.4767 5.61715 36.4942 5.6467 36.4942 5.68218C36.4884 5.72947 36.4593 5.76494 36.4128 5.77677V5.78268C36.4535 5.80042 36.4825 5.83589 36.4825 5.87727C36.4884 5.91274 36.4942 5.94821 36.5116 5.97777H36.4128C36.3954 5.94821 36.3896 5.91274 36.3838 5.87727C36.378 5.82998 36.3547 5.81224 36.3024 5.81224H36.2559V5.97777ZM36.2559 5.74721H36.3024C36.3547 5.74721 36.3954 5.72947 36.3954 5.68809C36.3954 5.64671 36.3663 5.62897 36.3082 5.62897C36.2908 5.62897 36.2734 5.62897 36.2559 5.63488V5.74721Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_232_23005">
          <rect
            width="37.9796"
            height="6.57862"
            fill="white"
            transform="translate(0.577759 0.978546)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProofpointIcon;
