import React from "react";

import DotLabel from "../dot-label";

import { CircleFilledGreenIcon } from "../../../../utils/icons";

const DotLabelGreen = ({ label = "" }) => {
  return <DotLabel icon={<CircleFilledGreenIcon />} label={label} />;
};

export default DotLabelGreen;
