import React, { useEffect, useState } from "react";

import { getDepartments, postRequestNewCustomAsset } from "../../../api";

import {
  ButtonOutlinedGreen,
  FileUpload,
  LoadingState,
  MultiSelect,
  TextField,
} from "../../../components";

import { CustomTwoColumn } from "../../../components/platform/configure/utils";

const RequestNewAsset = ({ isInitialLoading, email, setBreadcrumbs }) => {
  // Form input
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedTestFile, setSelectedTestFile] = useState([]);
  const [selectedAttachmentFiles, setSelectedAttachmentFiles] = useState([]);

  // Fetched data
  const [departments, setDepartments] = useState(null);

  // Messages
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangeDepartment = (selected) => {
    const isOptionSelected = selectedDepartments.some(
      (department) => department.value === selected.value,
    );

    if (isOptionSelected) {
      setSelectedDepartments(
        selectedDepartments.filter(
          (department) => department.value !== selected.value,
        ),
      );
    } else {
      setSelectedDepartments([...selectedDepartments, selected]);
    }
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleClickSubmitRequest = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    if (
      !description.trim() &&
      selectedTestFile.length === 0 &&
      selectedAttachmentFiles.length === 0
    ) {
      setErrorMessage("Enter a description or upload a file.");
      return;
    }

    if (selectedDepartments.length === 0) {
      setErrorMessage("Select at least one department.");
      return;
    }

    const form = new FormData();

    form.append(
      "department",
      selectedDepartments.map((department) => department.value).join(","),
    );

    if (description.trim()) {
      form.append("text", description);
    }

    if (selectedTestFile.length > 0) {
      form.append("file", selectedTestFile[0]);
    }

    selectedAttachmentFiles.forEach((file) => {
      form.append("file", file); // For additional files
    });

    try {
      const response = await postRequestNewCustomAsset(form);

      console.log(response);

      setSuccessMessage("Your request has been sent successfully.");
    } catch (error) {
      console.error(error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const loadDepartments = async () => {
      const departmentsData = await getDepartments();

      if (Object.keys(departmentsData.error).length > 0) {
        console.error(departmentsData.error.message);
      } else {
        const { departments } = departmentsData.result;

        setDepartments(departments);
      }
    };

    if (!isInitialLoading && email) {
      loadDepartments();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      // Update breadcrumbs last.
      setBreadcrumbs([
        {
          name: "Testing",
          path: "/configure/testing/",
        },
        {
          name: "Configure Testing",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  if (departments === null) {
    return <LoadingState message="Loading" />;
  }

  return (
    <div>
      {departments && departments.length > 0 && (
        <CustomTwoColumn
          firstColumn={
            <>
              <p className="body">Select Departments*</p>
              <p className="description mt-0.25">
                Which departments will this test apply to?
                <br />
                (You can select multiple)
              </p>
            </>
          }
          secondColumn={
            <MultiSelect
              value={selectedDepartments}
              options={departments.map((department) => ({
                name: department.departmentName,
                value: department.departmentName,
              }))}
              defaultValue="Select Departments"
              onChange={handleChangeDepartment}
            />
          }
        />
      )}
      <CustomTwoColumn
        firstColumn={
          <>
            <p className="body">Test Description*</p>
            <p className="description mt-0.25">
              Provide a brief description of the test you need. <br />
              (Max 1,500 characters)
            </p>
          </>
        }
        secondColumn={
          <TextField
            value={description}
            onChangeValue={handleChangeDescription}
            multiline
            rows={10}
            maxLength={1500}
            placeholder={`Provide a brief description of the test you need. For example: "Pretend to be the company's benefits team, asking employees to verify personal information for health insurance updates."`}
          />
        }
      />
      <CustomTwoColumn
        firstColumn={
          <>
            <p className="body">Test</p>
            <p className="description mt-0.25">
              Already have an HTML or EML file of the phishing test? Upload it
              here.
              <br />
              (Max file size: 10 MB)
            </p>
          </>
        }
        secondColumn={
          <FileUpload
            selectedFiles={selectedTestFile}
            setSelectedFiles={setSelectedTestFile}
            fileType="HTML"
            accept={{
              "text/html": [".html", ".htm"],
            }}
          />
        }
      />
      <CustomTwoColumn
        firstColumn={
          <>
            <p className="body">Additional Attachments</p>
            <p className="description mt-0.25">
              Have any inspiration or reference materials like previous phishing
              tests, websites, or email screenshots? Upload them here to help us
              build your test.
              <br />
              (PNG, JPEG, max file size: 10 MB)
            </p>
          </>
        }
        secondColumn={
          <FileUpload
            selectedFiles={selectedAttachmentFiles}
            setSelectedFiles={setSelectedAttachmentFiles}
            multiple
            fileType="PNG, JPEG"
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "image/svg+xml": [".svg"],
            }}
          />
        }
      />
      <div className="flex flex-col-reverse mt-2 md:flex-row md:justify-between">
        <div className="mt-1 md:mt-0">
          {successMessage && (
            <p className="body text-green">{successMessage}</p>
          )}
          {errorMessage && !successMessage && (
            <p className="body text-red">{errorMessage}</p>
          )}
        </div>
        <div>
          <ButtonOutlinedGreen
            title="Submit request."
            onClick={handleClickSubmitRequest}
          >
            Submit Request
          </ButtonOutlinedGreen>
        </div>
      </div>
    </div>
  );
};

export default RequestNewAsset;
